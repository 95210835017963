import React, { useMemo } from "react";
import { Button, Typography } from "@mui/material";
import { safe_get } from "../../report-visuals";
import { CMTable } from "../../components";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import { AddCircle } from "@mui/icons-material";

export const FiTable = observer(({ table_data, onAdd }: any) => {
  const rootStore = useStore();
  const { fieldIntelligenceStore } = rootStore;
  const { activeNode } = fieldIntelligenceStore;

  const title = safe_get(table_data, "title", "");

  const cols = table_data["cols"].map((col) => {
    return {
      Header: col,
      accessor: col,
    };
  });

  const colx = useMemo(() => {
    return table_data["cols"].map((col) => {
      return {
        Header: col,
        accessor: col,
      };
    });
  }, [activeNode["data"], table_data]);

  return (
    <>
      <Typography>{title}</Typography>
      <CMTable
        data={safe_get(table_data, "data", [])}
        columns={colx}
        pagesize={15}
        show_action_buttons={true}
        onRowClick={() => {}}
        hideFooter={true}
        onEdit={async (row) => {
          console.log(row);
          fieldIntelligenceStore.update_FiStoreProp("addNewModal", true);
          await fieldIntelligenceStore.update_FiStoreProp(
            "activeNode",
            table_data,
          );
          await fieldIntelligenceStore.update_FiStoreProp("activeRow", row);
        }}
        onDelete={() => {
          console.log("Delete");
        }}
      />
      <div className={"flex justify-end"}>
        <Button
          className={""}
          variant="contained"
          endIcon={<AddCircle />}
          onClick={() => {
            fieldIntelligenceStore.update_FiStoreProp("addNewModal", true);
            fieldIntelligenceStore.update_FiStoreProp("activeNode", table_data);
            onAdd(table_data);
          }}
        >
          Add {title}
        </Button>
      </div>
    </>
  );
});
