import React from "react";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../../helpers";
import { useMutation } from "@tanstack/react-query";
import { FTPTheme } from "../../ftp-theme";
import { Vortex } from "react-loader-spinner";

export const LoginButton = ({ otp, phone, disabled }) => {
  const rootStore = useStore();
  const { userStore } = rootStore;
  const { t } = useTranslation(["new_login"]);
  const navigate = useNavigate();

  const location = useLocation();
  const redirectPath = location.state?.path || "/";

  const { mutate: validateOTP, isLoading } = useMutation({
    mutationFn: async () => {
      return await userStore.validateOTP("+91" + phone, otp);
    },
    onSuccess: (response) => {
      if (response) {
        navigate("/", { replace: true });
        toast.success("Login successful!");
      } else {
        toast.error("Invalid OTP");
      }
    },
    onError: () => {
      toast.error("An error occurred while validating OTP.");
    },
  });

  const handleLogin = () => {
    if (!phone || !otp) {
      toast.error("Please enter OTP to login");
      return;
    }
    validateOTP();
  };

  return (
    <>
      <div className="mt-6">
        <Button
          onClick={handleLogin}
          type="submit"
          size={"large"}
          variant={"contained"}
          fullWidth
          loading={isLoading}
          loadingPosition={"start"}
          className={"flex self-end"}
          loadingIndicator={
            <Vortex
              height="30"
              width="30"
              ariaLabel="vortex-loading"
              wrapperClass="vortex-wrapper"
              colors={["red", "green", "blue", "yellow", "orange", "purple"]}
            />
          }
          disabled={disabled}
          sx={{
            backgroundColor: FTPTheme.palette.common.black,
            color: "white",

            opacity: disabled ? 0.5 : 1,
            pointerEvents: disabled ? "none" : "auto",
            transition: "opacity 0.3s ease-in-out",
            "&:hover": {
              backgroundColor: disabled
                ? FTPTheme.palette.common.black
                : "#f4ba40",
            },
          }}
        >
          {t("login")}
        </Button>
      </div>
    </>
  );
};
