import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Button, Stack, Typography } from "@mui/material";
import { FilterButton, LoadingState, ProjectHeader } from "../../components";
import { RNSearchBar } from "./il-search";
import { InfluentialLeadersList } from "./il-list";
import { useStore } from "../../helpers";
import { AddInfluentialLeader } from "./add-influential-leader";
import { defaultIlFilters, defaultILValues } from "./il-types";
import { ILFilters } from "./il-filters";
import { useGetFullProject } from "../../custom-hooks";
import { ILBCrumbs } from "./il-bread-crumbs";

const InfluentialLeadersHome = observer((props: any) => {
  const rootStore = useStore();
  const { influentialLeadersStore } = rootStore;
  const { isLoading } = useGetFullProject();

  useEffect(() => {
    //TODO: reset the query key in store, when this component unmounts.
    return () => {
      influentialLeadersStore.updateIlStoreProperty("searchQuery", "");
      influentialLeadersStore.updateIlStoreProperty(
        "activeInfluentialLeader",
        defaultILValues,
      );
      influentialLeadersStore.updateIlStoreProperty(
        "ilFilters",
        defaultIlFilters,
      );
      influentialLeadersStore.updateIlStoreProperty("offset", 1);
    };
  }, []);

  if (isLoading) return <LoadingState />;
  return (
    <>
      <ILBCrumbs />
      <Stack
        className="p-4 overflow-hidden primary-text"
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography sx={{ fontFamily: "Palanquin" }} variant={"h4"}>
          Influential Leaders
        </Typography>
        <Stack
          direction={"row"}
          gap={4}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <RNSearchBar
            onSearch={(query) => {
              influentialLeadersStore.updateIlStoreProperty(
                "searchQuery",
                query,
              );
            }}
          />
          <FilterButton
            filterOnClickHandler={() =>
              influentialLeadersStore.updateIlStoreProperty(
                "ilFiltersModal",
                true,
              )
            }
          />
          <Button
            endIcon={<i className={"fas fa-circle-plus"} />}
            variant={"contained"}
            onClick={() => {
              rootStore.influentialLeadersStore.updateIlStoreProperty(
                "addNewILModal",
                true,
              );
            }}
          >
            Add New
          </Button>
        </Stack>
      </Stack>
      <Stack className={"px-4 overflow-x-hidden"}>
        <ILFilters />
        <InfluentialLeadersList />
        <AddInfluentialLeader />
      </Stack>
    </>
  );
});

export default InfluentialLeadersHome;
