import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useStore, htmlValidation } from "../helpers";
import { useTranslation } from "react-i18next";
import { LoadingState } from "../components";
import { FTPBreadCrumbs } from "../components-new";

const crumbs = [{ name: "Settings", href: "#", current: true }];

const RootSettings = observer((props) => {
  const rootStore = useStore();

  const [actionInProgress, setActionInProgress] = useState(false);
  const [appVersion, setAppVersion] = useState("20230406 01");
  const [editAppVersion, setEditAppVersion] = useState(true);
  const [t] = useTranslation("settings");

  const fireAndForgetAction = async (path: string) => {
    setActionInProgress(true);
    await rootStore.fireAndForgetAction(path);
    setActionInProgress(false);
  };

  return (
    <>
      {actionInProgress ? <LoadingState /> : null}
      <section className=" ">
        <div className="flex flex-row">
          <h2 className="text-lg font-bold">{t("update_app_version")}</h2>
          <i
            className="fas fa-pencil text-indigo-600 px-4 cursor-pointer"
            onClick={(e) => setEditAppVersion(!editAppVersion)}
          />
        </div>
        <div className="flex flex-row items-center">
          <input
            type={"text"}
            className="p-2 rounded-lg border-1 border-gray-300 font-bold"
            value={appVersion}
            onChange={(e) => setAppVersion(e.target.value)}
            disabled={editAppVersion}
          />
          {!editAppVersion && (
            <button
              type="button"
              className="rounded-md bg-indigo-600 mx-4 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              onClick={async (e) => {}}
            >
              {t("update")}
            </button>
          )}
        </div>
      </section>
      <section className="mt-5 flex flex-col items-start">
        <h2 className="text-lg font-bold">{t("manage_redis_cache_server")}</h2>
        <a
          href="#"
          onClick={async (e) => {
            htmlValidation(e);
            fireAndForgetAction("cache/flushall");
          }}
          className="mt-2  ml-2 cursor-pointer text-blue-600"
        >
          <span>{t("flush")}</span>
        </a>
      </section>

      <section className="mt-5">
        <h2 className="text-lg font-bold text-left">
          {t("manage_permissions_for_root")}
        </h2>
        <div className="flex flex-col items-start">
          <div className="mt-2 ml-2">
            <a
              href="#"
              onClick={(e) =>
                fireAndForgetAction("utils/assignallprojectstoroot")
              }
              className="mt-2 cursor-pointer text-blue-600"
            >
              <span>{t("projects")}</span>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div className="mt-2  ml-2">
            <a
              href="#"
              onClick={(e) =>
                fireAndForgetAction("utils/assignallreportstoroot")
              }
              className="mt-2 cursor-pointer text-blue-600"
            >
              <span>{t("reports")}</span>
            </a>
          </div>
        </div>
        <div className="flex flex-col items-start">
          <div className="mt-2  ml-2">
            <a
              href="#"
              onClick={(e) =>
                fireAndForgetAction("utils/assignallflexistoroot")
              }
              className="mt-2 cursor-pointer text-blue-600"
            >
              <span>{t("databank")}</span>
            </a>
          </div>
        </div>
      </section>
    </>
  );
});

const SettingsPage = observer((props) => {
  const rootStore = useStore();
  const { userStore } = rootStore;
  return (
    <>
      <FTPBreadCrumbs crumbs={crumbs} />
      <div className="p-4">{userStore.isRoot() ? <RootSettings /> : null}</div>
    </>
  );
});

export default SettingsPage;
