import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";
import { useStore } from "../../helpers";
import { useParams } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { safe_get } from "../../report-visuals";
import { C3Header, VisualHeader } from "./c3-beneficiaries";
import { theme } from "../../pages";
import Select from "react-select";
import { CMTable, LoadingState, DonutChart3d } from "../../components";

const priority_colors = {
  High: "#E67E22",
  Medium: "#9DFF00",
  Low: "#89CFF0",
};
export const getIcon = (status, config) => {
  const item = config.find((x) => x.status === status);
  const icon = safe_get(item, "icon", "fas fa-list");
  return <i className={`${icon} h-6 w-6 text-white`} aria-hidden="true" />;
};

const C3Grievance = observer((props: any) => {
  const rootStore = useStore();
  const { c3Store, projectStore } = rootStore;
  const { projectid } = useParams();
  const {
    date_filetrs: { start_date, end_date },
    selected_booths,
    selected_office_persons,
  } = c3Store;
  const propertyValue = projectStore.getProjectProperty("grievances", {});
  const status_config = safe_get(propertyValue, "status_config", []);

  const fetchData = async () => {
    const payload = {
      projectid: parseInt(projectid),
      selected_office_persons,
      start_date: start_date,
      end_date: end_date,
      booths: selected_booths,
    };
    return await c3Store.get_grieviance_summary(payload);
  };

  const { data, isLoading, error } = useQuery({
    queryKey: [
      "grievancies",
      projectid,
      c3Store.date_filetrs,
      selected_booths,
      selected_office_persons,
    ],
    queryFn: fetchData,
  });
  console.log(data);

  const summary = safe_get(data, "data.summary", {});
  const by_priority = safe_get(data, "data.by_priority", []);
  const by_complaint = safe_get(data, "data.by_complaint", []);
  const by_department = safe_get(data, "data.by_department", []);
  const by_authority = safe_get(data, "data.by_authority", []);

  const getStatusCols = () => {
    const total = {
      Header: "Total",
      accessor: "Total",
    };
    const complaint_cols =
      status_config.length > 0
        ? status_config.map((x) => {
            return {
              Header: x.status,
              accessor: x.status,
            };
          })
        : [];

    return [...complaint_cols, total];
  };

  const columns = useMemo(() => {
    const department = {
      Header: "Department",
      accessor: "department",
    };
    const statusCols = getStatusCols();
    return [department, ...statusCols];
  }, [data]);

  const by_authority_cols = useMemo(() => {
    const statusCols = getStatusCols();

    return [
      {
        Header: "Authority Name",
        accessor: "authority_name",
      },
      {
        Header: "Department",
        accessor: "deptartment",
      },
      ...statusCols,
    ];
  }, [data]);

  const summary_donut = useMemo(() => {
    return Object.keys(summary).map((x) => {
      if (x === "Total" || x === "count") return;
      const y = status_config.find((q) => q["status"] === x);
      return {
        label: x,
        value: summary[x],
        color: safe_get(y, "color", ""),
      };
    });
  }, [summary]);

  const department_dropdown_values = by_complaint.map((x) => {
    return {
      label: x.dept,
      value: x.dept,
    };
  });

  const [selectedDepartment, setSelectedDepartment] = useState(
    department_dropdown_values[0]
  );

  const [complaintsByDept, setComplaintsByDept] = useState([]);

  useEffect(() => {
    const get_val = safe_get(selectedDepartment, "value", "");
    const filtered_dept = by_complaint.find((x) => x.dept === get_val);
    setComplaintsByDept(safe_get(filtered_dept, "complaints", []));
  }, [selectedDepartment]);

  const by_complaint_cols = useMemo(() => {
    const complaint_type = {
      Header: "Complaint Type",
      accessor: "department",
    };
    const statusCols = getStatusCols();

    return [complaint_type, ...statusCols];
  }, [data, complaintsByDept, status_config]);

  const priority_data = useMemo(() => {
    return by_priority.map((x) => {
      return {
        ...x,
        color: priority_colors[x["priority"]],
      };
    });
  }, [by_priority]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (data.status !== 200) {
    return (
      <div className={"font-bold text-xl p-4 italic text-gray-500"}>
        Grievance data is not available for this project.
      </div>
    );
  }

  return (
    <div className={"overflow-hidden"}>
      <C3Header
        title={"Summary Dashboard: Grievance Management"}
        start_date={start_date}
        end_date={end_date}
      />

      <div>
        <VisualHeader title="Summary" />
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
          {Object.keys(summary).map((item) => {
            const index = status_config.findIndex((x) => x.status === item);
            if (index === -1) return;
            if (item === "count" && summary[item] === 0) return;
            return (
              <div
                key={item}
                className="relative overflow-hidden rounded-lg bg-gray-100 px-4 py-5 shadow"
              >
                <dt>
                  <div
                    className="absolute rounded-md p-3"
                    style={{ backgroundColor: theme }}
                  >
                    {getIcon(item, status_config)}
                  </div>
                  <p className="ml-16 truncate text-sm font-medium text-gray-500">
                    {item}
                  </p>
                </dt>
                <dd className="ml-16 flex items-baseline pb-4">
                  <p className="text-2xl font-semibold text-gray-900">
                    {summary[item]}
                  </p>
                </dd>
              </div>
            );
          })}
        </dl>
      </div>

      <div className="flex flex-col gap-y-5">
        <DonutChart3d
          data={summary_donut}
          _label="label"
          value="value"
          title={"Summary"}
        />
        <div>
          <VisualHeader title={"Department"} />
          <CMTable
            columns={columns}
            data={by_department}
            onRowClick={() => {}}
            pagesize={10}
          />
        </div>

        <DonutChart3d
          data={priority_data}
          _label="priority"
          value="count"
          title="Priority"
        />

        <div>
          <VisualHeader title={"Complaint"} />
          <p className={"italic py-2 text-sm text-gray-400"}>
            * Select the Department
          </p>
          <Select
            className="mt-2 w-full sm:w-1/4"
            options={department_dropdown_values}
            onChange={(val) => {
              setSelectedDepartment(val);
            }}
            defaultValue={selectedDepartment}
          />
          {status_config.length > 0 ? (
            <CMTable
              data={complaintsByDept}
              columns={by_complaint_cols}
              onRowClick={() => {}}
              pagesize={10}
            />
          ) : null}
        </div>

        <div>
          <VisualHeader title={"Authority"} />
          <CMTable
            columns={by_authority_cols}
            data={by_authority}
            onRowClick={() => {}}
            pagesize={10}
          />
        </div>
      </div>
    </div>
  );
});

export default C3Grievance;
