import { action, makeAutoObservable } from "mobx";
import {
  defaultFilterTypes,
  filterTypes,
  projectWorkSummaryDefaultValues,
  projectWorkSummaryType,
} from "../../features/project-works-v2";
import { PWV2Props } from "./data";

export class ProjectWorkStoreV2 {
  rootStore: any;
  projectWorksList = [];
  add_project_modal: boolean = false;
  delete_project_modal: boolean = false;
  edit_pw_modal: boolean = false;
  add_media_modal: boolean = false;
  edit_media_modal: boolean = false;
  add_fund_modal: boolean = false;
  add_event_modal: boolean = false;
  add_key_contacts: boolean = false;
  cover_photo_path: string = "";
  searchQuery: string = "";
  filters: filterTypes = defaultFilterTypes;
  activeProjectWork: any = {};
  activeMediaModal: any = {};
  activeFundModal: any = {};
  projectWorkFilterModal: boolean = false;
  activeProject: any = {};
  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }
  @action
  setProjectWork = (projectWork) => {
    this.activeProject = projectWork;
  };
  @action
  resetFilters = () => {
    this.filters = {
      villages: [],
      status: [],
      start_date: "",
      end_date: "",
    };
  };

  updateFilters(filters: filterTypes) {
    this.filters = filters;
  }
  toggleProjectWorkFilterModal = () => {
    this.projectWorkFilterModal = !this.projectWorkFilterModal;
  };
  async getProjectWorks(payload, id) {
    return await this.rootStore.api.post("projectworks/search", payload, {
      id: parseInt(id),
    });
  }

  //TODO:2
  @action
  update_add_project_modal = (val) => {
    this.add_project_modal = val;
  };

  @action
  add_project_work = async (payload) => {
    return await this.rootStore.api.post("projectworks/add", payload);
  };

  @action
  edit_project_work = async (payload) => {
    return await this.rootStore.api.post("projectworks/update", payload);
  };

  @action
  async delete_project_work(id) {
    return await this.rootStore.api.post("projectworks/delete", { id: id });
  }

  @action
  update_project_work_property = (key: PWV2Props, value: any) => {
    switch (key) {
      case "set_project_works":
        this.projectWorksList = value;
        break;
      case "activeProjectWork":
        this.activeProjectWork = value;
        break;
      case "activeMediaModal":
        this.activeMediaModal = value;
        break;
      case "activeFundModal":
        this.activeFundModal = value;
        break;
      case "cover_photo":
        this.cover_photo_path = value;
        break;
      case "delete_modal":
        this.delete_project_modal = value;
        break;
      case "edit_pw_modal":
        this.edit_pw_modal = value;
        break;
      case "add_media_modal":
        this.add_media_modal = value;
        break;

      case "edit_media_modal":
        this.edit_media_modal = value;
        break;
      case "add_fund_modal":
        this.add_fund_modal = value;
        break;
      case "searchQuery":
        this.searchQuery = value;
        break;
      case "add_event_modal":
        this.add_event_modal = value;
        break;
      case "add_key_contacts":
        this.add_key_contacts = value;
        break;
    }
  };
}
