import * as Yup from "yup";

export const defaultAssignee = {
  assignee: {
    name: "",
    phone: "",
    position: "",
    department: "",
    description: "",
  },
  profile_pic: "",
  is_office_person: false,
  office_person: {
    id: 0,
    name: "",
    phonenum: "",
  },
};
export const AddAssigneeValidationSchema = (isChecked: boolean) =>
  Yup.object().shape({
    profile_pic: Yup.string(),
    is_office_person: Yup.boolean().required(),
    assignee: Yup.object().shape({
      name: Yup.string().when("is_office_person", (is_office_person, schema) =>
        !isChecked ? Yup.string().required("Name is required") : schema
      ),
      phone: Yup.string().when(
        "is_office_person",
        (is_office_person, schema) =>
          !isChecked
            ? Yup.string()
                .matches(
                  /^[6-9]\d{9}$/,
                  "Phone number must be a valid 10-digit Indian mobile number starting with 6, 7, 8, or 9"
                )
                .required("Phone is required")
            : schema
      ),
      description: Yup.string().when(
        "is_office_person",
        (is_office_person, schema) =>
          !isChecked ? Yup.string().required("Description is required") : schema
      ),
      position: Yup.string().required("Position is required"),
      department: Yup.string().required("Department is required"),
    }),

    office_person: Yup.object()
      .shape({
        id: Yup.number(),
        name: Yup.string(),
        phonenum: Yup.string(),
      })
      .when("is_office_person", (is_office_person, schema) =>
        isChecked
          ? Yup.object().shape({
              id: Yup.number()
                .required("ID is required")
                .min(1, "ID must be greater than 0"),
              name: Yup.string()
                .required("Office person is required")
                .min(3, "Name must be at least 3 characters"),
              phonenum: Yup.string(),
            })
          : schema
      ),
  });
