import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FTPSearchBar, FTPSelect } from "../../../components-new";
import { Button, InputLabel, MenuItem, Select } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { FTPPagination } from "../../../components-new";
import { useStore } from "../../../helpers";
import { SideOverlay } from "../../../components/side-overlay";
import { useTranslation } from "react-i18next";
import { SrFilter } from "./sr-filter";

const PAGE_SIZES = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
export const SRSearchPanel = observer((props: any) => {
  const rootStore = useStore();
  const { serviceRequestsStore } = rootStore;
  const {
    pageSize,
    pageNumber,
    setPageNumber,
    setPageSize,
    resultsSize,
    resultsCount,
  } = serviceRequestsStore;
  const { t } = useTranslation("manage_voters");
  // const [pageSize, setPageSize] = useState(10);

  const handleChange = (event) => {
    setPageSize(event.target.value);
  };
  useEffect(() => {
    setPageNumber(1);
  }, [pageSize]);
  return (
    <>
      <div
        className={
          "flex flex-col-reverse md:flex-row justify-between md:items-center my-5"
        }
      >
        <div
          className={
            "flex flex-col md:flex-row gap-2 items-center md:justify-between "
          }
        >
          <div className="w-full flex flex-row-reverse gap-x-4 justify-between px-1 md:flex-row  md:w-auto items-center">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={pageSize}
              label="Age"
              onChange={handleChange}
              sx={{
                width: "4.5rem",
                height: "2.5rem",
                borderRadius: "1.1rem",
                border: "1px solid #808080",

                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Removes border
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: "none", // Removes border on focus
                },
                "&.Mui-focused": {
                  outline: "none", // Removes focus ring
                  boxShadow: "none", // Removes default focus shadow
                },
              }}
            >
              {PAGE_SIZES.map((size) => (
                <MenuItem value={size} key={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>

            <FTPPagination
              handlePageChange={(event, page) => {
                setPageNumber(page);
              }}
              pageNumber={pageNumber}
              totalPages={Math.ceil(resultsSize / pageSize)}
            />
          </div>

          <p className="md:w-auto w-full mt-2 md:mt-0">
            Showing {pageSize < resultsSize ? resultsCount : resultsSize}{" "}
            records of {resultsSize} results
          </p>
        </div>

        <div
          className={
            "flex flex-row-reverse justify-between md:flex-row md:items-center gap-x-4 mb-5 md:mb-0"
          }
        >
          {/* for dektop */}
          <div className="hidden md:block">
            <Button
              variant="outlined"
              startIcon={<FilterAltIcon sx={{ color: "black" }} />}
              size="large"
              onClick={() => {
                serviceRequestsStore.setServiceFilter(true);
              }}
              sx={{
                color: "black",
                borderColor: "black",
                "&:hover": {
                  borderColor: "black",
                  backgroundColor: "transparent",
                },
              }}
            >
              Filters
            </Button>
          </div>

          <FTPSearchBar
            placeHolder={"Search By Name"}
            onSearch={(e) => {
              serviceRequestsStore.setSearchQuery(e);
            }}
          />
        </div>

        <SideOverlay
          children={<SrFilter />}
          onClose={() => {
            serviceRequestsStore.setServiceFilter(false);
          }}
          show={serviceRequestsStore.showServiceFilters}
          title={"Service Filter"}
          size={"large"}
        />
      </div>
    </>
  );
});
