import React from "react";
import { observer } from "mobx-react-lite";
import { DeleteModal } from "../../../../components";
import { useStore } from "../../../../helpers";
import { useDeletePriority } from "./priority-hooks";
import { useTranslation } from "react-i18next";

export const DeletePriority = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { t } = useTranslation("sr_priority");

  const {
    modals: { deletePriority },
    activePriority,
    setActivePriority,
  } = serviceRequestsStore;
  const { mutate: deletePriorityMutate } = useDeletePriority();
  const onDeletePriority = async () => {
    deletePriorityMutate(activePriority.id);
    closeDeleteModal();
  };

  const closeDeleteModal = () => {
    serviceRequestsStore.update_modal_status("deletePriority", false);
    setActivePriority({ id: null });
  };

  return (
    <>
      <DeleteModal
        onDeleteClick={onDeletePriority}
        onCancelClick={closeDeleteModal}
        isOpen={deletePriority}
        id={activePriority.id}
        desc={t("sure_priority_delete")}
      />
    </>
  );
});
