import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import { useStore } from "../../../helpers";
import WordCloudChart from "./WordCloudChart";

const SubjectTagCloud = observer(() => {
  const { flexiStore } = useStore();
  const [tagData, setTagData] = useState<
    { tag: string; count: number; color: string }[]
  >([]);

  useEffect(() => {
    if (!flexiStore.current_fsd?.r2) return;

    const subjectTagCloud = (flexiStore.current_fsd.r2 as Record<string, any>)
      ?.subject_tag_cloud;
    if (!Array.isArray(subjectTagCloud)) return;

    const formattedData = subjectTagCloud.map(({ cat, reaction, emotion }) => ({
      tag: cat,
      count: reaction || 1,
      color:
        emotion === "Positive"
          ? "green"
          : emotion === "Negative"
            ? "red"
            : "rgb(234, 179, 8)",
    }));

    setTagData(formattedData);
  }, [flexiStore.current_fsd]);

  return (
    <div className="bg-white p-14">
    <div className="text-black">
      <Typography
        variant="h1"
        sx={{ textAlign: "center" }}
        className="text-center"
      >
        Subject Insights
      </Typography>
      <Typography
        variant="subtitle2"
        sx={{
          textAlign: "center",
          maxWidth: "60ch",
          margin: "0 auto",
          whiteSpace: "normal",
        }}
        className="text-center mt-6"
      >
        A visual highlight of who’s being talked about. Larger words indicate
        higher engagement, while colors represent sentiment—green (positive),
        red (negative), and gold (neutral).
      </Typography>

      <div
        className="w-full bg-black h-screen rounded-3xl flex items-center justify-center mt-8 border-[3px] border-[#FFFA00]"
        style={{
          boxShadow: `
      0px 5px 11px 0px #2527284A,
      0px 20px 20px 0px #25272842,
      0px 44px 27px 0px #25272826,
      0px 79px 31px 0px #2527280A,
      0px 123px 34px 0px #25272803`,
        }}
      >
        {tagData.length > 0 ? (
          <WordCloudChart data={tagData} chartId="subjectTagCloud" />
        ) : (
          <Typography sx={{ textAlign: "center" }}>
            No data available
          </Typography>
        )}
      </div>
    </div>
    </div>
  );
});

export default SubjectTagCloud;
