import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import {
  BriefcaseIcon,
  ChartPieIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { Profile } from "./Profile";
import { Permissions } from "./Permissions";
import { Booths } from "./Booths";
import { UserTag } from "./user_tags";
import { SideOverlay, TabView } from "../../components";
import { useStore } from "../../helpers";
import { safe_get } from "../../report-visuals";

const tabs = [
  { name: "Permissions", icon: BriefcaseIcon },
  { name: "Booths", icon: ChartPieIcon },
  { name: "UserTag", icon: UserIcon },
];

export const UserDetails = observer((props: any) => {
  const { userStore } = useStore();
  const {} = props;
  const { userDetailsMode, activeUser } = userStore;
  const profile = safe_get(activeUser, "voterprofile.profile", {});
  const has_voter_profile = safe_get(activeUser, "has_voter_profile", false);

  const [activeTab, setActiveTab] = useState("Permissions");

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  const handleUserViewClose = () => {
    userStore.setUserDetailsMode(false);
  };

  useEffect(() => {
    console.log("Mounting");
    return () => {
      console.log("UnMounting");
      setActiveTab("Permissions");
    };
  }, [activeUser]);

  return (
    <SideOverlay
      onClose={handleUserViewClose}
      show={userDetailsMode}
      title={"User Details"}
    >
      <Profile />
      {has_voter_profile ? (
        <div className="p-2 flex flex-nowrap flex-col shadow-md rounded-lg bg-white hover:shadow-xl items-center grid grid-cols-1 divide-y-2 divide-black-200">
          <div>
            <div className="flex flex-row w-full justify-between">
              <div className="w-full ">
                <p className="text-lg text-indigo-600  font-bold pt-2 ">
                  {profile.name_eng}
                </p>
              </div>
            </div>

            <div className="flex flex-row w-full justify-between">
              <div className="w-full">
                <p className="text-md font-medium text-black-600 pb-2">
                  {profile.voterid}
                </p>
              </div>
            </div>
          </div>

          <div>
            <div className="flex flex-row w-full justify-between mt-2">
              <div className="w-1/2">
                <p className="text-sm font-medium text-black-600 pb-2">
                  {profile.age}
                </p>
              </div>
            </div>
            <div className="flex flex-row w-full justify-between">
              <div className="w-1/2">
                <p className="text-sm font-medium text-black-600 pb-2">
                  {profile.gender}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <TabView key={activeUser.id} tabs={tabs} onChange={onTabChange} />
      {activeTab === "Permissions" ? <Permissions /> : null}
      {activeTab === "Booths" ? <Booths /> : null}
      {activeTab === "UserTag" ? <UserTag /> : null}
    </SideOverlay>
  );
});
