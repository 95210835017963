import React, { useEffect, useState } from "react";
import { useServiceRequestsMeta } from "../sr-hooks";
import { safe_get } from "../../../report-visuals/report-utils";
import { useForm, FormProvider } from "react-hook-form";
import { useStore } from "../../../helpers/helpers";
import { FTPAutoComplete } from "../../../components-new";
import { RNCheckBoxTree } from "../../../components";
import { observer } from "mobx-react-lite";
import { VortexSpinner } from "../../../features/digital-repository";
import { get_booth_node_by_boothnumber } from "../../../custom-hooks/useBoothNumber";
import { FilterButtons } from "../../../components/cm-components/FilterButtons";
import { useLocation } from "react-router-dom";

export const SrFilter = observer((props: any) => {
  const { data, isLoading } = useServiceRequestsMeta();
  const { pathname } = useLocation();
  const rootStore = useStore();
  const { serviceRequestsStore, projectStore } = rootStore;
  const priority = safe_get(data, "data.priority", []);
  const statusOptions = safe_get(data, "data.status", []);
  const assignees = safe_get(data, "data.assignee", []);
  const assinessopion = assignees.filter(
    (item) => item.assignee && item.assignee.name.trim() !== ""
  );
  const locations = safe_get(data, "data.locations", []);
  const type = safe_get(data, "data.type", []);
  const hierarchy = projectStore.getProjectProperty("hierarchy", {});

  const [selectedLevel1, setselectedLevel1] = useState(
    safe_get(serviceRequestsStore.activeServiceFilter, "nodes", [])
  );

  const methods = useForm({
    defaultValues: {
      assignee: [],
      priority: [],
      status: [],
      location: [],
      type: [],
    },
  });

  const convertToArrayFormat = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key, [].concat(value ?? [])])
    );

  const apply_filters = (data) => {
    const formattedData = convertToArrayFormat(data);
    const transform = selectedLevel1.map((id) =>
      get_booth_node_by_boothnumber(id)
    );
    const payload = {
      ...formattedData,
      nodes: transform,
    };
    serviceRequestsStore.updateServiceFilter(payload); //payuload
    serviceRequestsStore.setServiceFilter(false); //close this sideoverlay
  };

  const handleCancel = () => {
    serviceRequestsStore.setServiceFilter(false);
    serviceRequestsStore.updateServiceFilter({});
  };

  useEffect(() => {
    const existingNodes = safe_get(
      serviceRequestsStore.activeServiceFilter,
      "nodes",
      []
    );

    const pivLevel2Array = existingNodes
      .map((item) => item["piv_level2"])
      .filter(Boolean);

    methods.reset(serviceRequestsStore.activeServiceFilter);
    setselectedLevel1(pivLevel2Array);
  }, [serviceRequestsStore.activeServiceFilter]);

  if (isLoading || !data) {
    return <VortexSpinner visible={isLoading} />;
  }

  return (
    <div className="py-5">
      <FormProvider {...methods}>
        <form
          className="space-y-4"
          onSubmit={methods.handleSubmit(apply_filters)}
        >
          <FTPAutoComplete
            key="assignee"
            data={assinessopion}
            lookupKey={"assignee.name"}
            name={"assignee"}
            label={"Assignees"}
            placeholder={"Select Assignees"}
            multiple={true}
          />
          <FTPAutoComplete
            key="priority"
            data={priority}
            lookupKey={"priority.name"}
            name={"priority"}
            label={"Priority"}
            placeholder={"Select Priority"}
            multiple={true}
          />
          <FTPAutoComplete
            key="status"
            data={statusOptions}
            lookupKey={"status.status"}
            name={"status"}
            label={"Status"}
            placeholder={"Select Status"}
            multiple={true}
          />
          <FTPAutoComplete
            key="location"
            data={locations}
            lookupKey={"location.location"}
            name={"location"}
            label={"Location"}
            placeholder={"Select Location"}
            multiple={true}
          />
          <FTPAutoComplete
            key="type"
            data={type}
            lookupKey={"type.name"}
            name={"type"}
            label={"Type"}
            placeholder={"Select Type"}
            multiple={true}
          />
          <RNCheckBoxTree
            checked_nodes={selectedLevel1}
            data={hierarchy}
            on_node_selection={(selected) => {
              setselectedLevel1(selected);
            }}
          />

          <div className="w-full fixed bottom-0 left-0 bg-gray-100">
            <FilterButtons
              type="submit"
              onSave={(e) => {}}
              onCancel={handleCancel}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
});
