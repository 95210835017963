import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../helpers";
import { Grid, IconButton } from "@mui/material";
import { AgGridReact } from "@ag-grid-community/react";
import { Skeleton } from "@mui/lab";
import dayjs from "dayjs";
import { ServiceRequestDelete } from "./service-request-delete";
import { ServiceFilteredBy } from "./sr-components/sr-selected-filter";

const rowStyle = {
  // display: "flex",
  // flexDirection: "row",
  // alignItems: "center",
  // justifyContent: "center",
  cursor: "pointer",
};

export const ServiceRequestsList = observer((props: any) => {
  const { projectid } = useParams();
  const rootStore = useStore();
  const { serviceRequestsStore } = rootStore;
  const { searchQuery, activeServiceFilter } = serviceRequestsStore;
  const [serviceRequestList, setServiceRequestList] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const isEmpty = Object.keys(activeServiceFilter).length === 0; // returns true if empty

  const navigate = useNavigate();

  const actionButtons = (params) => (
    <div className={"flex flex-row gap-4 items-center"}>
      <IconButton
        size={"small"}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          navigate("editrequest");
          serviceRequestsStore.setActiveServiceRequest(params?.data);
        }}
      >
        <i className={"fas fa-edit"} />
      </IconButton>
      <IconButton
        disableRipple
        size={"small"}
        onClick={(e) => {
          e.stopPropagation();
          serviceRequestsStore.update_modal_status(
            "deleteServiceRequest",
            true
          );
        }}
      >
        <i className={"fas fa-trash"} />
      </IconButton>
    </div>
  );

  const fetchServiceRequests = async () => {
    return await rootStore.apiRequestHandler(
      "unified_service_request/service-requests-search-filter",
      {
        projectid: parseInt(projectid),
        status: activeServiceFilter?.status ?? [],
        nodes: activeServiceFilter?.nodes ?? [],
        priorities: activeServiceFilter?.priority ?? [],
        locations: activeServiceFilter?.location ?? [],
        assignees: activeServiceFilter?.assignee ?? [],
        types: activeServiceFilter?.type ?? [],
        offset:
          (serviceRequestsStore.pageNumber - 1) * serviceRequestsStore.pageSize,
        query: searchQuery,
        count: serviceRequestsStore.pageSize,
      },
      "Couldn't fetch service request list, please try again later"
    );
  };

  const { isLoading, data } = useQuery({
    queryKey: [
      "sr-list",
      projectid,
      searchQuery,
      activeServiceFilter,
      serviceRequestsStore.pageNumber,
      serviceRequestsStore.pageSize,
    ],
    queryFn: fetchServiceRequests,
    refetchInterval: 5 * 60 * 1000,
    keepPreviousData: true,
    onSuccess: (data) => {
      //@ts-ignore
      console.log(data.data.result);
      //@ts-ignore
      setServiceRequestList(data?.data?.result);
      //@ts-ignore
      serviceRequestsStore.setResultsCount(data?.data?.count);
      //@ts-ignore
      serviceRequestsStore.setResultsSize(data?.data?.total);
    },
  });

  const onRowClicked = (params) => {
    serviceRequestsStore.setActiveServiceRequest(params.data);
    setSelectedRowId(params.data.id);
  };

  const { data: auditLogs } = useQuery({
    queryKey: ["sr_id", selectedRowId], // Pass row ID in queryKey
    queryFn: async () => {
      if (!selectedRowId) return; // Prevent API call without ID
      return await rootStore.apiRequestHandler(
        "unified_service_request/service-requests-audit-logs",
        {
          sr_id: selectedRowId,
        },

        "Couldn't fetch service request list, please try again later"
      );
    },
    enabled: !!selectedRowId, // Only trigger when rowId is set
    onSuccess: (data) => {
      serviceRequestsStore.setActiveLogs(data?.data);
    },
  });

  const [colDefs] = useState([
    {
      headerName: "Status",
      field: "status.status",
      cellRenderer: (data) => {
        const value = data.value;
        return (
          <p
            style={{
              backgroundColor: value.color,
            }}
            className={`inline-block px-4 max-w-max rounded-full text-center font-semibold tracking-wide my-2`}
          >
            {value.status}
          </p>
        );
      },
    },
    {
      headerName: "Tracking ID",
      field: "trackingid",
    },
    {
      headerName: "Name",
      field: "subject.subject.profile.name_eng",
    },
    {
      headerName: "Phone Number",
      field: "subject.subject.phone",
    },
    {
      headerName: "Request Type",
      field: "type.type.name",

      flex: 1,
    },

    {
      headerName: "Assigned To",
      field: "assignee",

      cellRenderer: (data) => {
        const values = data.value;
        return (
          <>
            {values?.is_office_person ? (
              <p>{values.user_profile?.name}</p>
            ) : (
              <p>{values.assignee?.name}</p>
            )}
          </>
        );
      },
    },
    {
      headerName: "Created Date",
      field: "created",
      cellRenderer: (params) => (
        <p>{dayjs(params.value).format("dddd, MMMM D, YYYY h:mm:ss A")}</p>
      ),
    },
    {
      headerName: "Modified Date",
      field: "modified",
    },

    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: actionButtons,
      suppressClickEdit: true, // Prevent editing on click
      suppressMovable: true, // Prevent column movement
      suppressMenu: true, // Suppress column menu
    },
  ]);

  const onCellClicked = (params) => {
    if (params.colDef.field !== "actions") {
      serviceRequestsStore.setshowServiceRequest(true);
    }
    params.event.stopPropagation();
  };

  return (
    <>
      {!isEmpty && <ServiceFilteredBy />}
      <div
        className="ag-theme-quartz"
        style={{
          height:
            serviceRequestsStore.pageSize < serviceRequestsStore.resultsSize
              ? serviceRequestsStore.pageSize * 40 + 100
              : serviceRequestsStore.resultsSize * 45 + 60,
          width: "100%",
        }}
      >
        {isLoading ? (
          <>
            <Grid container spacing={2}>
              {Array.from({ length: 15 }, (_, i) => (
                <Grid item xs={12} key={i}>
                  <Skeleton variant="rectangular" width="100%" height={40} />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <AgGridReact
            // @ts-ignore
            rowData={serviceRequestList}
            columnDefs={colDefs}
            rowStyle={rowStyle}
            onRowClicked={onRowClicked}
            onCellClicked={onCellClicked}
            suppressRowClickSelection={true}
            gridOptions={{
              sortingOrder: undefined,
            }}
            rowHeight={60}
          />
        )}
        <ServiceRequestDelete />
      </div>
    </>
  );
});
