import React from "react";
import { safe_get } from "../../../report-visuals/report-utils";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Skeleton, useTheme } from "@mui/material";

export type contact = {
  id: number;
  name: string;
  phone: string;
  alternate_phone: string;
  email: string;
  address: string;
  comments: string;
  designation: { label: string };
};

type contactProps = {
  contact: contact;
  onEdit: Function;
  onDelete: Function;
};

export const ContactCardV2Skeleton = () => {
  return (
    <Card
      className="group transition-all duration-300 !bg-[#F2F2F2]"
      sx={{ width: 400, borderRadius: "24px" }}
    >
      <CardHeader
        title={
          <div className="flex flex-col gap-1">
            <div className="flex items-center justify-between">
              <Skeleton
                variant="rectangular"
                width={"35%"}
                height={30}
                sx={{ borderRadius: "24px" }}
              />

              <div className="flex gap-3">
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="circular" width={40} height={40} />
              </div>
            </div>

            <Skeleton variant="text" width="60%" height={40} />
          </div>
        }
      />

      <CardContent>
        <div className="space-y-3">
          <div className="flex items-center gap-4">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="text" width="90%" height={24} />
          </div>

          <div className="flex items-center gap-4">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton variant="text" width="90%" height={24} />
          </div>

          <div className="flex items-start gap-4">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton
              variant="text"
              width="90%"
              height={50}
              sx={{ transform: "unset" }}
            />
          </div>

          <div className="flex items-start gap-4">
            <Skeleton variant="circular" width={20} height={20} />
            <Skeleton
              variant="text"
              width="90%"
              height={120}
              sx={{ transform: "unset" }}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export const ContactCardV2 = (props: contactProps) => {
  const { contact, onEdit, onDelete } = props;
  const theme = useTheme();

  return (
    <Card
      className="group transition-all duration-300  hover:bg-[#D1E3FF] w-full p-3"
      sx={{ borderRadius: "1.5rem", backgroundColor: "#F2F2F2" }}
    >
      <CardHeader
        title={
          <div className="flex flex-col gap-2 ">
            <div className="flex items-center justify-between">
              <Typography
                variant="subtitle1"
                className="transition-all duration-300 group-hover:bg-[#3A80F3] group-hover:text-white"
                sx={{
                  color: "#3A80F3",
                  border: "1px solid #3A80F3",
                  borderRadius: "24px",
                  display: "inline-block",
                  padding: "4px 12px",
                }}
              >
                {safe_get(contact, "designation.label", "Unknown Designation")}
              </Typography>

              <div className="flex gap-3 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <button
                  onClick={() => onEdit(contact)}
                  className="bg-black text-white w-10 h-10 flex items-center justify-center rounded-full hover:bg-[#1B1B1D]"
                >
                  <i className="fa-regular fa-pen-to-square"></i>
                </button>
                <button
                  onClick={() => onDelete(contact.id)}
                  className="bg-red-600 text-white w-10 h-10 flex items-center justify-center rounded-full hover:bg-black"
                >
                  <i className="fa-regular fa-trash"></i>
                </button>
              </div>
            </div>

            <Typography variant="h5">{contact.name}</Typography>
          </div>
        }
      />

      <CardContent>
        <div className="space-y-3">
          <div className="flex items-start gap-4">
            <i className="fa-regular fa-phone text-[#3a80f3] text-lg"></i>
            <Typography variant="body1">
              {contact.phone}{" "}
              {contact.alternate_phone && ` | ${contact.alternate_phone}`}
            </Typography>
          </div>

          <div className="flex items-start gap-4">
            <i className="fa-regular fa-envelope text-[#3a80f3] text-lg"></i>
            <Typography variant="body1">{contact.email}</Typography>
          </div>

          <div className="flex items-start gap-4">
            <i className="fa-regular fa-location-dot text-[#3a80f3] text-lg"></i>
            <Typography variant="body1">{contact.address}</Typography>
          </div>

          <div className="flex items-start gap-4">
            <i className="fa-regular fa-message-pen text-[#3a80f3] text-lg"></i>
            <div className="">
              <Typography variant="body1">{contact.comments}</Typography>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
};
