import { useParams } from "react-router-dom";
import React from "react";
import {FTPBreadCrumbs} from "../../components-new/BreadCrumbs";

export const GrievanceCrumbs = (props) => {
  const { projectid } = useParams();

  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: true },
    {
      name: "Grievance Management",
      href: "/project/" + `${projectid}` + "/grievance_management",
      current: true,
    },
  ];
  return <FTPBreadCrumbs crumbs={crumbs} />;
};
