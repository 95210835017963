import React from "react";
import { useStore } from "../../../../helpers";
import { VortexSpinner } from "../../../../features/digital-repository";
import { DeleteModal } from "../../../../components";
import { useDeleteServiceRequestType } from "../../sr-hooks";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

export const DeleteRequestType = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const {
    modals: { deleteRequestType },
    activeRequestType,
    setActiveRequestType,
  } = serviceRequestsStore;
  const { mutate, isLoading } = useDeleteServiceRequestType();
  const { t } = useTranslation("sr-request-type");

  const onDeleteRequestType = async () => {
    mutate(activeRequestType.id);
    closeDeleteModal();
  };

  const closeDeleteModal = () => {
    serviceRequestsStore.update_modal_status("deleteRequestType", false);
    setActiveRequestType({ id: null });
  };

  return (
    <>
      <VortexSpinner visible={isLoading} />
      <DeleteModal
        onDeleteClick={onDeleteRequestType}
        onCancelClick={closeDeleteModal}
        isOpen={deleteRequestType}
        id={activeRequestType?.id}
        desc={t("sure_req_type_delete")}
      />
    </>
  );
});
