import { useStore } from "../../../../../helpers";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import {
  useInvalidateMetaQuery,
  useRequestTypeMutation,
} from "../../../sr-hooks";
import apiRequestHandler from "../../../../utils";

export const useDeletePriority = () => {
  const rootStore = useStore();

  const deleteProrityMeta = async (id) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-priorities-delete",
      { id },
      "Failed to delete priority",
    );
  };
  return useRequestTypeMutation(
    deleteProrityMeta,
    "Priority deleted Successfully.",
  );
};
