import React, { useEffect } from "react";
import { useStore } from "../helpers";
import { Navigate, Outlet, useLocation, useParams } from 'react-router-dom'
import { SideOverlay, UpdateProfile, UserAgreement } from "../components";
import { observer } from "mobx-react-lite";
import { AppDrawer } from "../components/AppDrawer";
import FTPNavBar from "../layouts/shell-components/nav-bar";
import Shell from "../layouts/app-shell";


export const PrivateRoute = observer((props: any) => {

    const rootStore = useStore();
    const { userStore } = rootStore;
    const { isAuthorized } = userStore;
    const { projectid } = useParams();

    const location = useLocation();

    const isCheckedIn = userStore.checkLogin();

    useEffect(() => {
        if (!isAuthorized) {
            userStore.logout();
        }
    }, [isAuthorized])

    return (
        <div>
            {isCheckedIn ? (
                <>
                    <main className={"bg-[#F2F2F2] min-h-[100vh]"}>
                        <Shell>
                            <Outlet />
                            <UserAgreement key={projectid} />
                        </Shell>

                        <SideOverlay
                            children={<UpdateProfile />}
                            onClose={() => userStore.set_profile_update(false)}
                            show={userStore.profile_update}
                            title={"Update Profile"}
                        />
                    </main>
                </>
            ) :
                <Navigate to="/login" state={{ path: location.pathname }} />
            }
        </div>
    );
})

// @ts-ignore
export const PublicRoute = ({ component: Component, ...rest }) => {
    return <Component  {...rest} />;
};