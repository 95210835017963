import React from "react";
import { FormControl, FormHelperText, MenuItem, Select } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { FormLabel } from "../utils";

type Option = {
  label: string;
  value: string;
};

interface FTPSelectProps {
  name: string;
  label: string;
  options: Array<Option | string>;
}

const selectStyles = {
  borderRadius: "15px",
  "& legend": { display: "none" },
  "& fieldset": { top: 0 },
  "& .MuiSelect-select": { borderRadius: "25px" },
};

const renderOptionContent = (option: Option | string) => {
  const label = typeof option === "string" ? option : option.label;

  if (typeof option === "string") {
    return label;
  }

  return (
    <div className="flex items-center gap-2">
      {option?.value?.startsWith("#") ? (
        <div
          className="w-4 h-4 rounded-full"
          style={{ backgroundColor: option.value }}
        />
      ) : null}
      {label}
    </div>
  );
};

export const FTPSelect = (props: FTPSelectProps) => {
  const { name, label, options, ...rest } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="flex flex-col w-full">
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <FormControl fullWidth>
            <Select
              {...field}
              {...rest}
              variant="outlined"
              labelId={`select-${name}`}
              error={!!errors[`${name}`]}
              sx={selectStyles}
              // placeholder="Select Language"
              onChange={(e) => {
                field.onChange(e);
              }}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return <em>Select</em>; // Custom placeholder text
                }
                const option = options.find(
                  (option) => option["value"] === selected,
                );
                return option ? option["label"] : selected;
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  value={typeof option === "string" ? option : option.value}
                >
                  {renderOptionContent(option)}
                </MenuItem>
              ))}
            </Select>
            {error && <FormHelperText error>{error.message}</FormHelperText>}
          </FormControl>
        )}
      />
    </div>
  );
};
