import React, { useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TextField } from "@mui/material";
import { observer } from "mobx-react-lite";

export const LoginOtp = observer((props: any) => {
  const { control } = useFormContext();
  const inputs = useRef<HTMLInputElement[]>([]);

  const handleOTPChange = (e, field, index) => {
    field.onChange(e);
    const val = e.target.value;
    if (e.target.value.length === 1 && index < 5) {
      inputs.current[index + 1]?.focus();
    }
    const otp = inputs.current.map((input) => input.value).join("");
    if (otp.length === 6) {
      props.onOTPChange(otp);
    }
  };

  const handleOTPKeyDown = (e, field, index) => {
    if (e.key === "Backspace" && index > 0 && field.value === "") {
      inputs.current[index - 1]?.focus();
    }
  };

  return (
    <>
      <div className="w-full flex gap-x-2">
        {[...Array(6)].map((_, index) => (
          <Controller
            key={index}
            name={`otp[${index}]`}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                inputRef={(el) =>
                  (inputs.current[index] = el as HTMLInputElement)
                }
                type="number"
                placeholder="-"
                InputProps={{
                  inputProps: {
                    maxLength: 1,
                    style: { textAlign: "center" },
                  },
                }}
                sx={{
                  borderRadius: "10px",
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "10px",
                  },
                }}
                className="font-medium text-[16px] text-[#1B1B1D]"
                onChange={(e) => handleOTPChange(e, field, index)}
                onKeyDown={(e) => handleOTPKeyDown(e, field, index)}
              />
            )}
          />
        ))}
      </div>
    </>
  );
});
