import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { htmlValidation, useStore } from "../../helpers";
import {
  AddFamilyMembersValidations,
  AddFamilymembersFromVoterList,
  familyMemberProfileDefaultValues,
  GENDERS,
  relationShips,
  getFamilyMemberProfileDefaultValues,
} from "../../features/beneficiary-management-v2";
import { safe_get } from "../../report-visuals";
import { FormLabel, FTPInput, FTPSelect } from "../../components-new";
import { FormButtons, SideOverlay } from "../../components";
import FilePondUpload from "./sr-settings/assignees/upload-profile-pic";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { SrRenderFamily } from "./sr-render-family";
import { toJS } from "mobx";

export const FamilyMembers = observer((props: any) => {
  const params = useParams();
  const projectid = params.projectid;
  const {
    beneficiaryStore,
    beneficiaryStoreV2,
    serviceRequestsStore: {
      modals,
      selectedFamilyMembers,
      update_modal_status,
      activeFamilyMember,
      setFamilyMembers,
    },
  } = useStore();
  const { familyMembers } = beneficiaryStoreV2;
  // const { activeFamilyMember } = beneficiaryStore;
  const [uploadedFile, setUploadedFile] = useState([]);
  const [files, setFiles] = useState([]);

  const methods = useForm({
    defaultValues: getFamilyMemberProfileDefaultValues(activeFamilyMember),
    //@ts-ignore
    resolver: yupResolver(AddFamilyMembersValidations),
    mode: "onSubmit",
  });

  const saveFamilyMember = (data) => {
    const final = { ...data, profile_photo: uploadedFile };
    let updatedFamily = [...toJS(selectedFamilyMembers)];
    console.log(updatedFamily);
    const familyPersonToEditIndex = updatedFamily.findIndex((ele) => {
      return ele.id === final.voterid;
    });

    if (familyPersonToEditIndex > -1) {
      updatedFamily[familyPersonToEditIndex].profile = final;
    } else {
      updatedFamily.push({
        id: final.voterid,
        phone: final.phone,
        profile: final,
      });
    }

    setFamilyMembers(updatedFamily);
    closeFamilyMemberModal();
    setUploadedFile([]);
  };

  useEffect(() => {
    methods.reset(activeFamilyMember.profile);
    methods.setValue("phone", safe_get(activeFamilyMember, "phone", ""));
  }, [activeFamilyMember]);

  const closeFamilyMemberModal = () => {
    // beneficiaryStore.update_add_familymember_modal(false);
    update_modal_status("addFamilyMember", false);
  };
  const searchVotersHandler = () => {
    closeFamilyMemberModal();
    beneficiaryStore.update_addFamilyFromVoterListModal(true);
  };

  const addNewFamilyMemberHandler = () => {
    // beneficiaryStore.update_add_familymember_modal(true);
    // beneficiaryStore.resetActiveFamily();
    methods.reset(familyMemberProfileDefaultValues);
    update_modal_status("addFamilyMember", true);
  };
  const onError = (data) => {
    console.log(data);
  };

  return (
    <>
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-2">
        <FormLabel>Family Members</FormLabel>

        <div className="flex flex-row gap-2 sm:gap-x-4 w-full sm:w-auto">
          <Button
            variant="outlined"
            color="info"
            onClick={searchVotersHandler}
            size="small"
            endIcon={<AddCircleOutlineIcon />}
            className="sm:w-auto"
          >
            Add from Voter list
          </Button>
          <Button
            variant="outlined"
            color="info"
            onClick={addNewFamilyMemberHandler}
            size="small"
            endIcon={<AddCircleOutlineIcon />}
            className="sm:w-auto"
          >
            Add
          </Button>
        </div>
      </div>

      <AddFamilymembersFromVoterList />

      <SrRenderFamily />
      {/* //beneficiaryStore.add_familymember_modal */}
      <SideOverlay
        show={modals.addFamilyMember}
        onClose={closeFamilyMemberModal}
        size={"large"}
        title={"Add Family"}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              htmlValidation(e);
              methods.handleSubmit(saveFamilyMember, onError)(e);
            }}
            className="rounded-sm sm:my-4 sm:align-middle w-full"
          >
            <>
              <div className="flex flex-col gap-y-3">
                <FTPInput label="Name" name="name_eng" />

                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                  <FTPInput label="Phone Number" name="phone" />
                  <FTPSelect
                    label={"Gender"}
                    name={"gender"}
                    options={GENDERS}
                  />
                </div>

                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                  <FTPInput label={"Voter ID"} name={"voterid"} />
                  <FTPInput label={"Aadhar ID"} name={"aadharid"} />
                </div>

                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                  <FTPInput
                    label={"Date of Birth"}
                    name={"dob"}
                    type={"date"}
                    InputLabelProps={{ shrink: true }}
                  />
                  <FTPInput label={"Age"} name={"age"} type={"number"} />
                </div>

                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                  <FTPInput
                    label={"Relative Name"}
                    name={"relation_name_eng"}
                  />
                  <FTPSelect
                    label={"Relationship"}
                    name={"relationship_eng"}
                    options={relationShips}
                  />
                </div>

                <FTPInput
                  label={"Additional Information"}
                  name={"additional"}
                  multiline
                  rows={3}
                  fullWidth
                />

                <FilePondUpload
                  path={`project-${projectid}/beneficiary/family`}
                  files={files}
                  onUpdate={(files) => {
                    setUploadedFile(files);
                    methods.setValue("profile_photo", files);
                  }}
                />
              </div>
              <FormButtons
                onSave={() => {}}
                onCancel={closeFamilyMemberModal}
              />
            </>
          </form>
        </FormProvider>
      </SideOverlay>
    </>
  );
});
