import React from "react";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { useStore } from "../../../helpers";
import { FieldSummaryForRohan } from "./field-summary-for-rohan";
import { SurveysByUser } from "./surveys-by-user";
import { SurveysByDate } from "./surveys-by-date";
import { SurveysLevel1 } from "./surveys-by-level1";
import { SurveysLevel2 } from "./surveys-by-level2";
import { SurveysByAudio } from "./surveys-by-audio";
import { SurveysByDateHour } from "./surveys-by-date-hour";
import { SurveysByLevel1Audio } from "./surveys-by-level1-audio";
import { SurveysByLevel2Audio } from "./surveys-by-level2-audio";
import { SurveysByQaCheck } from "./surveys-by-qa-check";
import { SurveysByUserHL } from "./surveys-by-user-hl";
import { SurveysBylevel1HL } from "./survey-by-level1-hl";
import { SurveysBylevel2HL } from "./surveys-by-level2-hl";
import { SurveysByDateHL } from "./surveys-by-date-hl";
import { SurveysByAudioOnly } from "./survey-by-audio-only";
import { SurveysByQaCheckOly } from "./survey-by-qa-check-only";
import { Typography } from "@mui/material";
import Masonry from "@mui/lab/Masonry";

export const TableHeader = ({ title }) => {
  return (
    <Typography
      color={"primary"}
      variant={"h6"}
      sx={{ fontWeight: "bold", margin: "15px 0px 10px 0px" }}
    >
      {title}
    </Typography>
  );
};

export const SurveySummary = observer((props) => {
  const { surveyResponseStore } = useStore();

  const { allSurveyResponsesSummary } = surveyResponseStore;

  if (_.isEmpty(allSurveyResponsesSummary)) return null;

  return (
    <Masonry columns={{ sm: 1, md: 2, lg: 3, xl: 3 }} spacing={2}>
      <SurveysByUser />
      <SurveysByDate />
      <SurveysByDateHour />

      <SurveysLevel1 />
      <SurveysLevel2 />
      <SurveysByAudio />

      <SurveysByLevel1Audio />
      <SurveysByLevel2Audio />
      <SurveysByUserHL />

      <SurveysByQaCheck />
      <SurveysByQaCheckOly />
      <SurveysByAudioOnly />

      <SurveysBylevel1HL />
      <SurveysBylevel2HL />
      <SurveysByDateHL />

      <FieldSummaryForRohan />
    </Masonry>
  );
});
