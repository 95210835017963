import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormButtons, LoadingState, SideOverlay } from "../../components";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { Grid } from "@mui/material";
import { safe_get } from "../../report-visuals";
import { projectWorkSummaryDefaultValues } from "./pw-v2-types";
import { htmlValidation, useFileUpload, useStore } from "../../helpers";
import {
  RNInput,
  RNSelect,
  RNBoothSelector,
} from "../../components/form-components";
// import { useEditProjectWork } from "./pw-hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { addProjectWorkValidations } from "./utils";
import FilePondUpload from "../beneficiary-management-v2/beneficiary-requests/filepond";
import { FTPInput, FTPSelect } from "../../components-new";
import {
  useAddProjectWork,
  useEditProjectWork,
} from "./pw-hooks/useProjectWork";

export const AddProjectWork = observer((props: any) => {
  const { projectid } = useParams();
  const PATH = `project-${projectid}/projectworks/cover-photos`;
  const { projectWorkStoreV2, projectStore } = useStore();
  const { uploadFileToS3 } = useFileUpload();
  const { mutate: addProjectWork, isLoading } = useAddProjectWork();
  const { isLoading: isEditing, mutate: editProjectWork } =
    useEditProjectWork();
  const { activeProjectWork, add_project_modal } = projectWorkStoreV2;
  const [uploadedFile, setUploadedFile] = useState([]);
  const [files, setFiles] = useState([]);

  const projectWorkId = safe_get(activeProjectWork, "id", null);
  const projectWorksConfig = projectStore.getProjectProperty(
    "project_works",
    {},
  );
  const statusOptions = safe_get(projectWorksConfig, "status_config", []).map(
    (x) => {
      return { label: x.status, value: x.status };
    },
  );

  const methods = useForm({
    defaultValues: projectWorkSummaryDefaultValues,
    //@ts-ignore
    resolver: yupResolver(addProjectWorkValidations),
    mode: "onSubmit",
  });

  const cp = methods.watch("coverPhotoPath");
  const location = useLocation();

  // const isEditMode = location.pathname.includes("editrequest");
  useEffect(() => {
    if (location.state) {
      methods.reset(
        safe_get(activeProjectWork, "summary", projectWorkSummaryDefaultValues),
      );
      const file = safe_get(activeProjectWork, "summary.coverPhotoPath", {});
      setUploadedFile([...uploadedFile, file]);
      methods.setValue("coverPhotoPath", [file]);
    }
  }, [activeProjectWork]);

  const close_modal = async () => {
    projectWorkStoreV2.update_add_project_modal(false);
    methods.reset(projectWorkSummaryDefaultValues);
  };

  const onSubmit = async (data) => {
    const final = { ...data, coverPhotoPath: uploadedFile[0] };
    if (uploadedFile.length <= 0) {
      toast.warning("Please upload the profile Photo");
      return;
    }
    if (projectWorkId) {
      editProjectWork({
        ...activeProjectWork,
        summary: {
          ...data,
          coverPhotoPath: uploadedFile[0],
        },
      });
    } else {
      addProjectWork(final);
      // projectWorkStoreV2.update_project_work_property(
      //   "activeProjectWork",
      //   final,
      // );
    }
    // props.onAdd(final)
  };

  if (isLoading || isEditing) return <LoadingState />;

  return (
    <>
      <SideOverlay
        onClose={close_modal}
        show={add_project_modal}
        title={projectWorkId ? "Add New Project Work" : "Update Project Work"}
      >
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="bg-white px-4 py-2 rounded-sm flex flex-col gap-y-4"
          >
            <FTPInput label={"Name"} name={"name"} />

            <FTPInput
              label={"Description"}
              name={"description"}
              multiline
              rows={3}
              fullWidth
            />
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FTPInput label={"Start Date"} name={"startDate"} type="date" />

              <FTPInput
                label={"Est. Compltn. Date"}
                name={"estimatedCompletionDate"}
                type="date"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <FTPInput label={"Sublocation"} name={"sublocation"} />
              <RNBoothSelector
                label={"Booth/Village"}
                name={"booth"}
                isMulti={true}
                selector={"display"}
              />
            </div>
            <FTPInput label={"Location Co-ordinates"} name={"latlong"} />
            <FTPSelect
              label={"Status"}
              name={"status"}
              options={statusOptions}
            />

            <FilePondUpload
              path={`project-${projectid}/projectworks/cover-photos`}
              files={files}
              label="Cover Photo"
              onUpdate={(files) => {
                setUploadedFile(files);
                methods.setValue("coverPhotoPath", files);
              }}
            />

            <FormButtons onSave={() => {}} onCancel={close_modal} />
          </form>
        </FormProvider>
      </SideOverlay>
    </>
  );
});
