import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { FTPFormButton, FTPInput } from "../../../../components-new";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultStatus, statusValidationSchema } from "./status-schema";
import { useStore } from "../../../../helpers";
import { SideOverlay } from "../../../../components";
import { safe_get } from "../../../../report-visuals";
import { useEditStatus, useAddStatus } from "./status-hooks";
import { SRColorPicker } from "../sr-settings-components";
import { VortexSpinner } from "../../../../features/digital-repository";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const STATUS_ICONS = [
  "fa-solid fa-circle-plus",
  "fa-solid fa-ban",
  "fa-solid fa-check-circle",
  "fa-solid fa-clock",
];

export const AddStatus = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { activeStatus } = serviceRequestsStore;
  const { t } = useTranslation(["status"]);
  const statusId = safe_get(activeStatus, "id", null);
  const { projectid } = useParams();
  const [selectedIcon, setSelectedIcon] = useState(null);
  const { isLoading: isEditing, mutate: editStatus } = useEditStatus();
  const { isLoading: isAdding, mutate: addStatus } = useAddStatus(selectedIcon);

  const methods = useForm({
    defaultValues: defaultStatus,
    resolver: yupResolver(statusValidationSchema),
  });

  const handleClick = (icon) => {
    setSelectedIcon(icon);
  };

  useEffect(() => {
    if (statusId) {
      methods.reset(activeStatus?.status);
      setSelectedIcon(activeStatus.status?.icon);
    } else {
      methods.reset(defaultStatus);
    }
  }, [activeStatus]);

  const onSubmit = (data) => {
    if (statusId) {
      const editPayload = {
        ...activeStatus,
        status: {
          ...data,
          icon: selectedIcon,
        },
      };
      editStatus(editPayload);
    } else {
      const payload = {
        projectid: parseInt(projectid),
        status: {
          ...data,
          icon: selectedIcon,
        },
        more: {},
      };
      // @ts-ignore
      addStatus(payload);
    }
  };

  const close_modal = async () => {
    setTimeout(() => {
      serviceRequestsStore.setActiveStatus({});
      methods.reset(defaultStatus);
    }, 1000);
    serviceRequestsStore.update_modal_status("addStatus", false);
  };

  useEffect(() => {
    if (isAdding || isEditing) {
      close_modal();
    }
  }, [isAdding, isEditing]);

  return (
    <>
      <SideOverlay
        onClose={close_modal}
        show={serviceRequestsStore.modals.addStatus}
        title={statusId ? t("edit_status") : t("create_status")}
        size={"large"}
      >
        <FormProvider {...methods}>
          <form
            className={"flex flex-col bg-common-white rounded-xl p-4 gap-y-4"}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <FTPInput name={"status"} label={t("status")} />
            <SRColorPicker name={"color"} />

            <div className="flex space-x-8 mt-2">
              {STATUS_ICONS.map((icon, index) => (
                <div
                  key={index}
                  className={`px-4 py-2 rounded-full cursor-pointer transition-all ${
                    selectedIcon === icon
                      ? "bg-primary-light text-common-white"
                      : "bg-grey-200 text-grey-500"
                  }`}
                  onClick={() => handleClick(icon)}
                >
                  <i className={icon} style={{ fontSize: "24px" }}></i>
                </div>
              ))}
            </div>
            <div className="flex gap-x-2 justify-end mt-4">
              <VortexSpinner visible={isEditing || isAdding} />
              <FTPFormButton
                actionType="Cancel"
                handleButtonClick={close_modal}
              />
              <FTPFormButton
                actionType="Save"
                handleButtonClick={methods.handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </FormProvider>
      </SideOverlay>
    </>
  );
});
