

export const doesFilterHaveValues = (voter_filters) => {
  
  let arr = [];
  const keysToCheck = [
    "serialno",
    "house",
    "name",
    "relation",
    "voterid",
    "page",
    "booths",
    "genders",
    "age",
  ];
  const defaultValues = {
    genders: ["Male", "Female", "Third Gender"],
    age: [18, 120],
  };

  keysToCheck.forEach((key) => {
    const value = voter_filters[key];
    const defaultValue = defaultValues[key];

    const isDefaultValue = Array.isArray(defaultValue) // Check if the default value is an array
      ? JSON.stringify(value) === JSON.stringify(defaultValue) // Compare arrays as strings
      : value === defaultValue; // Compare directly for non-array values

    if (
      value !== null && // Not null
      value !== undefined && // Not undefined
      !(typeof value === "string" && value.trim() === "") && // Not an empty string
      !(Array.isArray(value) && value.length === 0) && // Not an empty array
      !(
        // Not an empty object
        (
          typeof value === "object" &&
          value !== null &&
          !Array.isArray(value) &&
          Object.keys(value).length === 0
        )
      ) &&
      !isDefaultValue // Exclude default values (arrays and others)
    ) {
      arr.push({ key, value }); // Push to arr with key-value pair
    }
  });

  const allNull = arr.length === 0; // Check if `arr` is empty (all null)

  return {
    hasValues: !allNull, // Return true if arr has non-null values
    filters: arr, // Return array of non-null key-value pairs
  };
};

