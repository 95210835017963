import { useStore } from "../../../../../helpers";
import { useRequestTypeMutation } from "../../../sr-hooks";
import { useTranslation } from "react-i18next";

export const useEditLocation = () => {
  const rootStore = useStore();
  const { t } = useTranslation(["location"]);

  const editLocation = async (payload: any) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-locations-update",
      payload,
      t("edit_location_failed"),
    );
  };

  return useRequestTypeMutation(editLocation, t("edit_location_success"));
};
