import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { classNames, useStore } from "../../../../helpers";
import Avatar from "@mui/material/Avatar";
import _ from "lodash";
import { useHoverDirty } from "react-use";
import { ActionButtons } from "../sr-settings-components";
import { useIsMobile } from "../../../../custom-hooks";
import { Skeleton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { avatarStyles } from "./MUIstyles";

type Assignee = {
  name: string;
  phone: string;
  position: string;
  department: string;
  description: string;
};

type UserProfile = {
  id: number;
  role: string;
  phonenum: string;
  name: string;
  profileimage: string;
  profileimageuri: string;
};

type ProjectAssignee = {
  id: number;
  projectid: number;
  userid: number | null;
  is_office_person: boolean;
  assignee: Assignee;
  more: Record<string, unknown>;
  user_profile: UserProfile;
};

const InfoRow = ({ label, value }) => {
  return (
    <div className="flex flex-wrap pr-3">
      <div className="flex-1 mx-4 font-light text-label-default">{label}</div>
      <div className="flex-1 text-right">{value}</div>
    </div>
  );
};

export const AssigneeCardSkeleton = () => {
  return (
    <div className="p-6 rounded-2xl bg-white">
      <div className="flex flex-row items-center justify-between gap-x-4">
        <div className="flex flex-row items-center flex-1 gap-x-4">
          <Skeleton variant="circular" width={50} height={50} />
          <Skeleton variant="text" width={"40%"} height={20} />
        </div>
        <Skeleton variant={"rounded"} width={"20%"} height={20} />
      </div>
      <div className="mt-4 flex flex-col gap-y-2 bg-background-imagePlaceholder rounded-[20px] px-1 py-4">
        <div className="flex flex-col mx-4">
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="60%" />
          <Skeleton variant="text" width="40%" />
          <Skeleton variant="text" width="80%" />
        </div>
      </div>
    </div>
  );
};

export const AssigneeCard = (props: ProjectAssignee) => {
  const ref = useRef(null);
  const isMobile = useIsMobile();
  const isHoverd = useHoverDirty(ref);
  const { t } = useTranslation(["assignee"]);
  const classes = avatarStyles();
  const {
    is_office_person,
    assignee: { name, department, description, phone, position },
    user_profile: { name: user_name, phonenum, profileimageuri },
    more,
  } = props;
  const { serviceRequestsStore } = useStore();

  const externalAssigneePic: string | any = _.get(more, "profile_pic", "");

  function editCurrentAssignee() {
    serviceRequestsStore.setActiveAssignee(props);
    serviceRequestsStore.update_modal_status("addAssignee", true);
  }

  const onDeleteHandler = () => {
    serviceRequestsStore.update_modal_status("deleteAssignee", true);
    serviceRequestsStore.setActiveAssignee(props);
  };

  return (
    <div
      className="relative border-slate-100 bg-white p-4 rounded-3xl"
      ref={ref}
    >
      <div className="flex md:gap-x-2">
        <div className="relative flex items-center justify-center">
          {is_office_person && profileimageuri !== "" ? (
            <Avatar
              src={profileimageuri}
              variant="rounded"
              className={classes.basic}
            />
          ) : externalAssigneePic ? (
            <Avatar
              src={externalAssigneePic}
              variant="rounded"
              className={classes.basic}
            />
          ) : (
            <Avatar
              src={externalAssigneePic}
              variant="rounded"
              className={classes.default}
            >
              <FontAwesomeIcon
                icon={"user"}
                className={` ${
                  is_office_person
                    ? "text-label-internal"
                    : "text-label-external"
                }`}
              />
            </Avatar>
          )}
        </div>

        <div className="flex flex-col justify-center md:flex-row md:items-center md:justify-between md:flex-1 ">
          <h4 className="ml-2 md:ml-0 font-bold mr-4 text-grey-800 text-center">
            {is_office_person ? user_name : name}
          </h4>
          <p
            className={`ml-2 md:ml-0 ${
              is_office_person
                ? `${
                    !isMobile &&
                    "border-[1px] bg-background-internal border-border-internal py-2 px-4 rounded-[20px]"
                  } text-label-internal`
                : `${
                    !isMobile &&
                    "border-[1px] bg-background-external border-border-external py-2 px-4 rounded-[20px]"
                  } text-label-external`
            }  text-left font-semibold text-md`}
          >
            {is_office_person ? t("internal") : t("external")}
          </p>
        </div>
      </div>

      <div className="mt-4 flex flex-col gap-y-2 bg-background-imagePlaceholder rounded-[20px] px-1 py-4">
        <InfoRow label={`${t("position")}`} value={position} />
        <InfoRow label={`${t("department")}`} value={department} />
        <InfoRow
          label={`${t("phone_number")}`}
          value={is_office_person ? phonenum : phone}
        />
        {!is_office_person && (
          <div className="flex flex-col text-left  ">
            <div className="flex-1 mx-4 font-light text-label-default">
              {`${t("description")}`}
            </div>
            <div className="flex-1 mx-4">{description}</div>
          </div>
        )}
      </div>
      {isHoverd ? (
        <ActionButtons
          positionClasses={classNames(
            !isHoverd ? "hidden" : "",
            "top-1/3 right-0",
          )}
          onEdit={editCurrentAssignee}
          onDelete={onDeleteHandler}
        />
      ) : null}
    </div>
  );
};
