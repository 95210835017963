import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { FormButtons, LoadingState, TailwindModal } from "../../components";
import { FormProvider, useForm } from "react-hook-form";
import { RNInput } from "../../components/form-components";
import { useStore } from "../../helpers";
import { Typography } from "@mui/material";
import { ReportDefaultValues, ReportValidationSchema } from "./pbi-utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { safe_get } from "../../report-visuals";
import { useAddPBIReport } from "./pbi-hooks";

export const AddPBIReport = observer((props) => {
  const { reportStore } = useStore();
  const { addPBIReportModal } = reportStore;
  const { activePBIReport } = reportStore;
  const { addPBIReport, isLoading, isEditing, editPBIReport } =
    useAddPBIReport();

  const methods = useForm({
    defaultValues: ReportDefaultValues,
    resolver: yupResolver(ReportValidationSchema),
  });

  const closeModal = () => {
    reportStore.update_addPBIReportModal(false);
    methods.reset(ReportDefaultValues);
  };

  const onSubmit = (data) => {
    const id = safe_get(data, "id", null);
    if (id) {
      editPBIReport(data);
    } else {
      addPBIReport(data);
    }
    methods.reset(ReportDefaultValues);
  };

  useEffect(() => {
    methods.reset(activePBIReport);
  }, [activePBIReport]);

  if (isEditing || isLoading) return <LoadingState />;
  return (
    <>
      <TailwindModal isOpen={addPBIReportModal}>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="px-4 py-2 rounded-sm sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          >
            <div className="flex flex-col gap-y-4 align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full p-4">
              <Typography variant={"h6"} color={"primary"}>
                New Power BI Report
              </Typography>
              <RNInput name={"title"} label={"Title"} />
              <RNInput name={"passcode"} label={"PassCode"} />
              <RNInput name={"route"} label={"Route"} />
              <RNInput
                name={"link"}
                label={"Link"}
                multiline
                rows={4}
                fullWidth
              />
              <FormButtons onSave={() => {}} onCancel={closeModal} />
            </div>
          </form>
        </FormProvider>
      </TailwindModal>
    </>
  );
});
