import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useStore } from "../../../../../helpers";
import {
  useInvalidateMetaQuery,
  useRequestTypeMutation,
} from "../../../sr-hooks";
import apiRequestHandler from "../../../../utils";

export const useEditPriorityRequest = () => {
  const rootStore = useStore();

  const updatePriorityRequest = async (payload) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-priorities-update",
      payload,
      "Failed to add priority",
    );
  };
  return useRequestTypeMutation(
    updatePriorityRequest,
    "Priority updated Successfully.",
  );
};
