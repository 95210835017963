import React, { useEffect } from "react";
import { useStore } from "../../helpers";
import { observer } from "mobx-react-lite";
import { VoterCard } from "./Voters";
import { useTranslation } from "react-i18next";
import { EditVoter } from "./edit-voter";
import { FilterOverLay, SearchVoterList } from "../voter-list";
import { LoadingState, SideOverlay } from "../../components";
import { useGetFullProject } from "../../custom-hooks";
import { useParams } from "react-router";
import { VirtualList } from "../../components-new/VirtualList";

export const ManageVoters = observer(() => {
  const rootStore = useStore();
  const { voterListStore } = rootStore;
  const { FilterStatus, Voterlist } = voterListStore;
  const { edit_voter_modal } = voterListStore;
  const { t } = useTranslation("manage_voters");
  const { isLoading } = useGetFullProject();
  const { projectid } = useParams();

  const close_Filter_modal = () => {
    voterListStore.setFilterStatus(false);
    voterListStore.setShowVoterlist(true);
    voterListStore.setFilterVal(null);
    voterListStore.setSelectedBooths([]);
  };

  useEffect(() => {
    return () => voterListStore.reset_filters(parseInt(projectid));
  }, []);

  if (isLoading) return <LoadingState />;

  return (
    <>
      <div className="px-4 pb-4 flex flex-col gap-y-4 items-start">
        <SearchVoterList isManage={true} user={true} />
        <div className=" my-2 w-100">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
            {Voterlist?.map((data, index) => (
              <>
                <VoterCard voterdata={data} key={index} />
                {/* <VirtualList voterdata={data} key={index} /> */}
              </>
            ))}
          </div>
        </div>
      </div>

      <SideOverlay
        children={<FilterOverLay />}
        onClose={close_Filter_modal}
        show={FilterStatus}
        title={t("filter_votersearch")}
        buttontitle={"Apply Filters"}
        size={"large"}
      />

      <SideOverlay
        onClose={() =>
          voterListStore.update_edit_voter_modal(!edit_voter_modal)
        }
        show={edit_voter_modal}
        children={<EditVoter />}
        title={"Edit Voter"}
      />
    </>
  );
});

export default ManageVoters;
