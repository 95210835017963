import React from "react";
import { useStore } from "../../../../helpers";
import { PriorityCard, PriorityCardSkeleton } from "./priority-card";
import { AddPriority } from "./add-prority";
import { observer } from "mobx-react-lite";
import { DeletePriority } from "./delete-priority";
import { MasonryGrid } from "../../../../components-new";

export const PriorityHome = observer((props: any) => {
  const {
    serviceRequestsStore: { meta, loading },
  } = useStore();

  return (
    <div className={"overflow-hidden"}>
      <MasonryGrid
        data={meta?.priority}
        isLoading={loading}
        skeletonCount={8}
        renderItem={(priority) => (
          <PriorityCard key={priority["id"]} servicePriority={priority} />
        )}
        renderSkeleton={(key) => <PriorityCardSkeleton key={key} />}
      />
      <AddPriority />
      <DeletePriority />
    </div>
  );
});
