import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { safe_get } from "../../../../report-visuals";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  useStore,
  zeroPad,
  uploadBlob,
  uploadFile,
  htmlValidation,
  makeid_alpa_numeric,
} from "../../../../helpers";
import { ACBankDAO } from "../acbank_dao";
import { useParams, useNavigate, useLocation } from "react-router";
import {
  ViewAttachment,
  LoadingState,
  VoiceRecorder,
} from "../../../../components";

export const default_node = {
  root: {
    direction: "ltr",
    format: "",
    indent: 0,
    type: "root",
    version: 1,
    children: [
      {
        children: [
          {
            detail: 0,
            format: 0,
            mode: "normal",
            style: "",
            text: "Start writing..",
            type: "text",
            version: 1,
          },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "paragraph",
        version: 1,
      },
    ],
  },
};

export const AddTimeLine = observer((props: any) => {
  const rootStore = useStore();
  const { flexiStore } = rootStore;
  const { active_timeline } = flexiStore;
  const dao = new ACBankDAO(rootStore);
  const acno = dao.get_summary_field("acno", 0);
  const { key, id } = useParams();
  const path = `ka-ac-bank/ac_${zeroPad(flexiStore.ac_number, 3)}/tl`;
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState({});
  const [t] = useTranslation(["timeline", "beneficiary"]);
  const editorData = useRef(null);
  const navigate = useNavigate();

  const delete_file = (name) => {
    const tmp = _.clone(buffer);
    const index = tmp.findIndex((a) => a.path === name);
    tmp.splice(index, 1);
    setBuffer(tmp);
  };

  const [inputKey, setInputKey] = useState("");

  const Itimeline = {
    state: key.slice(0, 2),
    acno: acno,
    summary: { title: "" },
    attach: { files: [], audio_files: [] },
    details: default_node,
  };
  let { state } = useLocation();
  const { timeline, edit } = state;
  const [buffer, setBuffer] = useState(
    edit
      ? safe_get(timeline, "attach.files", [])
      : Itimeline["attach"]["files"],
  );
  const [timeLine, setTimeLine] = useState(edit ? timeline : Itimeline);
  const [audio_response, setAudioResponse] = useState("");
  const onSubmit = async () => {
    setLoading(true);
    const clone = _.clone(timeLine);
    clone["details"] = editorData.current;
    if (editorData.current === null) {
      toast.error("Please save the document");
      setLoading(false);
      return;
    }
    if (!flexiStore.timeline_edit_mode) {
      const res = await flexiStore.add_timeline(clone);
      if (res.status === 200) {
        toast.success("Timeline Added successfully..");
        localStorage.setItem("timeline", JSON.stringify(clone));
        // navigate(-1)
      }
    } else {
      const response = await flexiStore.update_timeline(clone);
      if (response.status === 200) {
        toast.success("Timeline Updated successfully...");
        localStorage.setItem("timeline", JSON.stringify(clone));
        // navigate(-1)
      }
    }
    const payload = dao.get_timeline_payload();
    await flexiStore.get_timeline(payload);
    setLoading(false);
  };

  const onEditorSave = async (editorState) => {
    editorData.current = editorState;
    await onSubmit();
  };

  useEffect(() => {
    setTimeLine({ ...timeLine, attach: { files: buffer } });
  }, [buffer, audio_response]);

  const uploadFileHandler = (event) => {
    htmlValidation(event);
    if (!file["file"]) return false;

    const s3Path = uploadFile(path, file, "urn-fsd");
    if (s3Path) {
      setFile({});
      const randomString = Math.random().toString(6);
      setInputKey(randomString);
    }
    const file_type = file["file"]["type"];
    setBuffer([...buffer, { path: s3Path, type: file_type }]);
  };

  const titleChangeHandler = (event) => {
    const txt = event.target.value;
    setTimeLine({
      ...timeLine,
      summary: { ...timeLine.summary, title: txt },
    });
  };

  const fileChangeHandler = (event) => {
    const file = event.target.files[0];
    setFile({ ...file, file });
  };

  const onRecorderSubmit = async (recordedOutput) => {
    if (recordedOutput) {
      try {
        const id = makeid_alpa_numeric(8);
        const medianame = `${id}.mp4`;
        const path = `ka-ac-bank/ac_${zeroPad(acno, 3)}/timeline/audioclips`;
        const response = await uploadBlob(
          path,
          medianame,
          recordedOutput,
          "urn-fsd",
        );
        setAudioResponse(response["key"]);
        setBuffer([
          ...buffer,
          { path: `${path}/${medianame}`, type: "audio/mp4" },
        ]);
        toast.success("recording uploaded successfully");
      } catch (e) {
        console.log("something went wrong", e);
      }
    }
  };

  const deleteAudioResponse = () => {
    setAudioResponse(null);
  };

  const editor_initial_data = safe_get(timeLine, "details", default_node);
  if (!flexiStore.active_timeline) return null;

  return (
    <div
      className="pointer-events-auto relative w-screen max-w-[1200px] mx-auto"
      key={`tl_edit_${safe_get(flexiStore.active_timeline, "id", 1)}`}
    >
      {loading && <LoadingState />}
      {/*<form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col bg-white">*/}
      <div className="flex-1" style={{ backgroundColor: "#F3F4F6" }}>
        {/* Divider container */}
        <div className="sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
          {/* name */}
          <div className="grid grid-cols-3 gap-4">
            <div className="flex flex-col mb-2">
              <label
                htmlFor="name"
                className="block font-bold text-gray-900 sm:mt-px sm:py-1"
              >
                {t("title")}
              </label>
              <input
                type="text"
                name="name"
                id="name"
                value={safe_get(timeLine, "summary.title", "")}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2"
                onChange={titleChangeHandler}
              />
            </div>
            <div className="flex flex-row justify-between items-end flex-wrap">
              <div className="flex flex-col">
                <label
                  htmlFor="project-name"
                  className="block font-bold text-gray-900 sm:mt-px sm:py-1"
                >
                  {t("attachments")}
                </label>
                <input
                  key={inputKey}
                  type="file"
                  name="attachments"
                  id="attachments"
                  className="py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => fileChangeHandler(e)}
                />
              </div>
              <button
                className="btn btn-link"
                onClick={(e) => uploadFileHandler(e)}
              >
                {t("upload", { ns: "beneficiary" })}
              </button>
            </div>
            <div className="flex-shrink-0 px-4 py-5 sm:px-6 ">
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={() => navigate(-1)}
                >
                  {t("cancel", { ns: "beneficiary" })}
                </button>
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={(e) => {
                    htmlValidation(e);
                    onSubmit();
                  }}
                >
                  {flexiStore.timeline_edit_mode ? "Update" : "Add New"}
                </button>
              </div>
            </div>
          </div>
          {buffer.length > 0 && (
            <div className="font-bold flex-col items-center px-2 flex-wrap">
              {t("uploaded_documents", { ns: "beneficiary" })}
            </div>
          )}
          <div className="flex flex-row items-center flex-wrap">
            {buffer.map((x, index) => {
              return (
                <div key={index} className="flex flex-row items-center p-2">
                  <ViewAttachment
                    key={x.path}
                    attachment={x}
                    s3_bucket={"urn-fsd"}
                  />
                  {/*{file_icon(x.type.split('/')[0])}*/}
                  {/*<p className="px-2">{x.path.split('/')[3]}</p>*/}
                  <i
                    className="fa-solid fa-trash-can text-red-600 px-4"
                    onClick={() => delete_file(x.path)}
                  />
                </div>
              );
            })}
          </div>

          {/*<div className="py-4 flex flex-col ">*/}
          {/*    <div>*/}
          {/*        /!*<label htmlFor="description"*!/*/}
          {/*        /!*       className="block font-bold text-gray-900 sm:mt-px sm:py-1">{t("description")}</label>*!/*/}
          {/*        <BrihaspathiRichEditor*/}
          {/*            editorData={_.isEmpty(editor_initial_data) ? default_node : editor_initial_data}*/}
          {/*            editorOnChange={onEditorSave}*/}
          {/*            editable={true}*/}
          {/*        />*/}
          {/*    </div>*/}
          {/*</div>*/}
          <div className={"pb-8"}>
            <VoiceRecorder
              handleAudioUpload={onRecorderSubmit}
              handleReset={deleteAudioResponse}
            />
            <p className={"font-bold text-xl py-2"}>Instructions:-</p>
            <ul className="list-decimal px-4">
              <li>Click on the mic to start recording.</li>
              <li>
                Once you start the recording, <b>Pause</b> and <b>Stop</b>{" "}
                buttons will appear.
              </li>
              <li>
                If your recording is done, then press stop button, and{" "}
                <b>Upload</b> the file by clicking "Upload" button.
              </li>
              <li>
                <b>Clear</b> will remove the currently recorded audio.
              </li>
              <li>
                As a final step click on <b>Save</b> button at the top.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
});
