import React from "react";

export const VoterInfo = ({ name, localname }) => {
  return (
    <>
      <div className="flex items-center justify-between py-3 bg-[#F5F0FF] mb-[24px] py-[12px] px-[36px]">
        <h2 className="font-medium text-[16px] leading-5 ">
          {name}
          <p className="mt-[10px]">{localname}</p>
        </h2>

        {/* <div className="flex space-x-1 p-1.5 rounded-full shadow-inset-custom">
          {[...Array(3)].map((_, index) => (
            <div
              key={index}
              className="w-3.5 h-3.5 bg-[#F97D09] rounded-full"
            ></div>
          ))}
          {[...Array(2)].map((_, index) => (
            <div
              key={index}
              className="w-3.5 h-3.5 border border-[#F97D09] rounded-full"
            ></div>
          ))}
        </div> */}
      </div>
    </>
  );
};
