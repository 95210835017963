import { useParams } from "react-router-dom";
import { FTPBreadCrumbs } from "../../../components-new";
import React from "react";

export const SRAddCrumbs = (props) => {
  const { projectid } = useParams();
  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: false },
    {
      name: "Service Requests",
      href: `/project/${projectid}/servicerequests`,
      current: false,
    },
    { name: "Add Service Request", href: `#`, current: true },
  ];
  return <FTPBreadCrumbs crumbs={crumbs} />;
};
