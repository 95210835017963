import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import {
  PhoneIcon,
  MapPinIcon,
  IdentificationIcon,
} from "@heroicons/react/24/solid";
import { useTranslation } from "react-i18next";
import { Avatar } from "@mui/material";

export const Profile = observer((props: any) => {
  const { userStore, voterListStore } = useStore();
  const [t] = useTranslation("user_management");
  const { activeUser, userPermissionsAndParts } = userStore;
  const VoterProfile = userPermissionsAndParts?.has_voter_profile;

  return (
    <div className="flex items-center space-y-4 p-2 mb-4 ">
      <Avatar
        sx={{ width: 150, height: 150 }}
        src={activeUser.profileimageuri}
        alt={activeUser.name}
      />

      <div className="text-lg flex flex-col items-start leading-6 font-medium space-y-1 ml-7">
        <h3>
          {activeUser.name}{" "}
          <span className="text-indigo-600 capitalize">
            ({activeUser.role})
          </span>
        </h3>
        <p className="mt-2 flex items-center text-gray-500">
          <IdentificationIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span>{activeUser.id}</span>
        </p>
        <p className="mt-2 flex items-center text-gray-500">
          <PhoneIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span>{activeUser.phonenum}</span>
        </p>
        <p className="mt-2 flex items-center text-gray-500">
          <MapPinIcon
            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span>
            {activeUser.address} {activeUser.city}{" "}
          </span>
        </p>
        <p
          className={
            activeUser.active === true ? "text-green-500" : "text-red-500"
          }
        >
          {activeUser.active === true ? "Active" : "InActive"}
        </p>

        {!VoterProfile && (
          <h3>
            User Attach VoterProfile To{" "}
            <span
              className="text-indigo-600 capitalize underline cursor-pointer"
              onClick={() => {
                voterListStore.setShowVoterlist(true);
              }}
            >
              {t("connect")}
            </span>
          </h3>
        )}
      </div>
    </div>
  );
});
