import { Button, Chip, ListItem, Typography } from "@mui/material";
import { t } from "i18next";
import React, { useState, useEffect } from "react";
import { useStore } from "../../../helpers";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export const FilteredTags = () => {
  const {
    projectWorkStoreV2: { filters, resetFilters },
  } = useStore();
  const result = { filters: [] };
  const [filterTags, setFilterTags] = useState([]);
  useEffect(() => {
    setFilterTags([
      ...filters.status,
      //@ts-ignore
      ...filters.villages?.map((v) => v.label),
    ]);
  }, [filters]);
  const handleClearFilter = async (item) => {
    setFilterTags(filterTags.filter((tag) => tag !== item));
  };

  return (
    <>
      {filterTags.length > 0 && (
        <ul className="flex flex-wrap justify-between items-center p-2 border border-[#808080] rounded-[1.125rem] mb-[1.125rem] w-[80vw]">
          <div className="flex  overflow-x-auto  items-center">
            <Typography variant="body1">{t("Filtered By :")}</Typography>
            {filterTags.map((item, key) => {
              return (
                <>
                  <ListItem key={key} className="space-x-2">
                    <Chip
                      label={`${item}`} // Handle single value case
                      onDelete={() => handleClearFilter(item)} // Pass full data object to handleDelete
                    />
                  </ListItem>
                </>
              );
            })}
          </div>
          {/* clear filters */}
          <Button
            variant="text"
            color="error"
            startIcon={<CloseOutlinedIcon />}
            onClick={async () => {
              resetFilters();
              setFilterTags([]);
            }}
            sx={{
              "& .MuiButton-startIcon": {
                marginRight: "2px",
              },
            }}
          >
            {t("Clear Filters")}
          </Button>
        </ul>
      )}
    </>
  );
};
