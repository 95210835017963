import React, { useEffect, useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

interface StackedColumnChartProps {
  chartId: string;
  chartData: Array<{ category: string } & Record<string, number>>;
  seriesKeys: string[];
  tiltLabels?: boolean;
  chartColors?: {
    background?: string;
    bars?: string;
    text?: string;
    xAxis?: string;
  };
  seriesColors?: Record<string, string>;
  labelPosition?: "inside" | "above";
  chartTitle?: string;
  titleColor?: string;
}

const StackedColumnChart: React.FC<StackedColumnChartProps> = ({
  chartId,
  chartData,
  seriesKeys,
  tiltLabels = false,
  chartColors,
  seriesColors,
  labelPosition = "inside",
  chartTitle = "",
  titleColor = "white",
}) => {
  const chartRef = useRef<am4charts.XYChart | null>(null);

  useLayoutEffect(() => {
    if (!chartData.length || !seriesKeys.length) return;

    let chart = am4core.create(chartId, am4charts.XYChart);
    chart.paddingRight = 20;
    chart.data = chartData;

    if (chartColors?.background) {
      chart.background.fill = am4core.color(chartColors.background);
    }

    if (chartTitle) {
      let title = chart.titles.create();
      title.text = chartTitle;
      title.fontSize = 30;
      title.fill = am4core.color(titleColor);
      title.align = "left";
      title.marginTop = 10;
    }

    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "category";
    categoryAxis.renderer.grid.template.disabled = true;
    categoryAxis.renderer.minGridDistance = 30;

    if (chartColors?.text) {
      categoryAxis.renderer.labels.template.fill = am4core.color(
        chartColors.text
      );
    }

    if (chartColors?.xAxis) {
      categoryAxis.renderer.line.stroke = am4core.color(chartColors.xAxis);
      categoryAxis.renderer.line.strokeWidth = 2;
      categoryAxis.renderer.line.strokeOpacity = 1;
    }

    if (tiltLabels) {
      categoryAxis.renderer.labels.template.rotation = 60;
      categoryAxis.renderer.labels.template.horizontalCenter = "left";
      categoryAxis.renderer.labels.template.verticalCenter = "middle";
    }

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.min = 0;
    valueAxis.max = undefined;
    valueAxis.calculateTotals = true;
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.grid.template.disabled = true;
    valueAxis.strictMinMax = false;
    console.log(chartData); 
    seriesKeys.forEach((key, index) => {
      let series = chart.series.push(new am4charts.ColumnSeries());
      series.dataFields.valueY = key;
      series.dataFields.categoryX = "category";
      series.name = key;
      series.stacked = true;
      series.columns.template.tooltipText = `{name}: {valueY}`;
      series.columns.template.minHeight = 10;

      if (seriesColors && seriesColors[key]) {
        series.columns.template.fill = am4core.color(seriesColors[key]);
      } else if (chartColors?.bars) {
        series.columns.template.fill = am4core.color(chartColors.bars);
      }

      if (chartColors?.bars) {
        series.columns.template.fill = am4core.color(chartColors.bars);
      }

      if (index === 0) {
        series.columns.template.column.cornerRadiusBottomLeft = 20;
        series.columns.template.column.cornerRadiusBottomRight = 20;
      } else {
        series.columns.template.column.cornerRadiusBottomLeft = 0;
        series.columns.template.column.cornerRadiusBottomRight = 0;
      }

      if (index === seriesKeys.length - 1) {
        series.columns.template.column.cornerRadiusTopLeft = 20;
        series.columns.template.column.cornerRadiusTopRight = 20;
      } else {
        series.columns.template.column.cornerRadiusTopLeft = 0;
        series.columns.template.column.cornerRadiusTopRight = 0;
      }

      let labelBullet = series.bullets.push(new am4charts.LabelBullet());
      labelBullet.label.text = "{valueY}";
      labelBullet.label.fill = am4core.color("white");
      labelBullet.label.fontSize = 16;

      labelBullet.label.adapter.add("text", function (text, target) {
        return target.dataItem &&
          target.dataItem.values?.valueY?.workingValue === 0
          ? ""
          : text;
      });

      categoryAxis.renderer.labels.template.horizontalCenter = "middle";
      categoryAxis.renderer.labels.template.textAlign = "middle";
      categoryAxis.renderer.labels.template.maxWidth = 100;
      categoryAxis.renderer.labels.template.wrap = true;

      if (labelPosition === "above") {
        labelBullet.dy = -10;
      } else {
        labelBullet.locationY = 0.5;
        labelBullet.label.fill = am4core.color("white");
      }
    });

    chart.legend = new am4charts.Legend();
    chart.legend.position = "bottom";
    chart.legend.marginTop = 32;
    chart.responsive.enabled = true;

    if (chartColors?.text) {
      chart.legend.labels.template.fill = am4core.color(chartColors.text);
    }

    
    chartRef.current = chart;

    return () => {
      chart.dispose();
    };
  }, [
    chartId,
    chartData,
    seriesKeys,
    tiltLabels,
    chartColors,
    labelPosition,
    chartTitle,
    titleColor,
  ]);

  return <div id={chartId} className="w-full h-screen"></div>;
};

export default StackedColumnChart;
