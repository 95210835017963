import { safe_get } from "../../../report-visuals";
import { makeid_alpa_numeric, useStore } from "../../../helpers";
import { useParams } from "react-router";
import _ from "lodash";
import { get_booth_node_by_boothnumber } from "../../../custom-hooks/useBoothNumber";
import dayjs from "dayjs";

export const useCookGrievancePayload = () => {
  const { projectStore, userStore, grievanceStore, grievanceStoreV2 } =
    useStore();
  const { richMedia, attachments } = grievanceStoreV2;
  const { projectid } = useParams();
  const grievanceProperties = projectStore.getProjectProperty("grievances", {});
  const beneficiaryConfiguration = projectStore.getProjectProperty(
    "beneficiary",
    {},
  );

  const addPayloadGetter = async (data, previous_r1_data) => {
    const concerned_person_raw = safe_get(
      beneficiaryConfiguration,
      "owners",
      [],
    );

    const assigned_office_person = concerned_person_raw.find(
      (person) => person.name === data.assigned,
    );

    const authority_person_raw = safe_get(
      grievanceProperties,
      `departments.${data?.category_level_1}.owners`,
      [],
    );

    const assigned_authority_person = authority_person_raw.find(
      (person) => person.name === data?.assigned_office_person,
    );

    const village_details = data?.piv_level_2?.map((option) =>
      get_booth_node_by_boothnumber(option),
    );

    const summary_payload = {
      ...data,
      assigned: { ...assigned_office_person },
      assigned_office_person: assigned_authority_person,
      description: data?.more_details,
      piv_level_2: village_details,
      sla: "1w 1h 2d",
    };

    let r1Payload = {
      timeline_log: [
        {
          id: makeid_alpa_numeric(3),
          status: data?.current_status,
          comments: data?.description,
          ts: dayjs().format("DD/MM/YYYY HH:mm:ss"),
          who: {
            //current user info
            name: userStore.user.profile.name,
            phone: userStore.user.phonenum,
            address: userStore.user.profile.address,
            comments: data?.more_details,
            profileImage: userStore.user.profileimageuri,
          },
        },
        ...previous_r1_data,
      ],
      richmedia: richMedia,
      attachments: attachments,
      voters: safe_get(grievanceStore, "selected_voters", []),
    };
    console.log(data);
    return {
      key: "grievance",
      renderkey: "grievance",
      properties: {
        projectid: parseInt(projectid),
        summary: {
          ...summary_payload,
          tracing_id: `P${projectid}G`,
        },
        r1: {
          ...r1Payload,
        },
      },
    };
  };

  const editPayloadGetter = (formData, dataToEdit) => {
    const toUpdateData = _.cloneDeep(dataToEdit);
    console.log(toUpdateData);
    const merged_object = _.merge({}, toUpdateData, formData);

    return {
      summaryPayload: merged_object.summary,
      r1Payload: merged_object.r1,
    };
  };

  return {
    addPayloadGetter,
    editPayloadGetter,
  };
};
