import { useStore } from "../../../helpers";
import { useRequestTypeMutation } from "./useAddServiceRequestType";
import { useParams } from "react-router-dom";
import { useLocalStorage } from "react-use";

export const useAddServiceRequestSubject = () => {
  const rootStore = useStore();
  const { projectid } = useParams();
  const [value, setValue] = useLocalStorage("sr-subject", "{}");

  const addSerivceRequest = async (payload: any) => {
    const { profile, additional, ...others } = payload;
    const finalPayload = {
      projectid: parseInt(projectid),
      voter_attached: false,
      subject: {
        profile,
        ...others,
      },
      additional,
      more: {},
    };
    const response = await rootStore.apiRequestHandler(
      "unified_service_request/sr-subjects-add",
      finalPayload,
      "Couldn't create service request, please try again later",
    );
    if (response.ok) {
      setValue(JSON.parse(JSON.stringify(response?.data)));
    }
    return response;
  };

  return useRequestTypeMutation(
    addSerivceRequest,
    "Service request added successfully",
  );
};

export const useUpdateServiceRequestSubject = () => {
  const rootStore = useStore();

  const updateServiceRequest = async (payload: any) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-subjects-update",
      payload,
      "Couldn't update service request, please try again later",
    );
  };

  return useRequestTypeMutation(
    updateServiceRequest,
    "Service request updated successfully",
  );
};

export const useDeleteServiceRequestSubject = () => {
  const rootStore = useStore();

  const deleteServiceRequest = async (payload: any) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/service-requests-delete",
      payload,
      "Couldn't delete service request, please try again later",
    );
  };

  return useRequestTypeMutation(
    deleteServiceRequest,
    "Service request deleted successfully",
  );
};

export const useAddServiceRequest = () => {
  const rootStore = useStore();

  const addSerivceRequest = async (payload: any) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/service-requests-add",
      payload,
      "Couldn't create service request, please try again later",
    );
  };

  return useRequestTypeMutation(
    addSerivceRequest,
    "Service request added successfully",
  );
};

export const useUpdateServiceRequest = () => {
  const rootStore = useStore();

  const addSerivceRequest = async (payload: any) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/service-requests-update",
      payload,
      "Couldn't update service request, please try again later",
    );
  };

  return useRequestTypeMutation(
    addSerivceRequest,
    "Service request updated successfully",
  );
};
