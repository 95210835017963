import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BoothTree } from "../BoothTree";
import { FormHelperText, Typography } from "@mui/material";
import { FormLabel } from "../../components-new";

type RNBoothSelectorPropTypes = {
  name: string;
  label?: string;
  isMulti: boolean;
  selector: string;
  defaultValue?: any;
};

export const RNBoothSelector = (props: RNBoothSelectorPropTypes) => {
  const { label, name, isMulti, selector } = props;
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={`flex flex-col`}>
      <FormLabel> {label}</FormLabel>
      <Controller
        name={name}
        control={control}
        // defaultValue={props.defaultValue || null}

        render={({ field: { value, onChange }, fieldState: { error } }) => {
          return (
            <>
              <BoothTree
                onBoothSelection={(data) => {
                  console.log(data);
                  onChange(data);
                }}
                selected_booth={value}
                selector={selector}
                isMulti={isMulti}
              />
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </>
          );
        }}
      />
    </div>
  );
};
