import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Link, useParams } from "react-router-dom";
import { BeneficiariesTable } from "./beneficiaries-table";
import { AddBeneficiary } from "./add-beneficiary";
import { excel_col_def } from "./beneficiary-utils";
import { useTranslation } from "react-i18next";
import {
  DownloadAsExcelSheet,
  FilterButton,
  FSDPagination,
  LoadingState,
  ProjectHeader,
  SideOverlay,
} from "../../components";
import {
  FilterOverLay,
  SearchVoterList,
  VoterListWithRadioButton,
} from "../voter-list";
import { Button, Stack } from "@mui/material";
import { useGetFullProject, useIsMobile } from "../../custom-hooks";
import { useQuery } from "@tanstack/react-query";
import { BeneficiaryFilters } from "./beneficiaryFilters";
import { RNSearchBar } from "../influential-leaders";
import { toast } from "react-toastify";
import { useStore } from "../../helpers";
import { BeneficiaryCrumbs } from "./beneficiary-crumbs";
import BeneficiaryView from "./beneficiary-view";

const BeneficiaryManagemet = observer((props) => {
  const { userStore, beneficiaryStore, flexiStore, voterListStore } =
    useStore();
  const { projectid } = useParams();
  const isMobile = useIsMobile();
  const { t } = useTranslation(["beneficiary", "voter_list"]);
  const { add_beneficiary_modal, BeneficiariesFilter, filters } =
    beneficiaryStore;
  const { showVoterlist, FilterStatus } = voterListStore;
  const { fsd_search_page_size, fsd_search_current_page } = flexiStore;
  const [query, setQuery] = useState("");
  const [total, setTotal] = useState(0);
  const totalPages = Math.ceil(total / fsd_search_page_size);
  const { isLoading: fullProjectLoading } = useGetFullProject();

  const handlePerPageChange = (event) => {
    flexiStore.update_flexistore_property(
      "fsd_search_page_size",
      parseInt(event.target.value),
    );
  };

  const onVoterSelection = (voter) => {
    beneficiaryStore.update_add_beneficiary_modal(true);
    voterListStore.setShowVoterlist(false);
  };

  const fetchBeneficiries = async () => {
    await flexiStore.set_fsd_search_query(query);
    const res = await flexiStore.fetch_fsdlist(
      "beneficiary",
      parseInt(projectid),
      filters,
    );
    if (!res.ok) {
      throw new Error("Something went wrong");
    } else return res;
  };

  useEffect(() => {
    //TODO: Reset filters when this component unmounts
    return () => {
      beneficiaryStore.resetFilters();
    };
  }, []);

  const { isLoading } = useQuery({
    queryKey: [
      "beneficiaray",
      projectid,
      filters,
      query,
      fsd_search_current_page,
    ],
    queryFn: fetchBeneficiries,
    onSuccess: (data) => {
      beneficiaryStore.set_beneficiries(data["data"]["results"]);
      setTotal(data["data"]["total"]);
    },
    onError: (e) => toast.error(e["message"]),
  });

  const addNewBeneficiaryHandler = async () => {
    beneficiaryStore.update_add_beneficiary_modal(true);
    await beneficiaryStore.set_active_beneficiary({});
    await voterListStore.update_active_voter({});
    await beneficiaryStore.resetActiveFamily();
    await beneficiaryStore.resetActiveRequest();
  };

  const close_modal = async () => {
    beneficiaryStore.update_add_beneficiary_modal(false);
    await voterListStore.update_active_voter({});
  };

  const close_Filter_modal = () => {
    voterListStore.setFilterStatus(false);
    voterListStore.setShowVoterlist(true);
    voterListStore.setFilterVal(null);
    voterListStore.setSelectedBooths([]);
  };

  const close_benificieryFilter = () => {
    beneficiaryStore.setBeneficiarieFilter(false);
  };

  const close_voterList = () => {
    voterListStore.setShowVoterlist(false);
    voterListStore.update_active_voter({});
  };

  const downloadable_format =
    beneficiaryStore?.beneficiaries?.length > 0 &&
    beneficiaryStore.beneficiaries.map(
      (beneficiary) => beneficiary["summary"].profile,
    );

  if (isLoading || fullProjectLoading) return <LoadingState />;
  return (
    <>
      {!isMobile && (
        <>
          <BeneficiaryCrumbs />
          <ProjectHeader />
        </>
      )}
      <div className="p-4 overflow-hidden primary-text">
        <div className="flex align-center items-start gap-x-2">
          <div className="flex flex-row justify-between items-center mb-4 flex-1">
            <h2 className="font-bold text-3xl text-indigo-500 pr-14">
              {t("b_m")}
            </h2>
            <div className={"flex items-start justify-between gap-x-4"}>
              <RNSearchBar onSearch={(value) => setQuery(value)} />
              <Button
                variant={"contained"}
                startIcon={<i className="fas fa-plus-circle" />}
                onClick={addNewBeneficiaryHandler}
              >
                {t("n_b")}
              </Button>
            </div>
          </div>
          <FilterButton
            filterOnClickHandler={() =>
              beneficiaryStore.setBeneficiarieFilter(true)
            }
          />

          {(userStore.isRoot() || userStore.isClient()) && (
            <Link
              to={`/project/${projectid}/beneficiary_management/admin`}
              className="flex ml-4"
            >
              <i className={"fa-solid fa-gear text-4xl"} />
            </Link>
          )}
        </div>
        <Stack
          spacing={2}
          className="w-full"
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <FSDPagination key={"Beneficiary"} totalPages={totalPages} />
          <DownloadAsExcelSheet
            data={downloadable_format}
            cols={excel_col_def}
            name={`S`}
          />
        </Stack>

        <BeneficiariesTable />
        <BeneficiaryView />
      </div>
      <SideOverlay
        children={<FilterOverLay />}
        onClose={close_Filter_modal}
        show={FilterStatus}
        title={t("filter_votersearch")}
      />

      <SideOverlay
        children={<BeneficiaryFilters />}
        onClose={close_benificieryFilter}
        show={BeneficiariesFilter}
        title={t("Beneficiary Filters")}
      />
      <SideOverlay
        children={<AddBeneficiary />}
        onClose={close_modal}
        show={add_beneficiary_modal}
        title={t("add_new_beneficiary")}
        size={"small"}
      />
      <SideOverlay
        onClose={close_voterList}
        show={showVoterlist}
        title={t("search_voters")}
        size={"7xl"}
      >
        <SearchVoterList />
        <VoterListWithRadioButton onVoterSelection={onVoterSelection} />
      </SideOverlay>
    </>
  );
});

export default BeneficiaryManagemet;
