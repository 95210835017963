import React from "react";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Pagination } from "@mui/material";

const useStyles = makeStyles<Theme>((theme) => ({
  ul: {
    "& .MuiPaginationItem-root": {
      "&:hover": { backgroundColor: "#282828", color: "#fff" },
    },
  },
  pagination: {
    border: "1px solid #808080",
    borderRadius: "15px",
    "& .MuiPaginationItem-root": {
      margin: "0px",
      padding: "0px",
      minWidth: "50px",
      height: "50px",
      borderRadius: "0px",
      border: "0px",
      fontSize: "16px",

      // Mobile (small screens)
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
        minWidth: "35px",
        height: "40px",
      },
      // Tablet
      [theme.breakpoints.between("sm", "md")]: {
        fontSize: "14px",
        minWidth: "35px",
      },
    },
    "& .MuiPaginationItem-root[aria-label='Go to previous page']": {
      borderRadius: "15px 0px 0px 15px",
    },
    "& .MuiPaginationItem-root[aria-label='Go to next page']": {
      borderRadius: "0px 15px 15px 0px",
    },

    "& .MuiSvgIcon-root": {
      color: "#b3b1b1",
    },
    "& .MuiPaginationItem-root.Mui-selected": {
      color: "#fff",
      backgroundColor: "#1B1B1D",
    },
  },
}));

export const FTPPagination = ({ totalPages, pageNumber, handlePageChange }) => {
  const classes = useStyles();

  return (
    <Pagination
      count={totalPages}
      page={pageNumber}
      onChange={handlePageChange}
      variant="outlined"
      shape="rounded"
      color="primary"
      classes={{ ul: classes.ul, root: classes.pagination }}
    />
  );
};
