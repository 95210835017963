import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Avatar, IconButton, Typography } from "@mui/material";
import { useStore } from "../../helpers";
import { SideOverlay } from "../../components";
import { UserProfile } from "./user-profile";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { safe_get } from "../../report-visuals";
import { useLocalStorage } from "react-use";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/zoom.css";

export const labelStyle =
  "w-64 rounded-xl border border-gray-300 bg-white px-4 py-2 text-gray-700 shadow-md mt-1";

export const ProfileMenu = observer(() => {
  const rootStore = useStore();
  const { userStore } = rootStore;
  const [userprofile] = useLocalStorage("__userprofile");
  const [profileImage, setProfileImage] = useState(null);

  const handleClose = () => {
    userStore.set_profile_open(false);
  };

  useEffect(() => {
    setProfileImage(safe_get(userprofile, "profileimageuri", null));
  }, [userprofile]);

  const close_modal = () => {
    rootStore.update_user_profile(false);
  };

  const handleEditProfile = () => {
    rootStore.update_user_profile(true);
    userStore.set_profile_open(false);
  };

  return (
    <div>
      <Menu
        transition
        direction={"right"}
        arrow={true}
        position={"anchor"}
        menuButton={
          <MenuButton>
            <Avatar
              src={profileImage}
              className={"cursor-pointer"}
              sx={{
                width: 65,
                height: 65,
                display: {
                  xs: "none",
                  md: "flex",
                },
                zIndex: 10,
              }}
              alt={userprofile["name"]}
            />
          </MenuButton>
        }
        menuClassName={"z-99 static bg-red-300 mx-4 overflow-x-hidden "}
      >
        <MenuItem>
          <div className="flex flex-col rounded-3xl bg-grey-100 p-4">
            <div className="flex flex-row justify-between">
              <Typography variant="h6">User Profile</Typography>
              <IconButton
                onClick={() => handleClose()}
                sx={{
                  border: "1px #F0F0F0",
                }}
                size={"large"}
              >
                <CloseIcon className="inline-block" />
              </IconButton>
            </div>
            <div className="flex flex-row items-center gap-x-4">
              <div>
                <Avatar
                  src={safe_get(userprofile, "profileimageuri", null)}
                  alt={safe_get(userprofile, "name", null)}
                  variant={"rounded"}
                  sx={{
                    width: { xs: "75px", md: "150px" },
                    height: { xs: "75px", md: "150px" },
                    borderRadius: "30px",
                  }}
                />
              </div>
              <div className="flex flex-col ml-4 gap-1">
                <Typography variant="h5">
                  {safe_get(userprofile, "profile.name", null)}
                </Typography>
                <Typography variant="body1">
                  {safe_get(userprofile, "profile.city", null)}
                </Typography>
                <p
                  className=" flex flex-row bg-primary-light text-common-white text-sm p-2 justify-center  gap-1 rounded-full cursor-pointer"
                  onClick={handleEditProfile}
                >
                  <span>
                    {" "}
                    <EditIcon
                      sx={{ fontSize: 16 }}
                      className="text-common-white"
                    />
                  </span>
                  Edit my profile
                </p>
              </div>
            </div>
            <div className="flex flex-col">
              <div className="flex flex-row gap-3">
                <div className="flex flex-col mt-4">
                  <Typography variant="body1">Address</Typography>
                  <p className={labelStyle}>
                    {safe_get(userprofile, "profile.address", null)}
                  </p>
                </div>
                <div className="flex flex-col mt-4">
                  <Typography variant="body1">Phone number</Typography>
                  <p className={labelStyle}>
                    {safe_get(userprofile, "phonenum", null)}
                  </p>
                </div>
              </div>
              <div className="flex flex-row gap-3">
                <div className="flex flex-col mt-4">
                  <Typography variant="body1">Role</Typography>
                  <p className={labelStyle}>
                    {" "}
                    {safe_get(userprofile, "role", null)}
                  </p>
                </div>
                <div className="flex flex-col mt-4">
                  <Typography variant="body1">User ID</Typography>
                  <p className={labelStyle}>
                    {" "}
                    {safe_get(userprofile, "id", null)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </MenuItem>
      </Menu>
      <SideOverlay
        children={<UserProfile />}
        onClose={close_modal}
        show={rootStore.active_user_profile}
        title="Edit Profile"
      />
    </div>
  );
});
