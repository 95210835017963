import React from "react";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  requestCardContainer: (props) => ({
    //background: `linear-gradient(225deg, ${props.reqtype.color} 20%, #ffffff 75%)`,
    //background: `linear-gradient(125deg, ${props.reqtype.color} 20%, #ffffff 85%)`,
    background: `linear-gradient(225deg, ${props.reqtype?.color || "#ccc"} 20%, #ffffff 75%)`,
    opacity: 1,
    zIndex: 0,
    padding: "1rem",
    borderRadius: "1.5rem",
    marginTop: "30px",
  }),
  tagchip: {
    margin: "0px 10px",
  },
  requestaddForm: (props) => ({
    height: props.reqheight * 58,
  }),
});
