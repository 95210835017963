import { observer } from "mobx-react-lite";
import React, { useState, lazy, useEffect } from "react";
import { SRAddCrumbs } from "./sr-components";
import { FeatureHeader } from "../../components-new";
import { SideOverlay } from "../../components";
import {
  FilterOverLay,
  SearchVoterList,
  VoterListWithRadioButton,
} from "../../features";
import { useStore } from "../../helpers";
import Tab from "@mui/material/Tab";
import AddServiceRequestSubject from "./add-service-request-subject";
import { StyledTabs } from "./sr-styles";
import { TabPanel, TabContext } from "@mui/lab";
import { useTranslation } from "react-i18next";

const AddServiceRequest = lazy(
  () => import("../service-requests/add-service-request")
);

const TABS = ["Beneficiary Details", "Request and Related Information"];

const AddServiceRequestHome = observer((props: any) => {
  const { voterListStore } = useStore();
  const close_voterList = () => {
    voterListStore.setShowVoterlist(false);
  };
  const { t } = useTranslation(["beneficiary", "voter_list"]);
  const { showVoterlist, FilterStatus } = voterListStore;
  const [currentTabValue, setCurrentTabValue] = useState(TABS[0]);
  const navigateNextTab = () => {
    setCurrentTabValue(TABS[1]);
  };

  const close_Filter_modal = () => {
    voterListStore.setFilterStatus(false);
    voterListStore.setShowVoterlist(true);
    voterListStore.setFilterVal(null);
    voterListStore.setSelectedBooths([]);
  };

  return (
    <>
      <div className={"px-4 pb-4"}>
        <SRAddCrumbs />
        <FeatureHeader title={"Add new Service Request"} />
        <TabContext value={currentTabValue}>
          <StyledTabs
            // @ts-ignore
            indicatorColor={"primary.main"}
            variant={"fullWidth"}
            onChange={(e, value) => {
              setCurrentTabValue(value);
            }}
          >
            {TABS.map((tab) => (
              <Tab
                sx={{
                  fontSize: { xs: "14px !important", sm: "18px !important" },
                }}
                key={tab}
                label={tab}
                value={tab}
              />
            ))}
          </StyledTabs>
          <TabPanel sx={{ padding: "12px" }} value={TABS[0]} keepMounted>
            <AddServiceRequestSubject navigateNext={navigateNextTab} />
          </TabPanel>
          <TabPanel value={TABS[1]} sx={{ padding: "0px" }}>
            <AddServiceRequest />
          </TabPanel>
        </TabContext>

        <SideOverlay
          onClose={close_voterList}
          show={showVoterlist}
          title={"Search Voters"}
          size={"5xl"}
        >
          <SearchVoterList />
          <VoterListWithRadioButton onVoterSelection={close_voterList} />
        </SideOverlay>
        <SideOverlay
          children={<FilterOverLay />}
          onClose={close_Filter_modal}
          show={FilterStatus}
          title={t("filter_votersearch")}
        />
      </div>
    </>
  );
});

export default AddServiceRequestHome;
