import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { SideOverlay } from "../../../../components";
import { useStore } from "../../../../helpers";
import { FormProvider, useForm } from "react-hook-form";
import { defaultLocation, locationValidationSchema } from "./location-schema";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FTPCheckBox,
  FTPFormButton,
  FTPInput,
} from "../../../../components-new";
import { useAddLocation, useEditLocation } from "./location-hooks";
import { safe_get } from "../../../../report-visuals";
import { VortexSpinner } from "../../../../features/digital-repository";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const AddLocation = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { t } = useTranslation(["location"]);
  const methods = useForm({
    defaultValues: defaultLocation,
    resolver: yupResolver(locationValidationSchema),
  });
  const {
    isLoading: isEditing,
    mutate: editLocation,
    isSuccess,
  } = useEditLocation();
  const {
    isLoading: isAdding,
    mutate: addLocation,
    isSuccess: editSuccess,
  } = useAddLocation();
  const { activeLocation } = serviceRequestsStore;
  const locationId = safe_get(activeLocation, "id", null);
  const { projectid } = useParams();

  useEffect(() => {
    if (locationId) {
      const defaultValues = {
        ...activeLocation.location,
        active: activeLocation?.active || false,
      };
      methods.reset(defaultValues);
    } else {
      methods.reset(defaultLocation);
    }
  }, [activeLocation]);

  const onSubmit = (data) => {
    const commonPayload = {
      active: data.active,
      location: {
        location: data.location,
        address: data.address,
        description: data.description,
      },
    };

    if (locationId) {
      const payload = {
        ...activeLocation,
        ...commonPayload,
      };
      editLocation(payload);
    } else {
      const payload = {
        projectid: parseInt(projectid),
        ...commonPayload,
        more: {},
      };
      // @ts-ignore
      addLocation(payload);
    }
  };

  const close_modal = () => {
    setTimeout(() => {
      serviceRequestsStore.setActiveLocation({});
      methods.reset(defaultLocation);
    }, 1000);
    serviceRequestsStore.update_modal_status("addLocation", false);
  };

  useEffect(() => {
    if (isSuccess || editSuccess) {
      close_modal();
    }
  }, [editSuccess, isSuccess]);

  return (
    <>
      <SideOverlay
        onClose={close_modal}
        show={serviceRequestsStore.modals.addLocation}
        title={locationId ? t("edit_location") : t("create_location")}
        size={"large"}
      >
        <FormProvider {...methods}>
          <form
            className={"flex flex-col bg-white rounded-xl p-4 gap-y-4"}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="flex flex-col gap-y-4">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <FTPInput name={"address"} label={t("address")} />
                <FTPInput name={"location"} label={t("location")} />
              </div>
              <FTPInput name={"description"} label={t("description")} />
              <FTPCheckBox name={"active"} label={t("set_default")} />
            </div>

            <div className="flex gap-x-2 justify-end mt-4">
              <VortexSpinner visible={isEditing || isAdding} />
              <FTPFormButton
                actionType="Cancel"
                handleButtonClick={close_modal}
              />
              <FTPFormButton
                actionType="Save"
                handleButtonClick={methods.handleSubmit(onSubmit)}
              />
            </div>
          </form>
        </FormProvider>
      </SideOverlay>
    </>
  );
});
