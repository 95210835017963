import * as React from "react";
import { Controller, useFormContext } from "react-hook-form";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Avatar, FormHelperText } from "@mui/material";
import { AttentionSeeker } from "react-awesome-reveal";
import { autoCompleteStyles } from "./MUIstyles";

type OfficePersonAutoCompleteProps = {
  name: string;
  label: string;
  officeUserData: {
    id: string;
    name: string;
    phonenum: string;
    profile_pic: string;
  }[];
};

const OfficePersonAutoComplete = ({
  name,
  label,
  officeUserData,
}: OfficePersonAutoCompleteProps) => {
  const formContext = useFormContext();

  const { control } = formContext;
  const classes = autoCompleteStyles();
  const validUserData = officeUserData.filter((user) => user?.name);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Autocomplete
          options={validUserData}
          autoHighlight
          className={classes.root}
          getOptionLabel={(option) => option?.name ?? ""}
          value={validUserData.find((user) => user.id === value?.id) || null}
          onChange={(_, newValue) => onChange(newValue || null)}
          isOptionEqualToValue={(option, value) => option.id === value?.id}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={option.id} // Use a unique key
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...optionProps}
              >
                <Avatar
                  src={option.profile_pic}
                  alt={option.name}
                  sx={{ width: 48, height: 48 }}
                  variant={"circular"}
                />
                <div className="flex flex-col px-4">
                  <p>{option.name}</p>
                  <p>{option.phonenum}</p>
                </div>
              </Box>
            );
          }}
          renderInput={(params) => (
            <>
              <AttentionSeeker effect={`${error ? "shakeX" : "bounce"}`}>
                <label className="text-md leading-xl font-normal text-[#1B1B1D] mb-2">
                  {label}
                </label>
                <TextField
                  {...params}
                  label="Choose office person"
                  placeholder="Choose office person"
                  InputProps={{
                    ...params.InputProps,
                    placeholder: "Choose office person",
                  }}
                />
              </AttentionSeeker>
              {error && (
                //@ts-ignore
                <FormHelperText error>{error?.name?.message}</FormHelperText>
              )}
            </>
          )}
        />
      )}
    />
  );
};

export default OfficePersonAutoComplete;
