import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const DeleteButton = ({ OnDeleteClickHandler, ...props }) => {
  const { t } = useTranslation(["beneficiary", "voter_list"]);
  return (
    <Button
      onClick={OnDeleteClickHandler}
      startIcon={<i className={`fa-solid fa-trash`} aria-hidden="true" />}
      color={"error"}
      size={"small"}
      variant={"contained"}
      sx={{ height: "4rem" }}
    >
      {t("delete")}
    </Button>
  );
};
