import React, { useMemo, useState } from "react";
import { Button } from "@mui/material";
import { SideOverlay } from "../../../components";
import { FTPSearchBar } from "../../../components-new";
import { ServiceRequestTypeCard } from "./request-type-card";
import Fuse from "fuse.js";
import { classNames } from "../../../helpers";
import { Radio, RadioGroup } from "@headlessui/react";
import { Masonry } from "@mui/lab";
import { safe_get } from "../../../report-visuals";

const SEARCH_KEYS = [
  "type.name",
  "type.category",
  "type.subcategory",
  "type.display.text",
  "type.description.text",
];

export const SelectService = ({ services, onChange }) => {
  const [service, setService] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [results, setResults] = useState(services);
  const handleIconClick = () => {
    setService(true);
  };

  const closeModal = () => {
    setService(false);
  };

  const fuseOptions = {
    keys: SEARCH_KEYS,
    includeScore: true,
    threshold: 0.3,
  };

  const fuse = useMemo(() => new Fuse(services, fuseOptions), []);

  const handleSearch = (value) => {
    setSearchQuery(value);
    if (value === "") {
      setResults(services);
    } else {
      const searchResults = fuse.search(value);
      setResults(searchResults.map((result) => result.item));
    }
  };

  return (
    <>
      <Button
        variant={"outlined"}
        endIcon={<i className={"fas fa-plus"} />}
        size={"small"}
        onClick={handleIconClick}
      >
        Click to add service request
      </Button>

      <SideOverlay
        onClose={closeModal}
        show={service}
        size={"7xl"}
        title={"Select Service"}
      >
        <div className="w-full justify-center items-center sticky top-0 z-10 bg-gray-100 pb-4">
          <div className={"max-w-3xl mx-auto "}>
            <FTPSearchBar
              placeHolder="Search Service"
              onSearch={handleSearch}
              clientSideSearch={true}
            />
          </div>
        </div>

        <RadioGroup
          value={selected}
          onChange={(val) => {
            setSelected(val);
            onChange(val);
            setService(false);
          }}
        >
          <Masonry columns={{ sm: 1, md: 2, lg: 3, xl: 4 }}>
            {results.map((option) => (
              <Radio
                key={option?.id}
                value={option}
                aria-label={option?.type?.name}
                className={classNames(
                  option?.id === safe_get(selected, "id", null)
                    ? "rounded-3xl bg-primary-light"
                    : "bg-transparent",
                  "relative flex cursor-pointer p-2 items-center justify-center rounded-3xl focus:outline-none transition duration-500 ease-in-out"
                )}
              >
                <ServiceRequestTypeCard request={option} />
              </Radio>
            ))}
          </Masonry>
        </RadioGroup>
      </SideOverlay>
    </>
  );
};
