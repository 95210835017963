import { observer } from "mobx-react-lite";
import React, { useRef } from "react";
import { useHoverDirty } from "react-use";
import { classNames, useStore } from "../../../../helpers";
import { ActionButtons } from "../sr-settings-components";
import { Skeleton } from "@mui/lab";

export const LocationCardSkeleton = () => {
  return (
    <div className="bg-white p-3 rounded-3xl">
      <div className="flex flex-col gap-y-2 rounded-xl p-2">
        <Skeleton variant="text" width="50%" height={25} />
        <Skeleton variant="text" width="100%" height={20} />
      </div>
      <div className="my-2 p-3 rounded-3xl flex flex-col gap-4 bg-grey-300">
        <div className="flex flex-col gap-3">
          <div className="flex flex-col gap-y-2">
            <Skeleton variant="text" width="100%" height={15} />
            <Skeleton variant="text" width="100%" height={15} />
            <Skeleton variant="text" width="100%" height={15} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const LocationCard = observer(({ loc }: any) => {
  const { id, location, active } = loc;
  const ref = useRef(null);
  const isHovered = useHoverDirty(ref);
  const { serviceRequestsStore } = useStore();

  const onEditLocation = () => {
    serviceRequestsStore.update_modal_status("addLocation", true);
    serviceRequestsStore.setActiveLocation(loc);
  };

  const onDeleteLocationHandler = () => {
    serviceRequestsStore.update_modal_status("deleteLocation", true);
    serviceRequestsStore.setActiveLocation(loc);
  };

  return (
    <div
      key={id}
      className="relative bg-common-white rounded-3xl cursor-pointer overflow-hidden"
      ref={ref}
    >
      {active ? (
        <div className="rounded-tl-3xl rounded-tr-3xl py-2.5 text-center bg-primary-light text-common-white">
          Default
        </div>
      ) : null}
      <div className="flexBoxRow pl-3.5 pt-3">
        <div>
          <h4 className="font-semibold text-base text-common-black ">
            {location.location}
          </h4>
          <p className=" text-common-black font-normal text-sm">
            {location.address}
          </p>
        </div>

        {isHovered ? (
          <ActionButtons
            positionClasses={classNames(
              location.default == true ? "top-[30%]" : "top-[15%]",
              "absolute right-[1px]",
            )}
            onEdit={onEditLocation}
            onDelete={onDeleteLocationHandler}
          />
        ) : null}
      </div>
      <div className="m-2.5 p-3.5 rounded-3xl bg-grey-200 text-sm">
        <div className="max-w-fit space-x-1">
          <p>{location.description}</p>
        </div>
      </div>
    </div>
  );
});
