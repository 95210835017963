import { useParams } from "react-router-dom";
import React from "react";
import { FTPBreadCrumbs } from "../../components-new/BreadCrumbs";

export const ElectionDayDashboardCrumbs = (props) => {
  const { projectid } = useParams();
  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: true },
    {
      name: "Election Day Dashboard",
      href: "/project/" + projectid + "/election_day_dashboard",
      current: true,
    },
  ];
  return <FTPBreadCrumbs crumbs={crumbs} />;
};
