import { observer } from "mobx-react-lite";
import { KeyIcon } from "@heroicons/react/24/solid";
import {
  DownloadAsExcelSheet,
  FloatingMenu,
  SideOverlay,
} from "../../components";
import { toast } from "react-toastify";
import { safe_get } from "../../report-visuals";
import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useStore, htmlValidation } from "../../helpers";
import { Avatar, Button, Divider, Stack, Typography } from "@mui/material";
import { UserDetails } from "./user-details";
import {
  FilterOverLay,
  SearchVoterList,
  VoterListWithRadioButton,
} from "../voter-list";
import {
  excel_user_col_def,
  groupObjectsByKey,
  map_roles_by_key,
} from "./helpers";
import { RemoveUser } from "./remove-user";
import { BanUser } from "./ban-user";
import { UpdateUser } from "./update-user";

const UsersList = observer((props: any) => {
  const { users, onRefresh } = props;
  const { t } = useTranslation(["user_management", "survey_management"]);
  const rootStore = useStore();
  const { userStore, voterListStore } = rootStore;
  const { showVoterlist, FilterStatus } = voterListStore;
  const userProfile = userStore.user;
  const role = userProfile["role"];
  const [voterProfile, setVoterProfile] = useState({});

  const groupedUsers = groupObjectsByKey(users);

  const close_Filter_modal = () => {
    voterListStore.setFilterStatus(false);
    voterListStore.setShowVoterlist(true);
    voterListStore.setFilterVal(null);
    voterListStore.setSelectedBooths([]);
  };

  const close_voterList = () => {
    voterListStore.setShowVoterlist(false);
  };

  const onVoterSelection = (voter) => {
    voterListStore.update_connect_user_to_voterlist_modal(true);
  };

  const handleUserOnClick = (user) => {
    userStore.setUserId(user.id);
    userStore.setActiveUser(user);
    userStore.setUserDetailsMode(true);
  };

  const handleOTPClick = (e, user) => {
    htmlValidation(e);
    const text = navigator.clipboard.writeText(`${user.name} - ${user.otp}`);
    if (text) {
      toast.success(`${user.name} - ${user.otp} copied`);
    }
  };

  const handleBanUser = (e, user) => {
    htmlValidation(e);
    userStore.setActiveUser(user);
    userStore.update_user_store_prop("ban_user_modal", true);
  };

  const handleDeleteUser = (e, user) => {
    htmlValidation(e);
    userStore.setActiveUser(user);
    userStore.update_user_store_prop("remove_user_modal", true);
  };

  const handleEditUser = (e, user) => {
    htmlValidation(e);
    userStore.setActiveUser(user);
    userStore.update_user_store_prop("edit_user_modal", true);
  };

  const floatingMenuOptions = [
    {
      title: "Ban User",
      icon: "fas fa-ban text-red-700",
      onClick: (e, user) => handleBanUser(e, user),
    },
    {
      title: "Delete",
      icon: "fas fa-trash text-orange-500",
      onClick: (e, user) => handleDeleteUser(e, user),
    },
    {
      title: "Edit",
      icon: "fas fa-edit text-green-300",
      onClick: (e, user) => handleEditUser(e, user),
    },
  ];

  if (users && users?.length === 0) return null;

  return (
    <div className="bg-white rounded-lg shadow-lg mt-4">
      <div className="flex flex-row justify-between items-center">
        <h2 className="font-bold md:text-2xl text-lg text-indigo-500 p-4">
          {t("users")}
        </h2>
        <div className="flex flex-row justify-between items-center gap-x-4">
          <Button
            startIcon={<i className={"fa-solid fa-arrows-rotate"} />}
            color={"warning"}
            onClick={onRefresh}
            variant={"contained"}
            sx={{
              padding: "5px 25px",
              "@media (min-width: 600px)": {
                padding: "10px 25px",
              },
            }}
          >
            Refresh
          </Button>
          <Button
            startIcon={<i className="fa-solid fa-circle-plus" />}
            color={"primary"}
            onClick={(event) => {
              userStore.update_user_store_prop("add_new_users_modal", true);
            }}
            variant={"contained"}
            sx={{
              padding: "5px 20px",
              "@media (min-width: 600px)": {
                padding: "10px 25px",
              },
            }}
          >
            Add Users
          </Button>
          {(userStore.isRoot() || userStore.isClient()) && (
            <div className={""}>
              <DownloadAsExcelSheet
                data={
                  users &&
                  users?.length !== 0 &&
                  users.filter((user) => user.role !== "root")
                }
                cols={excel_user_col_def}
                name={`Users-Summary`}
              />
            </div>
          )}
        </div>
      </div>
      {groupedUsers &&
        Object.keys(groupedUsers).map((key) => {
          //Dont show root users to the client
          if (key === "root" && role === "client") return;
          return (
            <div key={key}>
              <Typography
                className={"p-4"}
                color={"primary"}
                variant={"h6"}
                sx={{
                  fontSize: "18px",
                  "@media (max-width: 600px)": {
                    fontSize: "15px",
                  },
                }}
              >
                {safe_get(map_roles_by_key, key, "")}
              </Typography>
              <ul
                role="list"
                className="mx-auto px-4 mb-2 sm:mb-4 grid max-w-2xl grid-cols-1 gap-6 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-4 xl:grid-cols-6"
              >
                {groupedUsers[key].map((user) => {
                  return (
                    <li
                      key={user.id}
                      className="rounded-xl relative group flex flex-col gap-y-2 items-center shadow-md bg-gray-100 p-4 border border-gray-300 cursor-pointer"
                      onClick={() => handleUserOnClick(user)}
                    >
                      <div className="flex-shrink-0 flex flex-row justify-between">
                        <Avatar
                          sx={{ width: 100, height: 100 }}
                          src={user.profileimageuri}
                          alt={user.name}
                        />
                        <div className={"absolute right-0"}>
                          <FloatingMenu
                            items={floatingMenuOptions}
                            data={user}
                          />
                        </div>
                      </div>

                      <Typography variant={"h6"} color={"primary"}>
                        {user.name}
                      </Typography>
                      <Typography variant={"body1"} color={""}>
                        {safe_get(map_roles_by_key, user.role, "")} ({user.id})
                      </Typography>
                      <Typography variant={"body1"}>{user.phonenum}</Typography>

                      {user.otp ? (
                        <Stack
                          direction={"row"}
                          className="flex-wrap justify-between items-center text-sm text-gray-700 mr-2"
                        >
                          <KeyIcon
                            className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                          <Typography variant={"body2"} className="truncate">
                            OTP {user.otp}
                          </Typography>
                          <i
                            className="fa-solid text-xl fa-copy text-blue-300 cursor-pointer px-4"
                            onClick={(e) => handleOTPClick(e, user)}
                          />
                        </Stack>
                      ) : null}
                    </li>
                  );
                })}
              </ul>
              <Divider />
            </div>
          );
        })}
      <UpdateUser />
      <RemoveUser />
      <BanUser />
      <SideOverlay
        onClose={close_voterList}
        show={showVoterlist}
        title="Search Voters"
      >
        <SearchVoterList />
        <VoterListWithRadioButton onVoterSelection={onVoterSelection} />
      </SideOverlay>
      <SideOverlay
        children={<FilterOverLay />}
        onClose={close_Filter_modal}
        show={FilterStatus}
        title="Filter VoterSearch"
      />
    </div>
  );
});

export default memo(UsersList);
