export const colors = [
  "#F4D1F4",
  "#C9A9D8",
  "#F1B8D7",
  "#D9B8D8",
  "#D2B8F6",
  "#D3C9F0",
  "#C9C8F4",
  "#D8D1F0",
  "#D1C9F1",
  "#E4C8F1",
  "#D1B9F4",
  "#E3D4E6",
  "#D1C1E5",
  "#D8D8E5",
  "#D5D4F1",
  "#C7D9F1",
  "#B1D9F0",
  "#A9D9F3",
  "#A8B9E0",
  "#A8C9E8",
  "#C4C9D9",
  "#A7B5D5",
  "#E6B4A1",
  "#F2C4A1",
  "#F9C8B1",
  "#F3C1D1",
  "#F5BFD2",
  "#F0C8D0",
  "#E4B8C1",
  "#F9C9C2",
  "#E5C9D0",
  "#C8A9C7",
  "#F0B9C1",
  "#F9D3C2",
  "#F7D1D1",
  "#F4D3D3",
  "#E8B7D7",
  "#F1C8D5",
  "#F6D7B9",
  "#F1C1B1",
  "#F1D1C1",
  "#F6D9C4",
  "#F1D1A6",
  "#F0D2A6",
  "#F3E1B1",
  "#F8D0A1",
  "#E4D4A1",
  "#F7D5A0",
  "#E4E8D5",
  "#F3F1D2",
  "#F2F1D1",
  "#D9D4B8",
  "#F2E1D5",
  "#F2E8D4",
  "#F1E8C9",
  "#F1E5D4",
  "#F1D9B2",
  "#F1F6E5",
  "#D2E0C6",
  "#C9B7A6",
  "#C0E0A1",
  "#A6F0D9",
  "#C0F1C7",
  "#B5F1DC",
  "#B6D9C6",
  "#A7F1B1",
  "#B1F0C9",
  "#B9F4C9",
  "#B9F2C9",
  "#B9C9B9",
  "#A6D7D6",
  "#C9F2D6",
  "#C9F1D2",
  "#C7F0D8",
  "#C6F4D1",
  "#D1F6D7",
  "#D1F1D9",
  "#C6F1E0",
  "#C9F1E6",
  "#D8F1E8",
  "#D7F1F7",
  "#B8D1D6",
  "#B8D2E6",
  "#B7F0F1",
  "#B9E8F7",
  "#C1E9F7",
  "#D1E0F7",
  "#D7F1F7",
  "#E0F1F7",
  "#E6F2D1",
  "#E5F1D1",
];