import { observer } from "mobx-react-lite";
import React from "react";
import { useTranslation } from "react-i18next";

const C3Historical = observer((props: any) => {
  const { t } = useTranslation("constituency_command_center");
  return (
    <>
      <div> {t("historical")} </div>
    </>
  );
});

export default C3Historical;
