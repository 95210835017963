import React, { useState } from "react";
import { classNames } from "../../../../helpers";
import { TailwindModal } from "../../../../components";
import { Controller, useFormContext } from "react-hook-form";
import { colors } from "./colors";
import { Button, FormHelperText } from "@mui/material";

interface ColorPickerProps {
  name: string;
  defaultValue?: string;
}

export const SRColorPicker: React.FC<ColorPickerProps> = ({
  name,
  defaultValue,
}) => {
  const { control } = useFormContext();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <div>
            <TailwindModal isOpen={isOpen}>
              <div className="inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle  max-w-lg sm:max-w-xl w-full p-4">
                <h3 className={"text-left font-bold pb-4 text-gray-500"}>
                  Select a color
                </h3>
                <div className="flex flex-row flex-wrap justify-between items-center gap-2  p-3 rounded-lg">
                  {colors.map((color, index) => {
                    const isSelected = color === value;
                    return (
                      <div
                        key={index}
                        className={classNames(
                          isSelected
                            ? "border-2 border-cyan-400 scale-110"
                            : "border-2 border-transparent",
                          "transition delay-30 duration-100 ease-in-out color-cell hover:translate-2 hover:scale-110",
                        )}
                        style={{ backgroundColor: color }}
                        onClick={() => {
                          onChange(color);
                          setIsOpen(false);
                        }}
                      />
                    );
                  })}
                </div>
                <div className={"flex flex-row justify-end "}>
                  <Button
                    variant={"contained"}
                    size={"small"}
                    color={"primary"}
                    onClick={() => setIsOpen(false)}
                    sx={{ marginTop: 2 }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </TailwindModal>
            <div className="flex flex-col">
              <div className="flex flex-col">
                <label className="text-md leading-xl font-normal text-[#1B1B1D] ">
                  Color
                </label>
                <div
                  className={classNames(
                    error
                      ? "border-2 border-red-400"
                      : "border-2 border-gray-200",
                    "rounded-xl w-full h-14 cursor-pointer ",
                  )}
                  style={{ backgroundColor: value }}
                  onClick={() => setIsOpen(true)}
                />
              </div>
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </div>
          </div>
        )}
      />
    </>
  );
};
