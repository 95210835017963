import React from "react";
import { Slide, Bounce } from "react-awesome-reveal";
import { Button, CardMedia, Typography } from "@mui/material";
import { useWindowSize } from "react-use";

export const MLAOR = (props) => {
  const { width } = useWindowSize();

  const bstyle = {
    fontWeight: "bold",
    width: width <= 450 ? width * 0.9 : "auto",
    letterSpacing: "2px",
    color: "black",
    padding: "8px 10px",
    textAlign: "center",
  };

  return (
    <div
      className={
        "h-auto w-auto flex flex-col gap-y-6 sm:gap-y-2 items-center justify-evenly bg-gradient-to-t from-white-500 to-white-500 h-screen py-4"
      }
    >
      <div className={"flex flex-col items-center gap-y-2"}>
        <img src={"QR_Lates.png"} className={"max-w-[200px]"} />
      </div>
      <div className={"flex flex-col items-center gap-y-4"}>
        <Slide direction={"left"}>
          <Button
            className="button"
            href={"https://dub.sh/MiraLalor_A0"}
            title={"Mira for Vote Appeal"}
          >
            Liberal For Lalor
          </Button>
        </Slide>
        <Slide direction={"right"}>
          <Button
            className="button"
            href={"https://dub.sh/MiraLalor_A2"}
            target={"_blank"}
            title={"Mira for Migrants"}
          >
            Lalor's migrants best choice
          </Button>
        </Slide>
        <Slide direction={"left"}>
          <Button
            className="button"
            href={"https://dub.sh/MiraLalor_A4"}
            target={"_blank"}
            title={"Party Workers"}
          >
            Vote Liberal to get Lalor back on track
          </Button>
        </Slide>
        <Slide direction={"right"}>
          <Button
            className="button"
            href={"https://dub.sh/MiraLalor_A3"}
            target={"_blank"}
            title={"Women"}
          >
            Voice of Lalor's Women
          </Button>
        </Slide>
      </div>
    </div>
  );
};
