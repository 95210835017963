import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useServiceRequestsMeta } from "../../new-features";
import { useStore } from "../../helpers";
import { safe_get } from "../../report-visuals";
import { FormProvider, useForm } from "react-hook-form";
import { get_booth_node_by_boothnumber } from "../../custom-hooks/useBoothNumber";
import { VortexSpinner } from "../digital-repository";
import { FTPAutoComplete, FTPCheckBox } from "../../components-new";
import { RNCheckBoxTree } from "../../components";
import { FilterButtons } from "../../components/cm-components/FilterButtons";
import { Checkbox, FormControlLabel } from "@mui/material";

export const ServiceRequestFilter = observer((props: any) => {
  const { data, isLoading } = useServiceRequestsMeta();
  const rootStore = useStore();
  const { projectStore, c3Store } = rootStore;
  const { servicerequestFilter } = c3Store;
  const priority = safe_get(data, "data.priority", []);
  const statusOptions = safe_get(data, "data.status", []);
  const assignees = safe_get(data, "data.assignee", []);
  const assinessopion = assignees.filter(
    (item) => item.assignee && item.assignee.name.trim() !== ""
  );
  const locations = safe_get(data, "data.locations", []);
  const type = safe_get(data, "data.type", []);
  const hierarchy = projectStore.getProjectProperty("hierarchy", {});

  const [selectedLevel1, setselectedLevel1] = useState(
    safe_get(c3Store.servicerequestFilter, "piv_level_2", [])
  );

  const [selectedService, setSelectedService] = useState(
    safe_get(c3Store.servicerequestFilter, "key", null)
  );

  const methods = useForm({
    defaultValues: {
      key: "",
      assignee: [],
      priority: [],
      status: [],
      location: [],
      type: [],
    },
  });

  //
  const convertToArrayFormat = (obj) =>
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) =>
        key === "key" ? [key, value] : [key, [].concat(value ?? [])]
      )
    );

  const handleChange = (event) => {
    const value = event.target.value;

    setSelectedService(selectedService === value ? null : value);
  };

  const apply_filters = (data) => {
    const formattedData = convertToArrayFormat(data);
    const transform = selectedLevel1.map((id) =>
      get_booth_node_by_boothnumber(id)
    );

    const { assignee, location, priority, status, type } = formattedData;

    const payload = {
      request_type: type ?? [],
      priority: priority ?? [],
      location: location ?? [],
      status: status ?? [],
      assigned: assignee ?? [],
      piv_level_2: transform ?? [],
      key: selectedService ?? "",
    };
    c3Store.setServicerequestFilter(payload);
    c3Store.update_c3FilterModal(false);
  };

  const handleCancel = () => {
    c3Store.update_c3FilterModal(false);
    c3Store.setServicerequestFilter({});
  };

  useEffect(() => {
    const existingNodes = safe_get(servicerequestFilter, "piv_level_2", []);

    const pivLevel2Array = existingNodes
      .map((item) => item["piv_level2"])
      .filter(Boolean);

    setselectedLevel1(pivLevel2Array);

    methods.reset({
      assignee: servicerequestFilter.assigned,
      location: servicerequestFilter.location,
      priority: servicerequestFilter.priority,
      type: servicerequestFilter.request_type,
      status: servicerequestFilter.status,
    });
  }, [servicerequestFilter]);

  if (isLoading || !data) {
    return <VortexSpinner visible={isLoading} />;
  }

  return (
    <div className="py-5">
      <FormProvider {...methods}>
        <form
          className="space-y-4"
          onSubmit={methods.handleSubmit(apply_filters)}
        >
          <div>
            <label>Service</label>
            <div>
              {["beneficiary", "grievance"].map((service) => (
                <FormControlLabel
                  key={service}
                  control={
                    <Checkbox
                      checked={selectedService === service}
                      onChange={handleChange}
                      value={service}
                    />
                  }
                  label={service.charAt(0).toUpperCase() + service.slice(1)}
                  sx={{ margin: 0 }}
                />
              ))}
            </div>
          </div>

          <FTPAutoComplete
            key="assignee"
            data={assinessopion}
            lookupKey={"assignee.name"}
            name={"assignee"}
            label={"Assignees"}
            placeholder={"Select Assignees"}
            multiple={true}
          />
          <FTPAutoComplete
            key="priority"
            data={priority}
            lookupKey={"priority.name"}
            name={"priority"}
            label={"Priority"}
            placeholder={"Select Priority"}
            multiple={true}
          />
          <FTPAutoComplete
            key="status"
            data={statusOptions}
            lookupKey={"status.status"}
            name={"status"}
            label={"Status"}
            placeholder={"Select Status"}
            multiple={true}
          />
          <FTPAutoComplete
            key="location"
            data={locations}
            lookupKey={"location.location"}
            name={"location"}
            label={"Location"}
            placeholder={"Select Location"}
            multiple={true}
          />
          <FTPAutoComplete
            key="type"
            data={type}
            lookupKey={"type.name"}
            name={"type"}
            label={"Type"}
            placeholder={"Select Type"}
            multiple={true}
          />
          <RNCheckBoxTree
            checked_nodes={selectedLevel1}
            data={hierarchy}
            on_node_selection={(selected) => {
              setselectedLevel1(selected);
            }}
          />

          <div className="w-full fixed bottom-0 left-0 bg-gray-100">
            <FilterButtons
              type="submit"
              onSave={(e) => {}}
              onCancel={handleCancel}
            />
          </div>
        </form>
      </FormProvider>
    </div>
  );
});
