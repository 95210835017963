import { useStore } from "../../helpers";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";

export const useAddUser = () => {
  const rootStore = useStore();
  const { userStore } = rootStore;

  const updateProfileRequest = async (payload) => {
    return await rootStore.apiRequestHandler(
      "user/updateprofile",
      payload,
      "Failed to update user",
    );
  };

  return useMutation({
    mutationFn: updateProfileRequest,
    onSuccess: async () => {
      try {
        toast.success("User profile updated successfully");
        const secondResponse = await userStore.getProfile();
        if (secondResponse.status === 200) {
          localStorage.setItem(
            "__userprofile",
            JSON.stringify(secondResponse.data),
          );
        } else {
          toast.error("Failed to update profile. Please try again.");
        }
      } catch (error) {
        toast.error("An error occurred while updating the profile.");
      }
    },
    onError: (error) => {
      toast.error(error["message"]);
    },
  });
};
