import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";

export const FTPCheckBox = ({ name, label }) => {
  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <FormControlLabel
      label={label}
      control={
        <Controller
          name={name}
          control={control}
          render={({ field, fieldState: { error } }) => (
            <>
              <Checkbox
                {...field}
                checked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
              />
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </>
          )}
        />
      }
    />
  );
};
