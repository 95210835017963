import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  ChartPieIcon,
  ChatBubbleLeftIcon,
  SpeakerWaveIcon,
} from "@heroicons/react/24/solid";
import { useStore } from "../../helpers";
import { useWindowSize } from "react-use";
import { TabView, ProjectHeader, LoadingState } from "../../components";
import { VoterReachOutTarget } from "./voter-reachout-Target";
import { VoterReachOutCampaigns } from "./voter-reachout-Campaigns";
import { useParams } from "react-router";
import VoterReachOut from "./voter-reachout-Messages/voter-reachout";
import { VoterReachOutCrumbs } from "./voter-reachout-crumbs";

const tabs = [
  { name: "Messages", icon: ChatBubbleLeftIcon },
  { name: "Target", icon: ChartPieIcon },
  { name: "Campaigns", icon: SpeakerWaveIcon },
];

const VoterReachOutIndex = observer((props: any) => {
  const { width } = useWindowSize();
  const { projectid } = useParams();
  const { voterReachOutStore } = useStore();
  const { isLoading } = voterReachOutStore;

  const isMobile = width <= 766;

  const [activeTab, setActiveTab] = useState("Messages");

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {isLoading && <LoadingState />}
      {!isMobile ? (
        <React.Fragment>
          <VoterReachOutCrumbs />
          <ProjectHeader />
        </React.Fragment>
      ) : null}
      <TabView tabs={tabs} onChange={onTabChange} />
      {activeTab === "Messages" ? (
        <VoterReachOut projectid={projectid} />
      ) : null}
      {activeTab === "Target" ? (
        <VoterReachOutTarget projectid={projectid} />
      ) : null}
      {activeTab === "Campaigns" ? (
        <VoterReachOutCampaigns projectid={projectid} />
      ) : null}
    </>
  );
});

export default VoterReachOutIndex;
