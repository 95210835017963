import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../../helpers";
import { ViewAttachment } from "../../../components";

export const SrAdditionalInfo = observer((props) => {
  const rootStore = useStore();
  const { serviceRequestsStore } = rootStore;
  const { activeServiceRequest } = serviceRequestsStore;

  return (
    <>
      <div className="m-4">
        <div className="flex flex-col bg-background-info mt-3 rounded-xl pb-2">
          <div className="p-4 flex flex-col">
            <p className="font-semibold text-common-black pb-2">
              Additional Information
            </p>
            <p>{activeServiceRequest["more"].additional}</p>
          </div>
        </div>
        <div className="flex flex-col bg-background-info mt-3 rounded-xl pb-2">
          <div className="p-4 flex flex-col">
            <p className="font-semibold text-common-black pb-2">
              Uploaded File(s)
            </p>

            {activeServiceRequest["more"].attachments.length > 0 &&
              activeServiceRequest["more"].attachments?.map((doc) => {
                return (
                  <ViewAttachment
                    label={false}
                    key={doc.path}
                    attachment={doc}
                    backgroundColor={""}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
});
