import { classNames } from "../../../helpers";
import React from "react";
// Voter ID Component

export const VoterId = ({ id, hover }) => {
  return (
    <>
      <div
        className={classNames(
          "transition-all duration-[700ms] ease-in-out font-medium text-sm leading-3 text-center space-x-2 rounded-tr-3xl rounded-tl-3xl py-[16px]",
          hover ? "bg-black" : "bg-white",
        )}
      >
        <span
          className={classNames(
            "transition-all duration-[700ms] ease-in-out",
            hover ? "text-[#A1A1A1]" : "text-[#848484]",
          )}
        >
          Voter Id
        </span>
        <span
          className={classNames(
            "transition-all duration-[700ms] ease-in-out",
            hover ? "text-white" : "text-[#1B1B1D]",
          )}
        >
          {id}
        </span>
      </div>
    </>
  );
};
