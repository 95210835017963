import React from "react";
import { observer } from "mobx-react-lite";
import { AddStatus } from "./add-status";
import { StatusCard, StatusCardSkeleton } from "./status-card";
import { useStore } from "../../../../helpers";
import { MasonryGrid } from "../../../../components-new";
import { DeleteStatus } from "./delete-status";

export const StatusHome = observer((props: any) => {
  const {
    serviceRequestsStore: { meta, loading },
  } = useStore();

  return (
    <div className={"overflow-hidden"}>
      <MasonryGrid
        data={meta?.status}
        isLoading={loading}
        skeletonCount={8}
        renderItem={(status) => (
          <StatusCard key={status["id"]} serviceStatus={status} />
        )}
        renderSkeleton={(key) => <StatusCardSkeleton key={key} />}
      />
      <AddStatus />
      <DeleteStatus />
    </div>
  );
});
