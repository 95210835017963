import React from "react";
import { IconButton } from "@mui/material";
import { useStore } from "../../../../helpers";
import { Skeleton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { getStatusStyles } from "./status-styles";

export const StatusCardSkeleton = () => {
  return (
    <div className="p-6 rounded-2xl bg-white">
      <div className="flex flex-row items-center gap-x-4">
        <Skeleton variant="circular" width={40} height={40} />
        <Skeleton variant={"text"} width={"50%"} height={20} />
      </div>
    </div>
  );
};

export const StatusCard = ({ serviceStatus }) => {
  const { status, id } = serviceStatus;
  const { serviceRequestsStore } = useStore();
  const styles = getStatusStyles(status);
  const { t } = useTranslation(["status"]);

  const onEditStatusHandler = () => {
    serviceRequestsStore.update_modal_status("addStatus", true);
    serviceRequestsStore.setActiveStatus(serviceStatus);
  };

  const onDeleteStatusHandler = () => {
    serviceRequestsStore.update_modal_status("deleteStatus", true);
    serviceRequestsStore.setActiveStatus(serviceStatus);
  };

  return (
    <div
      key={`${id}-${status?.icon}`}
      className="flexBoxRow p-6 bg-grey-400 rounded-2xl relative group"
      style={styles.container}
    >
      <div
        className="w-10 h-10 flex items-center justify-center rounded-full"
        style={styles.iconWrapper}
      >
        <i className={`${status?.icon} text-common-white text-xl`} />
      </div>
      <h4 className="font-semibold">{t(status?.status)}</h4>
      <div className="flexBoxRow gap-x-4 opacity-0 group-hover:opacity-100 transition-opacity">
        <IconButton
          size="small"
          disableFocusRipple
          onClick={onEditStatusHandler}
        >
          <i className="fa-regular fa-pen-to-square" />
        </IconButton>
        <IconButton
          size="small"
          disableFocusRipple
          onClick={onDeleteStatusHandler}
        >
          <i className="fa-regular fa-trash-can" />
        </IconButton>
      </div>
    </div>
  );
};
