import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";

type ExcelProps = {
  data: {}[];
  cols?: {}[];
  name: string;
  classes?: string;
};

export const DownloadAsExcelSheet = (props: ExcelProps) => {
  const { data, cols = [], name, classes } = props;
  const name_with_timestamp = `${name} - ${dayjs().format("LLL")}`;
  const [showDownload, setShowDownload] = useState(false);
  const { t } = useTranslation("downloadasexcelsheet");

  return (
    <div className={`flex flex-row items-center gap-x-4 justify-between }`}>
      <IconButton
        onClick={(e) => setShowDownload(!showDownload)}
        color={"warning"}
        size={"medium"}
      >
        <i
          className="fa-solid fa-download cursor-pointer text-xl md:text-3xl"
          title={"Download"}
        />
      </IconButton>

      {showDownload && (
        <CSVLink data={data} headers={cols} filename={name_with_timestamp}>
          <i className="text-blue-600 mx-4">Download</i>
        </CSVLink>
      )}
    </div>
  );
};
