import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../helpers";
import { FsFilterPane } from "../fsFilterPane";
import { WardBankSearchCard } from "../renderkeys/ward-bank/WardBankSearchCard";
import { CMXSearchCard } from "../renderkeys/cmx/CMXSearchCard";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ACBankSearchCard } from "../renderkeys/ac-bank";
import { LoadingState } from "../../components";
import { PCBankSearchCard } from "../renderkeys/pc-bank";
import { MH2024FieldSearchCard } from "../renderkeys/mh-2024-field";
import { Typography } from "@mui/material";
import { safe_get } from "../../report-visuals";
import { FSDListCrumbs } from "./fsd-list-crumbs";
import { BoothReportSearchCard } from "../renderkeys/booth-bank";
import { SocialListeningSearchCard } from "../renderkeys/social-listening/social-listening-search-card";

export const FSDListPage = observer((props) => {
  const rootStore = useStore();
  const [loading, setLoading] = useState(false);
  const { flexiStore } = rootStore;
  const navigate = useNavigate();
  const { key, fstid } = useParams();
  const { t } = useTranslation("pages_root");
  const [fst, setFst] = useState(null);
  const summary = safe_get(fst, "summary", {});

  useEffect(() => {
    return () => flexiStore.reset_fsd_search();
  }, []);

  useEffect(() => {
    getFSDList();
    setFst(JSON.parse(sessionStorage.getItem("fst")));
  }, [flexiStore.fsd_search_query, flexiStore.fsd_search_page_size]);

  const getFSDList = async () => {
    setLoading(true);
    await flexiStore.set_fsd_search_key(key);
    await flexiStore.get_search_fs_data();
    setLoading(false);
  };

  if (!key) return null;

  const handleSearch = (query) => {
    flexiStore.set_fsd_search_query(query);
  };

  const handleCloseModal = () => {
    flexiStore.set_show_booth_report_persmissions(false);
  };

  const FSDListRenderer = () => {
    return (
      <div className={"px-4"}>
        <div className="italic">
          {t("showing")} {flexiStore.fsd_search_results.length} {t("templates")}
          .
        </div>
        <div className="mt-5 max-w-none mx-auto grid gap-2 xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2">
          {flexiStore.fsd_search_results.map((x) => {
            const id = x.id;
            const key = x.key;
            const renderKey = x.renderkey;
            let renderElement = <></>;
            switch (renderKey) {
              case "ac-bank":
                renderElement = <ACBankSearchCard key={id} data={x} />;
                break;
              case "pc-bank":
                renderElement = <PCBankSearchCard key={id} data={x} />;
                break;
              case "ward-bank":
                renderElement = <WardBankSearchCard key={id} data={x} />;
                break;
              case "booth-report":
                renderElement = <BoothReportSearchCard key={id} data={x} />;
                break;
              case "cmx":
                renderElement = <CMXSearchCard key={id} data={x} />;
                break;
              case "mh-2024-field":
                renderElement = <MH2024FieldSearchCard key={id} data={x} />;
                break;
              case "social-listening-report":
                renderElement = <SocialListeningSearchCard key={id} data={x} />;
                break;
            }

            return (
              <>
                <div
                  key={id}
                  onClick={(evt) => {
                    flexiStore.set_fsd_ready_state(false);
                    navigate(`/databank/repo/${fstid}/${key}/${id}`);
                  }}
                >
                  {renderElement}
                </div>
                {/*<SideOverlay*/}
                {/*  children={<ACBankPermissions />}*/}
                {/*  onClose={handleCloseModal}*/}
                {/*  show={flexiStore.show_booth_report_persmissions}*/}
                {/*  title={"Permissions"}*/}
                {/*/>*/}
              </>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <FSDListCrumbs name={safe_get(summary, "name", "")} />
      <div className="px-4 flex flex-row items-center justify-between">
        {loading ? <LoadingState /> : null}
        <div className={"flex flex-col flex-1"}>
          <Typography variant={"h4"} className="title">
            {safe_get(summary, "name", t("data_bank_repositories"))}
          </Typography>
          <Typography variant={"body1"}>
            {safe_get(
              summary,
              "description",
              t("contains_several_data_series")
            )}
          </Typography>
        </div>
        <div
          className={
            "flex flex-row flex-1 gap-x-4 items-center justify-between"
          }
        >
          <FsFilterPane onSearch={handleSearch} />
          <div className="flex flex-row items-center">
            <label
              htmlFor="dropdown"
              className="text-sm mb-1 font-bold text-black px-3 "
            >
              Items per page:
            </label>
            <select
              value={flexiStore.fsd_search_page_size}
              onChange={(event) =>
                flexiStore.update_flexistore_property(
                  "fsd_search_page_size",
                  parseInt(event.target.value)
                )
              }
              className="p-2 rounded border border-gray-300 focus:outline-none focus:border-blue-500 bg-white z-999"
              style={{
                boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                zIndex: 0,
                appearance: "auto",
              }}
            >
              {[10, 20, 50, 100, 500].map((option, i) => (
                <option key={i} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      {flexiStore.fsd_search_results ? <FSDListRenderer /> : null}
      
    </>
  );
});
