import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { AddNewButton, FeatureHeader } from "../../components-new";
import { SRManageCrumbs } from "./sr-components";
import { ManageServiceRequestsTabs } from "./sr-settings";
import { useStore } from "../../helpers";

const ManageServiceRequests = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { activeTab } = serviceRequestsStore;

  const handleButtonClick = () => {
    switch (activeTab) {
      case "types":
        serviceRequestsStore.update_modal_status("addRequestType", true);
        break;
      case "status":
        serviceRequestsStore.update_modal_status("addStatus", true);
        break;
      case "priority":
        serviceRequestsStore.update_modal_status("addPriority", true);
        break;
      case "assignees":
        serviceRequestsStore.update_modal_status("addAssignee", true);
        break;
      case "locations":
        serviceRequestsStore.update_modal_status("addLocation", true);
        break;
      default:
        break;
    }
  };

  const buttonText = useMemo(() => {
    switch (activeTab) {
      case "types":
        return "Create a Request Type";
      case "assignees":
        return "Create an Assignee";
      default:
        return `Create a ${activeTab}`;
    }
  }, [activeTab]);

  useEffect(() => {
    return () => {
      serviceRequestsStore.set_active_tab("types");
    };
  }, []);

  return (
    <div className={"flex flex-col px-4 pb-4"}>
      <SRManageCrumbs />
      <div className={"flexBoxRow"}>
        <FeatureHeader title={"Service Management"} />
        <AddNewButton text={buttonText} onButtonClick={handleButtonClick} />
      </div>
      <ManageServiceRequestsTabs />
    </div>
  );
});

export default ManageServiceRequests;
