import React from "react";
import { useStore } from "../../../helpers";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

export const RenderFilters = observer((props: any) => {
  const { surveyResponseStore } = useStore();
  const { new_filters } = surveyResponseStore;
  const { t } = useTranslation("survey_management");

  if (new_filters.length == 0) {
    return null;
  }

  return (
    <div className=" top-0 bg-white rounded-lg p-2 z-40">
      <div className="flex flex-row  items-center">
        <div className="block text-lg font-medium text-gray-500">
          {t("filters", { ns: "survey_management" })}
        </div>
        <div
          className="cursor-pointer text-sm ml-4 text-indigo-500"
          onClick={() => {
            surveyResponseStore.clearAllFilters();
          }}
        >
          {t("clear_all")}
        </div>
      </div>
      <div className="flex flex-row flex-wrap my-2">
        {new_filters.length > 0 &&
          new_filters.map((x) => {
            const { key, val, display } = x;
            return (
              <>
                <div className="rounded-2xl text-sm bg-blue-200 flex flex-row items-center shrink-0 px-4 py-1 mt-2 mx-2">
                  <div>{display}</div>
                  <div
                    className="ml-4 pr-2"
                    onClick={() => {
                      surveyResponseStore.remove_new_filters(x);
                    }}
                  >
                    <i className="fa-solid fa-xmark text-lg cursor-pointer font-bold hover:text-red-500" />
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
});
