import React from "react";

export const PrivacyPolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p className="text-sm text-gray-600 mb-4">
        Effective Date: August 2, 2022
      </p>

      <section className="mb-6">
        <h2 className="text-lg tracking-wide font-semibold mb-2">
          Information We Collect
        </h2>
        <ul className="list-inside list-disc">
          <li>
            <strong className="tracking-wide">User Account Information:</strong>{" "}
            Name, email address, password, and other details provided during
            registration.
          </li>
          <li>
            <strong className="tracking-wide">Campaign Tracking Data:</strong>{" "}
            Campaign name, location, dates, and related details.
          </li>
          <li>
            <strong className="tracking-wide">Volunteer Information:</strong>{" "}
            Name, contact details, availability, and other related information.
          </li>
          <li>
            <strong className="tracking-wide">Location Data:</strong> With user
            consent, device location is collected for campaign organization.
          </li>
          <li>
            <strong className="tracking-wide">Usage Data:</strong> App activity,
            pages visited, and feature usage.
          </li>
          <li>
            <strong className="tracking-wide">Device Information:</strong>{" "}
            Device type, operating system, and technical details.
          </li>
        </ul>
      </section>

      <section className="mb-6">
        <h2 className="text-lg tracking-wide font-semibold mb-2">
          How We Use Your Data
        </h2>
        <ul className="list-inside list-disc">
          <li>Facilitate campaign tracking and organization.</li>
          <li>Connect volunteers with campaigns.</li>
          <li>Send notifications and updates.</li>
          <li>Conduct analytics and research to improve the App.</li>
          <li>Enhance app security and functionality.</li>
        </ul>
      </section>

      {/* Add more sections for Data Sharing, Security, User Rights, etc. */}
      <section className="mb-6">
        <h2 className="text-lg tracking-wide font-semibold mb-2">
          Contact Information
        </h2>
        <p>
          For privacy-related inquiries, contact:{" "}
          <a href="mailto:legal@forthepeople.ai" className="text-blue-500">
            legal@forthepeople.ai
          </a>
        </p>
      </section>
    </div>
  );
};
