export type metaTypes = {
  type: Array<Object>;
  status: Array<Object>;
  priority: Array<Object>;
  assignee: Array<Object>;
  locations: Array<Object>;
};

export const initialMetaTypes = {
  type: [],
  status: [],
  priority: [],
  assignee: [],
  locations: [],
  current_editing_assignee: {},
};

export type ModalKeyTypes =
  | "addRequestType"
  | "addStatus"
  | "addPriority"
  | "addAssignee"
  | "addLocation"
  | "deleteStatus"
  | "deletePriority"
  | "deleteRequestType"
  | "deleteAssignee"
  | "deleteLocation"
  | "selectVillageAndBooth"
  | "deleteServiceRequest"
  | "addFamilyMember";

export type modalTypes = {
  addAssignee: boolean;
  addLocation: boolean;
  addRequestType: boolean;
  addStatus: boolean;
  addPriority: boolean;
  deleteStatus: boolean;
  deletePriority: boolean;
  deleteRequestType: boolean;
  deleteAssignee: boolean;
  deleteLocation: boolean;
  deleteServiceRequest: boolean;
  addFamilyMember: boolean;
  // boolean
  selectVillageAndBooth: {
    village: boolean;
    booth: boolean;
  };
};

export const initialModalTypes = {
  addRequestType: false,
  addLocation: false,
  addStatus: false,
  addPriority: false,
  deleteStatus: false,
  addAssignee: false,
  deletePriority: false,
  deleteRequestType: false,
  deleteAssignee: false,
  deleteLocation: false,
  deleteServiceRequest: false,
  addFamilyMember: false,
  selectVillageAndBooth: {
    village: false,
    booth: false,
  },
};

export type backendConnectorKeyTypes =
  | "addAssignee"
  | "updateAssignee"
  | "deleteAssignee";
