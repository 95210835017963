import * as Yup from "yup";

export const REQUEST_TYPES = [
  { label: "Beneficiary", value: "beneficiary" },
  { label: "Grievance", value: "grievance" },
];

export type serviceRequestType = {
  request_type: string;
  name: string;
  color: string;
  category: any;
  subcategory: any;
  tags: Array<string>;
  display: Array<{
    language: string;
    text: string;
  }>;
  description: Array<{
    language: string;
    text: string;
  }>;
  required_docs: Array<string>;
};

export const defaultServiceRequest: serviceRequestType = {
  request_type: REQUEST_TYPES[0].value,
  name: "",
  color: "",
  category: {},
  subcategory: {},
  tags: [],
  display: [
    {
      language: "",
      text: "",
    },
  ],
  description: [
    {
      language: "",
      text: "",
    },
  ],
  required_docs: [],
};

export const serviceRequestValidationSchema = Yup.object().shape({
  request_type: Yup.string().required("type is Required"),
  name: Yup.string().required("Name is Required"),
  color: Yup.string().required("Color is required"),
  category: Yup.mixed().required("Category is required"),
  subcategory: Yup.mixed(),
  tags: Yup.array(),
  required_docs: Yup.array(),
  description: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required("Language is required"),
      text: Yup.string().required("Description text is required"),
    }),
  ),
  display: Yup.array().of(
    Yup.object().shape({
      language: Yup.string().required("Language is required"),
      text: Yup.string().required("Display text is required"),
    }),
  ),
});
