import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { FormButtons } from "../../components";
import { useStore } from "../../helpers";
import _ from "lodash";
import dayjs from "dayjs";
export const DAFilters = observer((props: any) => {
  const rootStore = useStore();
  const { digitalArchiveStore } = rootStore;

  const reset = () => {
    digitalArchiveStore.update_DAStoreProp("da_filter_modal", false);
  };

  const [selectedDate, setSelectedDate] = useState(
    digitalArchiveStore.daFilters.date,
  );

  const { onDateSave } = props;

  return (
    <>
      <div className={"flex flex-col"}>
        <label>Select Date</label>
        <input
          id="dob"
          name="dob"
          type="date"
          defaultValue={dayjs(selectedDate, ["DD-MM-YYYY"]).format(
            "YYYY-MM-DD",
          )}
          onChange={(e) => {
            setSelectedDate(dayjs(e.target.value).format("DD-MM-YYYY"));
          }}
          className="form-input mt-1 w-2/3 px-1 py-1 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>

      <FormButtons
        onSave={async () => {
          reset();
          const filters = _.clone(digitalArchiveStore.daFilters);
          filters.date = selectedDate;
          await digitalArchiveStore.update_da_filters(filters);
          await onDateSave();
        }}
        onCancel={() => {
          reset();
        }}
      />
    </>
  );
});
