import { observer } from "mobx-react-lite";
import React from "react";
import { HomeIcon } from "@heroicons/react/24/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { classNames, useStore } from "../../helpers";
import { useLocation, useWindowSize } from "react-use";
import { Button } from "@mui/material";

const boxShadow = {
  boxShadow: `
      0px 20px 41px 0px #7A7A7A2E,
      0px 75px 75px 0px #7A7A7A0A,
      0px 169px 101px 0px #7A7A7A08,
      0px 301px 120px 0px #7A7A7A03,
      0px 470px 132px 0px #7A7A7A00
    `,
};

export const FTPBreadCrumbs = observer((props: any) => {
  const rootStore = useStore();
  const appMode = rootStore.appMode;
  const { t } = useTranslation("Breadcrumbs");
  let crumbs: Array<any> = props["crumbs"];
  const { projectid } = useParams();
  const { width } = useWindowSize();
  const isMobile = width <= 766;
  const { pathname } = useLocation();
  const isRoot = rootStore.userStore.isRoot();
  const showHeader = pathname.split("/").includes("project");

  const navigate = useNavigate();

  const handleManageProject = () => {
    navigate(`/project/${projectid}/manage`);
  };
  return (
    <>
      {!appMode && !isMobile && (
        <nav
          className={classNames(
            showHeader ? "top-24" : "top-4",
            "noprint bg-white border-b border-gray-200 flex nop sticky rounded-full sm:mb-4 mx-4 flex-row justify-between z-10"
          )}
          aria-label="Breadcrumb"
          style={boxShadow}
        >
          <ol className="w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8 flex-1">
            <li className="flex">
              <div className="flex items-center">
                <Link
                  to={projectid ? `/project/${projectid}` : "/"}
                  className={"font-bold text-gray-600 hover:text-gray-500"}
                >
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{t("home")}</span>
                </Link>
              </div>
            </li>
            {crumbs.map((page, index) => (
              <li key={page.name} className="flex">
                <div className="flex items-center p-4">
                  <i className="fa-solid fa-chevron-right text-gray-500" />
                  <Link
                    to={page.href}
                    className={classNames(
                      crumbs.at(-1).name === page.name
                        ? "text-primary-main hover:text-primary-light"
                        : "text-gray-600 hover:text-gray-500",
                      "ml-4 font-bold pl-4 tracking-wider"
                    )}
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
          {isRoot ? (
            <div className={"flex flex-row self-center justify-end mx-2"}>
              <Button
                sx={{ color: "white", fontWeight: 600, fontSize: "1.15rem" }}
                size={"small"}
                color={"warning"}
                variant={"contained"}
                onClick={handleManageProject}
                startIcon={<i className="fa-regular fa-gears"></i>}
              >
                Manage Project
              </Button>
            </div>
          ) : null}
        </nav>
      )}
    </>
  );
});
