import React from "react";
import { LoadingState, ProjectHeader, SideOverlay } from "../../components";
import { useStore } from "../../helpers";
import { observer } from "mobx-react-lite";
import { AddProjectWork } from "./add-project-work-v2";
import { PWHomeCrumbs } from "./project-work-bread-crumbs";
import { useProjectWorksList } from "./pw-hooks";
import { t } from "i18next";
import { ProjectCard, ProjectWorksCardsSkeleton } from "./project-work-cards";
import { ProjectWorksFilters } from "./project-works-v2-filters";
import { NewProjectWorksHeader } from "./sections/NewProjectWorksHeader";
import { MasonryGrid } from "../../components-new";

const NewProjectWorksHome = observer((props: any) => {
  const {
    projectWorkStoreV2: {
      projectWorkFilterModal,
      toggleProjectWorkFilterModal,
      projectWorksList,
    },
  } = useStore();

  const { isLoading } = useProjectWorksList();

  // if (isLoading) return <LoadingState />;

  return (
    <>
      <PWHomeCrumbs />
      <div className="p-4">
        <NewProjectWorksHeader />
        <AddProjectWork />
        <MasonryGrid
          data={projectWorksList}
          isLoading={isLoading}
          renderItem={(item) => {
            return <ProjectCard project={item} key={item?.id} />;
          }}
          renderSkeleton={(key) => <ProjectWorksCardsSkeleton />}
          skeletonCount={projectWorksList.length}
        />
        {/* <ProjectWorksCardsSkeleton /> */}
      </div>

      <SideOverlay
        children={<ProjectWorksFilters />}
        onClose={toggleProjectWorkFilterModal}
        show={projectWorkFilterModal}
        title={t("Project Works Filters")}
      />
    </>
  );
});

export default NewProjectWorksHome;
