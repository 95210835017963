import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import {
  FTPAutoComplete,
  FTPFormButton,
  FTPRadioGroup,
  FTPInput,
} from "../../components-new";
import { FormProvider, useForm } from "react-hook-form";
import { useStore } from "../../helpers";
import { GENDERS } from "../../features/beneficiary-management-v2";
import { yupResolver } from "@hookform/resolvers/yup";
import { safe_get } from "../../report-visuals";
import { serviceRequestValidations } from "./service-request-validation-schema";
import { subjectDefaultValues } from "./service-request-types";
import { Fade } from "react-awesome-reveal";
import {
  useAddServiceRequestSubject,
  useUpdateServiceRequestSubject,
} from "./sr-hooks";
import { FamilyMembers } from "./family-members";
import { AddSubjectFromVoterList } from "./AddSubjectFromVoterList";
import { RNBoothSelector } from "../../components/form-components";
import { toJS } from "mobx";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";
import { FormButtons } from "../../components";
import { get_booth_node_by_boothnumber } from "../../custom-hooks/useBoothNumber";
const IS_OUTSIDE_VOTER = [
  { label: "No", value: false },
  { label: "Yes", value: true },
];

const AddServiceRequestSubject = observer((props: any) => {
  const { projectStore, voterListStore, serviceRequestsStore } = useStore();
  const { activeServiceRequest, selectedFamilyMembers, setFamilyMembers } =
    serviceRequestsStore;
  const subjectID = safe_get(activeServiceRequest, "subject.id", null);
  const { activeVoter } = voterListStore;
  const { mutate, isLoading, isSuccess } = useAddServiceRequestSubject();
  const profileOptions = projectStore.getProjectProperty(
    "profile_questions",
    {},
  );

  const casteOptions = safe_get(profileOptions, "caste", []);
  const navigate = useNavigate();

  const { navigateNext } = props;
  const location = useLocation();
  const isEditMode = location.pathname.includes("editrequest");

  const methods = useForm({
    defaultValues: subjectDefaultValues,
    //@ts-ignore
    resolver: yupResolver(serviceRequestValidations),
  });

  useEffect(() => {
    const voterID = safe_get(activeVoter, "id", null);
    const { profile, ...others } = activeVoter;

    if (voterID) {
      methods.reset({
        additional: {
          booth: get_booth_node_by_boothnumber(safe_get(others, "part", null)),
        },
        profile: { ...profile, gender: profile?.gender },
        caste: casteOptions.find((opt) => opt.value === activeVoter.caste),
        ...others,
      });
    } else if (subjectID && isEditMode) {
      setFamilyMembers(
        toJS(
          activeServiceRequest.subject?.subject?.additional?.family_members_list
            ? activeServiceRequest.subject?.subject?.additional
                ?.family_members_list
            : activeServiceRequest.subject?.additional?.family_members_list,
        ),
      );
      methods.reset({
        ...activeServiceRequest.subject?.subject,
        additional: {
          booth: toJS(
            activeServiceRequest?.subject?.subject?.additional?.booth,
          ),
        },
      });
    } else {
      methods.reset({ ...subjectDefaultValues });
      setFamilyMembers([]);
    }
  }, [activeVoter, subjectID]);

  useEffect(() => {
    return () => {
      voterListStore.update_active_voter({});
      serviceRequestsStore.setActiveServiceRequest({});
    };
  }, []);

  const isOtherACVoter = methods.watch("is_outsider");

  const {
    mutate: updateSubject,
    isLoading: isUpdating,
    isSuccess: isUpdated,
  } = useUpdateServiceRequestSubject();

  const onSubmit = (data) => {
    // data.additional.family_members_list[...ServiceRequestsStore.];
    let finalData = { ...data };
    finalData.additional.family_members_list = selectedFamilyMembers
      ? [...toJS(selectedFamilyMembers)]
      : [];

    if (!subjectID) {
      mutate({ ...finalData, ...activeVoter });
    } else {
      console.log(
        toJS({
          ...toJS(activeServiceRequest.subject),

          subject: { ...finalData },
        }),
      );
      updateSubject({
        ...activeServiceRequest.subject,

        subject: { ...finalData },
      });
    }
    setTimeout(() => {
      navigateNext();
    }, 1000);
  };

  const onError = (errors) => {
    console.log(errors);
  };

  const handleCancel = () => {
    methods.reset();
    navigate(-1);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit, onError)}
        className={"flex flex-col gap-4"}
      >
        <AddSubjectFromVoterList />

        <div
          className={"w-full flex flex-col sm:flex-row justify-between gap-8"}
        >
          <div className={"w-full sm:w-[60%] flex flex-col gap-y-4"}>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-4">
              <FTPInput
                className="col-span-1 sm:col-span-2 md:col-span-1"
                name="profile.name_eng"
                label="Name"
              />
              <FTPInput name="profile.voterid" label="Voter ID" />
              <FTPInput name="aadharid" label="Aadhar Number" />
              <FTPInput name="phone" label="Phone Number" />
              <FTPInput name="whatsapp" label="Whatsapp Number" />
              <FTPInput
                className="col-span-1 sm:col-span-2 md:col-span-1"
                name="profile.relation_name_eng"
                label="Relative Name"
              />
              <FTPAutoComplete
                className="col-span-1 sm:col-span-2 md:col-span-1"
                name="caste"
                label="Caste"
                data={casteOptions}
                lookupKey="label"
                placeholder="Select Caste"
              />
              <FTPInput
                className="col-span-1 sm:col-span-2 md:col-span-1"
                name="profile.address"
                label="Address"
              />
              <FTPInput type="date" name="dob" label="Date of Birth" />
              <FTPInput name="profile.age" label="Age" />

              <div className="col-span-1 sm:col-span-2 md:col-span-1">
                <FTPRadioGroup
                  name="profile.gender"
                  label="Gender"
                  options={GENDERS}
                />
              </div>

              <div className="col-span-1 sm:col-span-2 md:col-span-1 w-full">
                <RNBoothSelector
                  label="Booth"
                  name="additional.booth"
                  isMulti={false}
                  selector="display"
                />
              </div>

              <FTPRadioGroup
                name="is_outsider"
                label="Other AC Voter"
                options={IS_OUTSIDE_VOTER}
              />

              {isOtherACVoter ? (
                <Fade direction="left">
                  <FTPInput name="oac_name" label="AC Name" />
                  <FTPInput name="oac_voter_name" label="Voter Name" />
                  <FTPInput name="oac_voter_phonenumber" label="Phone Number" />
                </Fade>
              ) : null}
            </div>
          </div>
          <div className="w-full sm:w-[40%] h-[45vh] bg-gray-200 p-4 border border-gray-400 self-start rounded-xl flex flex-col gap-y-4 overflow-y-auto">
            <FamilyMembers />
          </div>
        </div>

        <div className={"flex flex-row self-end gap-4 items-center"}>
          <FormButtons
            onSave={() => {}}
            onCancel={handleCancel}
            saveText="Save and continue"
          />
          {/* <FTPFormButton
            type={"submit"}
            actionType={"Save"}
            handleButtonClick={() => {}}
          />
          <FTPFormButton
            actionType={"Cancel"}
            handleButtonClick={handleCancel}
            type={"button"}
          /> */}
        </div>
      </form>
    </FormProvider>
  );
});

export default AddServiceRequestSubject;
