import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers";
import { ViewAttachment } from "../../../components/ViewAttachements";
import { Typography } from "@mui/material";
import Masonry from "@mui/lab/Masonry";

export const PerformanceAssets = observer(() => {
  const { flexiStore } = useStore();

  if (!flexiStore.current_fsd?.r2) return null;

  let bestPerformingAssets = (flexiStore.current_fsd.r2 as Record<string, any>)
    ?.best_performing_assest;
  let worstPerformingAssets = (flexiStore.current_fsd.r2 as Record<string, any>)
    ?.worst_performing_assest;

  bestPerformingAssets = Array.isArray(bestPerformingAssets)
    ? bestPerformingAssets
    : [bestPerformingAssets];
  worstPerformingAssets = Array.isArray(worstPerformingAssets)
    ? worstPerformingAssets
    : [worstPerformingAssets];

  const hasBestAssets = bestPerformingAssets && bestPerformingAssets.length > 0;
  const hasWorstAssets =
    worstPerformingAssets && worstPerformingAssets.length > 0;

  if (!hasBestAssets && !hasWorstAssets) {
    return <p className="text-center text-gray-500">No images available</p>;
  }

  return (
    <div className="p-12 bg-white">
      {hasBestAssets && (
        <>
          <div className="flex flex-col md:flex-row justify-between items-center text-[#B97CFE] mb-8">
            <Typography variant="h3" fontWeight="bold" className="font-bold text-3xl md:text-4xl">
              Trending Posts
            </Typography>
            <Typography variant="h4" className="text-lg md:text-xl text-center md:text-right">
              A snapshot of the hashtags <br /> used and their frequency
            </Typography>
          </div>
          <Masonry
            columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            spacing={1}
            className="masonry-grid"
          >
            {bestPerformingAssets.map(
              (asset: any, index: number) =>
                asset.s3_path && (
                  <div
                    key={`best-${index}`}
                    className="rounded-3xl overflow-hidden border-[2px] border-[#9435FF]"
                    style={{
                      boxShadow: `
                        0px 5px 11px 0px #2527284A, 
                        0px 20px 20px 0px #25272842, 
                        0px 44px 27px 0px #25272826, 
                        0px 79px 31px 0px #2527280A, 
                        0px 123px 34px 0px #25272803
                      `,
                    }}
                  >
                    <ViewAttachment
                      s3_bucket="ftp-cndp"
                      attachment={{
                        path: asset.s3_path,
                        type: "image/png",
                      }}
                      label={false}
                    />
                  </div>
                )
            )}
          </Masonry>
        </>
      )}

      {hasWorstAssets && (
        <>
          <div className="flex flex-col md:flex-row justify-between items-center text-[#B97CFE] mt-8 mb-8">
            <Typography variant="h3" fontWeight="bold" className="font-bold text-3xl md:text-4xl">
              Tumbling Posts
            </Typography>
            <Typography variant="h4" className="text-lg md:text-xl text-center md:text-right">
              A snapshot of the hashtags <br /> used and their frequency
            </Typography>
          </div>
          <Masonry
            columns={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            spacing={1}
            className="masonry-grid"
          >
            {worstPerformingAssets.map(
              (asset: any, index: number) =>
                asset.s3_path && (
                  <div
                    key={`worst-${index}`}
                    className="rounded-3xl overflow-hidden border-[2px] border-[#9435FF]"
                    style={{
                      boxShadow: `
                        0px 5px 11px 0px #2527284A, 
                        0px 20px 20px 0px #25272842, 
                        0px 44px 27px 0px #25272826, 
                        0px 79px 31px 0px #2527280A, 
                        0px 123px 34px 0px #25272803
                      `,
                    }}
                  >
                    <ViewAttachment
                      s3_bucket="ftp-cndp"
                      attachment={{
                        path: asset.s3_path,
                        type: "image/png",
                      }}
                      label={false}
                    />
                  </div>
                )
            )}
          </Masonry>
        </>
      )}
    </div>
  );
});
