import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { PrivateRoute } from "./helpers";
import { BYRBJP } from "../pages/ad-hoc-features";
import { Enquiries, LoadingState } from "../components";
import { RenderFile } from "../features/digital-repository";
import { DashBoardRoutes } from "./analytical-dashboard-routes";
import { DataBankRoutes } from "./databank-routes";
import { ReportRoutes } from "./report-routes";
import { FeatureRoutes } from "./all-features-routes";
import { ToolsRoutes } from "./tools-routes";
import { SLAR } from "../pages/ad-hoc-features/somali-land-president/ar-landing-page";
import { NotionPage } from "../new-features/notion-react-trial";
import { TermsAndConditions, PrivacyPolicy } from "../pages/login-pages";
import { MLAOR } from "../pages/ad-hoc-features/Australia-Mira/miraforlalorar";

const NewLogin = lazy(() => import("../pages/login-pages/new-login"));
const DeleteUserPage = lazy(() => import("../pages/DeleteUserPage"));
const ProjectDashboardPage = lazy(
  () => import("../pages/dashboard/ProjectDashboard"),
);
const SettingsPage = lazy(() => import("../pages/Settings"));
const PBIDashBoardTemplate = lazy(
  () => import("../features/pbi-reports/pbi-dashboard-template"),
);

export const MainRoutes = () => {
  // @ts-ignore
  return (
    <Suspense fallback={<LoadingState />}>
      <Routes>
        {/*<Route path={"/login"} element={<Login/>}/>*/}
        <Route path={"/login"} element={<NewLogin />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path={"/delete_user_info"} element={<DeleteUserPage />} />
        <Route path={"/enquiries"} element={<Enquiries />} />
        <Route path={"/landing/BYRBJP"} element={<BYRBJP />} />
        <Route path={"/musebixi"} element={<SLAR />} />
        <Route path={"/miraforlalor"} element={<MLAOR />} />

        <Route path="/dashboard/*" element={<DashBoardRoutes />} />
        <Route path="/pbidashboard/:key" element={<PBIDashBoardTemplate />} />

        <Route path={"/"} element={<PrivateRoute />}>
          <Route index path="/" element={<ProjectDashboardPage />} />
          <Route path="/project/:projectid/*" element={<FeatureRoutes />} />
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/tools/*" element={<ToolsRoutes />} />
          <Route path="/notion" element={<NotionPage />} />
          <Route path="/databank/*" element={<DataBankRoutes />} />
          {/*<Route path="/reports/*" element={<ReportRoutes/>}/>*/}
          <Route path="/reports/*" element={<ReportRoutes />} />
          <Route path="/view-file/:key" element={<RenderFile />} />
        </Route>
      </Routes>
    </Suspense>
  );
};
