import React, { useEffect } from "react";
import { useStore } from "../../helpers";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { Button, Chip, ListItem } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export const ServiceRequestFilterTags = observer((props: any) => {
  const rootStore = useStore();
  const { t } = useTranslation("manage_voters");
  const { c3Store } = rootStore;
  const { servicerequestFilter } = c3Store;
  const clonedServiceFilter = { ...servicerequestFilter };

  const result = removeEmptyValues(clonedServiceFilter);

  console.log(servicerequestFilter);

  console.log(clonedServiceFilter);

  const handleClearFilter = (key, valueToRemove) => {
    const keyComparisonMapping = {
      assigned: "assignee.name",
      status: "status.status",
      location: "location.location",
      request_type: "type.name",
      piv_level_2: "name",
      priority: "priority.name",
    };
    if (!clonedServiceFilter[key]) return;
    if (typeof key === "string") {
      if (clonedServiceFilter[key] === valueToRemove) {
        delete clonedServiceFilter[key];
      }
    }

    if (Array.isArray(clonedServiceFilter[key])) {
      const comparisonProperty = keyComparisonMapping[key];
      clonedServiceFilter[key] = clonedServiceFilter[key].filter((item) => {
        if (comparisonProperty) {
          return getNestedValue(item, comparisonProperty) !== valueToRemove;
        }
        return item !== valueToRemove;
      });
    }

    c3Store.setServicerequestFilter(clonedServiceFilter);
  };

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <>
      <div className="flex flex-row items-center justify-between px-4 py-1 border w-[91vw] border-gray-500 rounded-full mx-4 mb-7">
        <div className="flex flex-row items-center space-x-2 w-[60%] md:w-[90%] ">
          <h6 className="font-normal text-xs whitespace-nowrap">
            {t("Filtered By :")}
          </h6>

          <ul className="flex flex-row overflow-x-auto ">
            {result.map(({ key, value }) => (
              <ListItem
                key={key}
                className="space-x-1"
                sx={{
                  padding: "5px",
                }}
              >
                {Array.isArray(value) ? (
                  value.map((item, index) => (
                    <Chip
                      key={`${key}-${index}`}
                      label={key === "piv_level_2" ? item : `${key}: ${item}`}
                      onDelete={() => handleClearFilter(key, item)}
                    />
                  ))
                ) : (
                  <Chip
                    label={key === "key" ? value : `${key}: ${value}`}
                    onDelete={() => handleClearFilter(key, value)}
                  />
                )}
              </ListItem>
            ))}
          </ul>
        </div>

        <Button
          variant="text"
          color="error"
          startIcon={<CloseOutlinedIcon />}
          onClick={async () => {
            await c3Store.setServicerequestFilter({});
          }}
        >
          <span className="whitespace-nowrap"> {t("Clear Filters")}</span>
        </Button>
      </div>
    </>
  );
});

const removeEmptyValues = (obj) => {
  let result = [];

  Object.entries(obj).forEach(([key, value]) => {
    let extractedValues;

    if (typeof value === "string") {
      if (value !== "") {
        extractedValues = value;
        key = key;
        result.push({ key: key, value: extractedValues });
      }
    }

    if (Array.isArray(value) && value.length > 0) {
      switch (key) {
        case "assigned":
          extractedValues = value
            .map((item) => item?.assignee?.name)
            .filter((name) => name);
          break;
        case "status":
          extractedValues = value
            .map((item) => item?.status?.status)
            .filter((status) => status);
          break;
        case "priority":
          extractedValues = value
            .map((item) => item?.priority?.name)
            .filter((name) => name);
          break;
        case "location":
          extractedValues = value
            .map((item) => item?.location?.location)
            .filter((location) => location);
          break;
        case "request_type":
          extractedValues = value
            .map((item) => item?.type?.name)
            .filter((name) => name);

          break;
        case "piv_level_2":
          extractedValues = value.map((item) => item?.name);

          break;

        default:
          return;
      }

      if (extractedValues.length > 0) {
        result.push({ key, value: extractedValues });
      }
    }
  });

  return result;
};
