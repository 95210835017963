import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { safe_get } from "../report-visuals/report-utils";
import { useStore } from "../helpers/helpers";
import _ from "lodash";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { htmlValidation } from "../helpers/utils";
import { useTranslation } from "react-i18next";

export const BoothTreeView = observer((props: any) => {
  const { onBoothSelection, selected_booth } = props;
  const cancelButtonRef = useRef(null);
  const [t] = useTranslation("task_manager");
  const rootStore = useStore();
  const { projectStore } = rootStore;
  const hierarchy = useState(projectStore.getProjectProperty("hierarchy", []));
  const [selectedBooth, setSelectedBooth] = useState({});

  useEffect(() => {
    setSelectedBooth(
      reformated_hierarchy_for_autoComplete.find(
        (x) => x.level2_id === selected_booth.id
      )
    );
  }, []);

  const reformated_hierarchy_for_autoComplete = hierarchy.reduce(
    (node, row) => {
      const hierarchyRows = [];
      row.children?.map((child) => {
        hierarchyRows.push({
          level1: row.display,
          level1_id: row.id,
          level2: child.display,
          level2_id: child.id,
        });
      });
      node = node.concat(hierarchyRows);
      return node;
    },
    []
  );

  const find_booth_by_id = (selected_booth) => {
    if (_.isEmpty(selected_booth)) return;

    const { level1_id, level2_id } = selected_booth;

    const filtered_l1 = _.find(hierarchy, (node) => {
      return node.id === level1_id;
    });

    const filtered_l2 = _.find(filtered_l1["children"], (x) => {
      return level2_id === x.id;
    });

    onBoothSelection(filtered_l2);
    // setSelectedBooth(filtered_l2)
  };

  return (
    <div className="bg-white rounded-lg my-4">
      <Autocomplete
        ref={cancelButtonRef}
        id="select-booth"
        value={selectedBooth || ""}
        options={reformated_hierarchy_for_autoComplete}
        autoHighlight
        getOptionLabel={(option) => safe_get(option, "level2", "")}
        onChange={(event, v) => {
          console.log({ v });
          htmlValidation(event);
          find_booth_by_id(v);
        }}
        groupBy={(option) => {
          // @ts-ignore
          return option.level1;
        }}
        // renderOption={(props, option) => (
        //     <Box key={option.level2_id} component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        //         {option.level2}
        //     </Box>
        // )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={`${t("select_id")}`}
            onChange={(event) => {
              console.log(params);
              htmlValidation(event);
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
          />
        )}
      />
    </div>
  );
});
