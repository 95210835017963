import React from "react";
import { observer } from "mobx-react-lite";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormikController from "../../components/formik/formik-controller";
import { useStore, htmlValidation } from "../../helpers";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { FormButtons } from "../../components";
import { TailwindModal } from "../../components";

export const AddEngineer = observer((props: any) => {
  const rootStore = useStore();
  const { grievanceStore } = rootStore;
  const { selectedDepartment, isAddNewEnginner } = grievanceStore;
  const { t } = useTranslation("grievance_management");
  const { onEngSave } = props;

  const initialValues = {
    name: "",
    phone: "",
    address: "",
    comments: "",
  };

  const formSubmit = async (values) => {
    onEngSave(values);
    console.log(values);
    const activeProjectProperties = JSON.parse(
      localStorage.getItem("__active_project")
    );
    const id = activeProjectProperties["id"];
    const payload = {
      id: id,
      properties: {
        ...activeProjectProperties.properties,
        grievances: {
          type: "json",
          value: {
            ...activeProjectProperties.properties.grievances.value,
            departments: {
              ...activeProjectProperties.properties.grievances.value
                .departments,
              [selectedDepartment]: {
                owners: [
                  ...activeProjectProperties.properties.grievances.value
                    .departments[selectedDepartment]["owners"],
                  { ...values },
                ],
              },
            },
          },
        },
      },
    };
    console.log(payload);
    const result = await grievanceStore.add_engineer(payload);
    if (result.status === 200) {
      toast.success(t("added_engineer_sucessfully"));
      // grievanceStore.udpate_add_engineer_modal(false)
      console.log(result);
      grievanceStore.update_grievance_store_property("isAddNewEnginner", false);
    } else {
      toast.error(t("something_went_wrong"));
      // grievanceStore.udpate_add_engineer_modal(false)
      grievanceStore.update_grievance_store_property("isAddNewEnginner", false);
    }
  };

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone: Yup.string().required("Phone Number is required"),
  });

  const labelStyle = "block text-gray-900 flex-2 w-48 sm:mt-px sm:py-1 w-full";
  const fieldStyle = "px-4 py-2 rounded-lg border border-gray-300 w-full";

  const handleClose = () => {
    grievanceStore.update_grievance_store_property("isAddNewEnginner", false);
    // grievanceStore.udpate_add_engineer_modal(false)
  };
  return (
    <>
      <TailwindModal isOpen={isAddNewEnginner}>
        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full  p-2 sm:p-4">
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={formSubmit}
            validationSchema={ValidationSchema}
          >
            <Form
              className="rounded-lg"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className="text-indigo-600 text-lg font-bold">
                Add Official/Authority
              </div>
              <div className="form-control sm:grid sm:grid-cols-  sm:items-start d-flex flex-column text-left">
                <FormikController
                  control="input"
                  type="text"
                  label={t("name")}
                  name="name"
                  labelStyle={labelStyle}
                  fieldStyle={fieldStyle}
                />
              </div>
              <div className="form-control sm:grid sm:grid-cols-  sm:items-start d-flex flex-column text-left">
                <FormikController
                  control="input"
                  type="text"
                  label={t("phone")}
                  name="phone"
                  labelStyle={labelStyle}
                  fieldStyle={fieldStyle}
                />
              </div>
              <div className="sm:grid sm:grid-cols-1  sm:items-start d-flex flex-column text-left">
                <FormikController
                  control="input"
                  type="text"
                  label={"Designation"}
                  name="designation"
                  labelStyle={labelStyle}
                  fieldStyle="px-4 py-2 rounded-lg border border-gray-300 w-full min-h-full max-h-40"
                />
              </div>

              <FormButtons
                onSave={() => {}}
                onCancel={(e) => {
                  htmlValidation(e);
                  handleClose();
                }}
              />
            </Form>
          </Formik>
        </div>
      </TailwindModal>
      {/*<Transition.Root show={isAddNewEnginner} as={Fragment}>*/}
      {/*    <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={handleClose}>*/}
      {/*        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">*/}
      {/*            <Transition.Child*/}
      {/*                as={Fragment}*/}
      {/*                enter="ease-out duration-300"*/}
      {/*                enterFrom="opacity-0"*/}
      {/*                enterTo="opacity-100"*/}
      {/*                leave="ease-in duration-200"*/}
      {/*                leaveFrom="opacity-100"*/}
      {/*                leaveTo="opacity-0"*/}
      {/*            >*/}
      {/*                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />*/}
      {/*            </Transition.Child>*/}

      {/*            /!* This element is to trick the browser into centering the modal contents. *!/*/}
      {/*            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">*/}
      {/*                &#8203;*/}
      {/*            </span>*/}
      {/*            <Transition.Child*/}
      {/*                as={Fragment}*/}
      {/*                enter="ease-out duration-300"*/}
      {/*                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}
      {/*                enterTo="opacity-100 translate-y-0 sm:scale-100"*/}
      {/*                leave="ease-in duration-200"*/}
      {/*                leaveFrom="opacity-100 translate-y-0 sm:scale-100"*/}
      {/*                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"*/}
      {/*            >*/}
      {/*                */}
      {/*            </Transition.Child>*/}
      {/*        </div>*/}
      {/*    </Dialog>*/}
      {/*</Transition.Root>*/}
    </>
  );
});
