import React, { useMemo, useState } from "react";
import { AgGridReact } from "@ag-grid-community/react";
import { safe_get } from "../../report-visuals";
import { Link } from "react-router-dom";
import { DeleteModal, FloatingMenu } from "../../components";
import ArrowUpRightIcon from "@heroicons/react/24/solid/ArrowUpRightIcon";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import { useDeletePBIReport } from "./pbi-hooks";
import { useLocalStorage } from "react-use";

export const PBIReportsTable = observer(({ reportsData }: any) => {
  const { reportStore } = useStore();
  const { deletePBIReportModal, activePBIReport } = reportStore;
  const reports = useMemo(
    () => safe_get(reportsData, "bireports", []),
    [reportsData],
  );
  const actionsMenuItems = [
    {
      title: "Edit",
      icon: "fas fa-edit text-green-300",
      onClick: (e, report) => {
        reportStore.update_addPBIReportModal(true);
        reportStore.update_activePBIReport(report);
      },
    },
    {
      title: "Delete",
      icon: "fas fa-trash text-orange-500",
      onClick: (e, report) => {
        setId(report?.id);
        reportStore.update_deletePBIReportModal(true);
      },
    },
  ];
  const title = safe_get(activePBIReport, "title", "");
  const { isDeleting, deletePBIReport } = useDeletePBIReport();
  const [value, setReportData] = useLocalStorage("pbi_report", "{}");
  const [id, setId] = useState(null);
  const [cols] = useState([
    {
      header: "Title",
      field: "title",
    },
    {
      header: "Route",
      field: "route",
    },
    {
      header: "Link",
      field: "link",
      flex: 1,
    },
    {
      header: "Created On",
      field: "created",
    },
    {
      header: "Last Modified",
      field: "modified",
    },
    {
      headerName: "Actions",
      field: "",
      cellRenderer: (params) => {
        console.log({ params });
        return (
          <div className={"flex flex-row items-center justify-evenly"}>
            <Link
              to={`/pbidashboard/${params.data.route}`}
              onClick={() =>
                setReportData(JSON.parse(JSON.stringify(params.data)))
              }
              className={"flex flex-row items-center gap-x-2"}
              target={"_blank"}
            >
              Visit
              <span>
                <ArrowUpRightIcon className={"w-4 h-4"} />
              </span>
            </Link>
            <FloatingMenu items={actionsMenuItems} data={params.data} />
          </div>
        );
      },
    },
  ]);

  const closeModal = () => {
    reportStore.update_deletePBIReportModal(false);
  };

  const onReportDeleteHandler = async () => {
    deletePBIReport({ id });
    closeModal();
  };

  return (
    <div className="ag-theme-quartz" style={{ height: 800, width: "100%" }}>
      <AgGridReact
        columnDefs={cols}
        rowData={reports}
        pagination
        rowStyle={{
          fontSize: "16px",
        }}
        rowHeight={45}
      />
      <DeleteModal
        onDeleteClick={onReportDeleteHandler}
        onCancelClick={closeModal}
        isOpen={deletePBIReportModal}
        desc={`Do you want to Delete ${title} report`}
      />
    </div>
  );
});
