import React from "react";
import { classNames, useStore } from "../../helpers";
import { observer } from "mobx-react-lite";
import { VoterCard } from "../manage-voters/Voters";
import { Radio, RadioGroup } from "@headlessui/react";
import Masonry from "@mui/lab/Masonry";
import { FormButtons } from "../../components";
import { useWindowSize } from "react-use";
import { Stack } from "@mui/material";

export const VoterListWithRadioButton = observer((props: any) => {
  const { voterListStore } = useStore();
  const { Voterlist, activeVoter } = voterListStore;
  const { height } = useWindowSize();
  const rootStore = useStore();
  const { beneficiaryStore } = rootStore;

  const searchVotersHandler = async (val) => {
    await voterListStore.update_active_voter(val);
  };

  const close = () => {
    setTimeout(() => {
      voterListStore.setShowVoterlist(false);
      beneficiaryStore.update_add_beneficiary_modal(true);
    }, 300);
  };
  return (
    <div className={"flex flex-col "}>
      <Stack
        className={
          "max-h-[] md:max-h-[55%] lg:max-h-[55%] xl:max-h-[65%] overflow-auto"
        }
        sx={{
          maxHeight: { sm: height * 0.8, md: height * 0.65 },
          overflow: "auto",
        }}
      >
        <RadioGroup
          value={activeVoter}
          onChange={(val) => searchVotersHandler(val)}
          className="p-2 bg-grey-300 rounded-3xl"
        >
          <Masonry columns={{ sm: 1, md: 2, lg: 3, xl: 3 }}>
            {Voterlist &&
              Voterlist.length > 0 &&
              Voterlist.map((option) => (
                <Radio
                  key={option.label}
                  value={option}
                  aria-label={option.label}
                  className={classNames(
                    option.id === activeVoter["id"]
                      ? "rounded-3xl bg-primary-light"
                      : "bg-transparent",
                    "relative flex cursor-pointer p-2 items-center justify-center rounded-3xl focus:outline-none transition duration-500 ease-in-out"
                  )}
                >
                  <VoterCard voterdata={option} key={option.id} />
                </Radio>
              ))}
          </Masonry>
        </RadioGroup>
      </Stack>
      <div className={"bg-white p-4 "}>
        <FormButtons
          onSave={close}
          onCancel={() => {
            close();
            voterListStore.update_active_voter({});
          }}
        />
      </div>
    </div>
  );
});
