import { createTheme } from "@mui/material";

export const FTPTheme = createTheme({
  // Create a custom MUI theme for consistent styling across the app.
  palette: {
    primary: {
      main: "#3A80F4",
      dark: "#2067D8",
      light: "#5D9AFF",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#F7433A",
      dark: "#DA1F1E",
      light: "#FF6F61",
    },
    success: {
      main: "#1DB954",
    },
    info: {
      main: "#3A80F4",
    },
    warning: {
      main: "#FFA726",
    },
    error: {
      main: "#F44336",
    },
    background: {
      default: "#ECEDEF",
    },
    text: {
      primary: "#1B1B1D",
    },
    grey: {
      100: "#ECEDEF",
      200: "#E0E0E0",
      300: "#DBDBDB",
      400: "#B6B6B6",
      500: "#959595",
    },
    common: {
      white: "#FFFFFF",
      black: "#1B1B1D",
    },
  },
  typography: {
    fontFamily: "WorkSans",
    fontSize: 16,
    h1: {
      fontSize: "clamp(2rem, 4vw, 3rem)",
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "clamp(1.5rem, 3vw, 2.5rem)",
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "clamp(1.25rem, 2.5vw, 2rem)",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: "clamp(1rem, 2vw, 1.5rem)",
      fontWeight: 400,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: "clamp(0.875rem, 1.5vw, 1.25rem)",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: "clamp(0.75rem, 1vw, 1rem)",
      fontWeight: 700,
      lineHeight: 1.2,
    },
    subtitle1: {
      fontSize: "clamp(0.875rem, 1vw, 1rem)",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    subtitle2: {
      fontSize: "clamp(0.75rem, 0.5vw, 0.875rem)",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body1: {
      fontSize: "clamp(0.875rem, 0.5vw, 1rem)",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "clamp(0.75rem, 0.5vw, 0.875rem)",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    button: {
      fontSize: "clamp(0.75rem, 0.5vw, 0.875rem)",
      fontWeight: 500,
      textTransform: "none",
      lineHeight: 1.5,
    },
    caption: {
      fontSize: "clamp(0.625rem, 0.25vw, 0.75rem)",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    overline: {
      fontSize: "clamp(0.625rem, 0.25vw, 0.75rem)",
      fontWeight: 400,
      lineHeight: 1.5,
    },
  },
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          fontFamily: "WorkSans",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "40px",
        },
        sizeSmall: {
          padding: "clamp(4px, 2vw, 8px) clamp(8px, 4vw, 16px)",
        },
        sizeMedium: {
          padding: "clamp(8px, 3vw, 12px) clamp(16px, 6vw, 24px)",
        },
        sizeLarge: {
          padding: "clamp(12px, 4vw, 16px) clamp(24px, 8vw, 32px)",
        },
      },
    },
  },
});
