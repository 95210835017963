import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import StackedColumnChart from "./StackedColumnChart";
import { useStore } from "../../../helpers";

export const SocialListeningBaseClassification = observer(() => {
  const { flexiStore } = useStore();
  const [chartData, setChartData] = useState([]);
  const [seriesKeys, setSeriesKeys] = useState<string[]>([]);

  const categoryColors: Record<string, string> = {
    "Calendar Based": "#FFA010",
    "Casual Update": "#B1070C",
    Combative: "#16B8F3",
    Comparative: "#C6723B",
    Critical: "#9F0FEF",
    Emotive: "#66B032",
    Informative: "#2138AB",
    "Not Marked": "#FF69AF",
    "Professional Update": "#05FFA6",
  };

  useEffect(() => {
    if (!flexiStore.current_fsd?.r2) return;

    const baseClassification = (
      flexiStore.current_fsd?.r2 as Record<string, any>
    )?.base_classification;
    if (!baseClassification) return;

    const allCategories = new Set<string>();

    const transformedData = Object.entries(baseClassification).map(
      ([week, categories]) => {
        const [weekPart, yearPart] = week.split(", ");
        const weekData: Record<string, any> = {
          category: `[bold #B97CFE]${weekPart}[/]\n[bold black]${yearPart}[/]`,
        };

        Object.entries(categories).forEach(([categoryName, values]) => {
          if (Array.isArray(values) && values.length > 0) {
            weekData[categoryName] = values[0] || 0;
            allCategories.add(categoryName);
          }
        });

        return weekData;
      }
    );

    setChartData(transformedData);
    setSeriesKeys(Array.from(allCategories));
  }, [flexiStore.current_fsd]);

  return (
    <div className="bg-white">
      <div className="flex justify-between items-center p-10">
        <Typography variant="h3" fontWeight="bold" className="text-[#B97CFE]">
          Weekly Post Classification
        </Typography>
        <Typography variant="h4">
          A detailed classification of posts based
          <br /> on their theme, diversity and purpose
        </Typography>
      </div>
      <div className="w-full h-screen">
        {chartData.length > 0 && seriesKeys.length > 0 ? (
          <StackedColumnChart
            chartId="baseClassificationChart"
            chartData={chartData}
            seriesKeys={seriesKeys}
            tiltLabels={false}
            chartColors={{
              background: "white",
              text: "black",
              xAxis: "yellow",
            }}
            seriesColors={categoryColors}
            labelPosition="inside"
          />
        ) : (
          <Typography>No data available</Typography>
        )}
      </div>
    </div>
  );
});
