import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { Srstyledtabs } from "../sr-styles";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { SrPersonalInfo } from "./sr-personal-info";
import { SrRequestServiceInfo } from "./sr-request-service-info";

const Tabs = ["Personal Information", "Request & Related Information"];

export const SrRequestView = observer((props) => {
  const [currentTabValue, setCurrentTabValue] = useState(Tabs[0]);

  return (
    <>
      <div>
        <TabContext value={currentTabValue}>
          <Srstyledtabs
            // @ts-ignore
            indicatorColor={"common.black"}
            variant={"fullWidth"}
            onChange={(e, value) => {
              setCurrentTabValue(value);
            }}
          >
            {Tabs.map((tab) => (
              <Tab key={tab} label={tab} value={tab} />
            ))}
          </Srstyledtabs>
          <TabPanel value={Tabs[0]}>
            <SrPersonalInfo />
          </TabPanel>
          <TabPanel value={Tabs[1]}>
            <SrRequestServiceInfo />
          </TabPanel>
        </TabContext>
      </div>
    </>
  );
});
