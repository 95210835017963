import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import FormikController from "../../components/formik/formik-controller";
import { htmlValidation, useStore } from "../../helpers";
import * as Yup from "yup";
import { FormButtons } from "../../components";
import { safe_get } from "../../report-visuals";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, TextField } from "@mui/material";
import { TailwindModal } from "../../components";

const labelStyle = "block text-gray-900 flex-2 w-48 sm:mt-px sm:py-1 w-full";
const fieldStyle = "px-4 py-2 rounded-lg border border-gray-300 w-full";

export const AddOfficials = observer((props: any) => {
  const rootStore = useStore();
  const { onOfficialAdd } = props;
  const { beneficiaryStore, projectStore } = rootStore;
  const { add_official_modal, users } = beneficiaryStore;
  const { t } = useTranslation("beneficiary");
  const { projectid } = useParams();
  const cancelButtonRef = useRef(null);

  useEffect(() => {
    fetch_users();
  }, []);

  const fetch_users = async () => {
    const payload = {
      projectid: parseInt(projectid),
      tags: [],
      query: "",
      limit: 500,
      offset: 0,
    };
    const res = await projectStore.getAllUsers(payload);
    if (res.status === 200) {
      beneficiaryStore.set_users(safe_get(res.data, "users", []));
    } else {
      toast.error("Users are not loaded, please refresh the page.");
    }
  };

  const onSubmit = async (data) => {
    onOfficialAdd(data);
    const activeProjectProperties = JSON.parse(
      localStorage.getItem("__active_project"),
    );

    const payload = {
      id: projectid,
      properties: {
        ...activeProjectProperties.properties,
        beneficiary: {
          type: "json",
          value: {
            ...activeProjectProperties.properties.beneficiary.value,
            owners: [
              ...activeProjectProperties.properties.beneficiary.value.owners,
              { ...data },
            ],
          },
        },
      },
    };

    const result = await projectStore.editProjectProperties(payload);
    if (result.status === 200) {
      toast.success(t("offical_added"));
    } else {
      toast.error(t("something_went_wrong"));
    }
    beneficiaryStore.update_add_official_modal(false);
  };

  const initialValues = {
    id: null,
    name: "",
    phone_number: "",
    designation: "",
  };
  const [initialUser, setInitialUser] = useState(initialValues);

  const ValidationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    phone_number: Yup.string().required("Phone Number is required"),
  });

  const handleClose = () => {
    beneficiaryStore.update_add_official_modal(false);
  };
  return (
    <>
      <TailwindModal isOpen={add_official_modal}>
        <div className=" relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
          <div className="mb-20 text-xl font-semibold text-gray-900 fp-text">
            Add official/Authority
          </div>
          <Autocomplete
            ref={cancelButtonRef}
            id="select-user"
            options={users}
            autoHighlight={true}
            filterOptions={(options, { inputValue }) =>
              options.filter(
                (item) =>
                  safe_get(item, "name", "").includes(inputValue) ||
                  safe_get(item, "phonenum", "").includes(inputValue),
              )
            }
            getOptionLabel={(option) => safe_get(option, "name", "")}
            onChange={(event, v) => {
              if (v) {
                setInitialUser({
                  id: v["id"],
                  name: v["name"],
                  phone_number: v["phonenum"],
                  designation: "",
                });
              }
            }}
            style={{
              marginBottom: 20,
              height: 50,
            }}
            renderOption={(props, option) => (
              <Box
                key={option["id"]}
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <img
                  loading="lazy"
                  width="40"
                  src={option["profileimageuri"]}
                />
                <div>
                  <p>{option["name"]}</p>
                  <p>{option["phonenum"]}</p>
                  <p>{option["role"]}</p>
                </div>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select User"
                onChange={(event) => {
                  htmlValidation(event);
                }}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "new-password", // disable autocomplete and autofill
                }}
              />
            )}
          />

          <Formik
            enableReinitialize
            initialValues={initialUser}
            onSubmit={onSubmit}
            validationSchema={ValidationSchema}
          >
            <Form
              className="rounded-lg"
              placeholder={undefined}
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
            >
              <div className="form-control p-2 sm:grid sm:grid-cols-  sm:items-start d-flex flex-column text-left">
                <FormikController
                  control="input"
                  type="text"
                  label={t("name")}
                  name="name"
                  labelStyle={labelStyle}
                  fieldStyle={fieldStyle}
                />
              </div>

              <div className="form-control p-2 sm:grid sm:grid-cols-  sm:items-start d-flex flex-column text-left">
                <FormikController
                  control="input"
                  type="text"
                  label={t("phone_number")}
                  name="phone_number"
                  labelStyle={labelStyle}
                  fieldStyle={fieldStyle}
                />
              </div>
              <div className="p-2  sm:grid sm:grid-cols-1  sm:items-start d-flex flex-column text-left">
                <FormikController
                  control="input"
                  label={t("designation")}
                  name="designation"
                  labelStyle={labelStyle}
                  fieldStyle="px-4 py-2 rounded-lg border border-gray-300 w-full min-h-full max-h-40"
                  height={40}
                />
              </div>
              <FormButtons
                onSave={() => {}}
                onCancel={(e) => {
                  htmlValidation(e);
                  handleClose();
                }}
              />
            </Form>
          </Formik>
        </div>
      </TailwindModal>
    </>
  );
});
