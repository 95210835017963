import {useParams} from "react-router-dom";
import React from "react";
import {FTPBreadCrumbs} from "../../../components-new/BreadCrumbs";

export const ACBankCrumbs = ({fst}) => {

    const {key, fstid} = useParams();

    const crumbs = [
        {name: 'DataBank', href: '/databank', current: false},
        {name: fst, href: '/databank/repo/' + `${fstid}/` + `${key}`, current: false},
        {name:"AC Bank", href: '#', current: true}
    ];

    return <FTPBreadCrumbs crumbs={crumbs}/>
}