import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers";
import _ from "lodash";
import { Table } from "./table";
import { toJS } from "mobx";
import { TableHeader } from "./survey-summary";
import { safe_get } from "../../../report-visuals";
import { useTranslation } from "react-i18next";
import { DownloadAsExcelSheet } from "../../../components";

export const SurveysLevel2 = observer((props) => {
  const rootStore = useStore();
  const { surveyResponseStore, userStore, projectStore } = rootStore;
  const { allSurveyResponsesSummary } = surveyResponseStore;
  const addressList = {};
  const { t } = useTranslation("survey_management");
  const excel_col_def = [
    {
      label: "Booth #",
      key: "piv_level2",
    },
    {
      label: "Valid",
      key: "valid",
    },
    {
      label: "Invalid",
      key: "invalid",
    },
    {
      label: "Not Checked",
      key: "not-checked",
    },
    {
      label: "Total",
      key: "total",
    },
  ];

  //To Show village name beside to booth number
  const memoised = useMemo(() => {
    try {
      const hierarchy = projectStore.getProjectProperty("hierarchy");
      hierarchy.map((node) => {
        return node.children.map((item) => {
          if (
            Object.keys(addressList) &&
            Object.keys(addressList).includes(item["id"])
          ) {
            return;
          } else {
            addressList[item["id"]] = item?.village_name;
          }
        });
      });
    } catch (e) {
      console.log(e);
    }
  }, [allSurveyResponsesSummary]);

  const data = useMemo(
    () => toJS(allSurveyResponsesSummary)["by_piv_level2_qa"],
    [allSurveyResponsesSummary]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("level2"),
        accessor: "piv_level2",
        Cell: (props) => {
          const row = props.row.original;
          const id = safe_get(row, "piv_level2", "001");
          return (
            <p>
              {id} {addressList[id]}
            </p>
          );
        },
        Footer: t("total"),
      },
      {
        Header: t("valid"),
        accessor: "valid",
        Footer: surveyResponseStore.get_total_surveys(data, "valid"),
      },
      {
        Header: t("invalid"),
        accessor: "invalid",
        Footer: surveyResponseStore.get_total_surveys(data, "invalid"),
      },
      {
        Header: t("not_checked"),
        accessor: "not-checked",
        Footer: surveyResponseStore.get_total_surveys(data, "not-checked"),
      },
      {
        Header: t("total_surveys"),
        accessor: "total",
        Footer: surveyResponseStore.get_total_surveys(data, "total"),
      },
    ],
    [allSurveyResponsesSummary]
  );

  if (_.isEmpty(allSurveyResponsesSummary["by_piv_level2_qa"])) return null;

  const row_click_handler = (x) => {
    surveyResponseStore.update_new_filters(
      "piv_level2",
      x["piv_level2"],
      `Level 2: ${x["piv_level2"]}`
    );
  };

  return (
    <div className="flex flex-col w-100 md:w-0">
      <div className="flex flex-row items-center justify-between">
        <TableHeader title={t("interviews_by_level_2")} />
        {(userStore.isRoot() || userStore.isClient()) && (
          <DownloadAsExcelSheet
            data={data}
            cols={excel_col_def}
            name={`p_${rootStore.getActiveProjectID()}_Interviews_By_Booth`}
          />
        )}
      </div>
      <Table data={data} columns={columns} onRowClick={row_click_handler} />
    </div>
  );
});
