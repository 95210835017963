import { action, makeAutoObservable } from "mobx";
import { safe_get } from "../report-visuals";

export type voterFiltersType = {
  projectid: number;
  booths: string[];
  genders: string[];
  age: number[];
  serialno: string;
  name: string;
  relation: string;
  voterid: string;
  page: string;
  df: {};
  house: string;
  offset: number;
  count: number;
  dob: any;
};

export class VoterListStore {
  rootStore: any;
  isFilterDataUpdated = false;
  voterListStore;
  FilterStatus: boolean = false;
  showVoterlist: boolean = false;
  selectedVoterData: any = null;
  FilterVal: any = null;
  SearchBooth: any = [];
  Voterlist: any = [];
  activeVoter: any = {};
  family_members: {}[] = [];
  connect_user_to_voterlist_modal: boolean = false;
  voter_filters: voterFiltersType = {
    // @ts-ignore
    projectid: safe_get(this.rootStore?.projectStore, "activeProject.id", -1),
    booths: [],
    genders: ["Male", "Female", "Third Gender"],
    age: [18, 120],
    serialno: "",
    name: "",
    relation: "",
    voterid: "",
    page: "",
    df: {},
    house: "",
    offset: 0,
    count: 100,
    dob: "",
  };
  prevDeps: any = {};

  edit_voter_modal: boolean = false;

  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.voterListStore = [];

    this.voter_filters = {
      // @ts-ignore
      projectid: safe_get(this.rootStore?.projectStore, "activeProject.id", -1),
      booths: [],
      genders: ["Male", "Female", "Third Gender"],
      age: [18, 120],
      serialno: "",
      name: "",
      relation: "",
      voterid: "",
      page: "",
      df: {},
      house: "",
      offset: 0,
      count: 100,
      dob: "",
    };
  }

  @action
  update_filters(params) {
    this.voter_filters.projectid = params.projectid;
    this.voter_filters.booths = params.booths;
    this.voter_filters.genders = params.genders;
    this.voter_filters.age = params.age;
    this.voter_filters.serialno = params.serialno;
    this.voter_filters.name = params.name;
    this.voter_filters.relation = params.relation;
    this.voter_filters.voterid = params.voterid;
    this.voter_filters.page = params.page;
    this.voter_filters.df = params.df;
    this.voter_filters.house = params.house;
    this.voter_filters.offset = params.offset;
    this.voter_filters.dob = params.dob;
    this.voter_filters.count = params.count;
  }

  @action
  reset_filters(projectid) {
    this.voter_filters.projectid = projectid;
    this.voter_filters.booths = [];
    this.voter_filters.genders = ["Male", "Female", "Third Gender"];
    this.voter_filters.age = [18, 120];
    this.voter_filters.serialno = "";
    this.voter_filters.name = "";
    this.voter_filters.relation = "";
    this.voter_filters.voterid = "";
    this.voter_filters.page = "";
    this.voter_filters.df = {};
    this.voter_filters.house = "";
    this.voter_filters.offset = 0;
    this.voter_filters.count = 100;
    this.voter_filters.dob = "";
  }

  @action
  setFilterVal(val) {
    this.FilterVal = val;
  }

  @action
  setShowVoterlist(status) {
    this.showVoterlist = status;
  }

  @action
  setSelectedBooths(val) {
    this.SearchBooth = val;
  }

  @action
  setVoterList(val) {
    this.Voterlist = val;
  }

  @action
  setSelectedVoterData(data) {
    this.selectedVoterData = data;
  }
  @action
  setFilterStatus(status) {
    this.FilterStatus = status;
  }

  @action
  update_active_voter(voter: any) {
    this.activeVoter = voter;
    if (voter["family"] && voter["family"].length > 0) {
      this.family_members = voter["family"];
    } else {
      this.family_members = [];
    }
  }

  @action
  update_connect_user_to_voterlist_modal(status) {
    this.connect_user_to_voterlist_modal = status;
  }

  @action
  update_family_members(data) {
    this.family_members = data;
  }

  @action
  async getVoterSearch() {
    const response = await this.rootStore.api.post(
      "voters/search",
      this.voter_filters
    );
    this.Voterlist = response?.data?.results;
    return response;
  }

  @action
  async attach_VoterProfile(payload) {
    return await this.rootStore.api.post(
      "/user/userattachvoterprofile",
      payload
    );
  }

  @action
  update_edit_voter_modal(val) {
    this.edit_voter_modal = val;
  }

  async update_voter_data(data) {
    return await this.rootStore.api.post("survey/voterrecordsessync", [data]);
  }
}
