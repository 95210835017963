import IconButton from "@mui/material/IconButton";
import React from "react";
import { useStore } from "../../helpers";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { FsFilterPane } from "../../flexistore/fsFilterPane";
import { TaskCreateMenu } from "./task-create-menu";
import { observer } from "mobx-react-lite";

export const TaskSearch = observer((props: any) => {
  const rootStore = useStore();
  const [t] = useTranslation("task_manager");
  const { projectid } = useParams();
  const { taskManagerStore, userStore } = rootStore;
  const hasPermission = userStore.isRoot() || userStore.isClient();

  const refresh = async () => {
    await taskManagerStore.refresh_project_tasks(parseInt(projectid));
    await taskManagerStore.get_task_list(projectid);
  };

  return (
    <div className="flex flex-col w-full md:flex-row md:items-center md:justify-between md:flex-1 sticky top-0 gap-x-4">
      <div className="flex-1">
        <FsFilterPane
          onSearch={(val) => taskManagerStore.set_task_list_query(val)}
        />
      </div>

      <div className="flex flex-row-reverse items-center gap-x-3 md:flex-row md:flex-1">
        <IconButton
          onClick={() => taskManagerStore.update_task_filter_modal(true)}
          color="primary"
          disableRipple
          size="large"
          title="Filters"
        >
          <i className="fas fa-filter" />
        </IconButton>

        <IconButton
          onClick={refresh}
          color="primary"
          disableRipple
          size="large"
          title="Refresh Tasks"
        >
          <i className="fas fa-sync" />
        </IconButton>

        {hasPermission && <TaskCreateMenu />}
      </div>
    </div>
  );
});
