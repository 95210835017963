import React from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import { useStore } from "../../../helpers";
import { SrAdditionalInfo } from "./sr-additional-info";
import RNAccordion from "../../../components/Accordion";
import { TimelineLog } from "../../../features";

export const SrRequestDetailsInfo = observer((props) => {

  const rootStore = useStore();
  const { serviceRequestsStore } = rootStore;
  const { activeServiceRequest, activelogs } = serviceRequestsStore;

  return (
    <>
      <div className="flex flex-col bg-common-white mt-3 rounded-xl">
        <div className="p-4">
          <Typography
            variant={"h6"}
            color={"primary"}
            sx={{
              fontWeight: 600,
            }}
          >
            Requested Details
          </Typography>
        </div>
        <div className="flex flex-col px-4 ">
          <div className="flex flex-row pb-3 items-center justify-between">
            <Typography  className="font-semibold text-common-black">
              Status
            </Typography>
            <Typography className="bg-label-status text-common-white px-2 py-1 rounded-xl ">
              {activeServiceRequest["status"].status.status}
            </Typography>
          </div>
          <div className="flex flex-row pb-3 items-center justify-between">
            <Typography className="font-semibold text-common-black">
              Assigned To
            </Typography>
            <Typography className=" text-common-black ">
              {activeServiceRequest["assignee"].assignee.name}
            </Typography>
          </div>
          <div className="flex flex-row pb-3 items-center justify-between">
            <Typography className="font-semibold text-common-black">
              Priority
            </Typography>
            <Typography className=" text-common-black ">
              {activeServiceRequest["priority"].priority.name}
            </Typography>
          </div>
          <div className="flex flex-row pb-3 items-center justify-between">
            <Typography className="font-semibold text-common-black">
              Requested On
            </Typography>
            <Typography className="text-common-black">
              {activeServiceRequest["more"].requested_date}
            </Typography>
          </div>
        </div>
        <SrAdditionalInfo />
      </div>
      <div className="flex flex-col bg-common-white mt-3 rounded-xl">
        <div className="p-4">
          <Typography
            variant={"h6"}
            color={"primary"}
            sx={{
              fontWeight: 600,
            }}
          >
            Logs
          </Typography>
        </div>
        <div className="flex flex-col px-4 pb-4 ">
          <RNAccordion title={""} subtitle={"You can see the logs here"}>
            <TimelineLog timeline_log={activelogs} mode={"VERTICAL"} />
          </RNAccordion>
        </div>
      </div>
    </>
  );
});
