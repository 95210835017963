import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { safe_get } from "../../report-visuals";
import _ from "lodash";
import { useStore } from "../../helpers";
import { useTranslation } from "react-i18next";
import { LoadingState } from "../../components";

type ElectionDayCardProps = {
  data: Object;
  ourParty: String;
  styles?: String;
};

export const ElectionDayCard = observer((props: ElectionDayCardProps) => {
  const { data, ourParty, styles } = props;
  const [loading, setLoading] = useState(false);
  const [totalVoters, setTotalVoters] = useState(0);
  const [support, setSupport] = useState(0);
  const [polledVotes, setPolledVotes] = useState(0);
  const [polledVotesPerc, setPolledVotesPerc] = useState(0);
  const [voteshare, setVoteshare] = useState({});
  const { electionDayDashboardStore } = useStore();
  const { nameInModal } = electionDayDashboardStore;
  const { t } = useTranslation("election_dashboard");

  useEffect(() => {
    fetchElectionDayCardData();
  }, []);

  const fetchElectionDayCardData = () => {
    setLoading(true);
    if (!_.isEmpty(data)) {
      const totvoters = safe_get(data, "total_voters", 0);
      setTotalVoters(totvoters);
      const parties = safe_get(data, "support_strength", {});
      if (!_.isEmpty(parties)) {
        const ourpartycount = safe_get(parties, ourParty, 0);
        if (totvoters > 0 && ourpartycount > 0) {
          const supp = (ourpartycount / totvoters) * 100;
          setSupport(supp);
        }
      }
      const gotvVote = safe_get(data, "gotv_voted", {});
      if (!_.isEmpty(gotvVote)) {
        const voted = safe_get(gotvVote, "voted", 0);
        if (totvoters > 0 && voted > 0) {
          setPolledVotes(voted);
          const votes = (voted / totvoters) * 100;
          setPolledVotesPerc(votes);
        }
      }
      const gotvparty = safe_get(data, "gotv_party", {});
      delete gotvparty["Not voted"];
      setVoteshare(gotvparty);
      setLoading(false);
    }
  };

  const section_style = "w-1/2 text-md capitalize font-bold text-gray-600";
  const label_style = "w-1/2 text-md ";
  const indentened_label_style = `ml-6 ${label_style}`;
  const value_style = "w-1/2 text-right font-bold text-gray-500 ";

  return (
    <>
      {loading && <LoadingState />}
      <div
        className={`bg-white p-2 lg:p-4 rounded-lg m-4 lg:max-h-200 ${styles}`}
      >
        <div className="flex flex-row">
          <div className="w-1/4">
            <CircularProgressbar
              value={polledVotesPerc}
              text={`${polledVotesPerc.toFixed(2)}%`}
            />
          </div>
          <div className="w-3/4 flex flex-col">
            <div className="font-bold text-xl text-purple-600">
              {nameInModal}
            </div>
            <div className="flex flex-row ml-2">
              <div className={label_style}>{t("total_voters")}</div>
              <div className={value_style}>{totalVoters}</div>
            </div>
            <div className="flex flex-row ml-2">
              <div className={label_style}>
                {t("strength")} ({ourParty})
              </div>
              <div className={value_style}>{support.toFixed(2)}%</div>
            </div>
            {!_.isEmpty(voteshare) ? (
              <>
                <div className="flex flex-row ml-2">
                  <div className={section_style}>Polled</div>
                  <div className={value_style}>{polledVotes}</div>
                </div>
                {Object.keys(voteshare).map((party) => {
                  const votes = safe_get(data, `gotv_party.${party}`, 0);
                  const votes_percent =
                    Math.round((votes / totalVoters) * 1000.0) / 10;
                  const votes_percent_string = `${votes_percent}%`;
                  return (
                    <div className="flex flex-row ml-2">
                      <div className={indentened_label_style}>{party}</div>
                      <div className={value_style}>
                        {votes} ({votes_percent_string})
                      </div>
                    </div>
                  );
                })}{" "}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
});
