import { observer } from "mobx-react-lite";
import React, { useState, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  RNBoothSelector,
  RNInput,
  RNSelect,
} from "../../components/form-components";
import { useTranslation } from "react-i18next";
import { Button, Switch, FormControlLabel } from "@mui/material";
import _ from "lodash";
import { useStore } from "../../helpers";
import { useParams } from "react-router-dom";
import { FormButtons, SideOverlay } from "../../components";
import {
  beneficiaryDefaultValues,
  GENDERS,
  getBeneficiaryDefaultValues,
} from "./beneficiary-v2-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { addBeneficiaryValidations } from "./utils";
import { safe_get } from "../../report-visuals";
import {
  AddFamilyMembersv2,
  RenderFamilyMembersV2,
} from "./beneficiary-family-members";
import {
  AddBeneficiaryRequests,
  RenderBeneficiaryRequestsV2,
} from "./beneficiary-requests";
import { useAddBeneficiary, useEditBeneficiary } from "./beneficiary-hooks";

export const AddBeneficiaryV2 = observer((props) => {
  const rootStore = useStore();
  const { t } = useTranslation(["beneficiary", "voter_list"]);
  const { beneficiaryStore, voterListStore, userStore, projectStore } =
    rootStore;
  const { activeVoter } = voterListStore;
  const { addBeneficiary, isLoading: isAdding } = useAddBeneficiary();
  const { editBeneficiary, isLoading: isEdititing } = useEditBeneficiary();
  const { projectid } = useParams();
  const [isOtherAssemblyVoter, setIsOtherAssemblyVoter] = useState(false);
  const { activeBeneficiary, add_beneficiary_modal } = beneficiaryStore;
  const profileOptions = rootStore.projectStore.getProjectProperty(
    "profile_questions",
    {},
  );
  const casteOptions = safe_get(profileOptions, "caste", []);
  const beneficiary_props = projectStore.getProjectProperty("beneficiary", {});
  const concernedPersons = safe_get(beneficiary_props, "owners", []);

  const loggedin_user = {
    //current user info
    name: userStore.user.profile.name,
    phone: userStore.user.phonenum,
    address: userStore.user.profile.address,
    profileImage: userStore.user.profileimageuri,
  };

  useEffect(() => {
    const voterid = safe_get(activeVoter, "id", null);
    methods.reset(
      !!voterid
        ? getBeneficiaryDefaultValues(activeVoter)
        : safe_get(
            activeBeneficiary,
            "summary.profile",
            beneficiaryDefaultValues,
          ),
    );
  }, [activeBeneficiary, activeVoter]);

  const methods = useForm({
    defaultValues: beneficiaryDefaultValues,
    //@ts-ignore
    resolver: yupResolver(addBeneficiaryValidations),
    mode: "onSubmit",
  });

  const onSubmit = async (data) => {
    const fsdid = safe_get(activeBeneficiary, "id", null);
    console.log(fsdid);
    if (fsdid) {
      editBeneficiary(data);
    } else {
      addBeneficiary(data);
    }
    close_modal();
  };

  const close_modal = async () => {
    beneficiaryStore.update_add_beneficiary_modal(false);
    methods.reset({ ...beneficiaryDefaultValues });
  };

  const searchVotersHandler = async () => {
    voterListStore.setShowVoterlist(true);
    beneficiaryStore.update_add_beneficiary_modal(false);
    const clone = _.clone(voterListStore.voter_filters);
    clone["projectid"] = parseInt(projectid);
    await voterListStore.update_filters(clone);
  };

  return (
    <SideOverlay
      onClose={close_modal}
      show={add_beneficiary_modal}
      title={t("add_new_beneficiary")}
    >
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className="bg-white px-4 py-2 rounded-sm flex flex-col gap-y-3"
        >
          <div className="py-3 flex flex-row justify-between items-center">
            <div className="text-indigo-500 font-bold text-xl overlay-text">
              {t("beneficiary_information")}
            </div>
            <Button
              onClick={searchVotersHandler}
              variant="contained"
              endIcon={<i className="fas fa-search" />}
              size="small"
            >
              {t("search_voters")}
            </Button>
          </div>
          <RNInput label="Name" name="name" />
          <RNInput label="Phone Number" name="phone" />
          <RNInput label="Whatsapp Number" name="whatsapp" />
          <RNInput label="Voter ID" name="voter_id" />
          <RNInput label="Aadhar ID" name="aadhar_id" />
          <RNInput
            label="Date of Birth"
            name="dob"
            type="date"
            InputLabelProps={{ shrink: true }}
          />
          <RNInput label="Age" name="age" type="number" />
          <RNInput label="Address" name="address" />
          <RNBoothSelector
            label="Booth"
            name="booth_number"
            isMulti={false}
            selector="display"
          />
          <RNBoothSelector
            label="Village"
            name="village"
            isMulti={false}
            selector="display"
          />
          <RNSelect label="Gender" name="gender" options={GENDERS} />
          <RNSelect label="Caste" name="caste" options={casteOptions} />

          <FormControlLabel
            control={
              <Switch
                checked={isOtherAssemblyVoter}
                onChange={(e) => setIsOtherAssemblyVoter(e.target.checked)}
                name="otherAssemblyVoter"
                color="primary"
              />
            }
            label="Other Assembly Constituency"
          />

          {isOtherAssemblyVoter && (
            <>
              <RNInput label="Assembly Name" name="ac_name" />
              <RNInput label="Voter Name" name="oacv_name" />
              <RNInput label="Phone Number" name="oacv_phone" type="number" />
            </>
          )}

          <div>
            <AddBeneficiaryRequests concernedPersons={concernedPersons} />
            <RenderBeneficiaryRequestsV2 />
          </div>

          <div>
            <AddFamilyMembersv2 />
            <RenderFamilyMembersV2 />
          </div>

          <FormButtons
            onSave={methods.handleSubmit(onSubmit)}
            onCancel={close_modal}
            type="submit"
          />

          {/*{isLoading && <p>Loading...</p>}*/}
        </form>
      </FormProvider>
    </SideOverlay>
  );
});
