import { makeStyles } from "@mui/styles";

// tab styles
export const tabStyles = makeStyles(() => ({
  tab: {
    backgroundColor: "#ECEDEF",
    borderRadius: "25px",
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: "#B88EFE",
      color: "#fff",
    },
    "& .MuiButtonBase-root": {
      color: "#1B1B1D",
      padding: "16px",
      fontSize: "18px",
    },
  },
}));

// action button styles
export const actionButtonStyles = makeStyles(() => ({
  box: {
    borderRadius: "50%",
    border: "1px solid",
    backgroundColor: "#FFFFFF66",
    width: "50px",
    height: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    borderRadius: "50%",
    padding: "5px",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease",
  },
}));

// hook to style office person auto complete component
export const autoCompleteStyles = makeStyles(() => ({
  root: {
    "& .MuiAutocomplete-inputRoot": {
      borderRadius: "15px",
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
    "& .MuiInputLabel-root": {
      display: "none",
    },
  },
}));

// avatar icons styles
export const avatarStyles = makeStyles(() => ({
  basic: { borderRadius: "10px", height: "70px", width: "70px" },
  default: {
    borderRadius: "10px",
    height: "90px",
    width: "90px",
    backgroundColor: "#1B1B1D0A",
  },
}));
