import React from "react";
import styles from "./votercard.module.css";

type InfoItemProps = {
  label: string;
  value?: string | number | null | undefined;
  renderValue?: () => React.ReactNode;
  withBorder?: boolean;
  textAlign?: "left" | "center" | "right"; // Combined alignment options
};

const InfoItem: React.FC<InfoItemProps> = ({
  label,
  value,
  renderValue,
  withBorder,
  textAlign = "left",
}) => {
  if (!value && !renderValue) return null;

  const alignmentClasses = {
    left: "text-left",
    center: "text-center",
    right: "text-right",
  };

  return (
    <div
      className={`flex flex-1 flex-col ${
        withBorder ? "border-r border-gray-300 pr-4" : ""
      } ${alignmentClasses[textAlign]}`}
    >
      <h6
        className={`font-normal text-sm leading-4 mb-1 ${styles.textSonicSilver}`}
      >
        {label}
      </h6>
      <span
        className={`font-medium text-base leading-5 ${styles.textEerieBlack}`}
      >
        {renderValue ? renderValue() : value}
      </span>
    </div>
  );
};

export const VoterDetails: React.FC<{
  serialno?: number;
  booth?: string;
  gender?: string;
  age?: number;
}> = ({ serialno, booth, gender, age }) => {
  const infoItems = [
    {
      label: "Serial No",
      value: serialno !== undefined ? serialno : null,
      withBorder: true,
    },
    {
      label: "Booth No",
      value: booth,
      withBorder: true,
      textAlign: "center" as const,
    },
    {
      label: "Gender/Age",
      renderValue: () => (
        <>
          <span>{gender}</span>, <span>{age} Y</span>
        </>
      ),
      textAlign: "right" as const,
    },
  ];

  return (
    <div className="flex mb-6 px-10">
      {infoItems.map((item, index) => (
        <InfoItem key={index} {...item} />
      ))}
    </div>
  );
};
