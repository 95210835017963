import { Chrono } from "react-chrono/dist/react-chrono";
import React from "react";
import dayjs from "dayjs";

export const TaskLogs = (props) => {
  const { task } = props;

  const items =
    task &&
    task["logs"]
      .map((x) => {
        return {
          cardTitle: x["op"],
          cardSubtitle: dayjs(x["when"]).format("MMMM Do YYYY, h:mm:ss a"),
          cardDetailedText: x["who"]["name"],
        };
      })
      .reverse();
  return (
    <div className="task-view" style={{ width: "500px", height: "950px" }}>
      <Chrono items={items} mode="VERTICAL" cardHeight={100} />
    </div>
  );
};
