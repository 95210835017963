import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers";
import _ from "lodash";
import { Table } from "./table";
import { toJS } from "mobx";
import { TableHeader } from "./survey-summary";
import { useTranslation } from "react-i18next";

export const SurveysByUser = observer((props) => {
  const { surveyResponseStore } = useStore();
  const { allSurveyResponsesSummary, new_filters } = surveyResponseStore;
  const { t } = useTranslation("survey_management");

  const data = useMemo(
    () => toJS(allSurveyResponsesSummary)["by_surveyor_qa"],
    [allSurveyResponsesSummary]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("name"),
        accessor: "surveyor_name",
        Footer: t("total"),
        Cell: (row) => {
          return (
            <div>
              {row.row.original["surveyor_id"]} - {row.cell.value}
            </div>
          );
        },
      },
      {
        Header: t("valid"),
        accessor: "valid",
        Footer: surveyResponseStore.get_total_surveys(data, "valid"),
      },
      {
        Header: t("invalid"),
        accessor: "invalid",
        Footer: surveyResponseStore.get_total_surveys(data, "invalid"),
      },
      {
        Header: t("not_checked"),
        accessor: "not-checked",
        Footer: surveyResponseStore.get_total_surveys(data, "not-checked"),
      },
      {
        Header: t("total_surveys"),
        accessor: "total",
        Footer: surveyResponseStore.get_total_surveys(data, "total"),
      },
    ],
    [allSurveyResponsesSummary]
  );

  if (_.isEmpty(allSurveyResponsesSummary["by_surveyor_qa"])) return null;

  const row_click_handler = (x) => {
    surveyResponseStore.update_new_filters(
      "surveyor_id",
      x["surveyor_id"],
      `Interviews By: ${x["surveyor_name"]}`
    );
  };

  return (
    <div className="flex flex-col w-100  md:w-0 ">
      <TableHeader title={t("interviews")} />

      <Table data={data} columns={columns} onRowClick={row_click_handler} />
    </div>
  );
});
