import React from "react";

export const FilterHeader = ({ title, content }) => {
  return (
    <>
      <p className="mt-1 font-bold flex items-center text-md text-purple-600 mr-2 flex-nowrap">
        <span className="text-base" style={{ whiteSpace: "break-spaces" }}>
          {title}
        </span>
      </p>
      <span className="text-sm">{content}</span>
    </>
  );
};
