import { safe_get } from "../../../../report-visuals";
import React, { useEffect, useState } from "react";
import _ from "lodash";
import { cooked_grader, F20Table, grader } from "../booth-reports-utils";
import { observer } from "mobx-react-lite";
import { useStore, float_to_percent_str } from "../../../../helpers";
import { useCurrentFSD } from "../../../../custom-hooks/useCurrentFSD";
import { useTranslation } from "react-i18next";
import Masonry from "@mui/lab/Masonry";

export const Form20BoothWise = observer((props: any) => {
  const { booth_key } = props;
  const { flexiStore } = useStore();
  const { group_by_booth } = flexiStore;
  const config = useCurrentFSD();
  const { skip_transform, f20_enabled } = config;

  const f20_ds = safe_get(flexiStore.current_fsd.r5, `f20.${booth_key}`, {});
  const f20_party_key = safe_get(
    flexiStore.current_fsd.r1,
    "config.f20.our_party_key",
    "",
  );
  const facets = Object.keys(f20_ds);
  const { t } = useTranslation("booth_bank");

  const [f20_tables, set_f20_tables] = useState([]);

  useEffect(() => {
    if (!f20_ds) return;

    const f20_tables_tmp = [];

    facets.map((facet) => {
      const title = facet;
      let headers = ["Party", "Votes", "Votes%"];

      const vote_map = safe_get(f20_ds, `${facet}.vote_map`, {});
      const vote_map_percent = safe_get(
        f20_ds,
        `${facet}.vote_map_percent`,
        {},
      );

      let parties = Object.keys(vote_map);
      parties.push(...Object.keys(vote_map_percent));
      parties = _.uniq(parties);

      const ds = [];
      for (let party of parties) {
        const votes = safe_get(vote_map, party, 0);
        let vote_percent = safe_get(vote_map_percent, party, 0);
        vote_percent = float_to_percent_str(vote_percent);
        ds.push([party, votes, vote_percent]);
      }

      f20_tables_tmp.push({
        title: title,
        ds: ds,
        headers: headers,
      });
    });

    set_f20_tables(f20_tables_tmp);
  }, []);

  const swot_data = [];
  Object.keys(f20_ds).map((facet) => {
    const category = safe_get(f20_ds, `${facet}.SWOT.${f20_party_key}`, "");
    swot_data.push([facet, category]);
  });

  if (!f20_ds) return null;

  const display = skip_transform
    ? cooked_grader(booth_key, flexiStore.current_fsd.r3, config)
    : grader(booth_key, config, group_by_booth);
  let f20_category = "";

  if (f20_enabled) {
    const f20_ds = safe_get(
      flexiStore.current_fsd.r5,
      `f20.${booth_key}`,
      null,
    );
    const f20_party_key = safe_get(
      flexiStore.current_fsd.r1,
      "config.f20.our_party_key",
      "",
    );
    const facet = safe_get(
      flexiStore.current_fsd.r1,
      "config.f20.ref_facet",
      "",
    );
    f20_category = safe_get(f20_ds, `${facet}.SWOT.${f20_party_key}`, "");
  }

  const bgcolor =
    f20_enabled && f20_category !== display["display"] ? "#FADBD8" : "#fff";

  return (
    <>
      <div
        className="no-print  pb-12"
        style={{ backgroundColor: `${bgcolor}` }}
      >
        <div className="uppercase text-indigo-500 font-bold p-2">
          {t("form")} 20
        </div>

        <Masonry
          columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
          spacing={2}
          className="ml-6"
        >
          <F20Table
            title={"SWOT"}
            ds={swot_data}
            headers={["Election", "SWOT"]}
          />
          {f20_tables.map((x, index) => {
            return (
              <F20Table
                key={index}
                title={x.title}
                ds={x.ds}
                headers={x.headers}
              />
            );
          })}
        </Masonry>
      </div>
    </>
  );
});
