import React from "react";
import { useStyles } from "../sr-settings/request-types";

export const ServiceRequestTypeCard = ({ request }) => {
  const { type, id } = request;
  const classes = useStyles({ reqtype: type });
  if (!id) return null;
  return (
    <>
      <div
        key={id}
        className={` rounded-3xl w-100 ${classes.requestCardContainer}`}
      >
        <div className={"relative"}>
          <p className="inline-block rounded-lg border border-gray-800 text-sm text-[#1B1B1D] px-2 py-1 m-4">
            {type.category} / {type.subcategory}
          </p>
          <div className="flex flex-col gap-y-4 p-4">
            {type.display.map((option) => (
              <h3 className="text-xl font-bold pt-2 rounded-lg text-[#1B1B1D]">
                {option.text}
              </h3>
            ))}
            {type.description.map((option) => (
              <p className="text-l font-normal pb-2 text-gray-800">
                {option.text}
              </p>
            ))}
          </div>
        </div>
        <div className="pl-2 pt-2">
          <p className="flex flex-col text-sm text-gray-600">
            Assigned to
            <span className=" text-base text-gray-800">{type.assigned}</span>
          </p>
        </div>
      </div>
    </>
  );
};
