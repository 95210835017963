import * as yup from "yup";

export const defaultLocation = {
  location: "",
  address: "",
  description: "",
  active: false,
};

export const locationValidationSchema = yup.object().shape({
  location: yup.string().required("Location is required"),
  address: yup.string().notRequired(),
  description: yup.string().notRequired(),
  active: yup.boolean(),
});
