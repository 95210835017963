import React, { useEffect, useRef, useState } from "react";
import { TailwindModal } from "../../../components";
import { useStore } from "../../../helpers";
import { observer } from "mobx-react-lite";
import { Button } from "@mui/material";
import CheckboxTree from "react-checkbox-tree";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Fuse from "fuse.js";
import { useParams } from "react-router-dom";
import { toJS } from "mobx";

const fuseOptions = {
  keys: [
    "name",
    "label",
    "id",
    "value",
    "children.name",
    "children.label",
    "children.id",
    "children.value",
    "children.children.value",
  ],
  threshold: 0.1,
  includeScore: true,
};
const SelectVillageAndBooth = observer(() => {
  const {
    serviceRequestsStore: {
      modals,
      update_modal_status,
      setActiveVillageAndBooth,
      activeVillageAndBooth,
    },
    projectStore,
  } = useStore();

  const { projectid } = useParams();
  const [results, setResults] = useState([]);
  const [query, setQuery] = useState<string | null>("");
  const hierarchy = projectStore.getProjectProperty("hierarchy", []);
  const [checked, setChecked] = useState([]);
  const [expanded, setExpanded] = useState([]);

  useEffect(() => {
    // setChecked([]);
    // setExpanded([]);
    if (
      toJS(activeVillageAndBooth.village) !== "" &&
      modals.selectVillageAndBooth.village
    ) {
      console.log(activeVillageAndBooth.village);
      setQuery(toJS(activeVillageAndBooth.village));
      // setChecked(toJS(activeVillageAndBooth.village));
      return;
    }
    if (
      toJS(activeVillageAndBooth.booth) !== "" &&
      activeVillageAndBooth.booth
    ) {
      console.log(activeVillageAndBooth.booth);
      setQuery(toJS(activeVillageAndBooth.booth).slice(7));
      // setChecked(toJS(activeVillageAndBooth.booth));
      return;
    }

    setResults(hierarchy);
  }, [projectid, modals.selectVillageAndBooth]);
  useEffect(() => {
    // console.log("Checked ");
    // console.log(checked);
    // console.log("Expanded ");
    // console.log(expanded);
    if (checked && checked.length > 0) {
      const village = expanded[expanded.length - 1];
      const booth = "Booth #" + checked[0];
      if (modals.selectVillageAndBooth.booth) {
        setActiveVillageAndBooth({ ...activeVillageAndBooth, booth: booth });
      }
      if (modals.selectVillageAndBooth.village) {
        setActiveVillageAndBooth({
          ...activeVillageAndBooth,
          village: village,
        });
      }
    }
  }, [checked, expanded]);
  useEffect(() => handleSearchKeyWordInput(query), [query]);
  const handleSearchKeyWordInput = (keyword) => {
    const value = keyword;
    if (value === "") {
      setResults(hierarchy);
      return;
    }
    const fuse = new Fuse(hierarchy, fuseOptions);
    const results = fuse.search(value ? value : "");
    const processedResults = results.map(({ item }) => {
      //@ts-ignore
      const childFuse = new Fuse(item.children, fuseOptions);
      const matchingChildren = childFuse.search(value).map((res) => res.item);

      return {
        //@ts-ignore
        ...item,

        children:
          matchingChildren.length > 0
            ? matchingChildren
            : //@ts-ignore
              item.children,
      };
    });
    setResults(processedResults.length > 0 ? processedResults : hierarchy);
  };

  return (
    // overflow-y-scroll
    <TailwindModal
      isOpen={
        modals.selectVillageAndBooth.booth ||
        modals.selectVillageAndBooth.village
      }
    >
      <div className="inline-block align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle  max-w-lg sm:max-w-xl w-full p-4">
        <p className="text-center font-bold text-xl ">
          Select{" "}
          {toJS(modals.selectVillageAndBooth.booth) ? "Booth" : "Village"}
        </p>
        <div className="flex flex-row flex-wrap justify-between items-center gap-2  p-3 rounded-lg max-h-screen ">
          <input
            type="text"
            className="border-2 border-grey-500 active:ring-2 active:ring-grey-500 rounded-lg w-full p-2"
            value={query}
            placeholder="Search Village or Booth number..."
            onChange={(e) => setQuery(e.target.value)}
          />
          <div className="max-h-[50vh] min-h-[50vh] w-full overflow-y-auto">
            <CheckboxTree
              nodes={results}
              checked={checked}
              expanded={expanded}
              onCheck={(checked) => setChecked(checked)}
              onExpand={(expanded) => setExpanded(expanded)}
              icons={{
                check: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-check"
                    icon="check-square"
                  />
                ),
                uncheck: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-uncheck"
                    icon={["fas", "square"]}
                  />
                ),
                halfCheck: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-half-check"
                    icon="check-square"
                  />
                ),
                expandClose: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-expand-close"
                    icon="chevron-right"
                  />
                ),
                expandOpen: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-expand-open"
                    icon="chevron-down"
                  />
                ),
                expandAll: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-expand-all"
                    icon="plus-square"
                  />
                ),
                collapseAll: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-collapse-all"
                    icon="minus-square"
                  />
                ),
                parentClose: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-parent-close"
                    icon="folder"
                  />
                ),
                parentOpen: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-parent-open"
                    icon="folder-open"
                  />
                ),
                leaf: (
                  <FontAwesomeIcon
                    className="rct-icon rct-icon-leaf-close"
                    icon="file"
                  />
                ),
              }}
            />
          </div>
        </div>
        <div className={"flex flex-row justify-end gap-x-2"}>
          <Button
            variant={"contained"}
            size={"small"}
            color={"primary"}
            onClick={() =>
              update_modal_status("selectVillageAndBooth", {
                village: false,
                booth: false,
              })
            }
            sx={{ marginTop: 2 }}
          >
            Close
          </Button>
        </div>
      </div>
    </TailwindModal>
  );
});

export default SelectVillageAndBooth;
