import { observer } from "mobx-react-lite";
import React from "react";
import { classNames } from "../../helpers";
import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "react-dropdown-tree-select/dist/styles.css";

function iconStyles(current) {
  return classNames(
    current
      ? "text-gray-500 group-hover:text-gray-500"
      : "text-gray-400 group-hover:text-gray-500",
    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
  );
}

const C3Items = observer((props: any) => {
  const { t } = useTranslation("constituency_command_center");
  const navigate = useNavigate();
  const navigation = [
    {
      name: t("demographics"),
      href: `demographics`,
      icon: "demographics",
      current: false,
    },
    {
      name: t("party_workers"),
      href: `partyworkers`,
      icon: "partyworkers",
      current: false,
    },
    {
      name: t("historical"),
      href: `historical`,
      icon: "historical",
      current: false,
    },
    {
      name: t("beneficiaries"),
      href: `beneficiaries`,
      icon: "beneficiaries",
      current: false,
    },
    { name: "Grievance", href: `grievance`, icon: "Grievance", current: false },
    {
      name: t("service_request"),
      href: `service_request`,
      icon: "servicerequest",
      current: false,
    },
    {
      name: "Project Works",
      href: `project_works`,
      icon: "projectworks",
      current: false,
    },
  ];

  return (
    <>
      <div className="sm:hidden my-2 formik-select">
        <label htmlFor="tabs" className="sr-only">
          {t("select_tab")}
        </label>
        <select
          id="tabs"
          name="tabs"
          className="p-4 block w-full focus:ring-indigo-500 focus:border-indigo-500 rounded-lg border border-white bg-white"
          onChange={(eve) => {
            const obj = navigation.find((x) => x.name === eve.target.value);
            const path = obj["href"];
            navigate(`${path}`);
          }}
        >
          {navigation.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <aside className="hidden sm:block py-6 px-2 sm:px-6 lg:col-span-2 lg:py-0 lg:px-0 bg-gray-100">
        <nav className="space-y-1">
          {navigation.map((item) => (
            <NavLink
              key={item.href}
              to={item.href}
              className={(props) =>
                classNames(
                  props.isActive
                    ? "bg-white text-gray-700 hover:bg-gray-200"
                    : "text-gray-700 hover:bg-white",
                  "group px-3 py-2 flex items-center font-medium"
                )
              }
              aria-current={item.current ? "page" : undefined}
            >
              {(() => {
                switch (item.icon) {
                  case "demographics":
                    return (
                      <i
                        className={`${iconStyles(item.current)} fa-solid fa-id-card text-xl`}
                        aria-hidden="true"
                      />
                    );
                  case "historical":
                    return (
                      <i
                        className={`${iconStyles(item.current)} fa-solid fa-rectangle-history text-xl`}
                        aria-hidden="true"
                      />
                    );
                  case "beneficiaries":
                    return (
                      <i
                        className={`${iconStyles(item.current)} fa-solid fa-handshake text-xl`}
                        aria-hidden="true"
                      />
                    );
                  case "partyworkers":
                    return (
                      <i
                        className={`${iconStyles(item.current)} fa-solid fa-person text-2xl`}
                        aria-hidden="true"
                      />
                    );
                  case "Grievance":
                    return (
                      <i
                        className={`${iconStyles(item.current)} fa-solid fa-users text-xl`}
                        aria-hidden="true"
                      />
                    );
                  case "servicerequest":
                    return (
                      <i
                        className={`${iconStyles(item.current)} fa-solid fa-gear text-xl`}
                        aria-hidden="true"
                      />
                    );

                  case "projectworks":
                    return (
                      <i
                        className={`${iconStyles(item.current)} fa-solid fa-users text-xl`}
                        aria-hidden="true"
                      />
                    );
                }
              })()}
              <span className="truncate">{item.name}</span>
            </NavLink>
          ))}
        </nav>
      </aside>
    </>
  );
});
export default C3Items;
