import React, { useEffect, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import * as Yup from "yup";
import {
  useStore,
  makeid_alpa_numeric,
  remove_item_from_array,
} from "../../../helpers";
import { useParams } from "react-router-dom";
import { TextError, FormButtons } from "../../../components";
import FilePondUpload from "../../beneficiary-management-v2/beneficiary-requests/filepond";

const AddEditWhatsapp = observer((props: any) => {
  const { projectid } = useParams();
  const rootStore = useStore();
  const { voterReachOutStore } = rootStore;
  const { formType, whatsappMessageFormValue, messageType } =
    voterReachOutStore;
  const [buffer, setBuffer] = useState([]);
  const [whatsappSubmit, setWhatsappSubmit] = useState(false);
  const [attachments, setAttachments] = useState([]);

  const { formSubmit, type } = props;

  const whatsappMessageFormInitialValues = {
    name: whatsappMessageFormValue.name,
    message: whatsappMessageFormValue.message,
  };

  const smsModel = {
    projectid: projectid,
    channel: type,
    more: {},
  };

  const whatsappMessageFormSubmit = async (values) => {
    try {
      const Attachments = buffer.map((file) => ({
        path: file.path,
        id: file.id,
        type: file.type,
      }));

      const smsPayload = {
        ...smsModel,
        ds: {
          name: values.name,
          text: values.message,
          attachements: Attachments,
        },
      };
      await formSubmit(smsPayload, whatsappMessageFormValue.id);
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileUpdate = (updatedFiles) => {
    setAttachments(updatedFiles);
    const mappedBuffer = updatedFiles.map((file) => ({
      path: file.path,
      type: file.type,
      id: makeid_alpa_numeric(8),
    }));
    setBuffer(mappedBuffer);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    message: Yup.string().required("Message is required"),
  });

  const delete_file = (name) => {
    const updated_values = remove_item_from_array(buffer, "path", name);
    setBuffer(updated_values);
  };

  useEffect(() => {
    if (messageType === "whatsapp Messages" && formType === "update") {
      setBuffer(whatsappMessageFormValue.attachements);
    }
  }, [messageType, formType]);

  return (
    <>
      <Formik
        initialValues={whatsappMessageFormInitialValues}
        onSubmit={whatsappMessageFormSubmit}
        validationSchema={validationSchema}
      >
        <Form
          className="rounded-lg text-center"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <div
            className={
              "form-control  sm:grid sm:grid-cols-  sm:items-start d-flex flex-column text-left"
            }
          >
            <label
              htmlFor="name"
              className="block text-gray-700 rounded-lg text-left font-semibold"
            >
              Name
            </label>
            <div className="flex flex-row items-center">
              <Field
                type="text"
                id="name"
                name="name"
                className="px-4 py-2 rounded-lg border border-gray-300 w-full"
              />
            </div>
            <div className="flex flex-row">
              <ErrorMessage name="name" component={TextError} />
            </div>
          </div>
          <div className=" sm:grid sm:grid-cols-1  sm:items-start d-flex flex-column text-left">
            <label
              htmlFor="message"
              className="block text-gray-700  rounded-lg text-left font-semibold"
            >
              Message
            </label>
            <div className="flex flex-row items-center min-h-80 max-h-full">
              <Field
                as="textarea"
                id="message"
                name="message"
                className="px-4 py-2 rounded-lg border border-gray-300 w-full h-40"
              />
            </div>
            <div className="flex flex-row">
              <ErrorMessage name="message" component={TextError} />
            </div>
          </div>

          <FilePondUpload
            path={`project-${projectid}/voterreachout/whatsapp`}
            files={attachments}
            onUpdate={handleFileUpdate}
          />

          <FormButtons
            onSave={() => setWhatsappSubmit(true)}
            onCancel={() => {
              voterReachOutStore.set_isSideOverlay(false);
            }}
          />
        </Form>
      </Formik>
    </>
  );
});

export default AddEditWhatsapp;
