import { observer } from "mobx-react-lite";
import { Pagination, Stack } from "@mui/material";
import React, { useState } from "react";
import { useStore } from "../../helpers";

export const ILPagination = observer(({ count }: any) => {
  const [currentPage, setCurrentPage] = useState(1);
  const rootStore = useStore();
  const { influentialLeadersStore } = rootStore;
  const { offset } = influentialLeadersStore;
  const noOfPages = Math.ceil(count / influentialLeadersStore.count);
  const end = offset + 1 * influentialLeadersStore.count;

  return (
    <>
      <Stack
        spacing={2}
        className="w-full"
        direction={"row"}
        alignItems={"center"}
      >
        <div className="font-bold text-gray-500">
          Showing {offset + 1} to {end > count ? count : end} of {count}{" "}
        </div>
        <Pagination
          color={"primary"}
          size={"medium"}
          variant={"outlined"}
          count={noOfPages}
          page={currentPage}
          onChange={(e, val) => {
            setCurrentPage(val);
            influentialLeadersStore.updateIlStoreProperty("offset", val);
          }}
        />
      </Stack>
    </>
  );
});
