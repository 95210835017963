import React from "react";
import { observer } from "mobx-react-lite";
import { ServiceRequestHeader, SRCrumbs, SRSearchPanel } from "./sr-components";
import { ServiceRequestsList } from "./service-requests-list";
import { SideOverlay } from "../../components";
import { SrRequestView } from "./sr-requests-view/sr-request-view";
import { useStore } from "../../helpers";

const ServiceRequestHome = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { showServiceRequest } = serviceRequestsStore;

  const close_modal = async () => {
    serviceRequestsStore.setshowServiceRequest(false);
  };

  return (
    <div className={"px-4"}>
      <SRCrumbs />
      <ServiceRequestHeader />
      <SRSearchPanel />
      <ServiceRequestsList />
      <SideOverlay
        children={<SrRequestView />}
        onClose={close_modal}
        show={showServiceRequest}
        title={"Service Request"}
        size={"large"}
      />
    </div>
  );
});

export default ServiceRequestHome;
