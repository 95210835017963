import * as Yup from "yup";
const latLongRegex =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const addProjectWorkValidations = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string().required("Description is required"),
  startDate: Yup.string().required("Start Date is required"),
  estimatedCompletionDate: Yup.string().required(
    "Estimated Completion Date is required",
  ),
  sublocation: Yup.string(),
  booth: Yup.array().required("Please select Booth/Village"),
  status: Yup.string().required("Status is required"),
  // latlong: Yup.string()
  //   .required("Latitude and Longitude are required")
  //   .matches(latLongRegex, "Invalid Latitude and Longitude"),
  //   latitude: Yup.number(),
  //   longitude: Yup.number(),
  coverPhotoPath: Yup.mixed().test("file", "Please select a photo", (value) => {
    console.log(value);
    return value["length"] > 0;
  }),
});
