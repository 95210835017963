import { styled } from "@mui/material/styles";
import TabList from "@mui/lab/TabList";

export const StyledTabs = styled(TabList)(({ theme }) => ({
  backgroundColor: "#E0E0E0",
  borderRadius: "25px",
  "& .MuiButtonBase-root.Mui-selected": {
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    transition: "all 0.5s ease 0s",
  },
  "& .MuiButtonBase-root": {
    color: "#1B1B1D",
    padding: "16px",
    fontSize: "18px",
  },
}));

export const Srstyledtabs = styled(TabList)(({ theme }) => ({
  backgroundColor: "#E0E0E0",
  borderRadius: "25px",
  "& .MuiButtonBase-root.Mui-selected": {
    backgroundColor: theme.palette.common.black,
    color: "#fff",
    transition: "all 0.5s ease 0s",
  },
  "& .MuiButtonBase-root": {
    color: "#1B1B1D",
    padding: "16px",
    fontSize: "18px",
  },
}));
