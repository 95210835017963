import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers/helpers";
import _ from "lodash";
import { Table } from "./table";
import { toJS } from "mobx";
import { TableHeader } from "./survey-summary";
import { useTranslation } from "react-i18next";

export const SurveysByQaCheckOly = observer((props) => {
  const { surveyResponseStore, userStore } = useStore();
  const { allSurveyResponsesSummary } = surveyResponseStore;
  const [t] = useTranslation(["analytical_summary", "survey_management"]);

  const data = useMemo(
    () => toJS(allSurveyResponsesSummary)["by_qa_status"],
    [allSurveyResponsesSummary]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("status"),
        accessor: "status",
        Footer: t("total", { ns: "survey_management" }),
      },
      {
        Header: t("no_of_interviews", { ns: "survey_management" }),
        accessor: "value",
        Footer: surveyResponseStore.get_total_surveys(data, "value"),
      },
    ],
    [allSurveyResponsesSummary]
  );

  if (
    _.isEmpty(allSurveyResponsesSummary["by_qa_status"]) ||
    userStore.is_field_worker()
  )
    return null;

  const row_click_handler = (x) => {
    surveyResponseStore.update_new_filters(
      "qa_status",
      x["status"],
      `QA Status: ${x["status"]}`
    );
  };

  return (
    <div className="flex flex-col w-100 md:w-0">
      <TableHeader
        title={t("interviews_by_qa_status", { ns: "survey_management" })}
      />
      <Table data={data} columns={columns} onRowClick={row_click_handler} />
    </div>
  );
});
