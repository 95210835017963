import { useParams } from "react-router-dom";
import React from "react";
import { FTPBreadCrumbs } from "../../components-new/BreadCrumbs";

export const BeneficiaryCrumbs = (props) => {
  const { projectid } = useParams();
  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: true },
    { name: "Beneficiary Management", href: "#", current: true },
  ];
  return <FTPBreadCrumbs crumbs={crumbs} />;
};
