import React from "react";
import AppHeader from "./shell-components/app-header";
import FTPNavBar from "./shell-components/nav-bar";
import { Stack } from "@mui/material";
import { useLocation } from "react-use";

const Shell = ({ children }) => {
  const { pathname } = useLocation();

  const showHeader = pathname.split("/").includes("project");

  return (
    <Stack direction={"row"}>
      <div
        className={
          "h-screen fixed top-0 left-0 border border-r-2 border-white max-w-[95px] overflow-y-hidden hidden sm:flex"
        }
      >
        <FTPNavBar />
      </div>
      <Stack className={"w-full sm:ml-24"}>
        {showHeader ? <AppHeader /> : null}
        {children}
      </Stack>
    </Stack>
  );
};

export default Shell;
