import React, { useState } from "react";
import { Button, Typography } from "@mui/material";
import { LoginButton } from "./login-button";
import { LoginOtp } from "./login-otp";
import { useFormContext, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useStore } from "../../helpers";
import { toast } from "react-toastify";
import { observer } from "mobx-react-lite";
import { useMutation } from "@tanstack/react-query";
import { FTPInput } from "../../components-new";
import { Vortex } from "react-loader-spinner";

export const PhoneLogin = observer(() => {
  const rootStore = useStore();
  const { userStore } = rootStore;
  const { control } = useFormContext();
  const { t } = useTranslation(["new_login"]);
  const [phone, setPhone] = useState("");
  const [otp, setOTP] = useState("");
  const [showOtpInput, setShowOtpInput] = useState(false);

  const phoneNumber = useWatch({
    control,
    name: "phoneNumber",
    defaultValue: "",
  });

  const isValidPhone = phoneNumber.length === 10 && /^\d+$/.test(phoneNumber);

  const { mutate, isLoading } = useMutation({
    mutationFn: async (phoneNumber) => {
      return await userStore.sendOTP("+91" + phoneNumber);
    },
    onSuccess: (response) => {
      if (response.status === 200) {
        toast.success("OTP sent successfully!");
        setShowOtpInput(true);
      } else {
        toast.error("Failed to send OTP. Please try again.");
      }
    },
    onError: () => {
      toast.error("An error occurred while sending OTP.");
    },
  });
  return (
    <div className={"flex flex-col flex-1 gap-4 md:gap-1 sm:gap-8 md:mt-0"}>
      <div className="flex flex-row gap-6 md:gap-3">
        <div className={"w-[75%]"}>
          <FTPInput
            name="phoneNumber"
            type="tel"
            inputProps={{ maxLength: 10 }}
            value={phone}
            label={t("phoneNumber")}
            placeholder={t("enterPhoneNumber")}
            onChange={(e) => setPhone(e.target.value)}
            fullwidth
          />
        </div>
        <Button
          onClick={() => mutate(phoneNumber)}
          type="button"
          disabled={!isValidPhone}
          loading={isLoading}
          loadingPosition="end"
          loadingIndicator={
            <Vortex
              height="25"
              width="25"
              ariaLabel="vortex-loading"
              wrapperClass="vortex-wrapper"
              colors={["red", "green", "blue", "yellow", "orange", "purple"]}
            />
          }
          sx={{
            width: "70%",
            maxWidth: 130,
            height: 52,
            borderRadius: 2,
            mt: 3,
            bgcolor: isValidPhone ? "#3A80F4" : "grey.200",
            color: "white",
            fontSize: 14,
            fontWeight: 500,
            lineHeight: "16px",
          }}
        >
          {t("getOtp")}
        </Button>
      </div>
      <div>
        {showOtpInput && (
          <div className="flex flex-col">
            <Typography variant={"inherit"}> {t("otp")}</Typography>
            <div className="flex sm:flex-row gap-x-2">
              <div className="w-full">
                <LoginOtp
                  onOTPChange={(val) => {
                    setOTP(val);
                  }}
                />
              </div>
            </div>
          </div>
        )}
        <LoginButton otp={otp} phone={phoneNumber} disabled={!showOtpInput} />
      </div>
    </div>
  );
});
