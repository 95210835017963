import React, { useRef, useState } from "react";
import { Button, Chip, Divider } from "@mui/material";
import { useHoverDirty } from "react-use";
import { ActionButtons } from "../sr-settings-components";
import Skeleton from "@mui/material/Skeleton";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../helpers";
import Stack from "@mui/material/Stack";
import { useStyles } from "./request-type-styles";

export const RequestCardSkeleton = () => {
  return (
    <div className={" p-3 rounded-3xl"}>
      <div className={"flex flex-col gap-y-2 rounded-xl p-3"}>
        <Skeleton variant={"text"} width={"80%"} height={30} />
        <Skeleton variant={"text"} width={"50%"} height={20} />
      </div>
      <div
        className={"my-2 p-3 rounded-3xl flex flex-col gap-4 bg-[#1B1B1D0A]"}
      >
        <div className={"flex flex-col gap-3"}>
          <div className={"flex flex-col gap-y-2"}>
            <Skeleton variant={"text"} width={"100%"} height={20} />
            <Skeleton variant={"text"} width={"100%"} height={20} />
            <Skeleton variant={"text"} width={"100%"} height={20} />
          </div>
          <div className={"flex flex-col gap-y-2"}>
            <Skeleton variant={"text"} width={"100%"} height={20} />
            <Stack direction={"row"} spacing={2}>
              <Skeleton variant={"text"} width={100} height={20} />
              <Skeleton variant={"text"} width={100} height={20} />
              <Skeleton variant={"text"} width={100} height={20} />
            </Stack>
          </div>
        </div>
      </div>
    </div>
  );
};

export const RequestCard = observer(({ request }: any) => {
  const { serviceRequestsStore } = useStore();
  const { type, id } = request;
  const ref = useRef(null);
  const isHovering = useHoverDirty(ref);
  const classes = useStyles({ reqtype: type });

  const [expand, setExpand] = useState(false);

  const onEditHandler = () => {
    serviceRequestsStore.update_modal_status("addRequestType", true);
    serviceRequestsStore.setActiveRequestType(request);
  };

  const onDeleteHandler = () => {
    serviceRequestsStore.update_modal_status("deleteRequestType", true);
    serviceRequestsStore.setActiveRequestType(request);
  };

  return (
    <>
      <div
        ref={ref}
        key={id}
        className={` p-3 rounded-3xl ${classes.requestCardContainer}`}
      >
        <div className={"relative"}>
          <div className={"flex flex-col gap-y-2 rounded-xl p-3"}>
            <div>
              <h4 className={"font-semibold"}>{type?.name}</h4>
              <p className={"text-gray-800 font-normal"}>
                {typeof type?.category === "string" ? type?.category : ""}
              </p>
            </div>
            <div className={"flex flex-wrap gap-2 "}>
              {type.tags?.map((p, i) => {
                return (
                  <Chip
                    key={i}
                    label={typeof p === "string" ? p : null}
                    variant={"outlined"}
                  />
                );
              })}
            </div>
          </div>
          {isHovering ? (
            <ActionButtons
              positionClasses={"-right-3 top-5"}
              onEdit={onEditHandler}
              onDelete={onDeleteHandler}
            />
          ) : null}
        </div>
        <div
          className={
            "my-2 p-3 rounded-3xl flex flex-col gap-4 bg-gray-800 bg-opacity-10"
          }
        >
          <div className={"flex flex-col gap-2"}>
            <div className={"flex justify-between items-center"}>
              <p className={"text-gray-400 "}>Category</p>
              <p className={"text-gray-800"}>
                {typeof type?.category === "string" ? type?.category : ""}
              </p>
            </div>
            {type.display.map((val, i) => {
              return (
                <div key={i} className={"flex justify-between items-center"}>
                  <p className={"text-primary "}>Display {i + 1}</p>
                  <p className={"flex-1 text-right text-primary"}>
                    {val?.text}
                  </p>
                </div>
              );
            })}
          </div>
          <div
            className={`overflow-hidden transition-all duration-1000 delay-100 ease-in-out ${
              expand ? "max-h-[500px]" : "max-h-0"
            }`}
          >
            <Divider className={"my-4"} />
            <div className={"flex flex-col gap-3"}>
              <div className={"flex flex-col gap-y-2"}>
                <p className={"text-gray-800"}>Description</p>
                {type.description.map((val, i) => (
                  <p key={i} className={"text-common-black text-sm"}>
                    {val?.text}
                  </p>
                ))}
              </div>
              <div className={"flex flex-col gap-y-2"}>
                <p className={"text-gray-800"}>Required Documents</p>
                <div
                  className={
                    "flex flex-row flex-wrap items-center justify-start gap-2"
                  }
                >
                  {type?.required_docs?.map((doc, i) => {
                    return (
                      <Chip
                        key={i}
                        label={typeof doc === "string" ? doc : null}
                        variant={"outlined"}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <Button
            size={"small"}
            variant={"text"}
            color={"info"}
            endIcon={
              !expand ? (
                <ChevronDownIcon className={"w-4 h-4"} />
              ) : (
                <ChevronUpIcon className={"w-4 h-4"} />
              )
            }
            onClick={() => setExpand(!expand)}
          >
            {!expand ? "Expand" : "Collapse"}
          </Button>
        </div>
      </div>
    </>
  );
});
