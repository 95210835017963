import { useMutation } from "@tanstack/react-query";
import { useStore } from "../../../helpers";
import { useInvalidateMetaQuery } from "./useInvalidateMetaQuery";
import { toast } from "react-toastify";
import apiRequestHandler from "../../utils";

export const useRequestTypeMutation = (mutationFn, successMessage) => {
  const invalidateMetaQuery = useInvalidateMetaQuery();

  return useMutation({
    mutationFn,
    onSuccess: async () => {
      await invalidateMetaQuery();
      toast.success(successMessage);
    },
    onError: (error) => {
      toast.error(error["message"]);
    },
  });
};

export const useAddServiceRequestType = () => {
  // const { serviceRequestsStore } = useStore();
  const rootStore = useStore();
  const onAddServiceRequestType = async (payload) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-types-add",
      payload,
      "Couldn't add this request"
    );
  };
  return useRequestTypeMutation(
    onAddServiceRequestType,
    "Request Added Successfully."
  );
};

export const useEditServiceRequestType = () => {
  const rootStore = useStore();

  const onUpdateServiceRequestType = async (payload) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-types-update",
      payload,
      "Failed to update Request type"
    );
  };

  return useRequestTypeMutation(
    onUpdateServiceRequestType,
    "Request type updated Successfully."
  );
};

export const useDeleteServiceRequestType = () => {
  const rootStore = useStore();

  const onDeleteServiceRequestType = async (id) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-types-delete",
      { id },
      "Failed to delete Request type"
    );
  };

  return useRequestTypeMutation(
    onDeleteServiceRequestType,
    "Request type deleted successfully."
  );
};
