import { Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FTPTheme } from "../../../ftp-theme";
import { useStore } from "../../../helpers";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { AddNewButton } from "../../../components-new";
export const TitleSection = () => {
  const {
    projectWorkStoreV2: { update_add_project_modal },
  } = useStore();
  const { t } = useTranslation("project_work");

  return (
    <div className="flex flex-row justify-between items-center mb-4 flex-1">
      <Typography
        variant="h2"
        color={FTPTheme.palette.text.primary}
        className="font-bold text-3xl pr-14"
      >
        {t("project_works")}
      </Typography>
      <div className="flex justify-center items-center gap-x-4">
        <SettingsOutlinedIcon className="cursor-pointer" onClick={() => {}} />
        <AddNewButton
          onButtonClick={() => update_add_project_modal(true)}
          text={t("add_project_work")}
        />
      </div>
    </div>
  );
};
