import { useParams } from "react-router-dom";
import { useStore } from "../../../helpers";
import { useRequestTypeMutation } from "../../../new-features";

export const useAddProjectWork = () => {
  const rootStore = useStore();
  const { projectid } = useParams();
  const addAssigneeRequest = async (data) => {
    const payload = {
      projectid: parseInt(projectid),
      features: {},
      summary: data,
      more: {},
    };
    return rootStore.apiRequestHandler(
      "projectworks/add",
      payload,
      "Failed to add this project"
    );
  };
  return useRequestTypeMutation(
    addAssigneeRequest,
    "Project work added successfully"
  );
};

export const useEditProjectWork = () => {
  const rootStore = useStore();
  const editProjectWorkRequest = async (payload: any) => {
    return rootStore.apiRequestHandler(
      "projectworks/update",
      payload,
      "Failed to edit this project"
    );
  };

  return useRequestTypeMutation(
    editProjectWorkRequest,
    "Project updated successfully"
  );
};
