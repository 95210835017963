import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import StackedColumnChart from "./StackedColumnChart";
import { useStore } from "../../../helpers";

export const SocialListeningWeekWise = observer(() => {
  const { flexiStore } = useStore();
  const [chartData, setChartData] = useState([]);
  const [seriesKeys, setSeriesKeys] = useState<string[]>(["Count"]);

  useEffect(() => {
    if (!flexiStore.current_fsd?.r2) return;

    const weekwise = (flexiStore.current_fsd.r2 as Record<string, any>)
      ?.week_wise;
    if (!weekwise) return;

    const transformedData = Object.entries(weekwise).map(([week, values]) => {
      const [weekPart, yearPart] = week.split(", ");
      return {
        category: `[bold #FFFF00]${weekPart}[/]\n[bold white]${yearPart}[/]`,
        Count: values[1] || 0,
      };
    });

    setChartData(transformedData);
  }, [flexiStore.current_fsd]);

  return (
    <div className="bg-black">
      <div className="flex justify-between items-center p-10">
        <Typography variant="h3" fontWeight="bold" className="text-[#B97CFE]">
          Week Post Frequency
        </Typography>
        <Typography variant="h4" className="text-[#FFFA00]">
          A visual breakdown of the number
          <br />of posts published each week
        </Typography>
      </div>
      <div className="w-full h-screen">
        {chartData.length > 0 ? (
          <StackedColumnChart
            chartId="weekWiseChart"
            chartData={chartData}
            seriesKeys={seriesKeys}
            tiltLabels={false}
            chartColors={{
              bars: "yellow",
              text: "white",
              xAxis: "yellow",
            }}
            labelPosition="above"
          />
        ) : (
          <Typography>No data available</Typography>
        )}
      </div>
    </div>
  );
});
