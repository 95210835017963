import React from "react";
import {FTPBreadCrumbs} from "../../components-new/BreadCrumbs";

export const FSDListCrumbs = ({name}) => {

    const crumbs = [
        {name: "DataBank", href: "/databank", current: false},
        {name, href: "#", current: true},
    ];

    return <FTPBreadCrumbs crumbs={crumbs}/>
}