import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers/helpers";
import _ from "lodash";
import { Table } from "./table";
import { toJS } from "mobx";
import { TableHeader } from "./survey-summary";
import { useTranslation } from "react-i18next";

export const SurveysBylevel2HL = observer((props) => {
  const { surveyResponseStore, userStore } = useStore();
  const { allSurveyResponsesSummary, new_filters } = surveyResponseStore;
  const { t } = useTranslation("survey_management");

  const data = useMemo(
    () => toJS(allSurveyResponsesSummary)["by_piv_level2_hl"],
    [allSurveyResponsesSummary]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("level2"),
        accessor: "piv_level2",
        Footer: t("total"),
      },
      {
        Header: t("highlights"),
        accessor: "has-hl",
        Footer: surveyResponseStore.get_total_surveys(data, "has-hl"),
      },
      {
        Header: t("no_highlights"),
        accessor: "no-hl",
        Footer: surveyResponseStore.get_total_surveys(data, "no-hl"),
      },
      {
        Header: t("not_checked"),
        accessor: "not-checked",
        Footer: surveyResponseStore.get_total_surveys(data, "not-checked"),
      },
      {
        Header: t("total"),
        accessor: "total",
        Footer: surveyResponseStore.get_total_surveys(data, "total"),
      },
    ],
    [allSurveyResponsesSummary]
  );

  if (
    _.isEmpty(allSurveyResponsesSummary["by_piv_level2_hl"]) ||
    userStore.is_field_worker()
  )
    return null;

  const row_click_handler = (x) => {
    surveyResponseStore.update_new_filters(
      "piv_level2",
      x["piv_level2"],
      `Has Highlights: ${x["piv_level2"]}`
    );
  };

  return (
    <div className="flex flex-col w-100 md:w-0">
      <TableHeader title={t("interviews_by_level_2")} />
      <Table data={data} columns={columns} onRowClick={row_click_handler} />
    </div>
  );
});
