// Default configuration for web features, indicating which features are enabled or disabled.
// This is used as a fallback for existing projects that may not have this property.
export const default_web_features = {
  beneficiary: "disabled",
  grievance: "enabled",
  service_requests: "enabled",
  project_works: "enabled",
  c3: "enabled",
  manage_voters: "enabled",
  voter_reach_out: "enabled",
  election_day_dashboard: "disabled",
  voter_registration_tracker: "disabled",
  field_payments: "disabled",
  field_team_report: "enabled",
  manage_project: "enabled",
  user_management: "enabled",
  taskmanager: "enabled",
  digital_repository: "enabled",
  beneficiary_v2: "disabled",
  influential_leaders: "disabled",
  grievance_V2: "disabled",
  analytical_dashboard: "disabled",
};
