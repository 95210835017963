import React, { useRef, useState } from "react";
import styles from "./votercard.module.css";
import { RenderAddressValue } from "./VoterAddress";

export const VoterAdditionalInfo = ({ phone, houseno }) => {
  return (
    <>
      <div className="flex space-x-2 mb-[24px] px-[24px]">
        {houseno && (
          <div
            className={`flex items-center  px-[10px] py-[4px] rounded-[20px] space-x-1 ${styles.bgAdditionalInfo}`}
          >
            <img src="/voter/house.png" />
            <span className="font-normal text-[12px] leading-[14px] text-[#848484]">
              {houseno}
            </span>
          </div>
        )}

        {phone && phone !== "+91" && (
          <div
            className={`flex  items-center px-[10px] py-[4px] rounded-[20px] space-x-1 ${styles.bgAdditionalInfo}`}
          >
            <img src="/voter/phone.png" alt="My Example" />
            <span className="font-normal text-[12px] leading-[14px] text-[#848484]">
              {phone}
            </span>
          </div>
        )}
      </div>
    </>
  );
};
