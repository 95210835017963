import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { useStore } from "../../../../../helpers";
import { useRequestTypeMutation } from "../../../sr-hooks";

// this hook gets data of office persons
export const useAssignee = () => {
  const { projectStore } = useStore();
  const { projectid } = useParams();
  const get_users = async () => {
    const payload = {
      projectid: parseInt(projectid),
      tags: [],
      query: "",
      limit: 500,
      offset: 0,
    };
    return await projectStore.getAllUsers(payload);
  };

  const { data } = useQuery({
    queryKey: ["users", projectid],
    queryFn: get_users,
    select: (data) => {
      return data?.data["users"].map((user) => {
        return {
          id: user.id,
          name: user.name,
          phonenum: user.phonenum,
          profile_pic: user.profileimageuri,
        };
      });
    },
  });

  return { data };
};

// this hook adds the assignee to the database
export const useAddAssigneeRequest = () => {
  const rootStore = useStore();

  const addAssigneeRequest = async (payload: any) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-assignees-add",
      payload,
      "Couldn't add this user"
    );
  };

  return useRequestTypeMutation(
    addAssigneeRequest,
    "Assignee added successfully"
  );
};

// this hook updates the assignee in the database
export const useUpdateAssigneeRequest = () => {
  const rootStore = useStore();
  const updateAssigneeRequest = async (payload) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-assignees-update",
      payload,
      "Couldn't update user"
    );
  };
  return useRequestTypeMutation(
    updateAssigneeRequest,
    "Assignee updated successfully"
  );
};

// this hook deletes the assignee from the database
export const useDeleteAssigneeRequest = () => {
  const rootStore = useStore();
  const deleteAssigneeRequest = async (payload) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-assignees-delete",
      payload,
      "Failed to delete this user"
    );
  };
  return useRequestTypeMutation(
    deleteAssigneeRequest,
    "Assignee deleted successfully"
  );
};
