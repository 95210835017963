import React from "react"
import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite"
import * as Yup from 'yup';
import {useParams} from "react-router-dom";
import { useStore } from "../../../helpers";
import {TextError} from "../../../components";

export const AddEditSms = observer((props:any) => {
    const rootStore = useStore();
    const { voterReachOutStore } = rootStore
    const { textMessageFormValue} = voterReachOutStore
    const { formSubmit } = props;
    const {projectid} = useParams()

    const smsModel = {
        "projectid": projectid,
        "channel": "sms",
        "more": {}
    }
    const textMessageFormInitialValues = {
        name: textMessageFormValue.name,
        message: textMessageFormValue.message
    }
    const textMessageFormSubmit = async (values) => {
        const smsPayload = {
            ...smsModel,
            ds: {
                name: values.name,
                text: values.message
            }
        }
        formSubmit(smsPayload, textMessageFormValue.id)
    }
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        message: Yup.string().required('Message is required')
    })
    return (
      <>
        <Formik
          initialValues={textMessageFormInitialValues}
          onSubmit={textMessageFormSubmit}
          validationSchema={validationSchema}
        >
          <Form
            className="rounded-lg text-center"
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div
              className={
                "form-control sm:grid sm:grid-cols  sm:items-start d-flex flex-column text-left"
              }
            >
              <label
                htmlFor="name"
                className="block text-gray-700 rounded-lg text-left font-semibold"
              >
                Name
              </label>
              <div className="flex flex-row items-center">
                <Field
                  type="text"
                  id="name"
                  name="name"
                  className="px-4 py-2 rounded-lg border border-gray-300 w-full"
                />
              </div>
              <div className="flex flex-row">
                <ErrorMessage name="name" component={TextError} />
              </div>
            </div>
            <div className=" sm:grid sm:grid-cols-1 sm:items-start d-flex flex-column text-left">
              <label
                htmlFor="message"
                className="block text-gray-700  rounded-lg text-left font-semibold"
              >
                Message
              </label>
              <div className="flex flex-row items-center min-h-80 max-h-full">
                <Field
                  as="textarea"
                  id="message"
                  name="message"
                  className="px-4 py-2 rounded-lg border border-gray-300 w-full h-40"
                />
              </div>
              <div className="flex flex-row">
                <ErrorMessage name="message" component={TextError} />
              </div>
            </div>
            <div className="mt-5 sm:mt-4 text-center flex flex-row justify-end mx-2">
              <button
                type="submit"
                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm mx-1 px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Save
              </button>
              <button
                type="button"
                className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => voterReachOutStore.set_isSideOverlay(false)}
              >
                Cancel
              </button>
            </div>
          </Form>
        </Formik>
      </>
    );
})