import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { ACBankDAO } from "../acbank_dao";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { htmlValidation, useStore } from "../../../../helpers";
import { DeleteModal } from "../../../../components";
import { Avatar } from "@mui/material";
import dayjs from "dayjs";

export const TimeLineCardView = observer((props: any) => {
  const rootStore = useStore();
  const { flexiStore, userStore } = rootStore;
  const [deleteModal, setDeleteModal] = useState(false);
  const { t } = useTranslation("timeline");
  const { key, id: fsdid } = useParams();
  const dao = new ACBankDAO(rootStore);
  const { card, onDelete } = props;
  const { author, id, created, summary, modified } = card;
  const navigate = useNavigate();

  const delete_tl = async (e, id) => {
    onDelete(id);
    setDeleteModal(false);
  };

  const cancelCB = (e) => {
    setDeleteModal(false);
  };

  const hasPermissions = (owner_of_the_post) => {
    const isRoot = userStore.isRoot();
    const logged_in_user_id = userStore.getLoggedinUserId();
    return isRoot || logged_in_user_id === owner_of_the_post;
  };

  const cardClickHandler = (e) => {
    navigate(`/databank/repo/${key}/${fsdid}/timeline/view`, {
      state: {
        data: card,
        editable: false,
      },
    });
  };

  return (
    <div className="w-full mb-2">
      <ul role="list" className="space-y-4">
        <div
          key={card.id}
          onClick={cardClickHandler}
          className="bg-white px-4 py-2 shadow sm:p-4 sm:rounded-lg cursor-pointer"
        >
          <article
            aria-labelledby={"question-title-" + id}
            className={"flex flex-col gap-y-2"}
          >
            <div className="flex flex-row items-center justify-between z-9">
              <div className="text-gray-900 text-xl font-bold">
                {summary.title}
              </div>
              {hasPermissions(author.id) && (
                <div className="flex flex-row">
                  <button
                    className="cursor-pointer mx-2 text-blue-600"
                    title="Edit TimeLine"
                    onClick={async (e) => {
                      htmlValidation(e);
                      navigate(`/databank/repo/${key}/${fsdid}/timeline/new`, {
                        state: {
                          timeline: card,
                          edit: true,
                        },
                      });
                      flexiStore.update_timeline_property(
                        "active_timeline",
                        card,
                      );
                      flexiStore.update_timeline_property(
                        "timeline_edit_mode",
                        true,
                      );
                    }}
                  >
                    <i className="fa-solid fa-pen-circle text-xl" />
                  </button>
                  <button
                    className="cursor-pointer mx-2 text-red-500"
                    title="Delete TimeLine"
                    onClick={(e) => {
                      htmlValidation(e);
                      flexiStore.update_timeline_property(
                        "active_timeline",
                        card,
                      );
                      setDeleteModal(true);
                    }}
                  >
                    <i className="fa-solid fa-circle-trash text-xl" />
                  </button>
                </div>
              )}
            </div>
            <div className="flex flex-row">
              <div className="flex-shrink-0">
                <Avatar alt={author.name} src={author.profileimageuri}></Avatar>
              </div>
              <div className="min-w-0 flex-1 px-4">
                <p className="text-sm font-medium text-gray-900">
                  {author.name}
                </p>
                <p className="text-sm text-gray-500">
                  <time
                    dateTime={dayjs(created).format("MMMM Do YYYY, h:mm a")}
                  >
                    {dayjs(created).format("MMMM Do YYYY, h:mm a")}
                  </time>
                </p>
              </div>
            </div>
          </article>
          {/*{card["attach"]["files"] && <>{card["attach"]["files"].map((x) => <ViewAttachment key={x.path} attachment={x} s3_bucket={"urn-fsd"}/>)}*/}
          {/*</>}*/}
        </div>
      </ul>

      {deleteModal ? (
        <DeleteModal
          onDeleteClick={delete_tl}
          id={flexiStore.active_timeline["id"]}
          onCancelClick={cancelCB}
          isOpen={deleteModal}
          desc={`Do you want to delete ${flexiStore.active_timeline["summary"]["title"]} ?`}
        />
      ) : null}
    </div>
  );
});
