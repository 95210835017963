import React from "react";
import TextField from "@mui/material/TextField";
import { FilterHeader } from "../filterHeader";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers";
import { useTranslation } from "react-i18next";

export const RichSearch = observer((props: any) => {
  const rootStore = useStore();
  const { handleRichSearch } = props;
  const { voterListStore } = rootStore;
  const { voter_filters } = voterListStore;
  const [t] = useTranslation("voter_list");

  return (
    <div className={"flex flex-col gap-y-4"}>
      <FilterHeader title={t("rich_search")} content={t("filter_by")} />

      <TextField
        label={t("serial_no")}
        id="outlined-basic"
        defaultValue={voter_filters.serialno}
        className="w-full"
        name="serialno"
        placeholder="Serial No"
        onChange={(e) => handleRichSearch("serialno", e.target.value)}
        size={"small"}
      />

      <TextField
        label={t("voter_id")}
        id="outlined-basic"
        defaultValue={voter_filters.voterid}
        className="w-full"
        placeholder="Voter Id"
        name="voterid"
        onChange={(e) => handleRichSearch("voterid", e.target.value)}
        size={"small"}
      />
      <TextField
        label={t("voter_name")}
        id="outlined-basic"
        defaultValue={voter_filters.name}
        className="w-full"
        placeholder="Voter Name"
        name="name"
        onChange={(e) => handleRichSearch("name", e.target.value)}
        size={"small"}
      />
      <TextField
        label={t("house_no")}
        id="outlined-basic"
        defaultValue={voter_filters.house}
        className="w-full"
        placeholder="Serial No"
        name="house"
        onChange={(e) => handleRichSearch("house", e.target.value)}
        size={"small"}
      />
      <TextField
        label={t("voter_father/husband_name")}
        id="outlined-basic"
        defaultValue={voter_filters.relation}
        className="w-full"
        placeholder="Relation"
        name="relation"
        onChange={(e) => handleRichSearch("relation", e.target.value)}
        size={"small"}
      />
    </div>
  );
});
