import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../helpers";
import { safe_get } from "../report-visuals";
import { SideOverlay } from "./side-overlay";
import { useTranslation } from "react-i18next";
import { theme } from "../pages";
import { Button } from "@mui/material";
import Projects from "../pages/Projects";

export const ProjectHeader = observer((props) => {
  const rootStore = useStore();
  const { projectStore } = rootStore;
  const project_name = safe_get(projectStore.activeProject, "name", "");
  const { t } = useTranslation("components");

  return (
    <>
      {/*<div className="py-2 px-4 flexBoxRow flex-wrap sm:flex-nowrap flex-1">*/}
      {/*  <h1 className="uppercase underline" style={{ color: theme }}>*/}
      {/*    {project_name}*/}
      {/*  </h1>*/}
      {/*  <Button*/}
      {/*    variant={"contained"}*/}
      {/*    onClick={() => {*/}
      {/*      rootStore.update_switch_project(true);*/}
      {/*    }}*/}
      {/*    endIcon={<i className="fas fa-exchange-alt" />}*/}
      {/*    color={"success"}*/}
      {/*  >*/}
      {/*    {t("switch_project")}*/}
      {/*  </Button>*/}
      {/*</div>*/}

      <SideOverlay
        onClose={() => {
          rootStore.update_switch_project(false);
        }}
        show={rootStore.switch_project}
        children={<Projects />}
        title="Projects"
      />
    </>
  );
});
