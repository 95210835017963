import React from "react";
import { Fade } from "react-awesome-reveal";

import styles from "./votercard.module.css";

function HoverVoterCardIcon({ onEdit }) {
  return (
    <>
      <div className="absolute right-0 top-[45%] ">
        <Fade duration={1500}>
          <div
            className={`p-[6px] rounded-tl-[28px] rounded-bl-[28px] ${styles["bg-hover-icon"]} space-y-2`}
          >
            <div
              className={`${styles["hover-card-icon"]} flex items-center justify-center`}
              onClick={onEdit}
            >
              {/* <img src="/voter/pencil.png" alt="My Example" /> */}
              <i className="fa-regular fa-pen-to-square text-sm"/>
            </div>
          </div>
        </Fade>
      </div>
    </>
  );
}

export default HoverVoterCardIcon;
