import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";

type CardProps = {
  key: number;
  path: string;
  children: React.ReactNode;
};

export const Card = observer((props: CardProps) => {
  const navigate = useNavigate();
  const { children, path } = props;
  return (
    <>
      <div className="group p-2 cursor-pointer " onClick={(e) => navigate(path)}>
        <div className="rounded-2xl shadow-lg p-6 border-2 border-transparent hover:border-blue-300 hover:shadow-xl bg-[#f5f5f5] ease-out duration-300 transition-all">
          {children}
        </div>
      </div>
    </>
  );
});
