import styled from "@emotion/styled";
import React from "react";

export const FormLabel = styled.label`
  font-size: medium;
  line-height: 1.5rem;
  font-weight: normal;
  color: #1b1b1d;
  //margin-bottom: 0.5rem;
`;
