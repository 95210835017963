import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import _ from "lodash";
import { useParams } from "react-router-dom";
import { Button, useTheme } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const AddSubjectFromVoterList = observer((props: any) => {
  const { voterListStore } = useStore();
  const { projectid } = useParams();
  const theme = useTheme();

  const searchVotersHandler = async (val) => {
    voterListStore.setShowVoterlist(true);
    const clone = _.clone(voterListStore.voter_filters);
    clone["projectid"] = parseInt(projectid);
    await voterListStore.update_filters(clone);
  };
  return (
    <>
      <div className={"flex flex-row gap-4 mt-8"}>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          variant={"contained"}
          onClick={searchVotersHandler}
          sx={{ backgroundColor: theme.palette.common.black }}
        >
          Add from Voter List
        </Button>
      </div>
    </>
  );
});
