import React from "react";
import { observer } from "mobx-react-lite";
import { usePBIReportList } from "./usePBIReportsList";
import { toast } from "react-toastify";
import { BreadCrumbs, LoadingState } from "../../components";
import { Button, Typography } from "@mui/material";
import { PBIReportsTable } from "./reports-table";
import { AddPBIReport } from "./add-pbi-report";
import { useStore } from "../../helpers";
import { PBITableSkeleton } from "./pbi-table-skeleton";

const Crumbs = () => {
  return (
    <BreadCrumbs
      crumbs={[{ name: "Power BI Reports", href: "#", current: true }]}
    />
  );
};

export const PBIReportsHome = observer((props: any) => {
  const { reportStore, userStore } = useStore();
  const { data, isLoading, isError, error } = usePBIReportList();

  if (isError) {
    toast.error(error["message"]);
  }

  return (
    <>
      <Crumbs />
      {userStore.isRoot() ? (
        <div className={"w-full sm:max-w-7xl mx-auto"}>
          <div className={"flex flex-row items-center justify-between"}>
            <Typography variant={"h5"} color={"warning"} className={"py-4"}>
              Power BI DashBoards
            </Typography>
            <Button
              variant={"contained"}
              color={"warning"}
              startIcon={<i className={"fas fa-plus-circle"} />}
              onClick={() => reportStore.update_addPBIReportModal(true)}
            >
              New DashBoard
            </Button>
          </div>
          {!isLoading ? (
            <PBIReportsTable reportsData={data?.data} />
          ) : (
            <PBITableSkeleton />
          )}

          <AddPBIReport />
        </div>
      ) : (
        <div className={"flex flex-col flex-1 items-center justify-center"}>
          <Typography
            variant={"h1"}
            color={"error"}
            className={"flex flex-col flex-1 items-center justify-center p-4"}
          >
            Restricted Area....
          </Typography>
        </div>
      )}
    </>
  );
});
