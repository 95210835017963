import React, { useEffect, useState } from "react";
import { classNames, useStore } from "../../helpers";
import { observer } from "mobx-react-lite";
import { useWindowSize } from "react-use";
import { useTranslation } from "react-i18next";
import { VoterCard } from "../manage-voters/Voters";
import Masonry from "@mui/lab/Masonry";
import { toJS } from "mobx";
import { FormButtons } from "../../components";
import { Stack } from "@mui/material";

export const VoterListWithCheckBox = observer((props: any) => {
  const {
    voterListStore,
    serviceRequestsStore: { setFamilyMembers, selectedFamilyMembers },
  } = useStore();
  const { Voterlist } = voterListStore;
  const { height } = useWindowSize();
  const { onSelection, onSave, onCancel, selected } = props;
  const [t] = useTranslation("voter_list");

  const [selected1, setSelected1] = useState([]);
  useEffect(() => {
    setSelected1(selectedFamilyMembers ? [...selectedFamilyMembers] : []);
  }, []);
  // useEffect(() => {
  //   setFamilyMembers(selected1);
  // }, [selected1]);
  const handleSelection = (voter) => {
    setSelected1((prevSelected) => {
      const isAlreadySelected = prevSelected.some((val) => val.id === voter.id);
      return isAlreadySelected
        ? prevSelected.filter((val) => val.id !== voter.id)
        : [...prevSelected, toJS(voter)];
    });
  };

  const isChecked = (data) => {
    if (selected1 && selected1.length > 0) {
      const index = selected1.findIndex((x) => x.id === data.id);
      return index !== -1;
    }
  };

  return (
    <>
      {/*<section style={{ height: height * 0.7 }}>*/}
      {/*  <div className="relative overflow-x-hidden overflow-y-auto h-full ">*/}
      <div className={"flex flex-col "}>
        <Stack
          className={
            "max-h-[] md:max-h-[55%] lg:max-h-[55%] xl:max-h-[65%] overflow-auto"
          }
          sx={{
            maxHeight: { sm: height * 0.8, md: height * 0.65 },
            overflow: "auto",
          }}
        >
          <Masonry columns={{ sm: 1, md: 2, lg: 2, xl: 3 }} spacing={2}>
            {Voterlist &&
              Voterlist.length > 0 &&
              Voterlist.map((data) => {
                return (
                  <div
                    key={data["id"]}
                    className={classNames(
                      isChecked(data) ? "border border-black" : "",
                      " rounded-3xl  bg-transparent"
                    )}
                    onClick={() => handleSelection(data)}
                  >
                    <VoterCard voterdata={data} />
                  </div>
                );
              })}
          </Masonry>
        </Stack>
        <div className={"bg-white p-4 "}>
          <FormButtons onSave={() => onSave(selected1)} onCancel={onCancel} />
        </div>
      </div>
    </>
  );
  // return (
  //   <section style={{ height: height * 0.7 }}>
  //     <div className="overflow-x-hidden overflow-y-auto h-full ">
  //       {Voterlist &&
  //         Voterlist.length > 0 &&
  //         Voterlist.map((data) => {
  //           return (
  //             <div key={data["id"]} className="relative mb-2 voter-list">
  //               <input
  //                 type="checkbox"
  //                 onChange={() => onSelection(data)}
  //                 className={
  //                   "absolute top-8 right-2 p-2 w-8 h-8 bg-red-600 chkbox"
  //                 }
  //                 defaultChecked={isChecked(data)}
  //               />
  //               <VoterCard voter={data} />
  //             </div>
  //           );
  //         })}
  //     </div>
  //
  //     <FormButtons onSave={onSave} onCancel={onCancel} />
  //   </section>
  // );
});
