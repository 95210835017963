import { safe_get } from "../../report-visuals";
import { get_booth_node_by_boothnumber } from "../../custom-hooks/useBoothNumber";

export type SubjectType = {
  profile: {
    voterid: string;
    name_eng: string;
    relation_name_eng: string;
    gender: string;
    age: number;
    address: string;
    members: number;
    dob: string;
  };
  additional: {
    booth: any;
    family_members_list: Array<object>;
    village: any;
  };
  phone: string;
  whatsapp: string;
  aadharid: string;
  caste: string;
  is_outsider: boolean;
  ac_name: string;
  oacv_name: string;
  oacv_phone: string;
};

export type requestType = {
  status: any;
  assignee: any;
  priority: any;
  location: any;
  type: any;
  more: {
    requested_date: string;
    additional: string;
    attachments: Array<any>;
  };
};

export const defaultRequestValues: requestType = {
  status: {},
  priority: {},
  assignee: {},
  location: {},
  type: {},
  more: {
    requested_date: "",
    additional: "",
    attachments: [],
  },
};

export const subjectDefaultValues: SubjectType = {
  profile: {
    voterid: "",
    name_eng: "",
    relation_name_eng: "",
    gender: "male",
    age: 0,
    address: "",
    members: 0,
    dob: "",
  },
  additional: {
    booth: {},
    village: {},
    family_members_list: [],
  },
  phone: "",
  whatsapp: "",
  aadharid: "",
  caste: "",
  is_outsider: false,
  ac_name: "",
  oacv_name: "",
  oacv_phone: "",
};
