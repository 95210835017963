import React, { useState } from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import {
  ArrowLeftOnRectangleIcon,
  CircleStackIcon,
  DocumentTextIcon,
  HomeIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";

import { Link, useNavigate } from "react-router-dom";
import { LogoutModal } from "../../components/logout-modal";

const FTPNavBar = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigation = [
    { name: "DashBoard", href: `/`, icon: HomeIcon, current: true },
    {
      name: "Data Bank",
      href: "/databank",
      icon: CircleStackIcon,
      current: false,
    },
    {
      name: "Reports",
      href: "/reports/manage",
      icon: DocumentTextIcon,
      current: false,
    },
    // {name: 'Reports', href: '/reports', icon: DocumentTextIcon, current: false},
    // {name: 'Tools', href: '/tools', icon: AdjustmentsVerticalIcon, current: false},
  ];
  const settings = [
    {
      name: "Settings",
      href: "/settings",
      icon: WrenchScrewdriverIcon,
      current: false,
    },
    {
      name: "Logout",
      href: "/login_new",
      icon: ArrowLeftOnRectangleIcon,
      current: false,
    },
  ];

  const handleLogoutConfirm = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate("/login", { replace: true });
  };

  return (
    <>
      <Stack className="flex flex-col flex-1 items-center justify-around px-4 py-6 gap-y-8 overflow-hidden">
        <Avatar
          sx={{ width: "50px", height: "50px" }}
          src={"/images/srimages/dashboardlogo.svg"}
        />
        <ul className={"flex flex-col justify-evenly gap-y-8"}>
          {navigation.map((item) => {
            return (
              <li
                className={
                  "w-[75px] h-[75px] rounded-full p-4 border border-transparent transition duration-500 hover:cursor-pointer hover:bg-white hover:border-1 hover:border-gray-300"
                }
                key={item.href}
                title={item.name}
              >
                <Link to={item.href}>
                  <item.icon />
                </Link>
              </li>
            );
          })}
        </ul>
        <ul className={"flex flex-col justify-evenly gap-y-8"}>
          <li
            className={
              "w-[75px] h-[75px] rounded-full p-4 border border-transparent transition duration-500 hover:cursor-pointer hover:bg-white hover:border-1 hover:border-gray-300"
            }
            title={"Settings"}
          >
            <Link to={"/settings"}>
              <WrenchScrewdriverIcon />
            </Link>
          </li>
          <li
            className="w-[75px] h-[75px] rounded-full p-4 border border-red-100 bg-red-100 transition duration-500 hover:cursor-pointer hover:bg-red-300 hover:border-red-300"
            title="Logout"
            onClick={() => setIsModalOpen(true)}
          >
            <ArrowLeftOnRectangleIcon color="red" />
          </li>
        </ul>
      </Stack>
      <LogoutModal
        isOpen={isModalOpen}
        desc="Are you sure you want to logout?"
        onDeleteClick={handleLogoutConfirm}
        onCancelClick={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default FTPNavBar;
