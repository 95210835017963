import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import { useParams } from "react-router";
import { useFileUpload } from "../../../helpers/file-upload";
import { Typography } from "@mui/material";

import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
  FilePondPluginFileEncode
);

const FilePondUpload = ({
  onUpdate,
  accept = "*/*",
  label = "Upload File",
  files = [],
  path,
}) => {
  const [fileState, setFileState] = useState(files);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const uploadedFileNames = new Set(uploadedFiles.map((file) => file.name));
  const { isUploading, uploadFileToS3 } = useFileUpload();
  const [buffer, setBuffer] = useState([]);

  const handleFileUpdate = async (fileItems) => {
    const newFiles = fileItems
      .map((item) => item.file)
      .filter((file) => !uploadedFileNames.has(file.name));

    for (const file of newFiles) {
      const result = await uploadFileToS3(path, file, "urn-assets");
      if (result?.status === 204) {
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            path: result.key,
            type: file.type,
            name: file.name,
          },
        ]);
        uploadedFileNames.add(file.name);
      }
    }
    setFileState(fileItems);
  };

  useEffect(() => {
    onUpdate(uploadedFiles);
  }, [uploadedFiles]);

  const handleOnFileRemove = (file) => {
    if (file && file.file) {
      console.log("removed", file.file.name);
      setUploadedFiles((prevFiles) =>
        prevFiles.filter((uploadedFile) => uploadedFile.name !== file.file.name)
      );
      setBuffer((prevBuffer) =>
        prevBuffer.filter((item) => item.path !== file.file.name)
      );
      setFileState((prevState) =>
        prevState.filter((item) => item.file.name !== file.file.name)
      );
    }
  };

  return (
    <div className="">
      <Typography variant={"subtitle1"}>{label}</Typography>
      <div className=" border-2 border-dashed border-[#1B1B1D59] p-2 rounded-md">
        <FilePond
          files={fileState}
          allowMultiple={true}
          name="documents"
          acceptedFileTypes={[accept]}
          onupdatefiles={handleFileUpdate}
          stylePanelAspectRatio="0.4"
          // labelIdle={`📂Drag & Drop your files or <span class="filepond--label-action">Browse</span>`}
          labelIdle={`
            <div style="margin-top:75px; display: flex; flex-direction: column; align-items: center; padding: 20px;">
              <img src="/images/filepond_bg_image.svg" alt="Upload Icon"  />
              <span><span class="filepond--label-action"><b>Click here</b></span> or <b>Drag & Drop</b> your files</span>
            </div>
          `}
          maxFileSize="50MB"
          minFileSize="1KB"
          imagePreviewHeight={200}
          allowImageExifOrientation={true}
          onremovefile={(error, file) => {
            if (error) {
              console.error("failed:", error);
            } else {
              console.log("File removed:", file.file.name);
              handleOnFileRemove(file);
            }
          }}
        />
      </div>
    </div>
  );
};

export default FilePondUpload;
