import { useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";

export const useInvalidateMetaQuery = () => {
  const queryClient = useQueryClient();
  const { projectid } = useParams();
  return async () => {
    setTimeout(async () => {
      await queryClient.invalidateQueries(["srmeta", projectid], {
        refetchType: "all",
      });
    }, 1000);
  };
};
