import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { makeid_alpa_numeric, TextError, useStore } from "../../../helpers";
import { safe_get } from "../../../report-visuals";
import { t } from "i18next";
import dayjs from "dayjs";

export const AddTimelineLog = observer((props: any) => {
  const [desc, setDesc] = useState("");
  const [status, setStatus] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [title, setTitle] = useState("");
  const { projectWorksStore, flexiStore, userStore } = useStore();
  const { current_fsd } = flexiStore;

  const descChange = (event) => {
    setDesc(event.target.value);
  };

  const titleChange = (event) => {
    setTitle(event.target.value);
  };

  const statusChange = (event) => {
    setStatus(event.target.value);
  };

  const saveTimeLineLog = async () => {
    setIsSubmit(true);
    const updatedTimelineObj = {
      id: makeid_alpa_numeric(8),
      date: dayjs().format("DD/MM/YYYY"),
      title: title,
      updateby: userStore.getDisplayName(),
      richmedia: [],
      description: desc,
      statusupdate: status,
    };
    const old_timeline_list_obj = safe_get(current_fsd.r1, "timeline", []);
    const timelineList = [...old_timeline_list_obj, updatedTimelineObj];
    flexiStore.update_current_fsd("r1", {
      ...current_fsd.r1,
      timeline: timelineList,
    });
    await flexiStore.api_update_current_fsd();
    props.refreshTimeline(timelineList);
    setIsSubmit(false);
    projectWorksStore.update_project_work_property("add_timeline_modal", false);
  };

  return (
    <>
      {projectWorksStore.add_timeline_modal ? (
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="p-4">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 pt-4"
            >
              {t("title")}
            </label>
            <input
              type="text"
              className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border-solid border p-2"
              value={title}
              onChange={titleChange}
            />
            <div>
              {isSubmit && title === "" && TextError(t("title_required"))}
            </div>

            <label
              htmlFor="additional"
              className="block text-sm font-medium text-gray-700 pt-4"
            >
              {t("description")}
            </label>
            <textarea
              className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"
              rows={3}
              value={desc}
              onChange={descChange}
            ></textarea>

            <label
              htmlFor="status"
              className="block text-sm font-medium text-gray-700 pt-4"
            >
              {t("status")}
            </label>
            <input
              type="text"
              className="max-w-lg0 block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border-solid border p-2"
              value={status}
              onChange={statusChange}
            />
            <div>
              {isSubmit && status === "" && TextError(t("status_required"))}
            </div>

            <div className="bg-gray-50 px-4 py-3 flex flex-row justify-center">
              <button
                type="button"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={saveTimeLineLog}
              >
                {t("save")}
              </button>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
});
