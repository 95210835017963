import React, { useState, useEffect } from "react";
import { useStore } from "../../helpers";
import AgeFilter from "./filters/ageFilter";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { GendersFilter, RichSearch, VotersDynamicFilters } from "./filters";
import { FormButtons } from "../../components";
import { DateSearch } from "./filters/date-searchFilter";
import { BoothFilters } from "./filters";
import dayjs from "dayjs";

const FILTER_TYPES = [
  "Rich Search",
  "Gender",
  "Age",
  "Booth wise",
  "Date of Birth",
  "More Filters",
];

export const FilterOverLay = observer(() => {
  const rootStore = useStore();
  const { projectid } = useParams();
  // const { height } = useWindowSize();
  const { beneficiaryStore, voterListStore, userStore } = rootStore;
  const [t] = useTranslation("voter_list");
  const { voter_filters } = voterListStore;
  const [activeFilter, setActiveFilter] = useState(FILTER_TYPES[0]);
  const { serialno, name, relation, voterid, page, house, df } = voter_filters;

  const [filters, setFilters] = useState({
    projectid: parseInt(projectid),
    serialno: serialno,
    name: name,
    relation: relation,
    voterid: voterid,
    page: page,
    house: house,
    genders: voter_filters["genders"],
    age: voter_filters["age"],
    booths: voter_filters.booths,
    dob: voter_filters["dob"],
    df: voter_filters.df,
    offset: voter_filters.offset,
    count: voter_filters.count,
  });

  // Callback function to receive data from child
  const handleDateSearch = (date) => {
    setFilters({ ...filters, dob: dayjs(date).format("DD-MM-YYYY") });
  };

  useEffect(() => {
    const activeUserId = JSON.parse(localStorage.getItem("__userprofile"));
    const UserId = activeUserId?.id;
    userStore.get_user_permissions_and_parts({
      projectid: Number(projectid),
      userid: UserId,
    });
  }, []);

  const handleBoothFilter = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFilters({ ...filters, genders: [...filters.genders, value] });
    } else {
      setFilters({
        ...filters,
        genders: filters.genders.filter((e) => e !== value),
      });
    }
  };

  const handleCancel = () => {
    voterListStore.setShowVoterlist(true);
    voterListStore.setFilterStatus(false);
    voterListStore.setFilterVal(null);
    voterListStore.setSelectedBooths([]);
  };

  const setBoothVal = (data: any, name) => {
    beneficiaryStore.setSelectedBooths(data);
    setFilters({ ...filters, booths: data });
  };

  const handleAgeFilter = (event) => {
    const { value, name } = event.target;
    if (name === "reset") {
      setFilters({ ...filters, age: [18, 120] });
    } else {
      const age = value.split(",").map((c) => {
        return Number(c);
      });
      setFilters({ ...filters, age: age });
    }
  };

  //TODO: update local filter variables to central store
  const apply_filters = async () => {
    voterListStore.update_filters(filters);
    voterListStore.setShowVoterlist(true);
    voterListStore.setFilterStatus(false);
  };

  return (
    <>
      <div className="px-3 py-9">
        <div className="flex space-x-9">
          <div className="w-2/5 rounded-3xl p-3.5 border border-[#1B1B1D26]">
            <div className="cursor-pointer">
              {FILTER_TYPES.map((tab) => (
                <ul
                  key={tab}
                  onClick={() => {
                    setActiveFilter(tab);
                  }}
                >
                  <li
                    className={`rounded-xl p-6 hover:bg-[#ECEDEF] ${
                      activeFilter === tab ? "bg-[#FFC53D]" : "bg-transparent"
                    }`}
                  >
                    <span className="work-sans font-normal text-xl text-[#1B1B1D]">
                      {tab}
                    </span>
                  </li>

                  <span
                    className={
                      activeFilter == tab
                        ? "text-purple-600 text-lg font-medium"
                        : "text-lg font-medium"
                    }
                  ></span>
                </ul>
              ))}
            </div>
          </div>
          <div className="w-3/5  rounded-3xl p-4 border border-[#1B1B1D26]">
            <div className="pt-4">
              {activeFilter == "Rich Search" && (
                <RichSearch
                  handleRichSearch={(key, value) =>
                    setFilters({ ...filters, [key]: value })
                  }
                />
              )}

              {activeFilter == "Gender" && (
                <GendersFilter
                  genders={filters.genders}
                  handleBoothFilter={handleBoothFilter}
                />
              )}

              {activeFilter == "Age" && (
                <AgeFilter handleAgeFilter={handleAgeFilter} />
              )}

              {activeFilter == "Booth wise" && (
                <BoothFilters onBoothChange={setBoothVal} />
              )}

              {activeFilter == "Date of Birth" && (
                <DateSearch handleDateSearch={handleDateSearch} />
              )}

              {activeFilter == "More Filters" && (
                <VotersDynamicFilters
                  habdleDF={(options, key) => {
                    setFilters({
                      ...filters,
                      df: { ...filters.df, [key]: options },
                    });
                  }}
                />
              )}
            </div>
            <FormButtons onSave={apply_filters} onCancel={handleCancel} />
          </div>
        </div>
      </div>
    </>
  );
});
