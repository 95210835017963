import React from "react";
import Masonry from "@mui/lab/Masonry";
import { Slide, Fade } from "react-awesome-reveal";

interface MasonryGridProps<T> {
  data: T[];
  isLoading: boolean;
  skeletonCount: number;
  renderItem: (item: T) => React.ReactNode;
  renderSkeleton: (key: number) => React.ReactNode;
}

export const MasonryGrid = <T,>({
  data,
  isLoading,
  skeletonCount,
  renderItem,
  renderSkeleton,
}: MasonryGridProps<T>) => {
  return (
    <Masonry columns={{ sm: 1, md: 2, lg: 3, xl: 4 }} spacing={2} sequential>
      {isLoading ? (
        Array.from({ length: skeletonCount }, (_, i) => renderSkeleton(i))
      ) : (
        <Fade direction="up">{data.map((item) => renderItem(item))}</Fade>
      )}
    </Masonry>
  );
};
