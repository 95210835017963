import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IconButton, Stack } from "@mui/material";
import { useForm, FormProvider } from "react-hook-form";
import {
  FileInputField,
  RNInput,
  RNSelect,
} from "../../../components/form-components";
import {
  FormButtons,
  SearchVotersButton,
  TailwindModal,
  UploadButton,
} from "../../../components";
import { useStore, htmlValidation, useFileUpload } from "../../../helpers";
import {
  familyMemberProfileDefaultValues,
  familyMemberProfileType,
  genders,
  getValues,
  relationShips,
} from "./family-members-types";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddFamilyMembersValidations } from "./beneficiary-fm-validation-schema";
import { safe_get } from "../../../report-visuals";
import { AddFamilymembersFromVoterList } from "./add-familymembers-from-voter-list";
import FilePondUpload from "../beneficiary-requests/filepond";

export const AddFamilyMembersv2 = observer((props: any) => {
  const { t } = useTranslation("beneficiary");
  const params = useParams();
  const projectid = params.projectid;
  const { beneficiaryStore, beneficiaryStoreV2 } = useStore();
  const { familyMembers } = beneficiaryStoreV2;
  const { activeFamilyMember } = beneficiaryStore;
  const [uploadedFile, setUploadedFile] = useState([]);
  const [files, setFiles] = useState([]);

  const methods = useForm({
    defaultValues: familyMemberProfileDefaultValues,
    //@ts-ignore
    resolver: yupResolver(AddFamilyMembersValidations),
    mode: "onSubmit",
  });

  const saveFamilyMember = (data) => {
    console.log(data);
    const final = { ...data, profile_photo: uploadedFile };
    const updatedFamily = [...familyMembers];
    const idx = updatedFamily.findIndex((ele) => ele.id === final.id);
    if (idx !== -1) {
      updatedFamily.splice(idx, 1, final);
    } else {
      updatedFamily.push({ ...final });
    }

    beneficiaryStoreV2.update_family_members(updatedFamily);
    beneficiaryStore.update_add_familymember_modal(false);
    methods.reset();
    setUploadedFile([]);
  };

  useEffect(() => {
    methods.reset(activeFamilyMember.profile);
    methods.setValue("phone", safe_get(activeFamilyMember, "phone", ""));
  }, [activeFamilyMember]);

  const closeFamilyMemberModal = () => {
    beneficiaryStore.update_add_familymember_modal(false);
  };

  const searchVotersHandler = () => {
    closeFamilyMemberModal();
    beneficiaryStore.update_addFamilyFromVoterListModal(true);
  };

  const addNewFamilyMemberHandler = () => {
    beneficiaryStore.update_add_familymember_modal(true);
    beneficiaryStore.resetActiveFamily();
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <div className="text-indigo-500 font-bold text-xl fp-text">
          {t("add_family_members")}
        </div>
        <IconButton
          color="primary"
          size="large"
          onClick={addNewFamilyMemberHandler}
          disableRipple
          sx={{ padding: "5px" }}
        >
          <i className="fas fa-plus-circle" />
        </IconButton>
      </div>

      <AddFamilymembersFromVoterList />

      <TailwindModal isOpen={beneficiaryStore.add_familymember_modal}>
        <FormProvider {...methods}>
          <form
            onSubmit={(e) => {
              htmlValidation(e);
              methods.handleSubmit(saveFamilyMember)(e);
            }}
            className="px-4 py-2 rounded-sm sm:my-8 sm:align-middle sm:max-w-xl sm:w-full"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full overflow-y-scroll">
              <div className="py-4 px-6 flex flex-col gap-y-3">
                <div className="flex justify-between items-center">
                  <div className="text-xl font-semibold fp-text">
                    {t("add_family_members")}
                  </div>
                  <SearchVotersButton
                    searchVotersHandler={searchVotersHandler}
                  />
                </div>

                <RNInput label="Name" name="name_eng" />

                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                  <RNInput label="Phone Number" name="phone" />
                  <RNSelect
                    label={"Gender"}
                    name={"gender"}
                    options={genders}
                  />
                </div>

                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                  <RNInput label={"Voter ID"} name={"voterid"} />
                  <RNInput label={"Aadhar ID"} name={"aadharid"} />
                </div>

                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                  <RNInput
                    label={"Date of Birth"}
                    name={"dob"}
                    type={"date"}
                    InputLabelProps={{ shrink: true }}
                  />
                  <RNInput label={"Age"} name={"age"} type={"number"} />
                </div>

                <div className={"grid grid-cols-1 sm:grid-cols-2 gap-2"}>
                  <RNInput label={"Relative Name"} name={"relation_name_eng"} />
                  <RNSelect
                    label={"Relationship"}
                    name={"relationship_eng"}
                    options={relationShips}
                  />
                </div>

                <RNInput
                  label={"Additional Information"}
                  name={"additional"}
                  multiline
                  rows={3}
                  fullWidth
                />

                <FilePondUpload
                  path={`project-${projectid}/beneficiary/family`}
                  files={files}
                  onUpdate={(files) => {
                    setUploadedFile(files);
                    methods.setValue("profile_photo", files);
                  }}
                />
              </div>
              <FormButtons
                onSave={() => {}}
                onCancel={(e) => {
                  closeFamilyMemberModal();
                  methods.reset();
                }}
              />
            </div>
          </form>
        </FormProvider>
      </TailwindModal>
    </>
  );
});
