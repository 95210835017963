import React, { Fragment } from "react"
import {Popover, Transition} from "@headlessui/react";
import {observer} from "mobx-react-lite"
import {ChevronDownIcon} from "@heroicons/react/24/solid";
import {classNames} from "../../helpers";

const datasetTypes = [
    {name: 'Text Message', description: 'type text messages', key: 'Text Messages'},
    {name: 'Voice Message', description: 'record a voice message', key: 'Voice Messages'},
    {name: 'Whatsapp Message', description: 'send a whatsapp message', key: 'whatsapp Messages'},
    {name: 'Social Media Post', description: 'Create a social media post', key: 'Social Media Post'}
];

const VoterReachOutHeader = observer((props: any) => {

    const {popOver, setPopOver, addNewReachOut, addNewButtonRef} = props

        return (
            <div className='flex flex-row items-center mb-4 justify-between'>
                <h2 className='font-bold text-2xl text-indigo-500'>Voter Reach Out Messages</h2>
                <Popover className="relative">
                    {({open}) => (
                        <>
                            <Popover.Button
                                ref={addNewButtonRef}
                                onClick={() => setPopOver(!popOver)}
                                className="inline-flex mx-2 items-center py-1 px-2 border border-transparent rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                <span>Add New</span>
                                <ChevronDownIcon
                                    className={classNames(open ? 'text-gray-600' : 'text-gray-400', 'ml-2 h-5 w-5 group-hover:text-gray-500')}
                                    aria-hidden="true"
                                />
                            </Popover.Button>

                            <Transition
                                show={popOver}
                                as={Fragment}
                                enter="transition ease-out duration-200"
                                enterFrom="opacity-0 translate-y-1"
                                enterTo="opacity-100 translate-y-0"
                                leave="transition ease-in duration-150"
                                leaveFrom="opacity-100 translate-y-0"
                                leaveTo="opacity-0 translate-y-1"
                            >
                                <Popover.Panel
                                    static
                                    className="absolute z-10 transform -translate-x-1/2 mt-3 px-2 w-64 sm:px-0"
                                >
                                    <div
                                        className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                        <div className="relative grid gap-6 bg-white px-3 py-2 sm:gap-8 sm:p-3">
                                            {datasetTypes.map((item) => (
                                                <div
                                                    key={item.name}
                                                     onMouseDown={(evt) => {
                                                     addNewReachOut(item.key)
                                                       setPopOver(false)
                                                    }}
                                                    className="-m-3 p-3 block rounded-sm cursor-pointer hover:bg-blue-50 transition ease-in-out duration-150"
                                                >
                                                    <p className="text-base font-bold text-gray-900">{item.name}</p>
                                                    <p className="text-sm text-gray-500">{item.description}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Popover.Panel>
                            </Transition>
                        </>
                    )}
                </Popover>
            </div>
        )
    }
)

export default VoterReachOutHeader;