import { action, makeAutoObservable } from "mobx";
import _ from "lodash";

import {
  initialMetaTypes,
  initialModalTypes,
  metaTypes,
  ModalKeyTypes,
  modalTypes,
  backendConnectorKeyTypes,
} from "./sr-types";
import { hydrateStore, makePersistable } from "mobx-persist-store/lib/esm5";

export class ServiceRequestsStore {
  rootStore: any;

  meta: metaTypes;
  modals: modalTypes;
  activeTab: string = "types";
  activeStatus: any = {};
  activeAssignee: any = {};
  activeLocation: any = {};
  activePriority: any = {};
  activeRequestType: any = {};
  loading: boolean = false;
  showServiceRequest: boolean = false;
  showServiceFilters: boolean = false;

  //TODO: This is main service request which will consist both request and subject;
  activeServiceRequest: any = {};
  activelogs: any = [];
  activeFamilyMember: any = {};
  activeVillageAndBooth: any = {};
  activeSubject: any = {};
  selectedFamilyMembers: any = [];
  searchQuery: string = "";
  activeServiceFilter: any = {};
  pageNumber: number = 1;
  pageSize: number = 10;
  resultsSize: number = 0;
  resultsCount: number = 0;
  constructor(rootStore: any) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.meta = initialMetaTypes;
    this.modals = initialModalTypes;
    makePersistable(this, {
      name: "folderBreadCrumbs",
      properties: ["activeTab"],
      storage: window.sessionStorage,
    });
    hydrateStore(this);
    console.log(this.rootStore);
  }
  @action
  setResultsSize = (size: number) => {
    this.resultsSize = size;
  };
  @action
  setResultsCount = (count: number) => {
    this.resultsCount = count;
  };
  @action
  setPageNumber = (pageNumber: number) => {
    this.pageNumber = pageNumber;
  };
  @action
  setPageSize = (pageSize: number) => {
    this.pageSize = pageSize;
  };
  getServicesMetaData = async (payload) => {
    return await this.rootStore.api.post(
      "unified_service_request/meta",
      payload
    );
  };

  @action
  setActiveFamilyMember = (val) => {
    // console.log(val);
    this.activeFamilyMember = val;
  };
  @action
  setFamilyMembers = (val) => {
    this.selectedFamilyMembers = val;
  };
  @action
  setSearchQuery = (val) => {
    this.searchQuery = val;
  };

  @action
  set_meta(data) {
    this.meta = data;
  }

  @action
  setshowServiceRequest(val) {
    this.showServiceRequest = val;
  }

  @action
  set_active_tab(value) {
    this.activeTab = value;
  }

  @action
  setActiveLocation = (val) => {
    this.activeLocation = val;
  };

  @action
  setActiveStatus = (val) => {
    this.activeStatus = val;
  };

  @action
  setActiveAssignee = (val) => {
    this.activeAssignee = val;
  };

  @action
  setActivePriority = (val) => {
    this.activePriority = val;
  };
  @action
  setActiveRequestType = (val) => {
    this.activeRequestType = val;
  };

  @action
  setActiveSubject(val) {
    this.activeSubject = val;
  }

  @action
  setActiveServiceRequest(val) {
    this.activeServiceRequest = val;
  }
  @action
  setActiveLogs(val) {
    this.activelogs = val.map(({ changed_by, change_type, changed_at }) => ({
      req_title: "",
      status: change_type,
      ts: changed_at,
      comments: "",
      who: {
        address: "",
        name: changed_by.name,
        profileImage: changed_by.profileimageuri,
        phone: changed_by.phonenum,
      },
    }));
  }

  @action
  setLoading(val) {
    this.loading = val;
  }
  @action
  setActiveVillageAndBooth = (val) => {
    this.activeVillageAndBooth = val;
  };

  @action
  setServiceFilter = (val) => {
    this.showServiceFilters = val;
  };

  @action
  updateServiceFilter = (val) => {
    this.activeServiceFilter = val;
    console.log(val);
  };

  @action
  update_modal_status = (key: ModalKeyTypes, value) => {
    switch (key) {
      case "addRequestType":
        this.modals.addRequestType = value;
        break;
      case "addStatus":
        this.modals.addStatus = value;
        break;
      case "addPriority":
        this.modals.addPriority = value;
        break;
      case "deleteStatus":
        this.modals.deleteStatus = value;
        break;
      case "addAssignee":
        this.modals.addAssignee = value;
        break;
      case "addLocation":
        this.modals.addLocation = value;
        break;
      case "deletePriority":
        this.modals.deletePriority = value;
        break;
      case "deleteRequestType":
        this.modals.deleteRequestType = value;
        break;
      case "deleteAssignee":
        this.modals.deleteAssignee = value;
        break;
      case "deleteServiceRequest":
        this.modals.deleteServiceRequest = value;
        break;
      case "deleteLocation":
        this.modals.deleteLocation = value;
        break;
      case "selectVillageAndBooth":
        this.modals.selectVillageAndBooth = value;
        break;
      case "addFamilyMember":
        this.modals.addFamilyMember = value;
        break;
      default:
        break;
    }
  };
}
