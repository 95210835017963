import React from "react";
import { Radio, RadioGroup } from "@headlessui/react";
import { useFormContext, Controller } from "react-hook-form";
import { classNames } from "../helpers";
import { FormLabel } from "./utils";

export type RadioGroupPropTypes = {
  name: string;
  label: string;
  options: string[] | { label: string; value: string | boolean }[];
};

const createOption = (label: string) => ({
  label,
  value: label.trim().toLowerCase(),
});

export const FTPRadioGroup = ({ name, label, options }) => {
  const { control } = useFormContext();
  const optionsList =
    typeof options[0] === "string"
      ? options.map((option) => createOption(option))
      : options;

  return (
    <fieldset>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        defaultValue={optionsList[0].value}
        render={({ field: { value, onChange } }) => (
          <RadioGroup
            value={value}
            onChange={(newValue) => onChange(newValue.value)}
            className="flex items-center gap-x-2 border p-1 border-[#B6B6B6] rounded-2xl"
          >
            {optionsList.map((option) => (
              <Radio
                key={option.value}
                value={option}
                aria-label={option.label}
                className={classNames(
                  option.value === value
                    ? "rounded-xl bg-primary-light text-white"
                    : "bg-transparent",
                  "relative flex flex-1 cursor-pointer items-center justify-center rounded-xl px-3 py-2.5 focus:outline-none border-1 border-grey-400 transition duration-500 ease-in-out"
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    option.value === value ? `text-white` : "",
                    "rounded-xl"
                  )}
                >
                  {option.label}
                </span>
              </Radio>
            ))}
          </RadioGroup>
        )}
      />
    </fieldset>
  );
};
