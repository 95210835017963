import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

interface DataTableProps {
  title: string;
  columns: { id: string; label: string }[];
  data: Record<string, any>[];
}

const DataTable: React.FC<DataTableProps> = ({ columns, data }) => {
  return (
    <TableContainer
      component={Paper}
      className="w-full max-w-6xl mx-auto overflow-hidden"
      sx={{
        border: "2px solid #FFFA00",
        backgroundColor: "#FFFA00",
        borderRadius: "24px",
        overflow: "hidden",
      }}
    >
      <Table
        sx={{
          border: "2px solid #FFFA00",
          borderRadius: "24px",
          overflow: "hidden",
        }}
      >
        <TableHead>
          <TableRow className="bg-black">
            {columns.map((column, index) => (
              <TableCell
                key={column.id}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  color: "#FFFA00",
                  padding: "12px",
                  textAlign: "center",
                  borderBottom: "2px solid rgba(255, 250, 0, 0.5)",
                  borderRight:
                    index < columns.length - 1 ? "2px solid rgba(255, 250, 0, 0.5)" : "none",
                }}
              >
                {column.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.length > 0 ? (
            data.map((row, rowIndex) => (
              <TableRow key={rowIndex} className="bg-black">
                {columns.map((column, colIndex) => (
                  <TableCell
                    key={column.id}
                    sx={{
                      color: "white",
                      textAlign: "center",
                      padding: "20px",
                      borderBottom: "2px solid rgba(255, 250, 0, 0.5)",
                      borderRight:
                        colIndex < columns.length - 1 ? "2px solid rgba(255, 250, 0, 0.5)" : "none",
                    }}
                  >
                    {row[column.id]}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                sx={{
                  textAlign: "center",
                  color: "white",
                  padding: "20px",
                  borderBottom: "2px solid rgba(255, 250, 0, 0.5)",
                }}
              >
                No data available
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
