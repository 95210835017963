import React, { useEffect, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";

import { useFileUpload } from "../../../../helpers";
import { Typography } from "@mui/material";

import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import { VortexSpinner } from "../../../../features/digital-repository";

registerPlugin(
  FilePondPluginFileEncode,
  FilePondPluginFileValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
  FilePondPluginFileEncode
);

const FilePondUpload = ({
  onUpdate,
  accept = "*/*",
  label = "Upload File",
  files = [],
  path,
  multiple = true,
}) => {
  const [fileState, setFileState] = useState(files);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const uploadedFileNames = new Set(uploadedFiles.map((file) => file.name));
  const { isUploading, uploadFileToS3 } = useFileUpload();
  const [buffer, setBuffer] = useState([]);

  const handleFileUpdate = async (fileItems) => {
    const newFiles = fileItems
      .map((item) => item.file)
      .filter((file) => !uploadedFileNames.has(file.name));

    for (const file of newFiles) {
      const result = await uploadFileToS3(path, file, "urn-assets");
      if (result?.status === 204) {
        console.log(result);
        setUploadedFiles((prevFiles) => [
          ...prevFiles,
          {
            path: result.key,
            type: file.type,
            name: file.name,
            location: result.location,
          },
        ]);
        uploadedFileNames.add(file.name);
      }
    }
    setFileState(fileItems);
  };

  useEffect(() => {
    onUpdate(uploadedFiles);
  }, [uploadedFiles]);

  const handleOnFileRemove = (file) => {
    if (file && file.file) {
      console.log("removed", file.file.name);
      setUploadedFiles((prevFiles) =>
        prevFiles.filter((uploadedFile) => uploadedFile.name !== file.file.name)
      );
      setBuffer((prevBuffer) =>
        prevBuffer.filter((item) => item.path !== file.file.name)
      );
      setFileState((prevState) =>
        prevState.filter((item) => item.file.name !== file.file.name)
      );
    }
  };

  return (
    <div>
      <Typography variant={"subtitle1"} color={"primary"}>
        {label}
      </Typography>
      <div className="border-4 border-dotted border-gray-500 p-2 rounded-md">
        <FilePond
          files={fileState}
          allowMultiple={multiple}
          name="documents"
          acceptedFileTypes={[accept]}
          onupdatefiles={handleFileUpdate}
          labelIdle={`📂Drag & Drop your files or <span class="filepond--label-action">Browse</span>`}
          maxFileSize="50MB"
          minFileSize="1KB"
          imagePreviewHeight={200}
          allowImageExifOrientation={true}
          onremovefile={(error, file) => {
            if (error) {
              console.error("failed:", error);
            } else {
              console.log("File removed:", file.file.name);
              handleOnFileRemove(file);
            }
          }}
        />
      </div>
      {isUploading ? (
        <div className="flex items-center">
          <VortexSpinner visible={isUploading} />
          <p className="text-orange-500 animate-pulse">
            Uploading your file, Please don't close the form or Save anything.
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default FilePondUpload;
