import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers/helpers";
import _ from "lodash";
import { Table } from "./table";
import { toJS } from "mobx";
import { TableHeader } from "./survey-summary";
import { useTranslation } from "react-i18next";
import { DownloadAsExcelSheet } from "../../../components";

export const SurveysByQaCheck = observer((props) => {
  const { surveyResponseStore, userStore } = useStore();
  const { allSurveyResponsesSummary, new_filters } = surveyResponseStore;
  const { user } = userStore;
  const { t } = useTranslation("survey_management");

  const data = useMemo(
    () => toJS(allSurveyResponsesSummary)["by_qalog_user_status"],
    [allSurveyResponsesSummary]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("name"),
        accessor: "surveyor_name",
        Footer: t("total"),
      },
      {
        Header: t("valid"),
        accessor: "valid",
        Footer: surveyResponseStore.get_total_surveys(data, "valid"),
      },
      {
        Header: t("invalid"),
        accessor: "invalid",
        Footer: surveyResponseStore.get_total_surveys(data, "invalid"),
      },
      {
        Header: t("total_surveys"),
        accessor: "total",
        Footer: surveyResponseStore.get_total_surveys(data, "total"),
      },
    ],
    [allSurveyResponsesSummary]
  );

  if (_.isEmpty(allSurveyResponsesSummary["by_qalog_user_status"])) return null;

  const row_click_handler = (x) => {
    surveyResponseStore.update_new_filters(
      "qalog.user.id",
      x["surveyor_id"],
      `QA Check By: ${x["surveyor_name"]}`
    );
  };

  const excel_col_def = [
    {
      label: t("name"),
      value: "surveyor_name",
    },
    {
      label: "Valid",
      value: "valid",
    },
    {
      label: "Invalid",
      value: "invalid",
    },
    {
      label: "Total Surveys",
      value: "total",
    },
  ];

  return (
    <div className="flex flex-col w-100 md:w-0">
      <div className="flex flex-row items-center justify-between">
        <TableHeader title={t("check")} />
        {user.role === "root" && (
          <DownloadAsExcelSheet
            data={data}
            cols={excel_col_def}
            name="QC-Summary"
          />
        )}
      </div>

      <Table data={data} columns={columns} onRowClick={row_click_handler} />
    </div>
  );
});
