import {useParams} from "react-router-dom";
import React from "react";
import {FTPBreadCrumbs} from "../../components-new/BreadCrumbs";

export const ILBCrumbs = (props) => {
    const { projectid } = useParams();
    const crumbs = [
        { name: "DashBoard", href: `/project/${projectid}`, current: true },
        { name: 'Influental Leaders', href: '#', current: true },
    ];
    return (
        <FTPBreadCrumbs crumbs={crumbs} />
    )
}