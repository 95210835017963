import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { classNames } from "../../../helpers";
import HoverVoterCardIcon from "./hoverVoterCardIcon";
import { useStore } from "../../../helpers";

import {
  ExpandButton,
  VoterAdditionalInfo,
  VoterDetails,
  VoterId,
  VoterInfo,
} from "../Voters";
import VoterAddress from "./VoterAddress";
import { toJS } from "mobx";
import _ from "lodash";
import { useHoverDirty } from "react-use";

export const VoterCard = observer((props: any) => {
  const { voterdata, onSelect } = props;
  const { profile } = voterdata;
  const rootStore = useStore();
  const ref = useRef(null);
  const isHovered = useHoverDirty(ref);
  const { voterListStore } = rootStore;
  const { edit_voter_modal } = voterListStore;
  const [isExpanded, setExapnded] = useState(false);

  const toggleExapnd = () => {
    setExapnded((prev) => !prev);
  };

  const handleEditIconClick = () => {
    voterListStore.update_edit_voter_modal(!edit_voter_modal);
    voterListStore.update_active_voter(voterdata);
  };

  return (
    <>
      <div
        key={voterdata.id}
        ref={ref}
        className={classNames(
          isHovered ? "relative" : "static",
          "rounded-3xl bg-white cursor-pointer break-inside-avoid-column hover-votercard-shadow self-start",
        )}
      >
        <VoterId id={profile.voterid} hover={isHovered} />
        <VoterInfo name={profile.name_eng} localname={profile["name_reg"]} />
        <VoterDetails
          serialno={profile.serialno}
          booth={voterdata.part}
          gender={profile.gender}
          age={profile.age}
        />
        <VoterAdditionalInfo phone={voterdata.phone} houseno={profile.house} />

        <VoterAddress
          address={profile.address}
          relationship={profile.relationship_eng}
          relation={profile.relation_name_eng}
          caste={voterdata.caste}
          dob={profile.dob}
          expand={isExpanded}
        />

        <ExpandButton onExapnd={toggleExapnd} />
        {isHovered && <HoverVoterCardIcon onEdit={handleEditIconClick} />}
      </div>
    </>
  );
});
