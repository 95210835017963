import React from "react";
import { Button, useTheme } from "@mui/material";

type FormButtonTypes = {
  actionType: "Save" | "Cancel";
  handleButtonClick: () => void;
  type?: "submit" | "button";
};

export const FTPFormButton = (props: FormButtonTypes) => {
  const { actionType, handleButtonClick, type = "button" } = props;
  const isSaveAction = actionType === "Save";
  const theme = useTheme();

  return (
    <Button
      type={type}
      variant={isSaveAction ? "contained" : "outlined"}
      color={isSaveAction ? "inherit" : "error"}
      sx={{
        backgroundColor: isSaveAction
          ? theme.palette.common.black
          : "transparent",
        width: "100px",
        color: isSaveAction ? theme.palette.common.white : undefined,
        "&:hover": {
          backgroundColor: isSaveAction ? theme.palette.grey[400] : "inherit",
        },
      }}
      onClick={handleButtonClick}
    >
      {actionType}
    </Button>
  );
};
