import React, { useEffect, useReducer } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PlusIcon as PlusIconOutline, TrashIcon } from "@heroicons/react/24/outline";
import { FormButtons } from "../../../components";
import { useStore } from "../../../helpers";
import { input_style, label_style } from "../../beneficiary-management";
import { addSchemeInitialState, addSchemeReducer } from "./add-scheme-reducer";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { safe_get } from "../../../report-visuals";

export const flex_row = "flex flex-row items-center justify-between"

const AddBeneficiaryScheme = observer((props: any) => {

    const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
    const [state, dispatch] = useReducer(addSchemeReducer, addSchemeInitialState);
    const { beneficiaryStore } = useStore();
    const { activeScheme } = beneficiaryStore;
    const { projectid } = useParams();
    const queryClient = useQueryClient();
    const schemeId = safe_get(activeScheme, "id", null)

    useEffect(() => {
        if (schemeId) {
            setValue("name", safe_get(activeScheme?.type, "name", ""));
            setValue("category", safe_get(activeScheme?.type, "category", ""));
            setValue("display1", safe_get(activeScheme?.type, "display1", ""));
            setValue("display2", safe_get(activeScheme?.type, "display2", ""));
            setValue("subcategory", safe_get(activeScheme?.type, "subcategory", ""));
            setValue("default_display", safe_get(activeScheme?.type, "default_display", ""));

            if (activeScheme.type.required_docs) {
                const docs = activeScheme.type.required_docs.map(doc => ({
                    name: doc.name,
                    required: doc.required
                }));
                docs.forEach((doc, index) => {
                    dispatch({ type: 'ADD_FIELD' });
                    setValue(`required_docs[${index}].name`, doc.name);
                    setValue(`required_docs[${index}].required`, doc.required);
                });
                dispatch({ type: 'SET_FIELDS', payload: docs });
            }
        }
    }, [activeScheme, schemeId, setValue, dispatch]);

    const editScheme = async (payload: any) => {
        return await beneficiaryStore.edit_beneficiary_scheme(payload);
    }

    const resetModal = () => {
        beneficiaryStore.setAddSchemes(false)
        beneficiaryStore.setActiveScheme(null);
    }

    const { mutate: editMutate } = useMutation({
        mutationFn: editScheme,
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["beneficiaray-schemes", projectid])
            }, 1000);
            toast.success("Updated Successfully")
            resetModal();
        },
        onError: error => {
            toast.error("Something went wrong")
        }
    })

    const { mutate } = useMutation({
        mutationFn: async (data: any) => {
            const payload = {
                projectid: parseInt(projectid),
                type: data,
                more: {}
            }
            await beneficiaryStore.add_beneficiary_scheme(payload)
        },
        onSuccess: async () => {
            setTimeout(async () => {
                await queryClient.invalidateQueries(["beneficiaray-schemes", projectid])
            }, 1000);
            toast.success("Scheme added successfully")
            resetModal();
        },
        onError: async (error) => {
            toast.error("Something went wrong");
        }
    })

    const onSubmit = async (data) => {
        const finalRequiredDocs = state.required_docs.map((doc, index) => ({
            ...doc,
            name: data.required_docs[index]?.name || doc.name,
            required: data.required_docs[index]?.required !== undefined ? data.required_docs[index].required : doc.required,
        }));

        const final = { ...data, required_docs: finalRequiredDocs };

        if (schemeId) {
            editMutate({
                ...activeScheme, type: {
                    ...activeScheme.type,
                    category: data.category,
                    default_display: data.default_display,
                    display1: data.display1,
                    display2: data.display2,
                    name: data.name,
                    required_docs: finalRequiredDocs,
                    subcategory: data.subcategory
                }
            });
        } else {
            mutate(final);
        }
    };

    const addField = () => {
        dispatch({ type: 'ADD_FIELD' });
    };

    const deleteField = (index) => {
        dispatch({ type: 'DELETE_FIELD', index });
    };

    const handleFieldChange = (index, fieldName, fieldValue) => {
        dispatch({ type: 'UPDATE_FIELD', index, fieldName, fieldValue });
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)} className="w-full h-screen overflow-y-auto flex flex-col gap-y-4 ">
            <div className={flex_row}>
                <label htmlFor="category" className={label_style}>Category</label>
                <input type="text" id="category" {...register('category', { required: true })}
                       className={input_style} />
                {errors.category && <span className="text-red-500 text-sm">Category is required</span>}
            </div>

            <div className={flex_row}>
                <label htmlFor="subcategory" className={label_style}>Subcategory</label>
                <input type="text" id="subcategory" {...register('subcategory', { required: true })}
                       className={input_style} />
                {errors.subcategory && <span className="text-red-500 text-sm">Subcategory is required</span>}
            </div>

            <div className={flex_row}>
                <label htmlFor="name" className={label_style}>Name</label>
                <input type="text" id="name" {...register('name', { required: true })}
                       className={input_style} />
                {errors.name && <span className="text-red-500 text-sm">Name is required</span>}
            </div>

            <div className={flex_row}>
                <label htmlFor="display1" className={label_style}>Display 1</label>
                <input type="text" id="display1" {...register('display1')}
                       className={input_style} />
            </div>

            <div className={flex_row}>
                <label htmlFor="display2" className={label_style}>Display 2</label>
                <input type="text" id="display2" {...register('display2')}
                       className={input_style} />
            </div>

            <div className="">
                <label className="label_style">Default Display</label>
                <div className="flex pt-4 pb-4">
                    <label className="inline-flex items-center mt-1 mr-4">
                        <input
                            type="radio"
                            id="radio1"
                            value="display1"
                            {...register('default_display', { required: true })}
                            className="form-radio h-4 w-4 text-indigo-600"
                        />
                        <span className="ml-2 text-gray-700">Display 1</span>
                    </label>
                    <label className="inline-flex items-center mt-1 ml-4">
                        <input
                            type="radio"
                            id="radio2"
                            value="display2"
                            {...register('default_display', { required: true })}
                            className="form-radio h-4 w-4 text-indigo-600"
                        />
                        <span className="ml-2 text-gray-700">Display 2</span>
                    </label>
                </div>
            </div>

            <div className="">
                <div className={flex_row}>
                    <label htmlFor="required_docs" className={label_style}>Required Documents</label>
                    <button
                        type="button"
                        onClick={addField}
                        className="p-2 m-4 border border-transparent rounded-full shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                    >
                        <PlusIconOutline className="h-3 w-3" aria-hidden="true" />
                    </button>
                </div>

                {state.required_docs.map((field, index) => (
                    <div key={index} className={flex_row}>
                        <div className={flex_row}>
                            <div className="flex flex-row items-center justify-between mt-2">
                                <label className={label_style}>Name</label>
                                <Controller
                                    name={`required_docs[${index}].name`}
                                    control={control}
                                    defaultValue={field.name}
                                    render={({ field }) => (
                                        <input
                                            type="text"
                                            placeholder="Document"
                                            {...field}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleFieldChange(index, 'name', e.target.value);
                                            }}
                                            className={input_style}
                                        />
                                    )}
                                />
                            </div>
                            <div className="flex flex-row">
                                <label className="block font-bold text-gray-600 flex-2 w-48 sm:mt-px sm:py-1 text-sm ml-4">Required</label>
                                <Controller
                                    name={`required_docs[${index}].required`}
                                    control={control}
                                    defaultValue={field.required}
                                    render={({ field }) => (
                                        <input
                                            type="checkbox"
                                            checked={field.value}
                                            onChange={(e) => {
                                                field.onChange(e);
                                                handleFieldChange(index, 'required', e.target.checked);
                                            }}
                                            className="form-checkbox app-checkbox h-6 w-6 text-indigo-600 ml-4"
                                        />
                                    )}
                                />
                            </div>
                            {/*<div className="mb-4">*/}
                            {/*    <label htmlFor="defaultAssignee" className="block text-md font-bold text-gray-700">Default Assignee</label>*/}
                            {/*    <div className="relative">*/}
                            {/*        <select id="defaultAssignee" {...register('defaultAssignee', { required: true })}*/}
                            {/*                className="mt-1 block w-full px-4 py-3 border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 appearance-none">*/}
                            {/*            {state.default_assignee((assignee, index) => (*/}
                            {/*                <option key={index} value={assignee}>{assignee}</option>*/}
                            {/*            ))}*/}
                            {/*        </select>*/}
                            {/*        <ChevronDownIcon className="absolute top-2 right-2 h-5 w-5 text-gray-500 pointer-events-none" />*/}
                            {/*    </div>*/}
                            {/*    {errors.defaultAssignee && <span className="text-red-500 text-sm">Assignee is required</span>}*/}
                            {/*</div>*/}
                        </div>
                        <button
                            type="button"
                            onClick={() => deleteField(index)}
                            className="ml-2 text-red-600 hover:text-red-800 focus:outline-none"
                        >
                            <TrashIcon className="h-5 w-5" />
                        </button>
                    </div>
                ))}
            </div>

            <FormButtons
                onSave={() => { }}
                onCancel={resetModal}
                type={"submit"}
            />
        </form>
    );
});

export default AddBeneficiaryScheme;
