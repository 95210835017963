export type projectWorkSummaryType = {
  date: string;
  name: string;
  description: string;
  location: string;
  booth: any;
  status: string;
  coverPhotoPath: any;
  latitude: number;
  longitude: number;
  startDate: string;
  estimatedCompletionDate: string;
  sublocation: string;
  latlong: string;
};

export const projectWorkSummaryDefaultValues: projectWorkSummaryType = {
  date: "",
  name: "",
  description: "",
  location: "",
  booth: null,
  status: "",
  coverPhotoPath: {},
  latitude: 0,
  longitude: 0,
  startDate: "",
  latlong: "",
  estimatedCompletionDate: "",
  sublocation: "",
};

export type projectWorkFeatureType = {
  funds: string[];
  contacts: string[];
  media: string[];
  date: string[];
  events: string[];
  timelineLogs: string[];
};

export const projectWorkFeatureDefaultValues: projectWorkFeatureType = {
  funds: [],
  contacts: [],
  media: [],
  date: [],
  events: [],
  timelineLogs: [],
};

export const PWDefaultMedia = {
  id: "",
  date: "",
  title: "",
  media: {
    path: "",
    type: "",
  },
  description: "",
  thumbnailpath: "",
};

export const AddMediaDefaultValues = {
  name: "",
  description: "",
};

export type filterTypes = {
  villages: string[];
  status: string[];
  start_date: string;
  end_date: string;
};

export const defaultFilterTypes: filterTypes = {
  villages: [],
  status: [],
  start_date: "",
  end_date: "",
};

export type fundsProps = {
  fund: {
    id: string;
    title: string;
    description: string;
    amount: string;
    date: string;
  };
  onEdit: Function;
  onDelete: Function;
};

export const AddFundDefaultValues = {
  id: "",
  title: "",
  description: "",
  amount: "",
  date: "",
};

export const ProjectWorkFundDefaultValues = {
  id: "",
  title: "",
  description: "",
  amount: "",
  date: "",
};
