import React from "react";
import { observer } from "mobx-react-lite";
import { FilterHeader } from "../filterHeader";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../helpers";
import { safe_get } from "../../../report-visuals";
import dayjs from "dayjs";

export const DateSearch = observer((props: any) => {
  const { t } = useTranslation("voter_list");
  const { handleDateSearch } = props;
  const rootStore = useStore();
  const { voterListStore } = rootStore;
  const { voter_filters } = voterListStore;
  const defaultDOB = safe_get(
    voter_filters,
    "dob",
    dayjs(new Date()).format("DD-MM-YYYY"),
  );

  return (
    <div>
      <FilterHeader
        title={t("Date of Birth")}
        content={t("Please Select Date of Birth")}
      />

      <div className="w-full mt-4">
        <input
          id="dob"
          name="dob"
          type="date"
          defaultValue={dayjs(voter_filters["dob"], ["DD-MM-YYYY"]).format(
            "YYYY-MM-DD",
          )}
          onChange={(e) => {
            handleDateSearch(e.target.value);
          }}
          className="form-input w-100 px-1 py-2 border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>
    </div>
  );
});
