import React from "react";
import { Button, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";

type FormButtonsProps = {
  onSave: (args) => void;
  onCancel: (args) => void;
  type?: "button" | "submit" | "reset";
};

export const FilterButtons = (props: FormButtonsProps) => {
  const { onSave, onCancel, type = "submit" } = props;
  const { t } = useTranslation("project_work");
  const theme = useTheme();
  return (
    <div className="flex-shrink-0 border-t border-gray-200 p-4 sm:px-6">
      <div className="flex justify-end space-x-3 cursor-pointer">
        <Button variant={"outlined"} onClick={onCancel} color={"error"}>
          {t("clear_filter")}
        </Button>
        <Button
          sx={{
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
            "&:hover": {
              backgroundColor: theme.palette.grey[400],
            },
          }}
          variant={"contained"}
          onClick={onSave}
          type={type}
        >
          {t("apply_filter")}
        </Button>
      </div>
    </div>
  );
};
