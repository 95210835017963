import React, { useState } from "react";
import { LoadingState, TabView } from "../../../components";
import { RootStore } from "../../../stores/root";
import { useStore } from "../../../helpers";
import { safe_get } from "../../../report-visuals";
import { LeafletACMap } from "../../../components";
import { useQuery } from "@tanstack/react-query";
import { observer } from "mobx-react-lite";
import _ from "lodash";
import { FieldSamplesData } from "./field-samples-data";
import { Stack, Typography } from "@mui/material";
import { DistrictTitleCard } from "./district-title-card";
import { AddUsersToFSD, ManageFSDPermissions } from "../../fsd-permissions";
import { HistoricalData } from "./historical-data";
import { useArekere } from "./useArekere";
import { MH2024Crumbs } from "./mh-2024-crumbs";
import { mh_map_config, tabOptions } from "./utils";
import { MHDemographics } from "./mh-demographics";
import { useParams } from "react-router";
import { MHSwotAnalysis } from "./mh-swot-analysis";

export const MH2024FieldViewMainPage = observer((props: any) => {
  const rootStore: RootStore = useStore();
  const { flexiStore } = rootStore;
  const isAllowed = useArekere();
  const { id } = useParams();
  const fst = safe_get(
    JSON.parse(sessionStorage.getItem("fst")),
    "summary.name",
    "Repositories"
  );
  const [activeTab, setActiveTab] = useState(tabOptions[0].name);
  // @ts-ignore
  const fsd = flexiStore.current_fsd;
  const r1 = safe_get(fsd, "r1", {});
  const r2 = safe_get(fsd, "r2", {});
  const r12 = safe_get(fsd, "r12", {});
  const rfield = isAllowed ? "r12" : "r1";

  const field_data = safe_get(fsd, `${rfield}.field.analytics`, {});
  const samples = safe_get(fsd, `${rfield}.field.summary.s1`, {});
  const location_records = safe_get(
    fsd,
    `${rfield}.field.location_records`,
    []
  );
  const acMapPath = safe_get(fsd, `${rfield}.field.summary.acmap`, {});
  const demographics = safe_get(fsd, "r3.demographics", {});
  const swot = safe_get(fsd, "r4.swot", {});

  const loadGeoJson = async () => {
    return await rootStore.flexiStore.load_s3_json_resource(
      acMapPath?.bucket,
      acMapPath?.path,
      ""
    );
  };

  const { data, isLoading } = useQuery({
    queryKey: ["mh-ac-map", id],
    queryFn: loadGeoJson,
    enabled: !_.isEmpty(acMapPath),
  });

  if (isLoading) <LoadingState />;

  return (
    <>
      <MH2024Crumbs fst={fst} />
      <DistrictTitleCard summary={fsd?.summary} />
      <div className={"w-full sm:max-w-7xl mx-auto"}>
        <div className={"pt-4 px-4 sm:px-0"}>
          <TabView
            tabs={tabOptions}
            onChange={(name) => {
              setActiveTab(name);
            }}
          />
        </div>
        {!_.isEmpty(isAllowed ? r12 : r1) ? (
          <>
            {activeTab === "Field Summary" && (
              <FieldSamplesData data={field_data} samples={samples} />
            )}
            {activeTab === "Sample Distribution" && (
              <div className={"py-4 px-4 sm:px-0"}>
                <LeafletACMap
                  config={mh_map_config}
                  geojson={data}
                  data={location_records}
                />
              </div>
            )}
          </>
        ) : ["Field Summary", "Sample Distribution"].includes(activeTab) ? (
          <DataNotAvailable />
        ) : null}
        {activeTab === "Historical" && <HistoricalData data={r2} />}
        {activeTab === "Demographics" && <MHDemographics data={demographics} />}
        {activeTab === "SWOT Analysis" && <MHSwotAnalysis data={swot} />}
      </div>
      <ManageFSDPermissions />
      <AddUsersToFSD />
    </>
  );
});

export const DataNotAvailable = () => {
  return (
    <Stack
      direction={"row"}
      gap={2}
      className={"h-[75vh] flex items-center justify-center p-4"}
    >
      <i className="fas fa-exclamation-circle text-4xl text-[#ed5e49]" />
      <Typography variant={"h5"} color={"error"}>
        Data not available
      </Typography>
    </Stack>
  );
};
