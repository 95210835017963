import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useStore } from "../helpers/helpers";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  Input,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";
import { VortexSpinner } from "../features/digital-repository";
import { Vortex } from "react-loader-spinner";

const Languages = [
  { key: "en", val: "English" },
  { key: "hi", val: "Hindi" },
  { key: "kn", val: "Kannada" },
];

export const Login = observer((props) => {
  const rootStore = useStore();
  const { userStore, base_url } = rootStore;
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [otp, setOTP] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const redirectPath = location.state?.path || "/";
  const [otpReceived, setOTPReceived] = useState(false);

  async function sendOTP() {
    setLoading(true);
    const response = await userStore.sendOTP("+91" + phone);
    if (response.status === 200) {
      setOTPReceived(true);
    }
    setLoading(false);
  }

  async function login() {
    setLoading(true);
    const loginState = await userStore.validateOTP("+91" + phone, otp);
    if (loginState) {
      navigate(redirectPath, { replace: true });
    } else {
      toast.error("Invalid OTP");
    }
    setLoading(false);
    //console.log('Login Success: ' + loginState);
  }

  const [language, setLanguage] = useState("en");

  const { t, i18n } = useTranslation("Login");

  const handlelanguageChange = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
    console.log(lang);
  };

  return (
    <>
      <div className="flex login-bg" style={{ height: "100vh" }}>
        {/*  login form */}
        <div className="w-full flex flex-col relative justify-evenly">
          <div className={"flex flex-col gap-y-4"}>
            <div
              className={"flex flex-row items-center justify-center gap-x-4"}
            >
              {/* <img src={"/img/ftp_ai.png"} className={"w-12 h-12"} /> */}
              <h2 className={"text-gray-700"}>For The People</h2>
            </div>
            <p className={"text-center text-gray-600"}>
              Empowering Voices, Amplifying Change
            </p>
          </div>
          <div className="w-full sm:w-7/12 min-w-lg overflow-hidden flex flex-row mx-auto items-center justify-between gap-y-3 bg-white rounded-md">
            <section className={"hidden sm:block sm:w-1/2 w-full"}>
              <img src={"/img/ftp_bg.jpg"} />
            </section>
            <section className="overflow-hidden sm:w-1/2 w-full flex flex-col mx-auto items-center justify-between p-4 gap-y-3 bg-white rounded-md">
              <div className={"text-center"}>
                <h3 className={"my-2 text-gray-700"}>Welcome Back !</h3>
                <p className={"text-gray-500 mb-2"}>
                  Sign in to continue to For The People
                </p>
              </div>
              <div className="w-full sm:max-w-md mx-auto">
                <div className="px-4 py-2 sm:p-4 flex flex-col gap-y-4">
                  <FormControl fullWidth>
                    <InputLabel
                      sx={{ fontWeight: "500", color: "black" }}
                      color={"primary"}
                      htmlFor="phone number"
                    >
                      {t("phone_number")}
                    </InputLabel>
                    <OutlinedInput
                      id="phone number"
                      startAdornment={
                        <InputAdornment color={"primary"} position="start">
                          <i className="fas fa-mobile" />
                        </InputAdornment>
                      }
                      label="phone number"
                      inputProps={{
                        maxLength: 10,
                        style: { color: "primary" },
                      }}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      disabled={otpReceived}
                      placeholder={"Enter your Phone number"}
                    />
                  </FormControl>
                  {otpReceived && (
                    <FormControl fullWidth>
                      <InputLabel color={"primary"} htmlFor="otp">
                        OTP
                      </InputLabel>
                      <OutlinedInput
                        id="otp"
                        startAdornment={
                          <InputAdornment color={"primary"} position="start">
                            <i className="fas fa-keyboard" />
                          </InputAdornment>
                        }
                        label="OTP"
                        inputProps={{
                          maxLength: 6,
                          style: { color: "primary" },
                        }}
                        value={otp}
                        type={"password"}
                        onChange={(e) => setOTP(e.target.value)}
                        placeholder={"Enter your 6 digit OTP"}
                      />
                    </FormControl>
                  )}
                </div>
                <div className="px-4 py-2 sm:px-3 flex flex-col">
                  {!otpReceived ? (
                    <Button
                      onClick={sendOTP}
                      color={"primary"}
                      variant={"contained"}
                      size={"large"}
                      endIcon={
                        <Vortex
                          visible={loading}
                          height="25"
                          width="25"
                          ariaLabel="vortex-loading"
                          wrapperStyle={{}}
                          wrapperClass="vortex-wrapper"
                          colors={[
                            "red",
                            "green",
                            "blue",
                            "yellow",
                            "orange",
                            "purple",
                          ]}
                        />
                      }
                    >
                      {!loading ? "Get OTP" : "Loading..."}
                    </Button>
                  ) : (
                    <Button
                      onClick={login}
                      color={"primary"}
                      variant={"contained"}
                      size={"large"}
                    >
                      {t("login")}
                    </Button>
                  )}
                </div>
              </div>
              <p className={"text-gray-700"}>
                Don't have an account ? Please contact Admin.
              </p>
            </section>
          </div>

          <h5 className={"text-gray-700 text-center"}>
            © 2024 forthepeople.ai
          </h5>
        </div>
      </div>
    </>
  );
});
