import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../helpers";
import Select from "react-select";
import { get_booth_node_by_boothnumber } from "../custom-hooks/useBoothNumber";
import { useParams } from "react-router";

export const colourStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: "inherit",
    border: "1px solid #B6B6B6",
    borderRadius: "15px",
    height: "55px",
  }),
  option: (styles, { isFocused, isSelected }) => {
    return {
      ...styles,
      fontSize: "15px",
      backgroundColor: isSelected ? "#f0f0f0" : isFocused ? "#e6e6e6" : "white",
      color: "#333",
    };
  },
  menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
};

// Function to get distinct objects based on a selector
function getDistinctObjects(data, selector) {
  const resArr = [];
  data.forEach((item) => {
    if (!resArr.some((x) => x[selector] === item[selector])) {
      resArr.push(item);
    }
  });
  return resArr;
}

type BoothTreeProps = {
  onBoothSelection: (args) => void;
  selected_booth: string | string[];
  isMulti?: boolean;
  selector?: string;
};

export const BoothTree = observer((props: BoothTreeProps) => {
  const {
    onBoothSelection,
    selected_booth,
    isMulti = false,
    selector = "piv_level2",
  } = props;

  const { projectid } = useParams();
  const rootStore = useStore();
  const { projectStore } = rootStore;
  const [hierarchy, setHierarchy] = useState([]);
  const [selectedBooth, setSelectedBooth] = useState([]);
  const [groupedOptions, setGroupedOptions] = useState([]);
  useEffect(() => {
    if (typeof selected_booth === "string") {
      const node = get_booth_node_by_boothnumber(selected_booth);
      setSelectedBooth(
        node ? [{ label: node[selector], value: node[selector] }] : []
      );
    } else if (selected_booth) {
      setSelectedBooth([
        { label: selected_booth[selector], value: selected_booth[selector] },
      ]);
    }
  }, [selected_booth]);
  useEffect(() => {
    setHierarchy(projectStore.getProjectProperty("hierarchy", []));
    // setSelectedBooth(selected_booth?.value);
  }, [projectid]);

  useMemo(() => {
    const flattenOptions = (groups) => {
      return groups.map((group) => ({
        label: group.label,
        options: flattenChildren(group.children, selector),
      }));
    };

    const flattenChildren = (children, selector) => {
      return children.reduce((acc, child) => {
        if (child.children?.length > 0) {
          return [...acc, ...flattenChildren(child.children, selector)];
        } else {
          return [
            ...acc,
            {
              label: child[selector],
              value: child.value,
            },
          ];
        }
      }, []);
    };

    if (isMulti) {
      setSelectedBooth(Array.isArray(selected_booth) ? selected_booth : []);
    } else {
      if (typeof selected_booth === "string") {
        const node = get_booth_node_by_boothnumber(selected_booth);
        setSelectedBooth(
          node ? [{ label: node[selector], value: node[selector] }] : []
        );
      } else if (selected_booth) {
        setSelectedBooth([
          { label: selected_booth[selector], value: selected_booth[selector] },
        ]);
      }
    }

    const distinct = flattenOptions(hierarchy)?.map((group) => {
      return {
        label: group.label,
        options: getDistinctObjects(group.options, "label"),
      };
    });

    setGroupedOptions(distinct);
  }, [hierarchy]);

  const handleRecipientChange = (selectedOptions) => {
    setSelectedBooth(selectedOptions);
    if (!isMulti && selectedOptions) {
      const node = get_booth_node_by_boothnumber(selectedOptions.value);
      onBoothSelection(node);
    } else {
      onBoothSelection(selectedOptions);
    }
  };

  return (
    <Select
      isMulti={isMulti}
      value={selectedBooth}
      options={groupedOptions}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
      formatGroupLabel={(data) => (
        <p className="font-bold text-lg text-purple-600">{data.label}</p>
      )}
      onChange={handleRecipientChange}
      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
      menuPortalTarget={document.querySelector("body")}
      styles={colourStyles}
    />
  );
});
