import React from "react";
import { Typography } from "@mui/material";

export const FeatureHeader = ({ title }) => {
  return (
    <>
      <Typography
        variant={"h2"}
        color={"background"}
        className={"py-5 text-xl"}
      >
        {title}
      </Typography>
    </>
  );
};
