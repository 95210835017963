import { Button } from "@mui/material";
import React from "react";
import { FTPTheme } from "../../ftp-theme";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
const FilterButton = ({ text, onClick }) => {
  return (
    <Button
      variant="outlined"
      startIcon={<FilterAltIcon />}
      size="large"
      sx={{
        color: FTPTheme.palette.text.primary,
        borderColor: FTPTheme.palette.text.primary,
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default FilterButton;
