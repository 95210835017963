import React, { useState } from "react";
import { useStore } from "../../../helpers/helpers";
import { useTranslation } from "react-i18next";
import { clone, values } from "lodash";
import { Button, ListItem } from "@mui/material";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

export const ServiceFilteredBy = () => {
  const rootStore = useStore();
  const { serviceRequestsStore } = rootStore;
  const { activeServiceFilter } = serviceRequestsStore;
  const { t } = useTranslation("manage_voters");
  const result = removeEmptyValues(activeServiceFilter);
  const clone_service_filters = clone(activeServiceFilter);

  const handleClearFilter = (key, valueToRemove) => {
    const updatedFilters = { ...clone_service_filters };
    const keyComparisonMapping = {
      assignee: "assignee.name",
      status: "status.status",
      location: "location.location",
      type: "type.name",
      nodes: "name",
    };
    if (!updatedFilters[key]) return;
    if (Array.isArray(updatedFilters[key])) {
      const comparisonProperty = keyComparisonMapping[key];
      updatedFilters[key] = updatedFilters[key].filter((item) => {
        if (comparisonProperty) {
          return getNestedValue(item, comparisonProperty) !== valueToRemove;
        }
        return item !== valueToRemove;
      });
    }
    serviceRequestsStore.updateServiceFilter(updatedFilters);
  };

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  return (
    <>
      <ul className="flex flex-row flex-wrap px-2 items-center  border border-[#808080] rounded-[1.125rem] mb-[1.125rem] w-[90vw] mx-3">
        <h6 className="font-normal text-xs mr-2 whitespace-nowrap">
          {t("Filtered By :")}
        </h6>

        {/* Make chips scrollable */}
        <div className="flex space-x-1 overflow-x-auto flex-nowrap max-w-[80%]">
          {result.map(({ key, value }) => (
            <ListItem
              key={key}
              className="space-x-1"
              sx={{
                padding: "5px",
              }}
            >
              {Array.isArray(value) ? (
                value.map((item, index) => (
                  <Chip
                    key={`${key}-${index}`}
                    label={key === "nodes" ? item : `${key}: ${item}`}
                    onDelete={() => handleClearFilter(key, item)}
                  />
                ))
              ) : (
                <Chip
                  label={key === "nodes" ? value : `${key}: ${value}`}
                  onDelete={() => handleClearFilter(key, value)}
                />
              )}
            </ListItem>
          ))}
        </div>

        <Button
          variant="text"
          color="error"
          startIcon={<CloseOutlinedIcon />}
          onClick={async () => {
            await serviceRequestsStore.updateServiceFilter({});
          }}
          sx={{
            "& .MuiButton-startIcon": {
              marginRight: "2px",
            },
          }}
        >
          {t("Clear Filters")}
        </Button>
      </ul>
    </>
  );
};
const removeEmptyValues = (obj) => {
  let result = [];

  Object.entries(obj).forEach(([key, value]) => {
    if (Array.isArray(value) && value.length > 0) {
      let extractedValues;

      switch (key) {
        case "assignee":
          extractedValues = value
            .map((item) => item?.assignee?.name)
            .filter((name) => name);
          break;
        case "status":
          extractedValues = value
            .map((item) => item?.status?.status)
            .filter((status) => status);
          break;
        case "location":
          extractedValues = value
            .map((item) => item?.location?.location)
            .filter((location) => location);
          break;
        case "type":
          extractedValues = value
            .map((item) => item?.type?.name)
            .filter((name) => name);
          break;

        case "nodes":
          extractedValues = value.map((item) => item?.name);

          break;
        default:
          return;
      }

      if (extractedValues.length > 0) {
        result.push({ key, value: extractedValues });
      }
    }
  });

  return result;
};
