import * as yup from "yup";

export const defaultuserdata = {
  name: "",
  city: "",
  address: "",
};

export const userValidationSchema = yup.object().shape({
  name: yup.string().notRequired(),
  city: yup.string().notRequired(),
  address: yup.string().notRequired(),
});
