import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { SideOverlay } from "../components";
import { useStore } from "../helpers";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LogoutIcon from "@mui/icons-material/Logout";
import Avatar from "@mui/material/Avatar";
import { avatarStyles } from "../new-features/service-requests/sr-settings/assignees/MUIstyles";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import StorageOutlinedIcon from "@mui/icons-material/StorageOutlined";
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, useTheme } from "@mui/material";

export const UserProfile = () => {
  const rootStore = useStore();
  const { t } = useTranslation(["mobile_navbar_links"]);
  const [user, setUser] = useState(null);
  const classes = avatarStyles();

  const getCurrentUser = async () => {
    const {
      data: {
        profile: { city, name },
        profileimageuri,
      },
    } = await rootStore.userStore.getProfile();
    return { name, profileimageuri, city };
  };

  useEffect(() => {
    const setCurrentUser = async () => setUser(await getCurrentUser());
    setCurrentUser();
  }, []);

  const handleEditProfile = () => {
    rootStore.update_user_profile(true);
  };

  return (
    <div className="flex items-center gap-x-4">
      <Avatar
        src={user && user.profileimageuri}
        variant="rounded"
        className={classes.default}
      />
      <div className="flex flex-col ">
        <p className="text-xl font-semibold">{user && user.name}</p>
        <p className="text-label-default text-sm">{user && user.city}</p>
        <Button
          variant={"text"}
          sx={{ p: "10px 5px" }}
          startIcon={<CreateOutlinedIcon className="" fontSize="small" />}
          onClick={handleEditProfile}
        >
          {t("edit_my_profile")}
        </Button>
      </div>
    </div>
  );
};
export const MobileNavbarLink = ({ icon, label, link }) => {
  const rootStore = useStore();
  return (
    <Link onClick={() => rootStore.update_appDrawerToggle(false)} to={link}>
      <div className="flex justify-between bg-[#1B1B1D0F] rounded-[14px] p-4">
        <div className="flex gap-x-2 text-[#1b1b1d]">
          {icon}
          {label}
        </div>
        <ChevronRightIcon />
      </div>
    </Link>
  );
};

export const LogoutButton = () => {
  const rootStore = useStore();
  const { t } = useTranslation(["mobile_navbar_links"]);
  const handleLogout = async () => {
    await rootStore.userStore.logout();
  };
  const theme = useTheme();

  return (
    <>
      <Button
        variant={"outlined"}
        startIcon={<LogoutIcon className="mx-3" />}
        color={"secondary"}
        size={"large"}
        sx={{
          border: "2px solid red",
          backgroundColor: theme.palette.secondary.light + "30",
        }}
        onClick={handleLogout}
      >
        {t("logout")}
      </Button>
    </>
  );
};

export const MainRoutes = () => {
  const { t } = useTranslation(["mobile_navbar_links"]);
  const mainRoutes = [
    { icon: <DashboardOutlinedIcon />, label: t("dashboard"), link: "/" },
    {
      icon: <StorageOutlinedIcon />,
      label: t("data_bank"),
      link: "/databank",
    },
    {
      icon: <InsertDriveFileOutlinedIcon />,
      label: t("reports"),
      link: "/reports",
    },
  ];
  return (
    <div className={"flex flex-col gap-y-2"}>
      {mainRoutes.map((route, index) => (
        <MobileNavbarLink key={index} {...route} />
      ))}
    </div>
  );
};

export const UtilityRoutes = () => {
  const { t } = useTranslation(["mobile_navbar_links"]);
  const { projectid } = useParams();
  const utilityRoutes = [
    {
      icon: <AccountTreeOutlinedIcon />,
      label: t("manage_projects"),
      link: `project/${projectid}/manage`,
    },
    { icon: <SettingsOutlinedIcon />, label: t("settings"), link: "/settings" },
    {
      icon: <PeopleAltOutlinedIcon />,
      label: t("constituency_profile"),
      link: "/constituency-profile",
    },
  ];
  return (
    <div className={"flex flex-col gap-y-2"}>
      {utilityRoutes.map((route, index) => (
        <MobileNavbarLink key={index} {...route} />
      ))}
    </div>
  );
};

export const MobileNavBar = observer(() => {
  const rootStore = useStore();

  return (
    <SideOverlay
      onClose={() => {
        rootStore.update_appDrawerToggle(false);
      }}
      show={rootStore.appDrawerToggle}
      size={"small"}
    >
      <div className={"flex flex-col"}>
        <UserProfile />
        <div className="my-4 flex flex-col gap-y-[20vh]">
          <MainRoutes />
          <UtilityRoutes />
        </div>
        <LogoutButton />
      </div>
    </SideOverlay>
  );
});
