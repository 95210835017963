import React from "react";
import { observer } from "mobx-react-lite";
import { DeleteModal } from "../../../../components";
import { useStore } from "../../../../helpers";
import { useDeleteAssigneeRequest } from "./hooks";
import { useTranslation } from "react-i18next";

export const DeleteAssignee = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const {
    modals: { deleteAssignee },
    activeAssignee,
    setActiveAssignee,
  } = serviceRequestsStore;
  const { mutate: deleteAssigneeRequest, error } = useDeleteAssigneeRequest();
  const { t } = useTranslation(["assignee"]);

  function deleteCurrentAssignee() {
    try {
      //@ts-ignore
      deleteAssigneeRequest({
        id: activeAssignee?.id,
      });
    } catch (er) {
      console.log(er);
    }
    closeDeleteModal();
  }

  const closeDeleteModal = () => {
    serviceRequestsStore.update_modal_status("deleteAssignee", false);
    setActiveAssignee(null);
  };

  return (
    <>
      <DeleteModal
        onDeleteClick={deleteCurrentAssignee}
        onCancelClick={closeDeleteModal}
        isOpen={deleteAssignee}
        id={activeAssignee?.id}
        desc={`${t("confirm_delete")}`}
      />
    </>
  );
});
