import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers/helpers";
import _ from "lodash";
import { Table } from "./table";
import { toJS } from "mobx";
import { TableHeader } from "./survey-summary";
import { useTranslation } from "react-i18next";

export const SurveysByAudioOnly = observer((props) => {
  const { surveyResponseStore, userStore } = useStore();
  const { allSurveyResponsesSummary } = surveyResponseStore;
  const { t } = useTranslation("survey_management");

  const data = useMemo(
    () => toJS(allSurveyResponsesSummary)["by_hasaudio"],
    [allSurveyResponsesSummary]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("audio_in"),
        accessor: "status",
        Footer: "Total",
      },
      {
        Header: t("no_of_interviews"),
        accessor: "value",
        Footer: surveyResponseStore.get_total_surveys(data, "value"),
      },
    ],
    [allSurveyResponsesSummary]
  );

  if (
    _.isEmpty(allSurveyResponsesSummary["by_surveyor_audio"]) ||
    userStore.is_field_worker()
  )
    return null;

  const row_click_handler = (x) => {
    const has_audio = x.status === "has audio";
    surveyResponseStore.update_new_filters(
      "hasaudiofile",
      has_audio,
      `Audio Status: ${x["status"]}`
    );
  };

  return (
    <div className="flex flex-col w-100 md:w-0">
      <TableHeader title={t("availability")} />
      <Table data={data} columns={columns} onRowClick={row_click_handler} />
    </div>
  );
});
