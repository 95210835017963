import React from "react";
import { Slide, Fade } from "react-awesome-reveal";

// Expand Button Component
export const ExpandButton = ({ onExapnd }) => {
  return (
    <>
      <div
        className="flex justify-center space-x-2 py-[18px] items-center cursor-pointer"
        onClick={onExapnd}
      >
        {/* <img src="/voter/Dropdown_arrow.png" alt="My Example" /> */}
        <i className="fa fa-chevron-down text-xs" />
        <span className="font-normal text-[14px] leading-[16px] text-[#1B1B1D]">
          Expand
        </span>
      </div>
    </>
  );
};
