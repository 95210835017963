import React from 'react';

interface TextInputProps {
    label: string;
    type: string;
    name: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    placeholder?: string;
}

const TextInput: React.FC<TextInputProps> = (props: TextInputProps) => {
    const {label, onChange, placeholder, type, value, name} = props;
    return (
        <>
            <label htmlFor={name} className="block text-left font-medium leading-6 text-gray-900 ">
                {label}
            </label>
            <div className="mt-2">
                <input
                    type={type}
                    name={name}
                    id={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-indigo-600"
                />
            </div>
        </>
    );
};

export default TextInput;
