import React from "react";
import { observer } from "mobx-react-lite";
import { DeleteModal } from "../../../../components";
import { useStore } from "../../../../helpers";
import { useDeleteLocation } from "./location-hooks";
import { safe_get } from "../../../../report-visuals";
import { useTranslation } from "react-i18next";

export const DeleteLocation = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { modals, activeLocation, setActiveLocation } = serviceRequestsStore;
  const { t } = useTranslation(["location"]);
  const id = safe_get(activeLocation, "id", null);
  const { isLoading, mutate: deleteLocationMutate } = useDeleteLocation();

  const onDeleteLocation = async () => {
    // @ts-ignore
    await deleteLocationMutate({ id });
    closeModal();
  };

  const closeModal = () => {
    serviceRequestsStore.update_modal_status("deleteLocation", false);
    setActiveLocation({ id: null });
  };

  return (
    <DeleteModal
      onDeleteClick={onDeleteLocation}
      onCancelClick={closeModal}
      isOpen={modals.deleteLocation}
      id
      desc={t("delete_location_confirm")}
    />
  );
});
