import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { RequestTypesHome } from "./request-types";
import { StatusHome } from "./status";
import { PriorityHome } from "./priority";
import { Assignees } from "./assignees";
import { LocationHome } from "./locations";
import { useStore } from "../../../helpers";
import { useServiceRequestsMeta } from "../sr-hooks";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  tab: {
    backgroundColor: "#fff",
    borderRadius: "25px",
    "& .MuiButtonBase-root.Mui-selected": {
      backgroundColor: theme["palette"].primary.main,
      color: "#fff",
    },
    "& .MuiButtonBase-root": {
      color: theme["palette"].common.black,
      padding: "16px",
      fontWeight: "500",
      fontSize: "18px",
    },
  },
}));

export const ManageServiceRequestsTabs = observer((props) => {
  const classes = useStyles();
  const { serviceRequestsStore } = useStore();
  const { activeTab } = serviceRequestsStore;

  const { isRefetching } = useServiceRequestsMeta();

  useEffect(() => {
    serviceRequestsStore.setLoading(isRefetching);
  }, [isRefetching]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    serviceRequestsStore.set_active_tab(newValue);
  };

  const SRTABS = useMemo(() => {
    return [
      {
        label: "Request Types",
        value: "types",
        component: <RequestTypesHome />,
      },
      {
        label: "Status",
        value: "status",
        component: <StatusHome />,
      },
      {
        label: "Priority",
        value: "priority",
        component: <PriorityHome />,
      },
      {
        label: "Assignees",
        value: "assignees",
        component: <Assignees />,
      },
      {
        label: "Locations",
        value: "locations",
        component: <LocationHome />,
      },
    ];
  }, []);

  return (
    <Box sx={{ width: "100%", overflowY: "auto" }}>
      <TabContext value={activeTab}>
        <TabList
          // @ts-ignore
          indicatorColor="#B88EFE"
          variant={"fullWidth"}
          onChange={handleChange}
          className={classes.tab}
        >
          {SRTABS.map((tab) => (
            <Tab key={tab.value} label={tab.label} value={tab.value} />
          ))}
        </TabList>

        {SRTABS.map((tab, index) => (
          <TabPanel key={index} value={tab.value}>
            {tab.component}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
});
