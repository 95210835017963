import React from "react";
import { FlexiStoreData } from "../../../stores/fs/fst";
import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

export const SocialListeningSearchCard = observer((props: any) => {
  const fsd: FlexiStoreData = props.data;
  const navigate = useNavigate();
  // @ts-ignore
  const {name, state, time} = fsd.summary;
  const { fstid } = useParams();

  return (
    <div
      className="flex flex-row rounded-lg shadow-lg overflow-hidden cursor-pointer bg-white p-3"
      style={{
        borderLeft: `4px solid`,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
      }}
      onClick={(evt) => {
        navigate(`/databank/repo/${fstid}/${fsd.key}/${fsd.id}`, {
          state: { renderkey: fsd.renderkey },
        });
      }}
    >
      <div
        className="rounded-full flex flex-col items-center justify-center bg-gray-200"
        style={{ width: 60, height: 60 }}
      >
        <i className={`text-3xl `} style={{ color: `green` }} />
        <div className="font-bold text-gray-800">Test</div>
        <div className="font-bold text-gray-800">test</div>
      </div>
      <div className="flex-1 flex flex-col ml-2">
        <div className="font-bold text-gray-700">{name}</div>
        <div className="text-gray-600 text-md">
          <p>{state}</p>
          <p>{time}</p>
        </div>
      </div>
    </div>
  );
});
