import React from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import { SocialListeningBaseClassification } from "./social-listening-base-classification";
import { SocialListeningWeekWise } from "./social-listening-week-wise";
import SubjectTagCloud from "./social-listening-subject-tag";
import TopicTagCloud from "./social-listening-topic-tag";
import HashtagTable from "./social-listening-hashtag-table";
import { PerformanceAssets } from "./social-listening-performance-asset";
import PostTypeData from "./social-listening-post-type";
import SocialMediaAnalysis from "./social-media-analysis";
import ProfileGlance from "./profile-glance";

export const SocialListeningMainPage = observer(() => {
  return (
    <div className="w-full h-full bg-[#9435FF]">
      <div>
        <SocialMediaAnalysis />
      </div>

      <div>
        <ProfileGlance />
      </div>

      <div>
        <SocialListeningWeekWise />
      </div>

      <div>
        <SocialListeningBaseClassification />
      </div>

      <div>
        <SubjectTagCloud />
      </div>

      <div>
        <TopicTagCloud />
      </div>

      <div>
        <HashtagTable />
      </div>

      <div>
        <PerformanceAssets />
      </div>

      <div>
        <PostTypeData />
      </div>

      <div className="flex justify-center items-center">
        <img src="/img/RajNeethi Logo 5.svg" alt="Rajneethi" />
      </div>
    </div>
  );
});
