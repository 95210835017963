import React from "react";
import AddAssignee from "./add-assignee";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../helpers";
import { AssigneeCard, AssigneeCardSkeleton } from "./assignee-card";
import { MasonryGrid } from "../../../../components-new";
import { DeleteAssignee } from "./delete-assignee";

export const Assignees = observer(() => {
  const {
    serviceRequestsStore: { meta, loading },
  } = useStore();

  return (
    <div className={"overflow-hidden"}>
      <MasonryGrid
        data={meta?.assignee}
        isLoading={loading}
        skeletonCount={8}
        // @ts-ignore
        renderItem={(item) => <AssigneeCard {...item} key={item["id"]} />}
        renderSkeleton={(key) => <AssigneeCardSkeleton key={key} />}
      />
      <AddAssignee />
      <DeleteAssignee />
    </div>
  );
});
