import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import { useStore } from "../../../helpers";
import { DonutChart3d } from "../../../components/Charts/DonutChart3d";

const POST_TYPE_COLORS: Record<string, string> = {
  video: "#9F0FEF",
  live: "#2138AB",
  image: "#66B032",
  collage: "#FFA010",
  article: "#05FFA6",
  reel: "#16B8F3",
};

const PostTypeData = observer(() => {
  const { flexiStore } = useStore();
  const [chartData, setChartData] = useState<{ label: string; value: number; color?: string }[]>([]);


  useEffect(() => {
    if (!flexiStore.current_fsd?.r2) return;

    const postTypeData = (flexiStore.current_fsd.r2 as Record<string, any>)
      ?.post_type;
    if (!postTypeData || typeof postTypeData !== "object") return;

    const formattedData = Object.entries(postTypeData).map(
      ([key, value], index) => ({
        label: key,
        value: Array.isArray(value) ? value[1] : 0,
        color: POST_TYPE_COLORS[key] || "#CCCCCC",
      })
    );

    setChartData(formattedData);
  }, [flexiStore.current_fsd]);

  return (
    <div className="bg-white rounded-b-[80px]">
      <div className="flex justify-between items-center text-[#B97CFE] p-12">
        <Typography variant="h3" fontWeight="bold">Post Type</Typography>
        <Typography variant="h4" fontWeight="bold">
          To be <br /> decided
        </Typography>
      </div>

      <div className="w-full h-screen flex items-center justify-center">
        {chartData.length > 0 ? (
          <DonutChart3d
            data={chartData}
            _label="label"
            value="value"
            title="Post Type Chart"
          />
        ) : (
          <Typography>No data available</Typography>
        )}
      </div>
    </div>
  );
});

export default PostTypeData;
