import * as yup from "yup";

export const defaultStatus = {
  status: "",
  color: "",
  icon: "",
};

export const statusValidationSchema = yup.object().shape({
  status: yup.string().required("Status is required"),
  color: yup.string().notRequired(),
  icon: yup.string().notRequired(),
});
