import _ from "lodash";
import { toJS } from "mobx";

// Reads the populated assignee data and fetches the options for department and position
export const getUniqueDepartmentsAndPositions = (assigneeArray) => {
  const values = assigneeArray.reduce(
    (acc, item) => {
      acc["department"].push(
        toJS(_.get(item, "assignee.department")) as string
      );
      acc["position"].push(toJS(_.get(item, "assignee.position")) as string);
      acc["department"] = [..._.uniq(acc["department"])];
      acc["position"] = [..._.uniq(acc["position"])];
      return acc;
    },
    { department: [], position: [] }
  );
  return values;
};
