import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import { useParams, Outlet } from "react-router-dom";
import { BoothReportView } from "../renderkeys/booth-bank";
import { CMXView } from "../renderkeys/cmx/CMXView";
import { ACBankView } from "../renderkeys/ac-bank";
import { LoadingState } from "../../components";
import { PCBankView } from "../renderkeys/pc-bank";
import { MH2024FieldViewMainPage } from "../renderkeys/mh-2024-field";
import { SocialListeningMainPage } from "../renderkeys/social-listening/social-listening-main-page";

export const FSDViewPage = observer((props) => {
  const { flexiStore } = useStore();
  const { current_fsd_ready } = flexiStore;
  const renderKey = flexiStore.get_current_fsd_render_key();
  const { id } = useParams();

  useEffect(() => {
    async function load_fsd() {
      await flexiStore.get_fsdata(id);
    }
    load_fsd();
  }, []);

  if (!current_fsd_ready) return <LoadingState />;

  return (
    <div>
      {renderKey === "booth-report" && <BoothReportView />}
      {renderKey === "ac-bank" && <ACBankView />}
      {renderKey === "cmx" && <CMXView />}
      {renderKey === "pc-bank" && <PCBankView />}
      {renderKey === "mh-2024-field" && <MH2024FieldViewMainPage />}
      {renderKey === "social-listening-report" && <SocialListeningMainPage />}
      <Outlet />
      {/*{renderKey === "qlty-booth-report" && <BoothReportView/>}*/}
    </div>
  );
});
