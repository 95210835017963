import React, { useState, useEffect, useReducer } from "react";
import { useStore, error_toast } from "../../helpers";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router";
import { clone } from "lodash";
import { FilterButton, LoadingState } from "../../components";
import { DataNotAvailable } from "../../flexistore/renderkeys/mh-2024-field";
import { FTPPagination } from "../../components-new";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { doesFilterHaveValues } from "../../helpers/filterHelper";
import { Button } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const initialState = {
  loading: true,
  voterList: [],
  totalVoters: 0,
  error: "",
};

const reducer = (state, action) => {
  switch (action.type) {
    case "START":
      return {
        ...state,
        loading: true,
        error: "",
      };
    case "SUCCESS":
      return {
        loading: false,
        voterList: action.payload.results,
        totalVoters: action.payload.total,
        error: "",
      };
    case "FAILURE":
      return {
        loading: false,
        voterList: [],
        totalVoters: 0,
        error: "Something went wrong",
      };
    default:
      return state;
  }
};

export const SearchVoterList = observer((props: any) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { projectid: pid } = useParams();
  const { pathname } = useLocation();
  const isManageVotersPage = pathname.includes("manage-voters");
  const { voterListStore, userStore } = useStore();
  const { voter_filters } = voterListStore;
  console.log(voter_filters);
  const {
    relation,
    projectid,
    page,
    house,
    genders,
    df,
    booths,
    age,
    name,
    serialno,
    voterid,
    offset,
    count,
  } = voter_filters;
  const { t } = useTranslation(["voter_list", "manage_voters"]);
  const clone_voter_filters = clone(voterListStore.voter_filters);

  const [pageNumber, setPageNumber] = useState(1);
  const totalPages = Math.ceil(state.totalVoters / 100);

  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const handlePageChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    setPageNumber(value);
    clone_voter_filters["offset"] = (value - 1) * count;
    await voterListStore.update_filters(clone_voter_filters);
  };

  const handleClearFilter = async (key, value) => {
    const updatedFilters = { ...clone_voter_filters }; // Clone the object to ensure a new reference

    if (Array.isArray(updatedFilters[key])) {
      // Handle array keys like genders and booths
      updatedFilters[key] = updatedFilters[key].filter(
        (item) => item !== value
      );

      // Reset to default values if array becomes empty
      if (updatedFilters[key].length === 0) {
        updatedFilters[key] =
          key === "genders"
            ? ["Male", "Female", "Third Gender"] // Default for genders
            : []; // Default for booths
      }
    } else {
      // Handle other keys with string values
      updatedFilters[key] = "";
    }

    await voterListStore.update_filters(updatedFilters); // Update filters
  };
  useEffect(() => {
    console.log("I m rendered");
    console.log(state);
  }, []);

  useEffect(() => {
    getVoterList();
  }, [
    relation,
    projectid,
    page,
    house,
    genders,
    df,
    booths,
    age,
    name,
    serialno,
    voterid,
    offset,
    pid,
    count,
  ]);

  const getVoterList = async () => {
    dispatch({ type: "START" });
    clone_voter_filters["projectid"] = parseInt(pid);
    await voterListStore.update_filters(clone_voter_filters);

    try {
      const res = await voterListStore.getVoterSearch();
      userStore.setUserDetailsMode(false);
      if (res.status !== 200) {
        error_toast();
        dispatch({ type: "FAILURE" });
      } else {
        dispatch({ type: "SUCCESS", payload: res.data });
        console.log(res.data);
      }
    } catch (e) {
      dispatch({ type: "FAILURE" });
    }
  };

  // const handleSearchBarChange = async (value) => {
  //   clone_voter_filters["name"] = value;
  //   await voterListStore.update_filters(clone_voter_filters);
  // };

  // const getUsers = async (event, done) => {
  //   await voterListStore.update_filters({
  //     ...voter_filters,
  //     count: state.totalVoters,
  //   });
  //   done(!state.loading);
  // };

  const result = doesFilterHaveValues(voterListStore.voter_filters);

  return (
    <>
      {state["loading"] && <LoadingState />}
      {/* <div className="flex flex-col w-full gap-4 sm:items-center sm:justify-between  sm:flex-row">
        <h2 className="font-bold text-2xl text-indigo-500 ">
          Voter Management
        </h2>
        <div
          className="flex flex-col gap-4 sm:flex-row sm:items-center sm:space-x-2"
          style={{ flexFlow: "wrap" }}
        >
          <RNSearchBar onSearch={handleSearchBarChange} />
          <div className="space-x-2">
            <FilterButton
              filterOnClickHandler={() => {
                voterListStore.setShowVoterlist(false);
                voterListStore.setFilterStatus(true);
              }}
            />
            <Button
              variant={"contained"}
              onClick={async () => {
                await voterListStore.reset_filters(parseInt(pid));
              }}
              endIcon={<i className="fas fa-retweet" />}
              color={"warning"}
            >
              {t("reset_search")}
            </Button>
            {
              isAllowed && (
                <CSVLink
                  data={voterListStore.Voterlist}
                  headers={downloadColumns}
                  filename={"Voter List"}
                  asyncOnClick={true}
                  onClick={getUsers}
                >
                  <i className="text-blue-600 mx-4">Download</i>
                </CSVLink>
              )
              // <DownloadAsExcelSheet data={voterListStore.Voterlist} cols={downloadColumns} name={"Voter List"}/>
            }
          </div>
        </div>
      </div> */}

      {/* search */}
      <div className="flex justify-between items-center w-100">
        <div className="">
          {isManageVotersPage && (
            <h3 className="text-3xl font-normal text-[1B1B1D] mb-2.5">
              {t("Search Voter")}
            </h3>
          )}
          <span className="text-base text-[#3A3A3A] font-normal ">
            {t(
              " Search the voters here and link them to auto-fill with the existing data"
            )}
          </span>
        </div>
        <div className="flex space-x-6 pb-2">
          <FilterButton
            filterOnClickHandler={() => {
              voterListStore.setShowVoterlist(false);
              voterListStore.setFilterStatus(true);
            }}
          />
        </div>
      </div>
      {/* filter by */}

      {result.hasValues && (
        <ul className="flex items-center p-2 border border-[#808080] rounded-[18px] mb-[18px] w-full">
          <div className="flex space-x-2 flex-1 items-center">
            <h6 className="font-normal text-xs">{t("Filtered By :")}</h6>
            {result.filters.map((data) => {
              const { key, value } = data; // Destructure properties
              return (
                <>
                  <ListItem key={key} className="space-x-2">
                    {Array.isArray(value) ? (
                      value.map((item, index) => (
                        <Chip
                          key={`${key}-${index}`} // Unique key for each chip
                          label={`${key}: ${item}`} // Format as "genders: Male"
                          onDelete={() => handleClearFilter(key, item)} // Pass key and value to handleDelete
                        />
                      ))
                    ) : (
                      <Chip
                        label={`${key}: ${value}`} // Handle single value case
                        onDelete={() => handleClearFilter(key, value)} // Pass full data object to handleDelete
                      />
                    )}
                  </ListItem>
                </>
              );
            })}
          </div>

          {/* clear filters */}
          <Button
            variant="text"
            color="error"
            startIcon={<CloseOutlinedIcon />}
            onClick={async () => {
              await voterListStore.reset_filters(parseInt(pid));
            }}
            sx={{
              "& .MuiButton-startIcon": {
                marginRight: "2px",
              },
            }}
          >
            {t("Clear Filters")}
          </Button>
        </ul>
      )}

      <div className="flex justify-between items-center flex-wrap gap-4 w-full py-2 sm:py-4">
        {state["totalVoters"] > 0 ? (
          <div className="text-sm font-normal text-[#858585]">
            Showing <span className="text-[#1B1B1D]">{offset + 1}</span> to{" "}
            <span className="text-[#1B1B1D]">
              {Math.min(offset + count, state.totalVoters)}
            </span>{" "}
            of <span className="text-[#1B1B1D]">{state.totalVoters}</span>{" "}
            voters
          </div>
        ) : (
          <DataNotAvailable />
        )}

        {totalPages ? (
          <FTPPagination
            totalPages={totalPages}
            pageNumber={pageNumber}
            handlePageChange={handlePageChange}
          />
        ) : null}
      </div>
    </>
  );
});
