/** TODO:
 * FTPAutoComplete Component
 *
 * A customizable autocomplete component built on top of Material-UI's Autocomplete component,
 * integrated with React Hook Form for form handling.
 *
 * Features:
 * - Single and multiple selection support
 * - Custom styling with rounded borders
 * - Add new options on the fly
 * - Filtering of already selected options in multiple mode
 * - Support for both string arrays and object arrays as data source
 * - Custom option rendering
 * - Form validation integration via React Hook Form
 *
 * @component
 *
 * @param {Object} props
 * @param {string} props.name - Field name for form control
 * @param {string} props.label - Label text displayed above the input
 * @param {Array<object|string>} props.data - Array of options to select from
 * @param {string} [props.lookupKey] - Key to use when displaying object data (e.g., "name")
 * @param {boolean} [props.multiple=false] - Enable multiple selection mode
 * @param {string} props.placeholder - Placeholder text for the input field
 *
 * @example
 * // Using with string array
 * <FTPAutoComplete
 *   name="category"
 *   label="Category"
 *   data={["Option 1", "Option 2"]}
 *   placeholder="Select category"
 * />
 *
 * @example
 * // Using with object array
 * <FTPAutoComplete
 *   name="users"
 *   label="Users"
 *   data={[{name: "John"}, {name: "Jane"}]}
 *   lookupKey="name"
 *   multiple={true}
 *   placeholder="Select users"
 * />
 */

import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { makeStyles } from "@mui/styles";
import { Controller, useFormContext } from "react-hook-form";
import { FormHelperText } from "@mui/material";
import { FormLabel } from "./utils";
import { safe_get } from "../report-visuals";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiAutocomplete-inputRoot": {
      borderRadius: "15px",
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
    "& .MuiInputLabel-root": {
      display: "none",
    },
  },
}));

const filter = createFilterOptions();

export type AutoCompProps = {
  name: string;
  label: string;
  data: Array<object> | Array<string>;
  lookupKey?: string;
  multiple?: boolean;
  placeholder: string;
  className?: string;
  addNewOption?: boolean;
};

export const FTPAutoComplete = (props: AutoCompProps) => {
  const {
    data,
    lookupKey = "",
    label,
    name,
    multiple = false,
    placeholder,
    className = "",
    addNewOption = true,
  } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const classes = useStyles();
  const [options, setOptions] = useState<Array<any>>(data);

  const handleOnChange = (
    event: React.SyntheticEvent,
    newValue: any,
    field: any
  ) => {
    const createNewValue = (input: string) => {
      if (lookupKey) {
        return { [lookupKey]: input };
      } else return input;
    };

    const processValue = (val: any) => {
      if (typeof val === "string") {
        return val;
      }
      if (val?.inputValue) {
        if (!options.includes(val.inputValue)) {
          setOptions((prev: Array<any>) => [...prev, val.inputValue]);
        }
        return createNewValue(val.inputValue);
      }
      return val;
    };

    if (multiple) {
      const processValues = newValue?.map(processValue) || [];
      field.onChange(processValues);
    } else {
      const processed = processValue(newValue);
      field.onChange(processed || null); // Return null instead of undefined for empty values
    }
  };

  const handleFiltering = (options: Array<any>, params: any, field: any) => {
    let filtered = filter(options, params);

    const { inputValue } = params;
    const isExisting = options?.some((option: any) => {
      if (typeof option === "string") {
        return inputValue === option;
      } else {
        return inputValue === safe_get(option, lookupKey, null);
      }
    });

    // Filter out already selected values for multiple select
    if (multiple && filtered.length > 0) {
      filtered = filtered.filter((option: any) => {
        const optionValue =
          typeof option === "string"
            ? option
            : safe_get(option, lookupKey, null);
        return !field.value?.some(
          (selectedItem: any) =>
            (typeof selectedItem === "string"
              ? selectedItem
              : selectedItem[lookupKey]) === optionValue
        );
      });

      // Function to check deep equality
      const isEqual = (obj1, obj2) =>
        JSON.stringify(obj1) === JSON.stringify(obj2);

      // Filtering out objects that are in two from one
      const fileringValue = filtered.filter(
        (item1) => !field.value.some((item2) => isEqual(item1, item2))
      );

      return fileringValue;
    }

    if (addNewOption && inputValue !== "" && !isExisting) {
      filtered.push({
        inputValue,
        [lookupKey]: `Add "${inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = (option: any) => {
    if (typeof option === "string") {
      return option;
    }
    if (option?.inputValue) {
      return option.inputValue;
    }
    return safe_get(option, lookupKey, "");
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={multiple ? [] : null}
      render={({ field, fieldState: { error } }) => (
        <div className={"flex flex-col w-full " + className}>
          <FormLabel>{label}</FormLabel>
          <Autocomplete
            key={name}
            {...field}
            multiple={multiple}
            id={`autocomplete-custom-${label}`}
            value={field.value || (multiple ? [] : null)}
            options={options || []}
            className={classes.root}
            onChange={(event, newValue) =>
              handleOnChange(event, newValue, field)
            }
            filterOptions={(options, params) =>
              handleFiltering(options, params, field)
            }
            fullWidth
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            getOptionLabel={getOptionLabel}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  placeholder={placeholder}
                  error={!!errors[`${name}`]}
                  InputProps={{
                    ...params.InputProps,
                    placeholder: placeholder,
                  }}
                />
                {error && (
                  <FormHelperText error>{error.message}</FormHelperText>
                )}
              </>
            )}
            renderOption={(props, option) => {
              const { key, ...optionProps } = props;
              const val =
                typeof option === "string"
                  ? option
                  : safe_get(option, lookupKey, null);
              return (
                <li key={option.id || key} {...optionProps}>
                  {val}
                </li>
              );
            }}
          />
        </div>
      )}
    />
  );
};
