import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { useHoverDirty } from "react-use";
import { classNames, useStore } from "../../../../helpers";
import Chip from "@mui/material/Chip";
import { ActionButtons } from "../sr-settings-components";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";

export const PriorityCardSkeleton = () => {
  return (
    <>
      <div className="bg-white p-3 rounded-3xl">
        <div className="flex flex-col gap-y-2 rounded-xl p-3">
          <Skeleton variant="text" width="80%" height={30} />
          <Skeleton variant="text" width="50%" height={20} />
        </div>
        <div className="my-2 p-3 rounded-3xl flex flex-col gap-4 bg-[#1B1B1D0A]">
          <Stack direction="row" spacing={2}>
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="text" width={100} height={20} />
            <Skeleton variant="text" width={100} height={20} />
          </Stack>
        </div>
      </div>
    </>
  );
};

export const PriorityCard = observer((props: any) => {
  const { servicePriority } = props;
  const { id, priority } = servicePriority;
  const { serviceRequestsStore } = useStore();
  const ref = useRef(null);
  const isHovered = useHoverDirty(ref);

  const onEditPriority = () => {
    serviceRequestsStore.update_modal_status("addPriority", true);
    setActivePriorityInStore();
  };

  const onDeletePriorityHandler = () => {
    serviceRequestsStore.update_modal_status("deletePriority", true);
    setActivePriorityInStore();
  };

  const setActivePriorityInStore = () => {
    serviceRequestsStore.setActivePriority(servicePriority);
  };

  return (
    <>
      <div
        key={id}
        className="relative bg-white rounded-3xl cursor-pointer overflow-hidden"
        ref={ref}
      >
        {priority.default ? (
          <div className="rounded-tl-3xl rounded-tr-3xl py-2.5 text-center bg-primary-light">
            default
          </div>
        ) : null}

        <div className="flexBoxRow m-4 p-2 ">
          <div>
            <h4 className="font-semibold text-base text-common-black ">
              {priority.name}
            </h4>
            <p className=" text-grey-800 font-medium text-sm">
              {priority.type}
            </p>
          </div>

          {isHovered ? (
            <ActionButtons
              positionClasses={classNames(
                priority.default ? "top-[23%]" : "top-[5%]",
                "absolute right-0"
              )}
              onEdit={onEditPriority}
              onDelete={onDeletePriorityHandler}
            />
          ) : null}
        </div>
        <div className="p-3 m-3 rounded-3xl bg-grey-100">
          <div className="max-w-fit space-x-1">
            {Object.values(priority.display).map((item, index) => (
              <Chip key={index} label={item["text"]} variant="outlined" />
            ))}
          </div>
        </div>
      </div>
    </>
  );
});
