import {useParams} from "react-router-dom";
import {FTPBreadCrumbs} from "../../../components-new/BreadCrumbs";
import React from "react";

export const SRManageCrumbs = (props) => {
    const { projectid } = useParams();
    const crumbs = [
        { name: "DashBoard", href: `/project/${projectid}`, current: false },
        { name: 'Service Requests', href: `/project/${projectid}/servicerequests`, current: false },
        { name: 'Manage Service Requests', href: `#`, current: true },
    ];
    return (
        <FTPBreadCrumbs crumbs={crumbs} />
    )
}