import React, { lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { FieldPayment } from "../features";
import ManageProject from "../pages/ManageProject";

const ProjectDashboardPage = lazy(
  () => import("../pages/dashboard/ProjectDashboard"),
);

const SurveyEditor = lazy(() => import("../pages/SurveyEditor"));
const FieldTeamReport = lazy(
  () => import("../features/survey-management/survey-details"),
);
const TaskManager = lazy(
  () => import("../features/task-manager/task-manager-landing"),
);
const VoterRegistrationTracker = lazy(
  () => import("../features/new-voter-registration/voter-registraion"),
);

const DigitalRepository = lazy(
  () => import("../features/digital-repository/digital-repository"),
);

const BeneficiaryManagementV2 = lazy(
  () =>
    import("../features/beneficiary-management-v2/beneficiary-management-v2"),
);

const InfluentialLeadersHome = lazy(
  () => import("../features/influential-leaders/influential-leaders-home"),
);

const UserManagement = lazy(
  () => import("../features/user-management/user-management"),
);
const ManageVotersdex = lazy(
  () => import("../features/manage-voters/manage-voters-index"),
);
const ElectionDayDashboard = lazy(
  () => import("../features/election-day-dashboard/election-day-dashboard"),
);
const VoterReachOutIndex = lazy(
  () => import("../features/voter-reachout/voter-reachoutIndex"),
);
const VoterReachOut = lazy(
  () =>
    import("../features/voter-reachout/voter-reachout-Messages/voter-reachout"),
);
const BeneficiaryRoutes = lazy(
  () => import("./feature-routes/beneficiary-routes"),
);
const GrievanceRoutes = lazy(() => import("./feature-routes/grievance-routes"));
const GrievanceV2Routes = lazy(
  () => import("./feature-routes/grievancev2-routes"),
);

const ProjectWorksRoutes = lazy(
  () => import("./feature-routes/project-works-routes"),
);

const C3Routes = lazy(() => import("./feature-routes/c3-routes"));
const PWV2Routes = lazy(() => import("./feature-routes/pw-v2-routes"));

const ServiceRequests = lazy(
  () => import("./feature-routes/service-requests-routes"),
);

const Notion = lazy(() => import("../new-features/ftp-notion/notion-home"));
export const FeatureRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<ProjectDashboardPage />} />
      <Route path="/manage" element={<ManageProject />} />
      <Route path="/manage/survey/:surveyid/edit" element={<SurveyEditor />} />
      <Route path="/fieldteamreport" element={<FieldTeamReport />} />
      <Route path="/taskmanager" element={<TaskManager />} />
      <Route
        path="/voter_registration_tracker"
        element={<VoterRegistrationTracker />}
      />
      <Route path="/servicerequests/*" element={<ServiceRequests />} />
      <Route path="/digital_repository" element={<DigitalRepository />} />
      <Route path="/beneficiary_v2" element={<BeneficiaryManagementV2 />} />
      <Route path="/influential_leaders" element={<InfluentialLeadersHome />} />
      <Route path="/usermanagement" element={<UserManagement />} />
      <Route path="/fieldpayment" element={<FieldPayment />} />
      <Route path="/manage-voters/" element={<ManageVotersdex />} />
      <Route
        path="/election_day_dashboard/"
        element={<ElectionDayDashboard />}
      />
      <Route path="/beneficiary_management/*" element={<BeneficiaryRoutes />} />
      <Route path="/grievance_management/*" element={<GrievanceRoutes />} />
      <Route
        path="/grievance_management_v2/*"
        element={<GrievanceV2Routes />}
      />
      <Route path="/project_works/*" element={<ProjectWorksRoutes />} />
      <Route path="/voter_reach_out" element={<VoterReachOutIndex />} />
      <Route path="/voter_reach_out/messages" element={<VoterReachOut />} />
      <Route path="/c3/*" element={<C3Routes />} />
      <Route path="/projectworks_v2/*" element={<PWV2Routes />} />
      <Route path="/notion" element={<Notion />} />
    </Routes>
  );
};
