import React from "react";
import { IconButton, Stack, Typography, useTheme } from "@mui/material";
import { useStore } from "../../helpers";
import { safe_get } from "../../report-visuals";
import { SideOverlay } from "../../components";
import Projects from "../../pages/Projects";
import { observer } from "mobx-react-lite";
import { MobileNavBar } from "../MobileNavBar";
import MenuIcon from "@mui/icons-material/Menu";
import { ProfileMenu } from "../../pages/update-profile/profile-menu";
import { useLocalStorage } from "react-use";

const AppHeader = observer(() => {
  const rootStore = useStore();
  const { projectStore } = rootStore;
  const project_name = safe_get(projectStore.activeProject, "name", "");
  const handleProjectSwitch = () => {
    rootStore.update_switch_project(false);
  };
  const theme = useTheme();
  return (
    <Stack className={"border-b-2 border-white sticky top-0 z-10 bg-[#F2F2F2]"}>
      <div className=" flex flex-row p-2 sm:px-4 sm:py-2 items-center gap-x-4">
        <Stack direction={"row"} alignItems={"center"} className={"flex-1"}>
          <IconButton
            aria-label="Switch Project"
            onClick={() => {
              rootStore.update_switch_project(true);
            }}
            size={"large"}
            disableRipple
            sx={{ color: theme.palette.common.black }}
          >
            <i className="fa-regular fa-circle-chevron-down" />
          </IconButton>
          <Typography
            sx={{
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
              width: "65%",
            }}
            variant={"h3"}
          >
            {project_name}
          </Typography>
        </Stack>

        <ProfileMenu />
        <IconButton
          onClick={() => rootStore.update_appDrawerToggle(true)}
          sx={{
            display: {
              xs: "flex",
              md: "none",
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      </div>
      <MobileNavBar />

      <SideOverlay
        onClose={handleProjectSwitch}
        show={rootStore.switch_project}
        children={<Projects />}
        title="Projects"
        size={"small"}
      />
    </Stack>
  );
});

export default AppHeader;
