import React, { useEffect, useState } from "react";
import { useStore } from "../helpers";
import { observer } from "mobx-react-lite";
import DropdownTreeSelect from "react-dropdown-tree-select";
import { Controller, useFormContext } from "react-hook-form";
import { FormLabel } from "./utils";
import { FormHelperText } from "@mui/material";
import "./styles.css";
import { safe_get } from "../report-visuals";
import { get_booth_node_by_boothnumber } from "../custom-hooks/useBoothNumber";
import { TreeItem, TreeView } from "@mui/lab";

export const HierarchyDropdown = observer((props: any) => {
  const rootStore = useStore();
  const { projectStore } = rootStore;
  const hierarchy = projectStore.getProjectProperty("hierarchy", []);

  function onChange(currentNode, selectedNodes) {
    // console.log({ currentNode });
    // console.log({ selectedNodes });
    props.onChange(selectedNodes);
  }

  return (
    <div className={"flex flex-col"}>
      <FormLabel>Select Booth</FormLabel>
      <DropdownTreeSelect
        data={hierarchy}
        onChange={onChange}
        className="mdl-demo"
        mode={"radioSelect"}
        inlineSearchInput
        texts={{ placeholder: "Select Booth" }}
      />
    </div>
  );
});

const FTPHierarchyDropDown = ({ label, name }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const rootStore = useStore();
  const { projectStore } = rootStore;
  const hierarchy = projectStore.getProjectProperty("hierarchy", []);
  const [selected, setSelected] = useState(null);
  console.log("re-render");
  return (
    <>
      <div className={"flex flex-col"}>
        <FormLabel>{label}</FormLabel>
        <Controller
          name={name}
          control={control}
          defaultValue={[]} // Initialize as an empty array of selected node IDs
          render={({ field, fieldState: { error } }) => (
            <div>
              <TreeView
                defaultCollapseIcon={<span>▼</span>}
                defaultExpandIcon={<span>▶</span>}
                onNodeSelect={(event, nodeId) => {
                  // Manage selected nodes as an array of node IDs
                  const newValue = field.value.includes(nodeId)
                    ? field.value.filter((id) => id !== nodeId) // Unselect if already selected
                    : [...field.value, nodeId]; // Select if not selected
                  field.onChange(newValue); // Update react-hook-form state
                }}
                // multiSelect // enable multi select
              >
                {/* Render Tree Items Dynamically from Hierarchy Data */}
                {hierarchy.map((node) => (
                  <CustomTreeItem
                    key={node.id}
                    nodeId={String(node.id)}
                    label={node.label}
                    children={node.children}
                  />
                ))}
              </TreeView>
              {error && <FormHelperText error>{error?.message}</FormHelperText>}
            </div>
          )}
        />
      </div>
    </>
  );
};

export default React.memo(FTPHierarchyDropDown);

// Helper component to recursively render tree items
const CustomTreeItem = ({ nodeId, label, children }) => {
  return (
    <TreeItem nodeId={nodeId} label={label}>
      {Array.isArray(children)
        ? children.map((child) => (
            <CustomTreeItem
              key={child.id}
              nodeId={String(child.id)}
              label={child.label}
              children={child.children}
            />
          ))
        : null}
    </TreeItem>
  );
};
