import { observer } from "mobx-react-lite";
import React from "react";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../helpers";
import { Typography } from "@mui/material";

type CardHeaderProps = {
  layout: string;
  icon: string;
  name: string;
  path: string;
};

type mainProp = {
  item: CardHeaderProps;
};

export const CardHeader = observer(({ item }: mainProp) => {
  const { layout = "col", name, icon } = item;
  const navigate = useNavigate();

  return (
    <>
      <div
        className={classNames(
          layout === "graph" ? " flex-col" : "flex-row-reverse items-center",
          "flex justify-between ",
        )}
      >
        <div
          className={classNames(
            layout === "graph" ? "mb-2" : "",
            "lg:w-[67px] lg:h-[67px] rounded-3xl bg-[#1B1B1D]/10 flex justify-center items-center",
          )}
        >
          <i className={`${icon} text-2xl text-gray-700`} />
        </div>
        <Typography variant={"h4"} className={"group-hover:font-semibold"}>
          {name}
        </Typography>
        {/*<h2 className="text-2xl group-hover:font-semibold">*/}
        {/*    {name}*/}
        {/*</h2>*/}
      </div>
    </>
  );
});
