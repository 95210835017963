import React from "react";
import {DashBoard} from "./dashboard-template";

export const TNDeckDashBoard = () => {

    const frame = `<iframe title="TN Final Deck -Scenario Analysis" width="100%" height="100%" src="https://app.powerbi.com/view?r=eyJrIjoiNTY4MDIwODUtNjQzOC00MjU0LThkNzItNGI1ZGVjZWIxNjI4IiwidCI6IjAzZjhjZWI2LTdhMGUtNDcwMy05ZGE0LWUyYTBkYmE1NmM4YyJ9" frameborder="0" allowFullScreen="true"></iframe>`

    return (
        <>
            <DashBoard passCode={"Tamilnadu"} iframe={frame} />
        </>
    )
}