import { useStore } from "../../../../../helpers";
import { useRequestTypeMutation } from "../../../sr-hooks";
import { useTranslation } from "react-i18next";

export const useDeleteLocation = () => {
  const rootStore = useStore();
  const { t } = useTranslation(["location"]);

  const onDeleteLocation = async (payload) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-locations-delete",
      payload,
      t("delete_location_failed"),
    );
  };

  return useRequestTypeMutation(onDeleteLocation, t("delete_location_success"));
};
