import { useState } from "react";
import React from "react";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { classNames, htmlValidation, useStore } from "../helpers";
import { useTranslation } from "react-i18next";
import { theme } from "./DashboardPage";
import { DeleteModal, LoadingState } from "../components";
import { Button, Stack } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { AddNewProject } from "./add-new-project";
import { useFilteredProjects, useIsMobile } from "../custom-hooks";
import { Skeleton } from "@mui/lab";
import { useLocalStorage } from "react-use";
import { safe_get } from "../report-visuals";

const Projects = observer(() => {
  const rootStore = useStore();
  const { userStore, projectStore, voterListStore, digitalArchiveStore } =
    rootStore;
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate();
  const [t] = useTranslation("");
  const [searchQuery, setSearchQUery] = useState("");
  const isMobile = useIsMobile();
  const isRoot = userStore.isRoot();
  const [value] = useLocalStorage("__userprofile", {});
  const projectsLength = safe_get(value, "projects", []);
  const { isLoading, refetch, isRefetching } = useQuery({
    queryKey: ["projects-list"],
    queryFn: async () => await userStore.getProjects(),
    onSuccess: (data) => {
      setProjects(data);
    },
  });

  const deleteProject = async (evt, id) => {
    htmlValidation(evt);
    const res = await projectStore.deleteProject({}, id);
    if (res.status === 200) {
      toast.warn("Project deleted successfully");
    } else toast.error("Something went wrong");
    setOnDeleteProjectClick(false);
    refetch();
  };

  const cancelCB = (evt) => {
    htmlValidation(evt);
    setOnDeleteProjectClick(false);
  };

  const [onDeleteProjectClick, setOnDeleteProjectClick] = useState(false);
  const [activeProject, setActiveProject] = useState({});

  const handle_project_change = async (project) => {
    const parts = window.location.pathname.split("/");
    const idx = parts.indexOf("project") + 1;
    parts[idx] = project["id"];
    const newPath = parts.join("/");
    navigate(newPath);
    await localStorage.setItem("isSigned", "no");
    rootStore.update_switch_project(false);
    // await digitalArchiveStore.update_folder_breadcrumbs([]);
    // await digitalArchiveStore.update_DAStoreProp("activeParentId", "")

    // //TODO: update project id in voter filters
    // const clone_voter_filters = clone(voterListStore.voter_filters);
    // clone_voter_filters["projectid"] = parseInt(project["id"])
    // await voterListStore.update_filters(clone_voter_filters);
  };

  const handleEditClick = (e, project) => {
    htmlValidation(e);
    projectStore.set_addNewProjectModal(true);
    projectStore.update_active_project(project);
  };

  const handleDeleteClick = (e, project) => {
    htmlValidation(e);
    setOnDeleteProjectClick(true);
    setActiveProject(project);
  };

  const { filteredProjects } = useFilteredProjects({ projects, searchQuery });

  return (
    <>
      {isLoading || isRefetching ? (
        <Stack spacing={1}>
          {Array(projectsLength.length)
            .fill("projects")
            .map((val, i) => (
              <Skeleton key={i} variant="rectangular" height={60} />
            ))}
        </Stack>
      ) : (
        <>
          <div className="flex flex-row flex-wrap sm:flex-nowrap items-center justify-between gap-x-2 cursor-pointer text-gray-500 text-xl">
            <input
              value={searchQuery}
              onChange={(evt) => {
                setSearchQUery(evt.target.value);
              }}
              className="border-2 border-gray-300 bg-white h-8 sm:h-12 px-5 rounded-lg focus:outline-none "
              type="search"
              name="search"
              placeholder="Search Projects"
            />
            {isRoot ? (
              <Button
                variant={"contained"}
                onClick={() => {
                  projectStore.set_addNewProjectModal(true);
                  projectStore.update_active_project({});
                }}
                endIcon={<i className="fas fa-plus-circle" />}
              >
                Add New Project
              </Button>
            ) : null}
          </div>

          <div className="mt-5 flex flex-col">
            {filteredProjects.map((project) => {
              return (
                <div
                  key={project["id"]}
                  className="flex flex-col rounded-lg shadow-lg overflow-hidden my-2 group"
                  style={{
                    borderLeft: `4px solid ${theme}`,
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                >
                  <div
                    className="flex-1 bg-white p-2 flex flex-row justify-between items-center cursor-pointer"
                    onClick={() => handle_project_change(project)}
                  >
                    <div className="text-lg text-gray-900 flex-1">
                      {project["name"]}
                    </div>
                    {isRoot ? (
                      <div
                        className={classNames(
                          !isMobile ? "invisible group-hover:visible" : "",
                          "flex flex-row flex-wrap space-x-4 bg-white px-3 text-sm "
                        )}
                      >
                        <a
                          className={`text-blue-500 cursor-pointer transition-opacity duration-800`}
                          onClick={(evt) => handleEditClick(evt, project)}
                        >
                          Edit
                        </a>
                        <a
                          className={`text-blue-500 cursor-pointer transition-opacity duration-800 `}
                          onClick={(evt) => handleDeleteClick(evt, project)}
                        >
                          Delete
                        </a>
                      </div>
                    ) : null}
                    <div className="text-gray-400 text-sm ml-auto text-right">
                      {project["id"]}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}

      <AddNewProject />
      <DeleteModal
        onDeleteClick={deleteProject}
        onCancelClick={cancelCB}
        isOpen={onDeleteProjectClick}
        id={activeProject["id"]}
        desc={`Do you want to Delete ${activeProject["name"]} Project ?`}
      />
    </>
  );
});

export default React.memo(Projects);
