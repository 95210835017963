import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { AddRequests } from "./add-beneficiary-requests";
import { RenderBeneficiaryRequests } from "./render-beneficiary-requests";
import { AddFamilyMembers } from "./add-family-members";
import { RenderFamilyMembers } from "./render-family-members";
import { safe_get } from "../../report-visuals";
import {
  classNames,
  makeid_alpa_numeric,
  remove_item_from_array,
} from "../../helpers";
import { Switch } from "@headlessui/react";
import Select from "react-select";
import { ProfileFormField } from "./beneficiary-utils";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { AddOfficials } from "./add-officials";
import { get_booth_node_by_boothnumber } from "../../custom-hooks/useBoothNumber";
import { BoothTree, FormButtons } from "../../components";
import { Button } from "@mui/material";
import { error_toast, useStore } from "../../helpers";
import dayjs from "dayjs";
import { AddFamilymembersFromVoterList } from "../beneficiary-management-v2";

const genders = ["Male", "Female", "Third Gender"];

export const label_style =
  "block font-bold text-gray-600 flex-2 w-48 sm:mt-px sm:py-1 text-sm";
export const input_style =
  "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-2";
export const dropdown_style =
  "block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm mb-2";

export const AddBeneficiary = observer(() => {
  const { t } = useTranslation(["beneficiary", "voter_list"]);
  const rootStore = useStore();
  const {
    beneficiaryStore,
    flexiStore,
    voterListStore,
    projectStore,
    userStore,
  } = rootStore;
  const { activeBeneficiary, beneficiary_edit_mode, activeRequest } =
    beneficiaryStore;
  const { activeVoter, family_members } = voterListStore;
  const { projectid } = useParams();
  const [concernedPersons, setConcernedPersons] = useState([]);

  const beneficiary_props = projectStore.getProjectProperty("beneficiary", {});
  const village_selector = safe_get(
    beneficiary_props,
    "more.village_selector",
    "piv_level2"
  );
  const booth_selector = safe_get(
    beneficiary_props,
    "more.booth_selector",
    "piv_level2"
  );
  const caste = useRef([]);
  const isParlimentary = safe_get(
    beneficiary_props,
    "more.isParlimentaryConstituency",
    false
  );
  const representatives = safe_get(
    beneficiary_props,
    "more.representatives",
    []
  );
  const hierarchy = projectStore.getProjectProperty("hierarchy", []);

  useEffect(() => {
    const project_props = projectStore.getProjectProperty(
      "profile_questions",
      {}
    );
    const esindex = projectStore.getProjectProperty("voterrecords_v2", {});
    setConcernedPersons(safe_get(beneficiary_props, "owners", []));
    caste.current = safe_get(project_props, "caste", []);
  }, []);

  const IProfile = {
    name: activeVoter?.profile?.name_eng,
    phone: safe_get(activeVoter, "phone", ""),
    whatsapp: safe_get(activeVoter, "whatsapp", ""),
    voter_id: activeVoter?.profile?.voterid,
    aadhar_id: "",
    address: activeVoter?.profile?.address,
    caste:
      "caste" in activeVoter
        ? activeVoter.caste
        : safe_get(activeVoter?.profile, "caste", null),
    dob: activeVoter?.profile?.dob,
    age: safe_get(activeVoter["profile"], "age", "N/A"),
    booth_number: safe_get(
      activeVoter,
      "part",
      safe_get(activeBeneficiary["summary"], "profile.booth_number", {})
    ),
    village: safe_get(
      activeVoter,
      "part",
      safe_get(activeBeneficiary["summary"], "profile.village", {})
    ),
    gender: safe_get(activeVoter["profile"], "gender", "N/A"),
    assembly: safe_get(activeVoter["profile"], "assembly", ""),
    representatives: safe_get(activeVoter["profile"], "representatives", ""),
    is_outsider: safe_get(activeVoter["profile"], "is_outsider", false),
    polling_station: safe_get(activeVoter["profile"], "booth_address", "N/A"),
    ac_name: "",
    oacv_name: "",
    oacv_phone: "",
  };

  const [addedRequests, setAddedRequests] = useState([]);
  const [addedFamilyMembers, setAddedFamilyMembers] = useState([]);

  const [profile, setProfile] = useState(IProfile);
  const [buffer, setBuffer] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm({ defaultValues: profile });
  useEffect(() => {
    if (beneficiary_edit_mode) {
      setProfile(safe_get(activeBeneficiary["summary"], "profile", IProfile));
      reset(safe_get(activeBeneficiary["summary"], "profile", IProfile));
      setAddedRequests(safe_get(activeBeneficiary["r1"], "requests", []));
      setAddedFamilyMembers(safe_get(activeBeneficiary["r1"], "family", []));
    }
  }, [beneficiary_edit_mode]);

  useEffect(() => {
    if (!beneficiary_edit_mode) {
      const dup = [...addedFamilyMembers, ...family_members];
      const pp = dup.filter(
        (ele, ind) => ind === dup.findIndex((elem) => elem["id"] === ele["id"])
      );
      setAddedFamilyMembers(pp);
    }
  }, [family_members]);

  const [logs, setLogs] = useState(
    beneficiary_edit_mode ? safe_get(activeBeneficiary.r1, "logs", []) : []
  );

  const onSubmit = async (values) => {
    const payload = {
      key: "beneficiary",
      renderkey: "beneficiary",
      properties: {
        projectid: parseInt(projectid),
        summary: {
          profile: profile,
          tracing_id: `P${projectid}B`,
        },
        r1: {
          logs: logs,
          family: addedFamilyMembers,
          requests: addedRequests,
        },
      },
    };
    if (beneficiary_edit_mode) {
      flexiStore.update_current_fsd("summary", payload.properties.summary);
      flexiStore.update_current_fsd("r1", payload.properties.r1);
      flexiStore.set_loading(true);
      const res = await flexiStore.api_update_current_fsd();

      try {
        if (res.status === 200) {
          toast.success("Beneficiary details have been updated.");
          flexiStore.set_loading(false);
          voterListStore.update_family_members([]);
        } else {
          error_toast();
          flexiStore.set_loading(false);
        }
      } catch (e) {
        error_toast();
        flexiStore.set_loading(false);
      }
      beneficiaryStore.update_show_benefic(false);
    } else {
      flexiStore.set_loading(true);
      const response = await beneficiaryStore.createBeneficiary(payload);
      try {
        if (response.status === 200) {
          toast.success("New Beneficiary added and saved successfully.");
          flexiStore.set_loading(false);
          voterListStore.setSelectedVoterData({});
        } else {
          error_toast();
          flexiStore.set_loading(false);
        }
      } catch (e) {
        flexiStore.set_loading(false);
      }
    }

    beneficiaryStore.update_show_benefic(false);
    beneficiaryStore.update_add_beneficiary_modal(false);
    await voterListStore.update_active_voter({});
    const res = await flexiStore.fetch_fsdlist(
      "beneficiary",
      parseInt(projectid)
    );
    if (res.status === 200) {
      beneficiaryStore.set_beneficiries(res.data["results"]);
    } else {
      error_toast();
    }
  };

  const profileOnChange = (val, field_name) => {
    setProfile({ ...profile, [field_name]: val });
  };

  const onFamilyMemberAdd = (member) => {
    beneficiaryStore.update_add_familymember_modal(false);

    let tempReq = [...addedFamilyMembers];
    let idx = tempReq.findIndex((ele) => ele.id === member.id);
    if (idx > -1) {
      // tempReq.splice(idx, 1, member);
      tempReq[idx] = member;
    } else {
      tempReq.push(member);
    }
    setAddedFamilyMembers([...tempReq]);
  };

  const loggedin_user = {
    //current user info
    name: userStore.user.profile.name,
    phone: userStore.user.phonenum,
    address: userStore.user.profile.address,
    profileImage: userStore.user.profileimageuri,
  };

  const onAddRequest = (request) => {
    beneficiaryStore.update_add_request_modal(false);
    let tempReq = [...addedRequests];
    let idx = tempReq.findIndex((ele) => ele.id === request.id);
    const time_stamp = dayjs().format("DD/MM/YYYY HH:mm:ss");

    if (idx !== -1) {
      tempReq.splice(idx, 1, request);
    } else {
      tempReq.push(request);
    }
    const new_log = {
      id: makeid_alpa_numeric(3),
      status: request.status,
      req_title: request.type,
      comments: !idx
        ? `Request has been updated - ${request.additional}`
        : `Request has been created - ${request.additional}`,
      ts: time_stamp,
      who: loggedin_user,
    };
    setLogs([...logs, new_log]);
    setAddedRequests([...tempReq]);
  };

  const deleteRequestHandler = (val) => {
    const updated_values = remove_item_from_array(addedRequests, "id", val);
    setAddedRequests(updated_values);
  };

  const deleteFamilyMemberHandler = (val) => {
    const updated_values = remove_item_from_array(
      addedFamilyMembers,
      "id",
      val
    );
    setAddedFamilyMembers(updated_values);
  };

  const onOfficialAdd = (data) => {
    setConcernedPersons([...concernedPersons, { ...data }]);
  };

  const get_booth_address = () => {
    const node = get_booth_node_by_boothnumber(profile.booth_number);
    return safe_get(node, "booth_address", "");
  };

  const searchVotersHandler = async () => {
    voterListStore.setShowVoterlist(true);
    beneficiaryStore.update_add_beneficiary_modal(false);
    const clone = _.clone(voterListStore.voter_filters);
    clone["projectid"] = parseInt(projectid);
    await voterListStore.update_filters(clone);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="flex h-full flex-col">
        <div className="flex flex-col flex-1">
          <div className="py-3 flex flex-row justify-between items-center">
            <div className="text-indigo-500 font-bold text-xl overlay-text">
              {t("beneficiary_information")}
            </div>
            <Button
              onClick={searchVotersHandler}
              variant={"contained"}
              endIcon={<i className="fas fa-search" />}
              size={"small"}
            >
              {t("search_voters")}
            </Button>
          </div>

          <ProfileFormField
            register={register}
            field_name={"name"}
            value={profile.name}
            label={t("name")}
            onChangeHandler={profileOnChange}
            required={true}
            error={errors}
            error_msg={t("name_required")}
          />
          <ProfileFormField
            register={register}
            field_name={"phone"}
            value={profile.phone}
            label={t("phone_number")}
            onChangeHandler={profileOnChange}
            required={true}
            error={errors}
            error_msg={t("number_required")}
          />
          <ProfileFormField
            register={register}
            field_name={"whatsapp"}
            value={profile.whatsapp}
            label="Whatsapp Number"
            onChangeHandler={profileOnChange}
            required={false}
            error={errors}
            error_msg={""}
          />

          <ProfileFormField
            register={register}
            field_name={"voter_id"}
            value={profile.voter_id}
            label={t("voter_iD")}
            onChangeHandler={profileOnChange}
            required={true}
            error={errors}
            error_msg={t("voter_required")}
          />
          <ProfileFormField
            register={register}
            field_name={"aadhar_id"}
            value={profile.aadhar_id}
            label={t("aadhar_id")}
            onChangeHandler={profileOnChange}
            required={false}
            error={errors}
            error_msg={""}
          />
          {isParlimentary && (
            <div className="flex flex-col space-y-2">
              <div className="flex flex-row justify-between items-center">
                <label className={label_style}>{"Assembly Constituency"}</label>
                <Select
                  name="assembly"
                  options={hierarchy}
                  value={hierarchy.find(
                    (node) => node.value === profile.assembly
                  )}
                  onChange={(val) => {
                    console.log(val);
                    setProfile({ ...profile, assembly: val?.value });
                  }}
                  placeholder="Select Assembly Constituency..."
                  className={dropdown_style}
                  classNamePrefix="select"
                  required={true}
                />
              </div>

              {representatives.length > 0 && (
                <div className="flex flex-row justify-between items-center mb-2">
                  <label className={label_style}>{"Representative Type"}</label>
                  <Select
                    name="Representative Type"
                    options={representatives.map((rep) => ({
                      label: rep,
                      value: rep,
                    }))}
                    value={
                      profile.representatives
                        ? {
                            label: profile.representatives,
                            value: profile.representatives,
                          }
                        : null
                    }
                    onChange={(val) => {
                      console.log(val);
                      setProfile({ ...profile, representatives: val?.value });
                    }}
                    placeholder="Select Representative Type..."
                    className={dropdown_style}
                    classNamePrefix="select"
                    required={true}
                  />
                </div>
              )}
            </div>
          )}

          {!profile.is_outsider && (
            <>
              <div className="flex flex-row items-center justify-center mb-2">
                <label className={label_style}>{t("booth_number")}</label>
                <BoothTree
                  onBoothSelection={(data) => {
                    console.log(data);
                    setProfile({ ...profile, booth_number: data });
                  }}
                  selected_booth={profile.booth_number}
                  selector={booth_selector}
                />
              </div>

              <div className="flex flex-row items-center justify-center mb-2">
                <label className={label_style}>{t("Village")}</label>
                <BoothTree
                  onBoothSelection={(data) => {
                    setProfile({ ...profile, village: data });
                  }}
                  selected_booth={profile.village}
                  isMulti={false}
                  selector={village_selector}
                />
              </div>
            </>
          )}

          {get_booth_address() && (
            <div className="flex flex-row justify-between items-center">
              <label className={label_style}> {t("polling_station")}</label>
              <p className={input_style}>{get_booth_address()}</p>
            </div>
          )}

          <div className="flex flex-row justify-between items-center mb-2">
            <label className={label_style}>{t("date_of_birth")}</label>
            <input
              type="date"
              className={input_style}
              value={profile.dob}
              onChange={(e) => {
                profileOnChange(e.target.value, "dob");
              }}
            />
          </div>
          <ProfileFormField
            register={register}
            field_name={"age"}
            value={profile.age}
            label={t("age", { ns: "voter_list" })}
            onChangeHandler={profileOnChange}
            required={true}
            error={errors}
            error_msg={t("age_req")}
          />
          <ProfileFormField
            register={register}
            field_name={"address"}
            value={profile.address}
            label={t("address")}
            onChangeHandler={profileOnChange}
            required={true}
            error={errors}
            error_msg={t("address_required")}
          />
          <div className="flex flex-row justify-between items-center mb-2">
            <label className={label_style}> {t("gender")}</label>
            <Select
              name="gender"
              options={genders.map((g) => ({
                label: g,
                value: g,
              }))}
              value={{ label: profile.gender, value: profile.gender }}
              onChange={(val) => {
                setProfile({ ...profile, gender: val?.value });
              }}
              placeholder="Select Gender..."
              className={dropdown_style}
              classNamePrefix="select"
              required={true}
            />
          </div>
          <div className="flex flex-row justify-between items-center mb-2">
            <label className={label_style}>{t("caste")}</label>
            <Select
              name="caste"
              options={caste.current}
              value={{ label: profile.caste, value: profile.caste }}
              onChange={(val) => {
                setProfile({ ...profile, caste: val?.value });
              }}
              placeholder="Select Caste..."
              className={dropdown_style}
              classNamePrefix="select"
              required={true}
            />
          </div>

          <div className="my-2">
            <Switch.Group as="div" className="flex items-center">
              <Switch.Label as="span" className={label_style} passive>
                {t("other_voters")}
              </Switch.Label>
              <Switch
                checked={profile.is_outsider}
                onChange={() => {
                  setProfile((prevProfile) => ({
                    ...prevProfile,
                    is_outsider: !prevProfile.is_outsider,
                    booth_number: !prevProfile.is_outsider
                      ? ""
                      : prevProfile.booth_number,
                    village: !prevProfile.is_outsider
                      ? ""
                      : prevProfile.village,
                  }));
                }}
                className={classNames(
                  profile.is_outsider ? "bg-indigo-600" : "bg-gray-200",
                  "relative inline-flex mx-4 h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    profile.is_outsider ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          {profile.is_outsider && (
            <div>
              <ProfileFormField
                register={register}
                field_name={"ac_name"}
                value={profile.ac_name}
                label="AC Name"
                onChangeHandler={profileOnChange}
                required={false}
                error={errors}
                error_msg={""}
              />
              <ProfileFormField
                register={register}
                field_name={"oacv_name"}
                value={profile.oacv_name}
                label={t("other_ac_name")}
                onChangeHandler={profileOnChange}
                required={true}
                error={errors}
                error_msg={t("name_required")}
              />
              <ProfileFormField
                register={register}
                field_name={"oacv_phone"}
                value={profile.oacv_phone}
                label={t("other_ac_number")}
                onChangeHandler={profileOnChange}
                required={true}
                error={errors}
                error_msg={t("number_required")}
              />
            </div>
          )}

          <div>
            <div>
              <AddRequests
                onRequestAddHandler={onAddRequest}
                buffer={buffer}
                setBuffer={setBuffer}
                requestState={activeRequest}
                concernedPersons={concernedPersons}
              />
              {addedRequests.length > 0 && (
                <RenderBeneficiaryRequests
                  requests={addedRequests}
                  edit={true}
                  deleteRequest={deleteRequestHandler}
                />
              )}
            </div>
            <div>
              <AddFamilyMembers onFamilyMemberAddHandler={onFamilyMemberAdd} />
              <RenderFamilyMembers
                family_members={addedFamilyMembers}
                edit={true}
                deleteRequest={deleteFamilyMemberHandler}
              />

              <AddFamilymembersFromVoterList />
            </div>
            <div
              className={
                "underline font-bold px-4 py-2 self-end cursor-pointer fp-text"
              }
              onClick={(e) =>
                beneficiaryStore.update_add_official_modal(
                  !beneficiaryStore.add_official_modal
                )
              }
            >
              + {t("add_concerned_office")}
            </div>

            <AddOfficials onOfficialAdd={onOfficialAdd} />
          </div>
        </div>

        <FormButtons
          onSave={(e) => {}}
          onCancel={async (e) => {
            beneficiaryStore.update_add_beneficiary_modal(false);
            await voterListStore.setSelectedVoterData({});
            await voterListStore.update_active_voter({});
          }}
        />
      </form>
    </>
  );
});
