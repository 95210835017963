import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FormButtons, TextError } from "../../../components";

export const AddEditTarget = observer((props: any) => {
  const rootStore = useStore();
  const params = useParams();
  const { voterReachOutStore } = rootStore;
  const { TargetFormValue } = voterReachOutStore;
  const projectId = params.projectid;

  const textMessageFormInitialValues = {
    name: TargetFormValue.name,
    text: TargetFormValue.text,
  };

  const formSubmit = async (payload) => {
    const res = await voterReachOutStore.update_reachout_target(payload);
    if (res.status === 200) {
      toast.success("Text message added successfully");
      props.getReachOutTarget();
      voterReachOutStore.isEditOverlayOpen = false;
    }
  };

  const textMessageFormSubmit = async (values) => {
    const smsPayload = {
      id: TargetFormValue.id,
      projectid: projectId,
      type: "static",
      name: values.name,
      text: values.text,
      src: {},
      more: {},
    };
    formSubmit(smsPayload);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    text: Yup.string().required("Message is required"),
  });
  return (
    <Formik
      initialValues={textMessageFormInitialValues}
      onSubmit={textMessageFormSubmit}
      validationSchema={validationSchema}
    >
      <Form
        className="rounded-lg text-center"
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        <div
          className={
            "form-control sm:grid sm:grid-cols  sm:items-start d-flex flex-column text-left"
          }
        >
          <label
            htmlFor="name"
            className="block text-gray-700  rounded-lg text-left font-semibold"
          >
            Name{" "}
          </label>
          <div className="flex flex-row items-center">
            <Field
              type="text"
              id="name"
              name="name"
              className="px-4 py-2 rounded-lg border border-gray-300 w-full"
            />
          </div>
          <div className="flex flex-row">
            <ErrorMessage name="name" component={TextError} />
          </div>
        </div>
        <div className=" sm:grid sm:grid-cols-1 sm:items-start d-flex flex-column text-left">
          <label
            htmlFor="message"
            className="block text-gray-700  rounded-lg text-left font-semibold"
          >
            Phone Numbers
          </label>
          <div className="flex flex-row items-center min-h-80 max-h-full">
            <Field
              as="textarea"
              id="text"
              name="text"
              className="px-4 py-2 rounded-lg border border-gray-300 w-full h-40"
              // style={{height:"40%"}}
              // disabled
            />
          </div>
          <div className="flex flex-row">
            <ErrorMessage name="text" component={TextError} />
          </div>
        </div>
        <FormButtons
          onSave={() => {}}
          onCancel={() => {
            voterReachOutStore.set_isEditOverlay(false);
          }}
        />
      </Form>
    </Formik>
  );
});
