import React from "react";
import { useTranslation } from "react-i18next";

export const QRCodeLogin = () => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col mt-6 w-full max-w-[480px] gap-y-2 sm:px-6 md:px-8 lg:px-0">
      <div className="flex flex-col mb-2">
        <p className="text-lg leading-xl font-normal text-[#1B1B1D] mb-2">
          {t("scanQRCode")}
        </p>
      </div>
      <div className="flex w-full">
        <img
          src="/img/qr_code.png"
          alt="QR Code"
          className="w-[180px] h-[180px] sm:w-[150px] sm:h-[150px] xs:w-[120px] xs:h-[120px]"
        />
      </div>
    </div>
  );
};
