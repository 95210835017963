export const formatHashtagData = (hashtagWordcloud: Record<string, any>) => {
  if (!hashtagWordcloud || typeof hashtagWordcloud !== "object") return [];

  const formattedData = Object.entries(hashtagWordcloud).map(([hashtag, value]) => ({
    hashtag,
    frequency: Array.isArray(value) && value.length > 1 ? value[1] : 0,
  }));

  const groupedData = formattedData.reduce((acc, { hashtag, frequency }) => {
    acc[frequency] = acc[frequency] || [];
    acc[frequency].push(hashtag);
    return acc;
  }, {} as Record<number, string[]>);

  return Object.entries(groupedData)
    .sort(([a], [b]) => Number(b) - Number(a))
    .map(([frequency, hashtags]) => ({
      frequency,
      hashtags: hashtags.join(", "),
    }));
};
