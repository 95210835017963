import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useRef } from "react";
import { useStore } from "../../helpers";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { safe_get } from "../../report-visuals";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { LoadingState } from "../../components";
import { useGetFullProject } from "../../custom-hooks";
import { useQuery } from "@tanstack/react-query";
import { default_web_features } from "./web-featues";
import { FTPBreadCrumbs } from "../../components-new";
import ClientProfile from "../../components/client-profile";
import { RenderFeatureCard } from "./render-feature-card";
import { useShowClientProfile } from "./useShowClientProfile";

export const NOTIFICATIONS = [
  {
    date: "15 Jan",
    heading: "Minister Inaugurates Auto-Rickshaw Stand in Mumbai",
    news: "A new auto-rickshaw stand was inaugurated by a state minister in Mumbai.The stand aims to improve public transportation and reduce traffic congestion in the area.",
  },
  {
    date: "18 Feb",
    heading: "Education Reform Program Unveiled in Chennai",
    news: "A comprehensive education reform program was introduced to enhance learning outcomes and provide better infrastructure in schools across the city.",
  },
  {
    date: "05 Mar",
    heading: "Solar Energy Project Launched in Rajasthan",
    news: "A new solar energy initiative was inaugurated to boost renewable energy production and reduce carbon footprints in the region.",
  },
  {
    date: "12 Apr",
    heading: "Skill Development Workshop Held in Hyderabad",
    news: "A free skill development workshop was conducted to empower youth with technical and vocational training opportunities.",
  },
  {
    date: "25 May",
    heading: "Clean India Drive Organized in Kolkata",
    news: "A large-scale cleanliness drive was organized to promote hygiene and environmental awareness among citizens.",
  },
  {
    date: "15 Jun",
    heading: "Healthcare Awareness Campaign in Chandigarh",
    news: "A healthcare awareness campaign was launched to educate people about preventive measures and the importance of regular health check-ups.",
  },
  {
    date: "20 Jul",
    heading: "Affordable Housing Scheme Announced in Lucknow",
    news: "An affordable housing project was announced to provide homes for underprivileged families in the city.",
  },
  {
    date: "10 Aug",
    heading: "Road Safety Awareness Week Kicked Off in Jaipur",
    news: "The city observed Road Safety Awareness Week with workshops and seminars to reduce road accidents and improve traffic behavior.",
  },
  {
    date: "01 Sep",
    heading: "Tree Plantation Drive Launched in Bengaluru",
    news: "A tree plantation initiative was launched to increase green cover and combat urban pollution in the metropolitan area.",
  },
];

const ProjectDashboardPage = observer((props) => {
  const rootStore = useStore();
  const navigate = useNavigate();
  const { projectid } = useParams();

  const {
    userStore,
    projectStore,
    surveyResponseStore,
    digitalArchiveStore,
    flexiStore,
  } = rootStore;

  const role = userStore.user["role"];
  const [t] = useTranslation("ProjectDashboard");
  const track_voter_record_config = useRef({});
  const show_voter_reg_card = useRef(false);
  const view_task_permission = useRef(false);
  const { isLoading } = useGetFullProject();
  const { showClientProfile } = useShowClientProfile();

  // Permissions for different project features.
  const view_beneficiary_permission = projectStore.getPermission(
    "manage_fs_beneficiary",
    false
  );
  const view_grievance_permission = projectStore.getPermission(
    "manage_fs_grievance",
    false
  );
  const view_projectworks_permission = projectStore.getPermission(
    "manage_fs_projectworks",
    false
  );

  const fetchData = async () => {
    return await rootStore.api.post("project/myprojects", {});
  };

  const { isLoading: projectsLoading } = useQuery({
    queryKey: ["projects-list"],
    queryFn: fetchData,
    onSuccess: (data) => {
      if (!projectid || projectid === "undefined") {
        const default_project_id = safe_get(data.data[0], "id", "1");
        navigate(`project/${default_project_id}`, { replace: true });
      }
    },
    enabled: projectid === "undefined" || projectid === undefined,
  });

  // Get project-specific web features or fallback to default features.
  const webFeatures = projectStore.getProjectProperty(
    "web_features",
    default_web_features
  );

  const isRootOrClient = userStore.isRoot() || userStore.isClient();

  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: true },
  ];

  useEffect(() => {
    if (!isLoading) {
      // Fetch configuration for tracking voter records and set related states.
      track_voter_record_config.current = projectStore.getProjectProperty(
        "track_voter_reg_eci",
        {}
      );
      show_voter_reg_card.current = safe_get(
        track_voter_record_config,
        "enabled",
        false
      );
      view_task_permission.current = projectStore.getPermission(
        "view_tasks",
        false
      );

      // Reset filters and digital archive store properties.
      surveyResponseStore.reset_new_filters();
      digitalArchiveStore.update_DAStoreProp("activeParentId", "");
    }

    // Reset page number in flexiStore whenever the project changes.
    flexiStore.reset_fsd_search();
  }, [projectid]);

  // useMemo hook to memoize the menu list generation based on project features.
  const populateMenuList = useMemo(() => {
    const pm = { id: 1, heading: t("project_management"), subItems: [] };
    const fw = { id: 2, heading: t("field_work"), subItems: [] };
    const cm = { id: 3, heading: t("management"), subItems: [] };

    // Loop through each feature in webFeatures and populate the menu sections based on the feature status.
    for (let key of Object.keys(webFeatures)) {
      const val = webFeatures[key];
      if (val === "enabled") {
        switch (key) {
          case "beneficiary":
            if (view_beneficiary_permission || isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-hands-helping",
                name: t("beneficiary"),
                path: "/project/" + projectid + "/beneficiary_management",
              });
            }
            break;
          case "grievance":
            if (view_grievance_permission || isRootOrClient) {
              cm.subItems.push({
                icon: "fas fa-list-ul",
                name: t("grievance"),
                path: "/project/" + projectid + "/grievance_management",
              });
            }
            break;
          case "service_requests":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fas fa-list-ul",
                name: "Service Requests",
                path: "/project/" + projectid + "/servicerequests",
              });
            }
            break;
          case "grievance_management_v2":
            if (view_grievance_permission || isRootOrClient) {
              cm.subItems.push({
                icon: "fas fa-face-sad-tear",
                name: "Grievance management V2",
                path: "/project/" + projectid + "/grievance_management_v2",
              });
            }
            break;
          case "project_works":
            if (isRootOrClient || view_projectworks_permission) {
              cm.subItems.push({
                icon: "fa-solid fa-diagram-project",
                name: t("project"),
                path: "/project/" + projectid + "/project_works",
              });
            }
            break;
          case "c3":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-grid-horizontal",
                name: t("command"),
                path: "/project/" + projectid + "/c3",
              });
            }
            break;
          case "analytical_dashboard":
            if (isRootOrClient && projectid == "621") {
              cm.subItems.push({
                icon: "fa-solid fa-chart-simple",
                name: "Analytial Dashboard",
                path: "/dashboard/kopargaon",
              });
            }
            break;
          case "manage_voters":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fas fa-poll-people",
                name: t("manage"),
                path: "/project/" + projectid + "/manage-voters",
              });
            }
            break;
          case "voter_reach_out":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-comments",
                name: t("reach"),
                path: "/project/" + projectid + "/voter_reach_out",
              });
            }
            break;
          case "election_day_dashboard":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-table",
                name: t("election"),
                path: "/project/" + projectid + "/election_day_dashboard",
              });
            }
            break;
          case "voter_registration_tracker":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-person-booth",
                name: t("tracker"),
                path: "/project/" + projectid + "/voter_registration_tracker",
              });
            }
            break;
          case "digital_repository":
            if (isRootOrClient) {
              cm.subItems.push({
                icon: "fa-solid fa-person-booth",
                name: t("Digital Repository"),
                path: "/project/" + projectid + "/digital_repository",
              });
            }
            break;
          case "booth_selection":
            if (isRootOrClient) {
              fw.subItems.push({
                icon: "fa-solid fa-person-booth",
                name: "Booth Selection",
                path: "/project/" + projectid + "/booth_selection",
              });
            }
            break;
          case "field_payments":
            const WHITELIST_FOR_USERS = [
              9669, 9174, 74, 1, 92, 10693, 292, 11717, 11780,
            ];
            const has_permission = WHITELIST_FOR_USERS.includes(
              userStore.user.id
            );
            if (has_permission) {
              fw.subItems.push({
                icon: "fas fa-wallet",
                name: t("field_pay"),
                path: "/project/" + projectid + "/fieldpayment",
              });
            }
            break;
          case "field_team_report":
            fw.subItems.push({
              icon: "fas fa-file-chart-line",
              name: t("team_report"),
              path: "/project/" + projectid + "/fieldteamreport",
            });
            break;
          case "taskmanager":
            // Adding Taskmanager card if the user has permission to view tasks or is root/client.
            if (
              view_task_permission.current ||
              role === "root" ||
              role === "client"
            ) {
              fw.subItems.push({
                icon: "fa-solid fa-list-check",
                name: t("task"),
                path: "/project/" + projectid + "/taskmanager",
              });
            }
            break;
          // case "manage_project":
          //   // Only root users can access project management.
          //   if (userStore.isRoot() || userStore.user.id === 16003) {
          //     pm.subItems.push({
          //       icon: "fa-solid fa-cogs",
          //       name: t("manage_project"),
          //       path: `/project/${projectid}/manage`,
          //     });
          //   }
          //   break;
          case "user_management":
            if (userStore.isRoot() || userStore.isClient()) {
              pm.subItems.push({
                icon: "fa-solid fa-users",
                name: t("user_manage"),
                path: "/project/" + projectid + "/usermanagement",
              });
            }
            break;
          case "beneficiary_v2":
            if (userStore.isRoot() || userStore.isClient()) {
              cm.subItems.push({
                icon: "fa-solid fa-users",
                name: "Beneficiary V2",
                path: "/project/" + projectid + "/beneficiary_v2",
              });
            }
            break;
          case "project_works_v2":
            if (userStore.isRoot() || userStore.isClient()) {
              cm.subItems.push({
                icon: "fa-solid fa-users",
                name: "PW V2",
                path: "/project/" + projectid + "/projectworks_v2",
              });
            }
            break;
          case "influential_leaders":
            if (userStore.isRoot() || userStore.isClient()) {
              cm.subItems.push({
                icon: "fas fa-user-friends",
                name: "Influential Leaders",
                path: "/project/" + projectid + "/influential_leaders",
              });
            }
            break;
          // Additional cases can be added here as needed.
        }
      }
    }
    return [cm, fw, pm];
  }, [webFeatures, projectid]);

  if (isLoading) return <LoadingState />;

  return (
    <div className="w-[100%] flex flex-col sm:flex-row gap-4">
      <div className={`${showClientProfile ? "w-full sm:w-[75%]" : "w-full"}`}>
        <FTPBreadCrumbs crumbs={crumbs} />
        <div className="px-4 flex flex-col gap-y-2 sm:gap-y-6 ">
          {!isLoading &&
            _.sortBy(populateMenuList, (item) => item.id).map(
              (feature, index) => (
                <RenderFeatureCard key={index} feature={feature} />
              )
            )}
        </div>
      </div>
      {showClientProfile ? (
        <div className="w-full sm:w-[25%]">
          <ClientProfile
            profileName="Sharath Kumar Bache Gowda"
            profileDescription=" A seasoned politician known for their unwavering dedication to public service. With a proven track record of delivering results, they continue to inspire hope and progress in the community."
            notifications={NOTIFICATIONS}
          />
        </div>
      ) : null}
      {/* Render any nested routes */}
      <Outlet />
    </div>
  );
});

export default ProjectDashboardPage;
