import { useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import React, { useMemo } from "react"
import { useStore } from "../../helpers";
import { useTranslation } from "react-i18next";
import _ from "lodash"
import {CMTable, LoadingState} from "../../components";
import {RenderRequestStatus} from "../beneficiary-management";
import {DataNotAvailable} from "../../flexistore/renderkeys/mh-2024-field";
import {safe_get} from "../../report-visuals";

export const GrievanceTable = observer((props: any) => {

    const navigate = useNavigate()
    const { projectid } = useParams()
    const rootStore = useStore();
    const { flexiStore, grievanceStore } = rootStore
    const {fsd_search_page_size} = flexiStore;
    const { isLoading, grievanceList } = grievanceStore
    const { t } = useTranslation(["grievance_management", "field_payment", "Login", "beneficiary"]);

    const filtered_list = _.filter(grievanceList, x => !_.isEmpty(x.summary));

    const columns = useMemo(() => [
        {
            Header: t("tracking_id", {ns: "beneficiary"}),
            accessor: 'id',
            Cell: props => {
                return <p>P{projectid}G{props.cell.value}</p>
            }
        },
        {
            Header: t("date", {ns: "beneficiary"}),
            accessor: 'summary.ts_create',
        },
        {
            Header: t("name", { ns: "field_payment" }),
            accessor: 'summary.reported_by.name',
        },
        {
            Header: t("phone_number", { ns: "Login" }),
            accessor: 'summary.reported_by.phone',
        },
        {
            Header: t("title"),
            accessor: 'summary.title',
        },
        {
            Header: t("department"),
            accessor: 'summary.category_level_1',
        },
        {
            id: "location",
            Header: t("location"),
            accessor: 'summary.piv_level_2',
            Cell: ({ value }) => {
                if (Array.isArray(value)) {
                    return value.map(item => item.label).join(", ");
                }
                return "";
            }
        },
        // {
        //     Header: t("description"),
        //     accessor: 'summary.description',
        // },
        {
            Header: t("status"),
            accessor: 'summary.current_status',
        },
        {
            Header: t("priority"),
            accessor: 'summary.priority',
        },
        {
            id: "additional_information",
            Header: t("concerned_office_person"),
            accessor: 'summary.assigned_office_person',
            Cell: props => {
                const value = props.cell.value;
                return (
                    <>
                        <span>{safe_get(value, "name", "")}</span>
                    </>
                )
            }
        }
    ], [grievanceList])

    const row_click_handler = (item) => {
        navigate(`/project/${projectid}/grievance_management/${item.id}/view`)
    }

    return (
        <>
            {isLoading && <LoadingState />}
            {filtered_list && filtered_list.length > 0  ?
                <CMTable
                    data={filtered_list}
                    columns={columns}
                    pagesize={fsd_search_page_size}
                    onRowClick={row_click_handler}
                    hideFooter={true} highlightRow={true}
                /> : <DataNotAvailable />
            }
        </>
    )
})