import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { useStore } from "../../../helpers";

export const useServiceRequestsMeta = () => {
  const { projectid } = useParams();
  const { serviceRequestsStore } = useStore();

  return useQuery({
    queryKey: ["srmeta", projectid],
    queryFn: async () => {
      return serviceRequestsStore.getServicesMetaData({
        projectid: parseInt(projectid),
      });
    },
    onSuccess: (data) => {
      serviceRequestsStore.set_meta(data?.data);
    },
  });
};
