import {safe_get} from "../../report-visuals";
import {useStore} from "../../helpers";


export const useShowClientProfile = () => {

    const {projectStore} = useStore();

    const clientProfileConfig = projectStore.getProjectProperty(
        "client_profile",
        {}
    );
    const showClientProfile = safe_get(
        clientProfileConfig,
        "show_client_profile",
        false
    );

    return {showClientProfile}
}