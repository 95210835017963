import React from "react";

export const TermsAndConditions = () => {
  return (
    <div className="max-w-4xl mx-auto p-4 bg-white shadow-lg rounded-lg mt-10">
      <h1 className="text-3xl font-bold text-center mb-6">
        Terms and Conditions
      </h1>
      <p className="text-gray-600 text-sm text-center">
        Effective Date: August 2, 2022
      </p>

      <section className="mt-6">
        <h2 className="text-lg tracking-wide font-semibold">
          Acceptance of Terms
        </h2>
        <p className="text-gray-700 mt-2">
          Welcome to <strong>"For the People"</strong> (the "App"), a campaign
          tracking and volunteering platform provided by For the People AI Labs.
          These Terms govern your use of the App. By accessing or using the App,
          you agree to be bound by these Terms.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg tracking-wide font-semibold">Definitions</h2>
        <ul className="list-disc list-inside text-gray-700 mt-2">
          <li>
            <strong className="tracking-wide">Customer/You/Your:</strong> Refers
            to you as a user of the Services.
          </li>
          <li>
            <strong className="tracking-wide">Content:</strong> Includes
            campaign details, text, images, and other data.
          </li>
          <li>
            <strong className="tracking-wide">Your Content:</strong> Content you
            upload or share via the Services.
          </li>
          <li>
            <strong className="tracking-wide">For the People Content:</strong>{" "}
            Content created and provided by us.
          </li>
          <li>
            <strong className="tracking-wide">Third-Party Content:</strong>{" "}
            Content from external sources or users.
          </li>
        </ul>
      </section>

      <section className="mt-6">
        <h2 className="text-lg tracking-wide font-semibold">Eligibility</h2>
        <p className="text-gray-700 mt-2">
          You must be at least 18 years old to use the Services.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg tracking-wide font-semibold">
          Changes to Terms
        </h2>
        <p className="text-gray-700 mt-2">
          We reserve the right to modify these Terms at any time. Continued use
          of the App constitutes acceptance of the revised Terms.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg tracking-wide font-semibold">
          User Accounts and Conduct
        </h2>
        <p className="text-gray-700 mt-2">
          Users must create an account to access certain features.
        </p>
        <p className="text-gray-700 mt-2">
          You agree not to post harmful or illegal content.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg tracking-wide font-semibold">
          Disclaimer of Warranties
        </h2>
        <p className="text-gray-700 mt-2">
          The Services are provided "as is" without warranties.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg tracking-wide font-semibold">
          Limitation of Liability
        </h2>
        <p className="text-gray-700 mt-2">
          We are not responsible for damages arising from your use of the
          Services.
        </p>
      </section>

      <section className="mt-6">
        <h2 className="text-lg tracking-wide font-semibold">
          Contact Information
        </h2>
        <p className="text-gray-700 mt-2">
          For questions, contact:{" "}
          <a href="mailto:legal@forthepeople.ai" className="text-blue-500">
            legal@forthepeople.ai
          </a>
        </p>
      </section>
    </div>
  );
};
