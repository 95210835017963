import React from "react";
import { Box, IconButton } from "@mui/material";
import { Fade } from "react-awesome-reveal";
import { actionButtonStyles } from "../assignees/MUIstyles";

interface ActionButtonsProps {
  positionClasses: string;
  onEdit: () => void;
  onDelete: () => void;
}

export const ActionButtons = (props: ActionButtonsProps) => {
  // @ts-ignore
  const classes = actionButtonStyles();
  return (
    <Fade delay={100}>
      <div
        style={{ opacity: 1 }}
        className={`${props.positionClasses} flex flex-col gap-y-2 absolute bg-[#7B7B7B66] rounded-l-[28px] mr-0`}
      >
        <IconButton
          size={"small"}
          disableFocusRipple
          className={`rounded-full p-2 border border-1 ${classes.button}`}
          sx={{
            "&:hover": {
              backgroundColor: "#B88EFD",
            },
          }}
          onClick={props.onEdit}
        >
          <Box className={classes.box}>
            <i className="fa-regular fa-pen-to-square text-gray-700" />
          </Box>
        </IconButton>
        <IconButton
          size={"small"}
          disableFocusRipple
          className={`rounded-full p-2 border border-1 ${classes.button}`}
          onClick={props.onDelete}
          sx={{
            "&:hover": {
              backgroundColor: "#B88EFD",
            },
          }}
        >
          <Box className={classes.box}>
            <i className="fa-regular fa-trash-can text-gray-700" />
          </Box>
        </IconButton>
      </div>
    </Fade>
  );
};
