import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import { useHoverDirty } from "react-use";
import { useIsMobile } from "../../custom-hooks";

const RenderVoterInfo = ({ label, value }) => {
  return (
    <>
      {value ? (
        <div className="flex flex-col">
          <p className="text-gray-500  font-bold py-1 ">{label}</p>
          <p className="text-gray-900  font-normal ">{value}</p>
        </div>
      ) : null}
    </>
  );
};

type VoterCardProps = {
  voter: {};
  edit?: boolean;
};

export const VoterCard = observer((props: VoterCardProps) => {
  const { voter, edit } = props;
  // @ts-ignore
  const { profile } = voter;
  const rootStore = useStore();
  const { voterListStore } = rootStore;
  const { edit_voter_modal } = voterListStore;
  const isMobile = useIsMobile();

  const handleEditIconClick = () => {
    voterListStore.update_edit_voter_modal(!edit_voter_modal);
    voterListStore.update_active_voter(voter);
  };

  const ref = useRef(null);

  const isHovering = useHoverDirty(ref);

  return (
    <>
      <div
        ref={ref}
        className="flex items-center p-2 sm:px-2 cursor-pointer h-full bg-white hover:bg-gray-50 rounded-lg shadow-lg"
      >
        <div className="min-w-0 flex-1 flex items-center ">
          <div className="min-w-0 flex-1 md:grid md:grid-cols-1 md:gap-2 ">
            <div className="grid grid-cols-2 gap-3 relative">
              <p className="text-center text-indigo-600 font-bold py-1 ">
                {profile.name_reg}
              </p>
              <p className="text-center text-indigo-600 font-bold ">
                {profile["name_eng"]}
              </p>
              {edit && (isHovering || isMobile) && (
                <i
                  color="#3182CE"
                  onClick={handleEditIconClick}
                  className="absolute cursor-pointer fas fa-pencil h-8 w-8 right-0"
                />
              )}
            </div>
            <div className="grid grid-cols-3 gap-2">
              <RenderVoterInfo label={"Serial #"} value={profile["serialno"]} />
              <RenderVoterInfo label={"Voter Id"} value={profile["voterid"]} />
              <RenderVoterInfo label={"Booth #"} value={voter["part"]} />
            </div>
            <div className="grid grid-cols-3 gap-2">
              <RenderVoterInfo label={"Age"} value={profile["age"]} />
              <RenderVoterInfo label={"House#"} value={profile["house"]} />
              <RenderVoterInfo label={"Gender"} value={profile["gender"]} />
            </div>
            <div className="grid grid-cols-2 gap-2">
              <RenderVoterInfo
                label={profile["relationship_eng"]}
                value={`${profile["relation_name_eng"]} ${profile["relation_name_reg"]}`}
              />
              <RenderVoterInfo label={"Phone Number"} value={voter["phone"]} />
              <RenderVoterInfo label={"Date of Birth"} value={profile["dob"]} />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-1">
              <RenderVoterInfo label={"Address"} value={profile["address"]} />
              {voter["caste"] && (
                <RenderVoterInfo label={"Caste"} value={voter["caste"]} />
              )}
            </div>
            {voter["is_living_outside_ac"] && (
              <RenderVoterInfo label={"Outside City"} value={voter["city"]} />
            )}
            {voter["party"] && (
              <RenderVoterInfo label={"Party"} value={voter["party"]} />
            )}
          </div>
        </div>
      </div>
    </>
  );
});
