import React, { useState } from "react";
import { Fragment } from "react";
import { Transition, Dialog } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  HomeIcon,
  Bars3Icon,
  CircleStackIcon,
  DocumentTextIcon,
  LanguageIcon,
  WrenchScrewdriverIcon,
  ArrowLeftOnRectangleIcon,
  PencilIcon,
} from "@heroicons/react/24/solid";
import { NavLink, useParams } from "react-router-dom";
import { classNames, useStore } from "../helpers";
import { useTranslation } from "react-i18next";
import { LanguageChange } from "./LanguageChange";
import { observer } from "mobx-react-lite";
import { UpdateProfile } from "./UpdateProfile";
import { SideOverlay } from "./side-overlay";
import { safe_get } from "../report-visuals/report-utils";

export const Header = observer(() => {
  const rootStore = useStore();
  const { userStore, projectStore } = rootStore;
  const { t } = useTranslation("");
  const { projectid } = useParams();

  const handleOpenModal = () => {
    userStore.set_profile_update(true);
  };
  const handleCloseModal = () => {
    userStore.set_profile_update(false);
  };

  const id = !projectid
    ? safe_get(projectStore, "activeProject.id", projectid)
    : projectid;

  const navigation = [
    {
      name: "DashBoard",
      href: `/project/${id}`,
      icon: HomeIcon,
      current: true,
    },
    {
      name: "Data Bank",
      href: "/databank",
      icon: CircleStackIcon,
      current: false,
    },
    {
      name: "Reports",
      href: "/reports",
      icon: DocumentTextIcon,
      current: false,
    },
    // {name: 'Tools', href: '/tools', icon: AdjustmentsVerticalIcon, current: false},
    {
      name: "Settings",
      href: "/settings",
      icon: WrenchScrewdriverIcon,
      current: false,
    },
    // {name: 'Logout', href: '/login', icon: ArrowLeftOnRectangleIcon, current: false}
  ];
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <div className="relative">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                  <div className="flex h-16 shrink-0 items-center">
                    <img src={"/img/ftp_ai.png"} className="w-12 h-12" />
                  </div>

                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <a
                                href={item.href}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-50 text-indigo-600"
                                    : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-indigo-600"
                                      : "text-gray-400 group-hover:text-indigo-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </li>
                      <li>
                        <a
                          href="#"
                          className="flex items-center p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                        >
                          <img
                            className="h-12 w-12 rounded-full bg-gray-50"
                            src={userStore.user.profileimageuri}
                            alt=""
                          />
                          <span className="sr-only">Your profile</span>
                          <br />
                        </a>
                        <div className="flex flex-col">
                          <p
                            className="text-white text-sm font-bold"
                            aria-hidden="true"
                          >
                            {userStore.getDisplayName()}
                          </p>
                          <p className="text-white text-sm text-center font-bold">
                            {userStore.getDisplayRole()}
                          </p>
                        </div>

                        <button
                          className="btn gap-2 btn-sm"
                          onClick={() => {
                            rootStore.update_language_Change(true);
                          }}
                        >
                          <LanguageIcon className="h-4 w-4" />
                        </button>
                      </li>
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      {/*//  Static sidebar for desktop */}
      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-48 lg:flex-col">
        <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 fp-bg">
          <div className={"flex flex-col justify-between items-center"}>
            <div className="flex h-16 shrink-0 items-center">
              <img
                className="h-8 w-auto"
                src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                alt="Your Company"
              />
            </div>
          </div>
          {/*Sidebar*/}

          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" className="-mx-2 space-y-1">
                  {navigation.map((item) => (
                    <li key={item.name}>
                      <NavLink
                        to={item.href}
                        className={({ isActive }) =>
                          classNames(
                            isActive
                              ? `bg-gray-50 fp-text`
                              : `text-white hover:text-gray-700 hover:bg-gray-50`,
                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                          )
                        }
                        onClick={(e) => {
                          console.log(e);
                        }}
                      >
                        <item.icon
                          className={"h-6 w-6 shrink-0"}
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              </li>
              <li className="-mx-6 mt-auto flex flex-row items-center ">
                <a
                  href="#"
                  className="flex items-center p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-50"
                  onClick={() => rootStore.userStore.set_profile_update(true)}
                >
                  <img
                    className="h-12 w-12 rounded-full bg-gray-50"
                    src={userStore.user.profileimageuri}
                    alt=""
                  />
                  <span className="sr-only">Your profile</span>
                  <br />
                </a>
                <div className="flex flex-col">
                  <p className="text-white font-bold" aria-hidden="true">
                    {" "}
                    {userStore.getDisplayName()}
                  </p>
                  <p className="text-white font-bold">
                    {userStore.getDisplayRole()}
                  </p>
                </div>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      {/*<aside*/}
      {/*    className="h-full w-24 items-center justify-evenly text-white hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:flex-col"*/}
      {/*    style={{backgroundColor: theme}}>*/}
      {/*    <ul role="list" className="py-8 flex flex-col items-center md:gap-y-4 lg:gap-y-4 xl:gap-y-8 my-8 ">*/}
      {/*        <li>    */}
      {/*            <img src={"/img/ftp_ai.png"} className="w-12 h-12"/>*/}
      {/*        </li>*/}
      {/*        <li className=" flex flex-col items-center gap-y-2">*/}
      {/*            {navigation.map((item,i) => (*/}
      {/*                <NavLink*/}
      {/*                    key={i}*/}
      {/*                    to={item.href}*/}
      {/*                    onClick={e => {*/}
      {/*                        if(item.name === "Logout"){*/}
      {/*                            userStore.logout();*/}
      {/*                        } else return;*/}
      {/*                    }}*/}
      {/*                    className={({isActive}) => classNames(*/}
      {/*                        isActive ? `bg-gray-50 fp-text` : `text-white hover:text-gray-700 hover:bg-gray-50`,*/}
      {/*                        'group flex gap-x-3 rounded-md py-4 px-2 text-sm leading-6 font-semibold h-8 w-8 sm:h-16 sm:w-16 flex items-center justify-center rounded-lg cursor-pointer hover:text-gray-800 hover:bg-white hover:duration-300 hover:ease-linear focus:bg-white'*/}
      {/*                    )}*/}
      {/*                >*/}
      {/*                    <item.icon*/}
      {/*                        className={'h-6 w-6 sm:h-8 sm:w-8 shrink-0'}*/}
      {/*                        aria-hidden="true"*/}
      {/*                        title={item.name}*/}
      {/*                    />*/}
      {/*                    /!*{item.name}*!/*/}
      {/*                </NavLink>*/}
      {/*            ))}*/}
      {/*        </li>*/}

      {/*        <li className="-mt-2 flex flex-col items-center ">*/}
      {/*            <a*/}
      {/*                href="#"*/}
      {/*                className="flex items-center text-sm font-semibold leading-6 text-gray-900"*/}
      {/*            >*/}
      {/*                <img*/}
      {/*                    className="mt-1 h-12 w-12 rounded-full bg-gray-50"*/}
      {/*                    src={userStore.user.profileimageuri}*/}
      {/*                    alt=""*/}
      {/*                />*/}
      {/*                <span className="sr-only">Your profile</span><br/>*/}
      {/*                <PencilIcon className="mt-8 h-4 w-5 text-white hover:text-black cursor-pointer"*/}
      {/*                    onClick={() => rootStore.userStore.set_profile_update(true)} />*/}
      {/*            </a>*/}
      {/*            <div className="flex flex-col">*/}
      {/*                <p className="text-white text-sm font-bold"*/}
      {/*                   aria-hidden="true">{userStore.getDisplayName()}</p>*/}
      {/*                <p className="text-white text-sm text-center font-bold">{userStore.getDisplayRole()}</p>*/}
      {/*            </div>*/}

      {/*        </li>*/}
      {/*        <li className="mt-auto flex flex-col items-center">*/}
      {/*            <button className="btn gap-2 btn-sm text-white hover:text-gray-700 hover:bg-gray-50"*/}
      {/*                    onClick={() => {*/}
      {/*                        rootStore.update_language_Change(true)*/}
      {/*                    }}*/}
      {/*            >*/}
      {/*                <LanguageIcon className="h-4 w-4"/>*/}
      {/*            </button>*/}
      {/*        </li>*/}
      {/*    </ul>*/}
      {/*</aside>*/}

      <SideOverlay
        children={<UpdateProfile />}
        onClose={handleCloseModal}
        show={userStore.profile_update}
        title={"Update Profile"}
      />
      <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 fp-bg lg:hidden">
        <button
          type="button"
          className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3Icon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 text-sm font-semibold leading-6 text-gray-900">
          {t("dashboard")}
        </div>
        <a href="#">
          <span className="sr-only">{t("your_profile")}</span>
          <img
            className="h-8 w-8 rounded-full bg-gray-50"
            src={userStore.user.profileimageuri}
            alt=""
          />
        </a>
      </div>
      <LanguageChange />
    </div>
  );
});
