import { useStore } from "../../../../../helpers";
import { useRequestTypeMutation } from "../../../sr-hooks";
import { useTranslation } from "react-i18next";

export const useAddStatus = (selectedIcon: string) => {
  const rootStore = useStore();
  const { t } = useTranslation(["status"]);

  const onAddServiceRequestType = async (payload) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-statuses-add",
      payload,
      t("add_status_failed"),
    );
  };
  return useRequestTypeMutation(
    onAddServiceRequestType,
    t("add_status_success"),
  );
};
