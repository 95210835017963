import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { ProjectWorksCardList } from "./project-works-card-list";
import { useNavigate, useParams } from "react-router-dom";
import { error_toast, useStore } from "../../helpers";
import { useTranslation } from "react-i18next";
import { FilterButton, FSDPagination, LoadingState } from "../../components";
import { useGetFullProject, useIsMobile } from "../../custom-hooks";
import { useQuery } from "@tanstack/react-query";
import { SideOverlay } from "../../components";
import { ProjectWorksFilters } from "./project-works-filters";
import { RNSearchBar } from "../influential-leaders";
import { Button } from "@mui/material";
import { FTPBreadCrumbs } from "../../components-new";

const PWCrumbs = () => {
  const { projectid } = useParams();
  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: false },
    {
      name: "Project Works",
      href: "/project/" + projectid + "/project_works",
      current: true,
    },
  ];
  return <FTPBreadCrumbs crumbs={crumbs} />;
};

const ProjectWorks = observer((props: any) => {
  const navigate = useNavigate();
  const { projectid } = useParams();
  const isMobile = useIsMobile();
  const { projectWorksStore, flexiStore } = useStore();
  const { filters } = projectWorksStore;
  const { projectWorksList, projectWorkFilter } = projectWorksStore;
  const { t } = useTranslation("project_work");
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const { fsd_search_page_size, fsd_search_current_page } = flexiStore;
  const totalPages = Math.ceil(total / fsd_search_page_size);
  const { isLoading: fullProjectLoading } = useGetFullProject();

  const handlePerPageChange = (event) => {
    flexiStore.update_flexistore_property(
      "fsd_search_page_size",
      parseInt(event.target.value)
    );
  };

  const fetchProjectWorks = async () => {
    await flexiStore.set_fsd_search_query(query);
    return await flexiStore.fetch_fsdlist(
      "project-works",
      parseInt(projectid),
      filters
    );
  };

  const { isLoading } = useQuery({
    queryKey: [
      "project-works",
      projectid,
      fsd_search_current_page,
      fsd_search_page_size,
      filters,
      query,
    ],
    queryFn: fetchProjectWorks,
    onSuccess: (data) => {
      projectWorksStore.update_project_work_property(
        "set_project_works",
        data["data"]["results"]
      );
      setTotal(data["data"]["total"]);
    },
  });

  useEffect(() => {
    //TODO: Reset filters when this component unmounts
    return () => {
      projectWorksStore.resetFilters();
    };
  }, []);

  const handleSearchBarChange = (value) => {
    setQuery(value);
  };

  const clone_project_work_handler = async () => {
    setLoading(true);
    const res = await flexiStore.clone_fsd(414);
    if (res.status === 200) {
      flexiStore.set_current_fsd(res.data);
      projectWorksStore.update_project_work_property("add_pw_modal", true);
      navigate("/project/" + projectid + "/project_works" + "/" + res.data.id);
    } else error_toast();
    setLoading(false);
  };

  const close_ProjectFilter_modal = () => {
    projectWorksStore.setProjectFilterStatus(false);
  };

  return (
    <>
      {(isLoading || loading || fullProjectLoading) && <LoadingState />}
      <PWCrumbs />
      <div className="p-4">
        <div className="flex align-center gap-x-4">
          <div className="flex flex-row justify-between items-center mb-4 flex-1">
            <h2 className="font-bold text-3xl text-indigo-500 pr-14">
              {t("project_works")}
            </h2>
            <div className={"flex items-start justify-between gap-x-4"}>
              <RNSearchBar onSearch={handleSearchBarChange} />
              <Button
                variant={"contained"}
                startIcon={<i className="fas fa-plus-circle" />}
                onClick={clone_project_work_handler}
              >
                {t("add_project_work")}
              </Button>
            </div>
          </div>
          <FilterButton
            filterOnClickHandler={() => {
              projectWorksStore.setProjectFilterStatus(true);
            }}
          />
        </div>

        <FSDPagination key={"grievance"} totalPages={totalPages} />
        <ProjectWorksCardList />
        {/* side popup for project work */}
        <SideOverlay
          children={<ProjectWorksFilters />}
          onClose={close_ProjectFilter_modal}
          show={projectWorkFilter}
          title={t("Project Works Filters")}
        />
      </div>
    </>
  );
});

export default ProjectWorks;
