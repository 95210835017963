import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { PhoneLogin } from "./phone-login";
import { EmailLogin } from "./email-login";
import { QRCodeLogin } from "./qr-code-login";
import { useTranslation } from "react-i18next";
import { IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

const icons = [
  { id: "phone", src: "/img/phone.png" },
  { id: "email", src: "/img/mail.png" },
  { id: "qr", src: "/img/code.png" },
];

const NewLogin = observer(() => {
  const [activeIcon, setActiveIcon] = useState("phone");
  const methods = useForm();
  const { t } = useTranslation(["new_login"]);
  const theme = useTheme();

  const handleIconClick = (icon: string) => {
    setActiveIcon(icon);
  };

  const onSubmit = (data: any) => {
    console.log(data);
  };

  return (
    <>
      <div className="flex flex-col justify-center w-full p-6 sm:p-4 min-h-screen sm:max-w-[90%] md:max-w-[80%] lg:max-w-[70%] mx-auto gap-10">
        <div className="flex flex-col gap-8 sm:gap-8 sm:mt-0">
          <div className="flex flex-row justify-between">
            <img
              src="/images/srimages/Black_Full_Logo_NoBG.svg"
              alt="For The People"
              className="w-16 sm:w-20 md:w-20 lg:w-24 xl:w-28"
            />

            <img
              src="/images/srimages/Rajneethi.png"
              className="w-20 md:w-32"
            />
          </div>

          <Typography
            variant="h1"
            className="text-lg sm:text-xl md:text-2xl break-words"
          >
            <span className="text-[#B6B6B6]">{t("access")} </span> {t("tools")}
          </Typography>

          <Typography
            variant="h3"
            sx={{ color: theme.palette.grey[500] }}
            className="text-base sm:text-lg md:text-xl"
          >
            {t("empowering")}
          </Typography>
        </div>

        <div className="flex flex-col justify-between gap-4 sm:gap-6 sm:mr-auto self-stretch sm:self-start">
          <Typography variant="h3" className="text-lg sm:text-xl">
            {t("login")}
          </Typography>
          <div className="flex flex-row flex-1 gap-x-3 sm:gap-x-4">
            {icons.map((icon) => (
              <IconButton
                sx={{ backgroundColor: "#1B1B1D", borderRadius: "10px" }}
                onClick={() => handleIconClick(icon.id)}
              >
                <img
                  src={icon.src}
                  alt={`${icon.id} Icon`}
                  className="w-full h-full object-contain"
                />
              </IconButton>
              // <div
              //   key={icon.id}
              //   className={`rounded-xl border border-gray-300 flex flex-1 cursor-pointer h-[60px] sm:h-[72px] p-4 sm:p-[22px] ${
              //     activeIcon === icon.id ? newLoginStyles.activeIcon : ""
              //   }`}
              //   onClick={() => handleIconClick(icon.id)}
              // >
              //   <img
              //     src={icon.src}
              //     alt={`${icon.id} Icon`}
              //     className="w-full h-full object-contain"
              //   />
              // </div>
            ))}
          </div>
        </div>

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onSubmit)}
            className="flex self-start w-full  sm:w-[80%] md:w-[65%] lg:w-[50%] xl:w-[40%] transition-all duration-300"
          >
            {activeIcon === "phone" && <PhoneLogin />}
            {activeIcon === "email" && <EmailLogin />}
            {activeIcon === "qr" && <QRCodeLogin />}
          </form>
        </FormProvider>
        <footer className="flex flex-col-reverse justify-between md:flex-row text-center">
          <div className="flex space-x-5">
            <Link
              to="/terms-and-conditions"
              className="text-blue-500 underline mx-2"
            >
              Terms and Conditions
            </Link>
            <Link to="/privacy-policy" className="text-blue-500 underline mx-2">
              Privacy Policy
            </Link>
          </div>
          <p>Owned and Managed by Rajneethi Management Consultancy</p>
          <p>© 2025 For the People</p>
        </footer>
      </div>
    </>
  );
});

export default NewLogin;
