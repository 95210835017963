import { BoothsByGrade } from "./BoothsByGrade";
import { reduce_for_visual, safe_get } from "../../../../report-visuals";
import { LeafletMap } from "../../../../components";
import _ from "lodash";
import React from "react";
import BoothReportMatrixTable, {
  BoothReportSimpleTable,
  build_arr_reducer,
  build_reducer,
  grader,
  PageSection,
  RenderACName,
} from "../booth-reports-utils";
import { Form20BoothWise } from "./Form20BoothWise";
import { useCurrentFSD } from "../../../../custom-hooks/useCurrentFSD";
import { useStore } from "../../../../helpers";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { toJS } from "mobx";
import Masonry from "@mui/lab/Masonry";

export const RenderLiveBoothReport = observer((props) => {
  const { flexiStore } = useStore();
  const { swot_by_booth, group_by_booth } = flexiStore;
  const { t } = useTranslation("booth_bank");

  const config = useCurrentFSD();
  const {
    f20_enabled,
    booth_details,
    booth_key_prefix,
    report_questions,
    title,
    ac_name,
    sub_title,
    report_min_interviews,
    booth_report_maps,
    show_swot,
    hide_maps,
  } = config;

  const acname = ac_name ? ac_name : title;

  if (!swot_by_booth && !config && !group_by_booth)
    return <p className="p-4 font-bold text-gray-400">{t("no_data_found")}</p>;

  console.log(toJS(group_by_booth));
  return (
    <>
      <div className="print-parent section-to-print">
        <RenderACName title={acname} sub_title={sub_title} />
        <BoothsByGrade key={`booth_count_${swot_by_booth.length}`} />
        <PageSection text="Detailed Booth Reports" />
        {Object.keys(booth_details)
          .sort()
          .map((booth_key) => {
            const { left, name, booth, right } = booth_details[booth_key];
            let ds = group_by_booth[`${booth_key_prefix}${booth_key}`];
            if (!ds) ds = [];
            if (ds.length < report_min_interviews) return null;

            // For grading
            const { display, fgcol, bgcol } = grader(
              booth_key,
              config,
              group_by_booth,
            );

            if (display === "IN PROGRESS") return null;

            const maps = safe_get(booth_report_maps, booth_key, {});
            const hasmaps = Object.keys(maps).length > 0;
            let geojson = safe_get(maps, "geojson", {});

            return (
              <>
                <div className="page-container bg-white mx-auto mb-4">
                  <div
                    className={`grid grid-cols-3 gap-4 bg-blue-100 text-center font-bold text-xl`}
                  >
                    <div className="flex flex-col items-center">
                      <div className="ml-2 py-4">
                        {acname}
                        <br />
                        <span>{left}</span>
                      </div>
                    </div>

                    <div className="ml-2 py-4 flex items-center justify-center">
                      {right}
                    </div>
                    {show_swot && (
                      <div
                        className="text-center text-white py-2 ml-16 flex justify-center items-center"
                        style={{ backgroundColor: bgcol, color: fgcol }}
                      >
                        {display}
                      </div>
                    )}
                  </div>
                  <div className="text-center bg-gray-100 py-1">{name}</div>
                  {!hide_maps && (
                    <div
                      className="text-center pb-1"
                      style={{
                        fontFamily: "sans-serif",
                        textAlign: "center",
                        height: "8cm",
                      }}
                    >
                      {hasmaps ? (
                        <LeafletMap
                          geojson={geojson}
                          map_height={"8cm"}
                          mode="leaflet"
                        />
                      ) : null}
                    </div>
                  )}

                  <Masonry
                    columns={{ sm: 1, md: 2, lg: 2, xl: 2 }}
                    spacing={2}
                    sx={{paddingLeft: "20px"}}
                  >
                    {report_questions.map((widget, index) => {
                      if (widget.type === "matrix") return null;

                      // @ts-ignore
                      const { colkey, headers, is_array, title, width } =
                        widget;

                      const filter_q_key = safe_get(
                        widget,
                        "filter_q_key",
                        null,
                      );
                      const filter_q_value = safe_get(
                        widget,
                        "filter_q_value",
                        null,
                      );

                      let reducer = is_array
                        ? build_arr_reducer(colkey)
                        : build_reducer(colkey);

                      const filtered_ds = [];
                      if (filter_q_key) {
                        for (const item of ds) {
                          if (
                            filter_q_value == safe_get(item, filter_q_key, "")
                          ) {
                            filtered_ds.push(item);
                          }
                        }
                        console.log(filtered_ds);
                      }

                      const transformed = _.orderBy(
                        reduce_for_visual(
                          filter_q_key ? filtered_ds : ds,
                          reducer,
                          {},
                        ),
                        ["surveys"],
                        ["desc"],
                      );

                      if (colkey == "qr_schemes_arr_") {
                        console.log(transformed);
                      }
                      return (
                        <>
                          <div
                            key={index}
                            className="m-2"
                            style={{ width: `350px` }}
                          >
                            <div className="font-bold text-indigo-700">
                              {title}
                            </div>
                            <BoothReportSimpleTable
                              widget={widget}
                              ds={ds}
                              transformed={transformed}
                              booth_key={booth_key}
                            />
                          </div>
                        </>
                      );
                    })}
                  </Masonry>

                  <div className={"ml-6"}>
                    {report_questions.map((widget, index) => {
                      if (widget.type !== "matrix") return null;
                      // @ts-ignore
                      const { colkey, title, width } = widget;

                      return (
                        <div
                          key={index}

                          style={{ width: width }}
                        >
                          <div className="font-bold text-indigo-700">
                            {title}
                          </div>
                          <BoothReportMatrixTable
                            widget={widget}
                            ds={ds[colkey]}
                            booth_key={booth_key}
                          />
                        </div>
                      );
                    })}
                  </div>

                  {f20_enabled ? (
                    <Form20BoothWise booth_key={booth_key} />
                  ) : null}
                </div>
              </>
            );
          })}
      </div>
    </>
  );
});
