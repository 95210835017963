import React from "react";
import { classNames } from "../../../helpers";

export const RenderAddressValue = ({ label, value }) => {
  return (
    <div>
      <div className="font-normal text-[12px] leading-[14px] text-[#848484] mb-[8px]">
        {label}
      </div>
      <div className="font-medium text-[14px] leading-[16px] text-[#1B1B1D] mb-[24px]">
        {value}
      </div>
    </div>
  );
};

function VoterAddress({ address, relation, relationship, expand, caste, dob }) {
  return (
    <>
      <div
        className={classNames(
          expand ? "accordion-content-open" : "",
          "accordion-content-close",
        )}
      >
        <div className="px-[38px] mb-[6px]">
          {caste && <RenderAddressValue label={"Caste"} value={caste} />}
          {dob && <RenderAddressValue label={"Date of Birth"} value={dob} />}
          {address && <RenderAddressValue label={"Address"} value={address} />}
          {relationship && (
            <RenderAddressValue
              label={`${relationship} Name`}
              value={relation}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default VoterAddress;
