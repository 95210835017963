import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { FTPTheme } from "../../ftp-theme";

export const EditButton = ({ OnEditClickHandler, ...props }) => {
  const { t } = useTranslation(["beneficiary", "voter_list"]);
  return (
    <Button
      onClick={OnEditClickHandler}
      startIcon={<i className={`fa-solid fa-pen`} aria-hidden="true" />}
      color={"primary"}
      sx={{ backgroundColor: FTPTheme.palette.text.primary, height: "4rem" }}
      size={"small"}
      variant={"contained"}
    >
      {t("edit")}
    </Button>
  );
};
