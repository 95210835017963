import {action, makeAutoObservable} from "mobx";
import TrieSearch from 'trie-search';
import {safe_get} from "../report-visuals";
import _ from "lodash";
import {math} from "@amcharts/amcharts4/core";

type TaskFilters = {
    userList: number[],
    pivLevel1: string[],
    status: string[],
    surveys: any[]
}

export class TaskManagerStore {

    rootStore: any;

    task_summary: any = {};
    task_list_query: string = '';
    task_list: any = [];
    filtered_task_trie_store: TrieSearch<any> = new TrieSearch<any>();
    filtered_task_list: any = [];
    filtered_task_list_display: any = [];
    task_filters: TaskFilters = {
        userList: [],
        pivLevel1: [],
        status: [],
        surveys: []
    };
    task_filter_modal: boolean = false;

    task_count_per_page:number = 10;
    tasklist_current_page_number:number = 1;
    offset: number = 0;

    task_list_page_size = 10;
    task_list_page_current = 0;

    active_task: any = {};
    surveys: any = [];
    users: any = [];

    loading: boolean = false;
    task_edit_modal: boolean = false;
    task_add_modal: boolean = false;
    task_view_modal: boolean = false;
    show_booth_map: boolean = false;
    task_type: any = "";
    marked_locations: any = [];
    enable_marking_locations: boolean = true;

    selected_booth: any = {};

    constructor(rootStore: any) {
        makeAutoObservable(this);
        this.rootStore = rootStore;
        this.task_list = [];
    }

    @action
    set_loading(state) {
        this.loading = state;
    }

    @action
    set_task_edit_modal(status) {
        this.task_edit_modal = status;
    }

    @action
    set_task_add_modal(status) {
        this.task_add_modal = status;
    }

    @action
    set_task_view_modal(status) {
        this.task_view_modal = status;
    }

    @action
    set_users(users) {
        this.users = users;
        console.log(users);
    }

    @action
    set_surveys(surveys) {
        this.surveys = surveys;
       
    }

    @action
    set_selected_node(node) {
        this.selected_booth = node;
    }

    @action
    set_task_type(type) {
        this.task_type = type;
    }

    @action
    set_task_list_query(query) {
        this.task_list_query = query;
        this.filter_tasks()
    }

    @action
    set_marked_locations(locations) {
        this.marked_locations = locations;
        console.table(locations)
    }

    @action
    add_marked_locations(location) {
        this.marked_locations.push(location);
        console.table(this.marked_locations)
    }

    @action
    update_marked_location(location) {
        const i = _.findIndex(this.marked_locations, (loc) => loc['id'] === location['id'])
        this.marked_locations[i]['lat'] = location['lat']
        this.marked_locations[i]['lng'] = location['lng']
        this.marked_locations[i]['radius'] = location['radius']
        console.table(this.marked_locations)
    }

    @action
    set_enable_marking_locations(val) {
        this.enable_marking_locations = val;
    }

    @action
    async get_task_list(projectid) {
        this.loading = true;
        const {pivLevel1, status, userList, surveys} = this.task_filters;
        const payload = {
            projectid,
            userList,
            pivLevel1,
            status,
            surveys,
            offset: (this.tasklist_current_page_number - 1) * this.task_count_per_page,
            count: this.task_count_per_page
        };
        const res = await this.rootStore.api.post("tf/projecttasklist", payload);

        try {
            if (res.status === 200) {
                this.task_list = res.data['list']
                this.task_summary = res.data['summary']
                this.filtered_task_trie_store = new TrieSearch(
                    [
                        ['node', 'name'],
                        ['node', 'label'],
                        ['node', 'display'],
                        ['node', 'piv_level0'],
                        ['node', 'piv_level1'],
                        ['node', 'piv_level2'],
                        ['more', 'name'],
                        ['user', 'name'],
                        ['user', 'phonenum'],
                    ], {
                        splitOnRegEx: /[\s\/\(\)#-]/,
                        min: 2,
                        ignoreCase: true,
                        indexField: 'id',
                    })
                this.filtered_task_trie_store.addAll(res.data['list'])
                this.filter_tasks()
            }
            this.loading = false
            return res.data
        } catch (e) {
            console.log(e)
        }
        this.loading = false
    }

    @action
    set_active_task(task) {
        this.active_task = task;
    }

    @action
    filter_tasks() {
        if (this.task_list_query.trim() === '') {
            this.filtered_task_list = this.task_list
        } else {
            this.filtered_task_list = this.filtered_task_trie_store.search(this.task_list_query.trim())
        }
        // this.reset_task_page()
        this.update_task_page_items()
    }

    @action
    update_task_page_items() {
        const start = this.task_list_page_current * this.task_list_page_size
        const end = math.min((this.task_list_page_current + 1) * this.task_list_page_size, this.filtered_task_list.length)

        this.filtered_task_list_display = _.slice(this.filtered_task_list, start, end)
    }

    @action
    task_list_next_page() {
        const max_page = math.ceil(this.filtered_task_list.length / this.task_list_page_size);
        if ((this.task_list_page_current + 1) < max_page) this.task_list_page_current += 1;
        this.update_task_page_items()
    }

    @action
    task_list_prev_page() {
        if ((this.task_list_page_current - 1) >= 0) this.task_list_page_current -= 1;
        this.update_task_page_items()
    }

    @action
    reset_task_page() {
        this.task_list_page_current = 0
    }

    @action
    update_task_page(val: number) {
        this.task_list_page_current = val
    }

    @action
    async create_single_task(payload) {
        return await this.rootStore.api.post("tf/createsurveytask", payload)
    }

    @action
    async create_bulk_tasks(payload) {
        return await this.rootStore.api.post("tf/bulkcreatesurveytask", payload)
    }

    @action
    update_task = async () => {
        await this.rootStore.api.post("tf/taskupdate", this.active_task);
    }

    @action
    async save_locations() {
        const clone = _.clone(this.active_task);
        clone.more["marked_locations"] = this.marked_locations;
        const res = await this.rootStore.api.post("tf/taskupdate", clone)
        this.active_task = clone;
        return res;
    }

    async view_task() {
        this.loading = true;
        if (!this.active_task) return null;
        const id = safe_get(this.active_task, "id", "");
        const response = await this.rootStore.api.post("tf/taskdetails", {"id": id})
        try {
            if (response.data["more"]["marked_locations"]) {
                this.marked_locations = safe_get(response.data, "more.marked_locations", []);
            } else {
                this.marked_locations = [];
            }
        } catch (e) {
            console.log(e)
        }
        this.loading = false
        return response;
    }

    @action
    async refresh_user_tasks(taskid) {
        this.loading = true;
        const response = await this.rootStore.api.post("tf/refresh", {id: taskid})
        this.loading = false;
        return response;
    }

    @action
    async refresh_project_tasks(projectid) {
        this.loading = true;
        const response = await this.rootStore.api.post("tf/refreshprojecttasks", {projectid: projectid})
        this.loading = false;
        return response;
    }

    async clone_task(taskid: number) {
        this.loading = true;
        const response = await this.rootStore.api.post("tf/clone", {id: taskid})
        this.loading = false;
        return response;
    }

    async delete_task(taskid: number) {
        this.loading = true;
        const response = await this.rootStore.api.post("tf/delete", {id: taskid})
        this.loading = false;
        return response;
    }

    update_task_filters(filters) {
        this.task_filters = filters;
        console.log(this.task_filters);
    }

    update_task_filter_modal(val) {
        this.task_filter_modal = val
        console.log(val);
        console.log("this is task filter modal")
    }

    update_show_booth_map(val) {
        this.show_booth_map = val
    }

    @action
    update_tm_store_prop(prop, value) {
        switch (prop) {
            case "task_count_per_page":
                this.task_count_per_page = value;
                break;
            case "tasklist_current_page_number":
                this.tasklist_current_page_number = value;
                break;
            case "offset":
                this.tasklist_current_page_number = value;
                break;
        }
    }
}