import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../../helpers";
import { useNavigate, useParams } from "react-router-dom";
import { ViewAttachment } from "../../components";
import { safe_get } from "../../report-visuals";
import { RenderRequestStatus } from "../beneficiary-management";
import { DataNotAvailable } from "../../flexistore/renderkeys/mh-2024-field";
import { Fade } from "react-awesome-reveal";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import { faUserClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toJS } from "mobx";
import { Skeleton } from "@mui/material";

export const displayVillageNames = (villageList) => {
  if (Array.isArray(villageList)) {
    return villageList.map((x) => <span>{safe_get(x, "label", "")}, </span>);
  } else return "N/A";
};

export const ProjectWorksCardsSkeleton = () => {
  return (
    <div className="py-4  grid grid-cols-2 gap-2 md:grid-cols-3 xl:grid-cols-3 lg:grid-cols-4 ">
      <div
        // key={index}
        className="flex flex-col rounded-3xl shadow-lg overflow-hidden cursor-pointer max-h-[600px] w-full"
      >
        <Skeleton variant="rectangular" width="100%" height={160} />

        <div className=" bg-white flex flex-col justify-between p-4">
          <div className="flex flex-row justify-between items-center">
            <Skeleton
              variant="rectangular"
              width={150}
              height={50}
              sx={{ borderRadius: "24px" }}
            />
            <Skeleton
              variant="rectangular"
              width={100}
              height={50}
              sx={{ borderRadius: "16px" }}
            />
          </div>

          <Skeleton
            variant="text"
            width="100%"
            height={100}
            sx={{ borderRadius: "16px" }}
          />
          <Skeleton
            variant="text"
            width="100%"
            height={180}
            sx={{ borderRadius: "16px", transform: "unset" }}
          />

          <Skeleton
            variant="rectangular"
            width="30%"
            height={32}
            sx={{ borderRadius: "16px", marginTop: 2 }}
          />
          <Skeleton
            variant="rectangular"
            width="80%"
            height={32}
            sx={{ borderRadius: "16px", marginTop: 2 }}
          />
          <Skeleton
            variant="rectangular"
            width="60%"
            height={32}
            sx={{ borderRadius: "16px", marginTop: 2 }}
          />
        </div>
      </div>
    </div>
  );
};

// export const ProjectWorksCards = observer((props: any) => {
//   const {
//     projectWorkStoreV2: { projectWorksList },
//   } = useStore();
//   return (
//     <>
//       {projectWorksList.length > 0 ? (
//         <div className="py-4 max-w-lg mx-auto grid gap-2 md:grid-cols-3 xl:grid-cols-3 lg:grid-cols-4 lg:max-w-none">
//           {projectWorksList.map((project, index) => {
//             return <ProjectCard key={index} project={project} />;
//           })}
//         </div>
//       ) : (
//         <DataNotAvailable />
//       )}
//     </>
//   );
// });

export const ProjectCard = ({ project }) => {
  const { projectid } = useParams();
  const navigate = useNavigate();
  const { projectWorkStoreV2 } = useStore();
  const projDetailsClickHandler = async (fsdid, project) => {
    projectWorkStoreV2.update_project_work_property(
      "activeProjectWork",
      project,
    );
    navigate("/project/" + projectid + "/projectworks_v2/" + fsdid, {
      state: toJS(project),
    });
  };
  const events = safe_get(project, "others.events", []);
  const attachment = safe_get(project.summary, "coverPhotoPath", {});
  return (
    <Fade
      cascade={false}
      direction="up"
      // delay={index * 10}
      triggerOnce
      key={project.id}
    >
      <div
        className="flex flex-col rounded-3xl shadow-lg overflow-hidden cursor-pointer max-h-[600px]"
        onClick={() => projDetailsClickHandler(project.id, project)}
        title={project.summary["decription"]}
      >
        <div className="flex-shrink-0">
          <div className="h-40 w-full object-cover">
            {project?.summary["coverPhotoPath"] !== "" ? (
              <ViewAttachment attachment={attachment} label={false} />
            ) : (
              <img
                className="h-40 w-full object-cover"
                src={"/images/default_image.jpeg"}
                alt=""
              />
            )}
          </div>
        </div>

        <div className="flex-1 bg-white flex flex-col justify-between p-4">
          <div className="flex flex-row justify-between items-center">
            <div className="flex font-medium items-center gap-x-2">
              <CalendarMonthIcon />
              <p>{project["created"]}</p>
            </div>
            <RenderRequestStatus
              feature="project_works"
              status={safe_get(project, "summary.status", "")}
            />
          </div>

          <div className="flex-1 flex flex-col mt-4">
            <p className="text-xl font-semibold text-gray-800">
              {project.summary["name"]}
            </p>

            <p className="py-4 overflow-hidden text-sm text-gray-500">
              {project.summary["decription"]}
            </p>

            {events.length > 0 && (
              <div className="flex flex-row gap-x-2 items-center bg-[#3A80F3] text-white rounded-3xl p-2 w-fit">
                <FontAwesomeIcon icon={faUserClock} className="text-white" />
                <p className="font-bold">{events.length} Events</p>
              </div>
            )}

            <div className="inline-flex items-center gap-x-2 mt-4 border text-[#3A80F3] border-[#3A80F3] rounded-3xl p-2 w-fit">
              <HowToVoteIcon color="primary" />
              <p>{displayVillageNames(project.summary["village"])}</p>
            </div>

            <div className="inline-flex items-center gap-x-2 mt-4 border text-[#3A80F3] border-[#3A80F3] rounded-3xl p-2 w-fit">
              <FmdGoodOutlinedIcon color="primary" />
              <p>{project.summary["location"]}</p>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
};
