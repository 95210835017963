import * as Yup from "yup";

export const serviceRequestValidations = Yup.object().shape({
  profile: Yup.object().shape({
    name_eng: Yup.string().required("Name is required"),
    relation_name_eng: Yup.string().required("Relative name is required"),
    voterid: Yup.string().required("Voter ID is required"),
    gender: Yup.string(),
    age: Yup.string(),
    address: Yup.string().required("Address is required"),
  }),
  additional: Yup.object().shape({
    booth: Yup.mixed().required("Please select Booth/Village"),
    family_members_list: Yup.mixed(),
    village: Yup.mixed(),
    is_outsider: Yup.boolean(),
    ac_name: Yup.string(),
    oacv_name: Yup.string(),
    oacv_phone: Yup.string(),
  }),
  dob: Yup.string(),
  phone: Yup.string().required("Phone is required"),
  whatsapp: Yup.string(),
  aadharid: Yup.string().required("Aadhaar ID is required"),
  caste: Yup.mixed(),
});

export const requestValidations = Yup.object().shape({
  status: Yup.mixed().required("Select the status"),
  priority: Yup.mixed().required("Select the priority"),
  assignee: Yup.mixed().required("Select the Assignee"),
  location: Yup.mixed().required("Select the location"),
  type: Yup.mixed().required("Select the request type"),
  more: Yup.object().shape({
    additional: Yup.string(),
    attachments: Yup.mixed(),
  }),
});
