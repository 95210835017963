import { useStore } from "../../../../../helpers";
import { useTranslation } from "react-i18next";
import { useRequestTypeMutation } from "../../../sr-hooks";

export const useDeleteStatus = () => {
  const rootStore = useStore();
  const { t } = useTranslation(["status"]);

  const deleteStatusMeta = async (id) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-statuses-delete",
      { id },
      t("delete_status_failed"),
    );
  };

  return useRequestTypeMutation(deleteStatusMeta, t("delete_status_success"));
};
