import { observer } from "mobx-react-lite";
import React from "react";
import { useStore } from "../helpers";
import { HomeIcon } from "@heroicons/react/24/solid";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

export const BreadCrumbs = observer((props: any) => {
  const rootStore = useStore();
  const appMode = rootStore.appMode;
  const { t } = useTranslation("Breadcrumbs");
  let crumbs: Array<any> = props["crumbs"];
  const { projectid } = useParams();

  return (
    <>
      {!appMode && (
        <nav
          className="noprint bg-white border-b border-gray-200 flex nop sticky top-0 "
          style={{ zIndex: 99 }}
          aria-label="Breadcrumb"
        >
          <ol className="w-full mx-auto px-4 flex space-x-4 sm:px-6 lg:px-8">
            <li className="flex">
              <div className="flex items-center">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={() => rootStore.update_appDrawerToggle(true)}
                  sx={{ mr: 2, display: { xs: "block" } }}
                >
                  <MenuIcon />
                </IconButton>
                <Link
                  to={projectid ? `/project/${projectid}` : "/"}
                  className={"ml-4 font-bold text-gray-600 hover:text-gray-500"}
                >
                  <HomeIcon
                    className="flex-shrink-0 h-5 w-5"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{t("home")}</span>
                </Link>
              </div>
            </li>
            {crumbs.map((page) => (
              <li key={page.name} className="flex">
                <div className="flex items-center">
                  <svg
                    className="flex-shrink-0 w-6 h-full text-gray-600"
                    viewBox="0 0 24 44"
                    preserveAspectRatio="none"
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                    aria-hidden="true"
                  >
                    <path d="M.293 0l22 22-22 22h1.414l22-22-22-22H.293z" />
                  </svg>
                  <Link
                    to={page.href}
                    className={
                      "ml-4 font-bold text-gray-600 hover:text-gray-500"
                    }
                    aria-current={page.current ? "page" : undefined}
                  >
                    {page.name}
                  </Link>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      )}
    </>
  );
});
