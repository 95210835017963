import {Controller, useFormContext} from "react-hook-form";
import {TextField} from "@mui/material";
import React from "react";

export const LoginInput = ({
                               name,
                               label,
                               type = "text",
                               placeholder,
                               inputProps,
                               ...props
                           }) => {
    const {
        control,
        formState: {errors},
    } = useFormContext();

    return (
        <div>
            <Controller
                name={name}
                control={control}
                render={({field, fieldState: {error}}) => (
                    <TextField
                        {...props}
                        {...field}
                        type={type}
                        placeholder={placeholder}
                        inputProps={inputProps}
                        error={!!error}
                        helperText={error?.message}
                        label={label}
                        sx={{
                            width: "100%",
                            borderRadius: "20px",
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "20px",
                            },
                        }}
                        className="w-full my-2 font-light text-[12px] leading-[16.42px] text-[#1B1B1D]"
                    />
                )}
            />
        </div>
    );
};
