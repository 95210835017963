import React from "react";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";
import { safe_get } from "../../../../report-visuals";
import { default_node } from "./add-timeline";
import { ViewAttachment } from "../../../../components";

export const TimeLineView = observer((props) => {
  const { state } = useLocation();
  const { data, editable } = state;
  const editor_data = safe_get(data, "details", default_node);
  return (
    <section className={"max-w-[1400px] mx-auto p-4"}>
      <div className={"text-lg sm:text-2xl text-blue-600 font-bold p-4"}>
        {safe_get(data, "summary.title", "")}
      </div>
      {data["attach"]["files"] && (
        <div className={"grid grid-cols-1 sm:grid-cols-4 gap-2"}>
          {data["attach"]["files"].map((x) => (
            <ViewAttachment key={x.path} attachment={x} s3_bucket={"urn-fsd"} />
          ))}
        </div>
      )}
      {/*<BrihaspathiRichEditor editorData={editor_data} editable={editable} editorOnChange={() => {}}/>*/}
    </section>
  );
});
