import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { FTPFormButton, FTPInput } from "../../components-new";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { defaultuserdata, userValidationSchema } from "./user-schema";
import { Avatar, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useFileUpload, useStore } from "../../helpers";
import { useAddUser } from "./useAddUser";
import { safe_get } from "../../report-visuals";
import { useLocalStorage } from "react-use";
import { VortexSpinner } from "../../features/digital-repository";

export const labelStylePro =
  "w-58 rounded-xl border border-gray-300 bg-gray-200 px-4 py-2 text-gray-700 shadow-md mt-1";

export const UserProfile = observer((props) => {
  const rootStore = useStore();
  const [image, setImage] = useState(null);
  const fileInputRef = React.useRef(null);
  const [userprofile, setUserProfile] = useLocalStorage("__userprofile");
  const [profileImage, setProfileImage] = useState(
    safe_get(userprofile, "profile.profileimage", null),
  );
  const { isUploading, uploadFileToS3 } = useFileUpload();

  const handleImageChange = async (event) => {
    const file = event.target.files[0];

    const uploadedData = await uploadFileToS3(
      `user-profile/${safe_get(userprofile, "id", "un-organised")}`,
      file,
      "urn-assets",
    );

    if (uploadedData?.status === 204) {
      setProfileImage(uploadedData.key);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        setImage(base64Image);
      };
      reader.readAsDataURL(file);
      console.log(uploadedData);
    }
  };

  const close_modal = () => {
    rootStore.update_user_profile(false);
  };

  const handleEditClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const methods = useForm({
    defaultValues: defaultuserdata,
    resolver: yupResolver(userValidationSchema),
  });

  useEffect(() => {
    const id = safe_get(userprofile, "id", null);
    if (id) {
      methods.reset({
        name: userprofile["profile"].name,
        city: userprofile["profile"].city,
        address: userprofile["profile"].address,
      });
    }
  }, []);

  const { mutate: updateProfileRequest } = useAddUser();

  const onSubmit = async (data) => {
    const payload = {
      profile: {
        ...data,
        profileimage: profileImage,
      },
      deviceinfo: {
        model: "",
        deviceId: "",
        carrier: "",
        maker: "",
      },
    };
    updateProfileRequest(payload);
    rootStore.update_user_profile(false);
  };
  return (
    <>
      <FormProvider {...methods}>
        <form
          className={"flex flex-col bg-common-white rounded-xl p-4 gap-y-4"}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <div className="relative w-32 h-32 pb-4">
            <Avatar
              src={image || safe_get(userprofile, "profileimageuri", null)}
              alt={safe_get(userprofile, "name", null)}
              variant={"rounded"}
              sx={{
                width: { xs: "75px", md: "150px" },
                height: { xs: "75px", md: "150px" },
                borderRadius: "30px",
              }}
            />
            <VortexSpinner visible={isUploading} />
            <input
              type="file"
              accept="image/*"
              className="hidden"
              ref={fileInputRef}
              onChange={handleImageChange}
            />
            <button
              onClick={handleEditClick}
              className="absolute bottom-2 right-2 border border-common-black bg-transparent p-2 rounded-full cursor-pointer hover:bg-grey-200"
            >
              <EditIcon fontSize="small" className="text-common-black" />
            </button>
          </div>
          <FTPInput name={"name"} label={"Name of the user"} />
          <FTPInput name={"city"} label={"City"} />
          <FTPInput name={"address"} label={"Address"} />
          <div className="flex flex-col">
            <div className="flex flex-row gap-4">
              <div className="flex flex-col flex-1">
                <Typography variant="body1">Phone number</Typography>
                <p className={labelStylePro}>
                  {safe_get(userprofile, "phonenum", null)}
                </p>
              </div>
              <div className="flex flex-col flex-1">
                <Typography variant="body1">User ID</Typography>
                <p className={labelStylePro}>
                  {safe_get(userprofile, "id", null)}
                </p>
              </div>
            </div>

            <div className="flex flex-col mt-4 w-full">
              <Typography variant="body1">Role</Typography>
              <p className={labelStylePro}>
                {safe_get(userprofile, "role", null)}
              </p>
            </div>

            <div className="flex gap-x-2 justify-end mt-4">
              <FTPFormButton
                actionType="Cancel"
                handleButtonClick={close_modal}
              />
              <FTPFormButton
                type={"submit"}
                actionType="Save"
                handleButtonClick={() => {}}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
});
