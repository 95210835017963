import React, { useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers";
import _ from "lodash";
import { Table } from "./table";
import { toJS } from "mobx";
import { TableHeader } from "./survey-summary";
import { useTranslation } from "react-i18next";

export const SurveysByAudio = observer((props) => {
  const { surveyResponseStore, userStore } = useStore();
  const { allSurveyResponsesSummary } = surveyResponseStore;
  const { t } = useTranslation("survey_management");

  const data = useMemo(
    () => toJS(allSurveyResponsesSummary)["by_surveyor_audio"],
    [allSurveyResponsesSummary]
  );

  const columns = useMemo(
    () => [
      {
        Header: t("name"),
        accessor: "surveyor_name",
        Footer: t("total"),
        Cell: (row) => {
          return (
            <div>
              {row.row.original["surveyor_id"]} - {row.cell.value}
            </div>
          );
        },
      },
      {
        Header: t("has_audio"),
        accessor: "has audio",
        Footer: surveyResponseStore.get_total_surveys(data, "has audio"),
      },
      {
        Header: t("no_audio"),
        accessor: "no audio",
        Footer: surveyResponseStore.get_total_surveys(data, "no audio"),
      },
      {
        Header: t("total_surveys"),
        accessor: "total",
        Footer: surveyResponseStore.get_total_surveys(data, "total"),
      },
    ],
    [allSurveyResponsesSummary]
  );

  if (_.isEmpty(allSurveyResponsesSummary["by_surveyor_audio"])) return null;

  const row_click_handler = (x) => {
    surveyResponseStore.update_new_filters(
      "surveyor_id",
      x["surveyor_id"],
      `Audios By: ${x["surveyor_name"]}`
    );
  };

  return (
    <div className="flex flex-col w-100 md:w-0">
      <TableHeader title={t("interviews_highlights")} />
      <Table data={data} columns={columns} onRowClick={row_click_handler} />
    </div>
  );
});
