import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import {
  file_icon,
  htmlValidation,
  makeid_alpa_numeric,
  TextError,
  useStore,
  useFileUpload,
} from "../../../helpers";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { safe_get } from "../../../report-visuals";
import { useTranslation } from "react-i18next";
import { FormButtons, UploadButton } from "../../../components";
import { toast } from "react-toastify";
import { VortexSpinner } from "../../digital-repository";
import { IconButton } from "@mui/material";
import dayjs from "dayjs";

export const AddMedia = observer((props: any) => {
  const { selected, refreshMedia } = props;
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [isSubmit, setIsSubmit] = useState(false);
  const [files, setFiles] = useState([]);
  const params = useParams();
  const { projectid } = params;
  const path = `project-${projectid}/projectworks/media`;
  const [buffer, setBuffer] = useState([]);
  const { projectWorksStore, flexiStore } = useStore();
  const { add_media_modal, edit_media_modal } = projectWorksStore;
  const { current_fsd } = flexiStore;
  const { t } = useTranslation("project_work");
  const { isUploading, uploadFileToS3 } = useFileUpload();

  const nameChange = (event) => {
    setName(event.target.value);
  };

  const descChange = (event) => {
    setDesc(event.target.value);
  };

  const cancelHandler = () => {
    projectWorksStore.update_project_work_property("add_media_modal", false);
    projectWorksStore.update_project_work_property("edit_media_modal", false);
  };

  const saveMedia = async () => {
    setIsSubmit(true);
    let mediaList;
    if (name !== "") {
      if (projectWorksStore.add_media_modal && buffer.length > 0) {
        const old_media_list_obj = safe_get(current_fsd.r1, "richmedia", []);
        mediaList = [...old_media_list_obj, ...buffer];
        console.log(mediaList);
      } else if (projectWorksStore.edit_media_modal) {
        mediaList = current_fsd.r1["richmedia"];
        if (mediaList.length > 0) {
          let fileType;
          let mediapath;
          let thumbnailpath;
          if (buffer.length > 0) {
            const updatedObj = buffer[0];
            fileType = updatedObj["file_type"];
            mediapath = updatedObj["mediapath"];
            thumbnailpath = updatedObj["thumbnailpath"];
          }
          const idx = _.findIndex(mediaList, { id: selected.id });
          mediaList[idx]["date"] = dayjs().format("DD/MM/YYYY");
          mediaList[idx]["type"] = fileType
            ? fileType
            : mediaList[idx]["fileType"];
          mediaList[idx]["title"] = name;
          mediaList[idx]["mediapath"] = mediapath
            ? mediapath
            : mediaList[idx]["mediapath"];
          mediaList[idx]["description"] = desc;
          mediaList[idx]["thumbnailpath"] = thumbnailpath
            ? thumbnailpath
            : mediaList[idx]["thumbnailpath"];
        }
      }
      await flexiStore.update_current_fsd("r1", {
        ...current_fsd.r1,
        richmedia: mediaList,
      });
      await flexiStore.api_update_current_fsd();
      refreshMedia(mediaList);
    }
    setIsSubmit(false);
    cancelHandler();
  };

  const delete_file = (name) => {
    const tmp = _.clone(buffer);
    const index = tmp.findIndex((a) => a.path === name);
    tmp.splice(index, 1);
    setBuffer(tmp);
  };

  useEffect(() => {
    if (!_.isEmpty(selected)) {
      setName(selected.title);
      setDesc(selected.description);
      setBuffer([...buffer, selected]);
    }
  }, [add_media_modal, edit_media_modal, selected]);

  const media_upload = async (e) => {
    htmlValidation(e);

    const filesArray = [...files];
    if (!filesArray.length) {
      toast.error("Please select the file...");
      return;
    }

    filesArray.forEach((fileToUpload, index) => {
      //Todo: timeout for better user experience while uploading multiple files.
      setTimeout(async () => {
        const uploadedData = await uploadFileToS3(
          path,
          fileToUpload,
          "urn-assets",
        );
        if (uploadedData?.status === 204) {
          setBuffer((prevUploadedFiles) => [
            ...prevUploadedFiles,
            {
              id: makeid_alpa_numeric(4),
              date: dayjs().format("DD/MM/YYYY"),
              type: fileToUpload["type"],
              title: name,
              mediapath: uploadedData.key,
              description: desc,
              thumbnailpath: uploadedData.key,
            },
          ]);
        }
      }, 1000 * index);
    });
  };

  return (
    <>
      {(projectWorksStore.add_media_modal ||
        projectWorksStore.edit_media_modal) && (
        <div className="">
          <div className="">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700 pt-4"
            >
              {t("name")}
            </label>
            <input
              type="text"
              className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border-solid border p-2"
              value={name}
              onChange={nameChange}
            />
            <div>
              {isSubmit && name === "" && TextError("Name is Required")}
            </div>
            {/*<label htmlFor="additional"*/}
            {/*       className="block text-sm font-medium text-gray-700 pt-4">{t("description")}</label>*/}
            {/*<textarea*/}
            {/*    className="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md border p-2"*/}
            {/*    rows={3}*/}
            {/*    value={desc}*/}
            {/*    onChange={descChange}*/}
            {/*>*/}
            {/*    </textarea>*/}
            <div className="p-2 flex flex-row justify-between items-end flex-wrap ml-1">
              <div className="flex flex-col">
                <label className="block text-gray-700 pt-4 rounded-lg text-left font-medium">
                  {t("uploaded_media")}
                </label>
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  name="mediaattachments"
                  id="mediaattachments"
                  className="py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  onChange={(e) => {
                    setFiles(Array.from(e.target.files));
                  }}
                />
              </div>
              <UploadButton uploadHandler={media_upload} />
              <VortexSpinner visible={isUploading} />
            </div>
            <div className="flex flex-col justify-between items-center">
              {buffer &&
                buffer.map((x) => {
                  if (!x.mediapath) return null;
                  return (
                    <div
                      key={x.id}
                      className="flex flex-row items-center justify-between"
                    >
                      <p className={"px-4"}>
                        <span>{file_icon(x.type?.split("/")[0])}</span>
                        {x.mediapath?.split("/")[3]} {}
                      </p>
                      <IconButton
                        color={"error"}
                        size={"small"}
                        onClick={() => delete_file(x.mediapath)}
                      >
                        <i className={"fas fa-trash"} />
                      </IconButton>
                    </div>
                  );
                })}
            </div>

            <FormButtons onSave={saveMedia} onCancel={cancelHandler} />
          </div>
        </div>
      )}
    </>
  );
});
