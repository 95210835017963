import React, { useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { RequestCard, RequestCardSkeleton } from "./request-card";
import { useStore } from "../../../../helpers";
import { AddRequestType } from "./add-request-type";
import { DeleteRequestType } from "./delete-request-type";
import { FTPSearchBar, MasonryGrid } from "../../../../components-new";
import { useTranslation } from "react-i18next";
import Fuse from "fuse.js";

const SEARCH_KEYS = [
  "type.name",
  "type.category",
  "type.subcategory",
  `type.display.text`,
  `type.description.text`,
  `type.tags`,
];

export const RequestTypesHome = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { meta, loading } = serviceRequestsStore;
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation("sr-request-type");

  const fuseOptions = {
    keys: SEARCH_KEYS, // Fields to search in
    includeScore: true, // Include score in results
    threshold: 0.3, // Lower threshold means more strict matching
  };

  const fuse = useMemo(() => new Fuse(meta?.type, fuseOptions), []);

  const searchResults = searchQuery
    ? fuse.search(searchQuery)
    : meta?.type.map((item) => ({ item }));

  return (
    <div className={"overflow-hidden"}>
      <FTPSearchBar
        onSearch={(val) => setSearchQuery(val)}
        placeHolder={t("search_request_type")}
        clientSideSearch={true}
      />
      <MasonryGrid
        data={searchResults}
        isLoading={loading}
        skeletonCount={8}
        renderItem={(type) => (
          <RequestCard key={type["item"]["id"]} request={type["item"]} />
        )}
        renderSkeleton={(key) => <RequestCardSkeleton key={key} />}
      />
      <AddRequestType />
      <DeleteRequestType />
    </div>
  );
});
