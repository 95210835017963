import React from "react";
import { Button, IconButton } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { FTPSelect, FTPInput } from "../../../../components-new";
import { LANGUAGE_OPTIONS } from "../sr-settings-utils";
import { useFieldArray, useFormContext } from "react-hook-form";

interface DynamicFieldGroupProps {
  label: string;
  name: string;
}

export const DynamicFieldGroup = (props: DynamicFieldGroupProps) => {
  const { label, name } = props;

  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name: name, // Matches the defaultValues key
  });

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-center">
        <p className="font-medium">{label}</p>
        <Button
          variant={"contained"}
          color={"info"}
          onClick={() => append({ language: "", text: "" })}
          size={"small"}
          startIcon={<AddCircleOutlineIcon />}
        >
          Add
        </Button>
      </div>

      {fields.map((field, index) => (
        <div key={field.id} className="flex flex-row gap-4">
          <div className="w-full sm:w-32">
            <FTPSelect
              label={`Language ${index + 1}`}
              name={`${name}[${index}].language`}
              options={LANGUAGE_OPTIONS}
            />
          </div>
          <div className="flex-1">
            <FTPInput label="Display" name={`${name}[${index}].text`} />
          </div>
          {index !== 0 ? (
            <IconButton
              color={"error"}
              disableFocusRipple
              size={"small"}
              onClick={() => remove(index)}
            >
              <i className={"fas fa-trash"} />{" "}
            </IconButton>
          ) : null}
        </div>
      ))}
    </div>
  );
};
