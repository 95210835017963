import React, { useCallback, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SideOverlay } from "../../../../components";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../helpers";
import { FTPFormButton, FTPInput } from "../../../../components-new";
import {
  defaultPriorityRequest,
  priorityValidationSchema,
} from "./priority-schema";
import {
  useEditPriorityRequest,
  useAddPriorityRequest,
} from "./priority-hooks";
import { useParams } from "react-router-dom";
import { safe_get } from "../../../../report-visuals";
import _ from "lodash";
import FormControlLabel from "@mui/material/FormControlLabel";
import { DynamicFieldGroup } from "../sr-settings-components";
import { VortexSpinner } from "../../../../features/digital-repository";
import Checkbox from "@mui/material/Checkbox";
import { useTranslation } from "react-i18next";

export const AddPriority = observer((props: any) => {
  const { projectid } = useParams();
  const { serviceRequestsStore } = useStore();
  const { activePriority } = serviceRequestsStore;
  const { t } = useTranslation("sr_priority");
  const {
    mutate: addRequest,
    isLoading: addLoading,
    isSuccess: isAddSuccess,
  } = useAddPriorityRequest();

  const {
    mutate: updateRequest,
    isSuccess: isEditSuccess,
    isLoading: editLoading,
  } = useEditPriorityRequest();

  const id = safe_get(activePriority, "id", null);

  const methods = useForm({
    defaultValues: defaultPriorityRequest,
    resolver: yupResolver(priorityValidationSchema),
  });

  const onSubmit = async (data) => {
    const payload = id
      ? { ...activePriority, priority: data } // Update existing priority
      : { projectid: Number(projectid), priority: data, more: {} }; // Add new priority

    if (id) {
      await updateRequest(payload);
    } else {
      await addRequest(payload);
    }
  };

  const close_modal = useCallback(async () => {
    serviceRequestsStore.update_modal_status("addPriority", false);
    serviceRequestsStore.setActivePriority({});
    methods.reset();
  }, []);

  useEffect(() => {
    methods.reset(
      id ? _.clone(activePriority)?.priority : { ...defaultPriorityRequest }
    );
  }, [activePriority]);

  useEffect(() => {
    if (isEditSuccess || isAddSuccess) {
      close_modal();
    }
  }, [isEditSuccess, isAddSuccess]);

  return (
    <>
      <SideOverlay
        onClose={close_modal}
        show={serviceRequestsStore.modals.addPriority}
        title={id ? t("edit_priority") : t("delete_priority")}
        size={"large"}
      >
        {/*  */}
        <FormProvider {...methods}>
          <form
            className={"flex flex-col bg-white rounded-xl p-4 gap-y-4"}
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <div className="flex flex-row gap-x-4">
              <div className="flex-1">
                <FTPInput name={"name"} label={t("name")} />
              </div>
              <div className="flex-1">
                <FTPInput name={"type"} label={t("type")} />
              </div>
            </div>

            <div className="flex flex-row gap-x-4">
              <div className="flex-1">
                <DynamicFieldGroup label={t("display")} name={"display"} />
              </div>
            </div>

            <FormControlLabel
              control={
                <Checkbox
                  {...methods.register("default")}
                  checked={methods.watch("default")}
                />
              }
              label={t("set_priority_default")}
            />

            <div className="flex gap-x-2 justify-end mt-4">
              <VortexSpinner visible={addLoading || editLoading} />
              <FTPFormButton
                actionType="Cancel"
                handleButtonClick={close_modal}
              />
              <FTPFormButton
                type={"submit"}
                actionType="Save"
                handleButtonClick={() => {}}
              />
            </div>
          </form>
        </FormProvider>
      </SideOverlay>
    </>
  );
});
