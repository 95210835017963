import React from "react";
import { FilteredTags } from "./FilteredTags";
import { TitleSection } from "./TitleSection";
import { PaginationAndFilters } from "./PaginationAndFilters";

export const NewProjectWorksHeader = () => {
  return (
    <>
      <TitleSection />
      <PaginationAndFilters />
      <FilteredTags />
    </>
  );
};
