import { Field, Form, Formik, useFormikContext } from "formik";
import { observer } from "mobx-react-lite";
import React, { useState, useEffect, useRef } from "react";
import FormikController from "../../components/formik/formik-controller";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { safe_get } from "../../report-visuals";
import { toast } from "react-toastify";
import {
  AttachmentsList,
  htmlValidation,
  makeid_alpa_numeric,
  remove_item_from_array,
  SubHeading,
  useStore,
  uploadFile,
} from "../../helpers";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import _, { update } from "lodash";
import { AddEngineer } from "./add-engineer";
import { useTranslation } from "react-i18next";
import { AddVoters } from "./add-persons-from-voter-list";
import Select from "react-select";
import { AddUser } from "./add-user-from-voterlist";
import { input_style, label_style } from "../beneficiary-management";
import {
  BoothTree,
  colourStyles,
  FormButtons,
  LoadingState,
  SearchVotersButton,
  SideOverlay,
  UploadButton,
} from "../../components";
import { FilterOverLay } from "../voter-list";
import { EditVoter } from "../manage-voters";
import { Button } from "@mui/material";
import { toJS } from "mobx";
import dayjs from "dayjs";

const form_field_style =
  "form-control p-1 sm:grid sm:grid-cols- sm:items-start d-flex flex-column text-left";

const selected_voter = {
  name: "",
  phone: "",
  whatsapp: "",
  voter_id: "",
  aadhar: "",
  address: "",
  caste: "",
  dob: "",
  age: 0,
  gender: "",
};

const summary = {
  title: "",
  category_level_1: "",
  category_level_2: "",
  location: "",
  piv_level_1: "",
  description: "",
  current_status: "",
  priority: "",
};
const genders = ["Male", "Female", "Third Gender"];

export const AddEditGrievance = observer((props: any) => {
  const rootStore = useStore();
  const { projectid } = useParams();
  const {
    grievanceStore,
    flexiStore,
    projectStore,
    userStore,
    voterListStore,
  } = rootStore;
  const { activeVoter } = voterListStore;
  const {
    grievance_edit_mode,
    selectedGrievance,
    isAddNewEnginner,
    selectedDepartment,
  } = grievanceStore;
  const [loading, setLoading] = useState(false);

  const initialChoices = [{ key: "Select", value: "" }];

  const grievanceProperties = projectStore.getProjectProperty("grievances", {});
  const [StatusOptions, setStatusOptions] = useState([]);
  const [category_level_1_options, setCategoryLevel1Options] = useState([]);
  const [category_level_2_options, setCategoryLevel2Options] = useState([]);
  const [hierarchyOptions, sethierarchyOptions] = useState([]);
  const [priorityOptions, setPriorityOptons] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const defaultStatus = safe_get(
    projectStore.getProjectProperty("grievances", {}),
    "default_status",
    "",
  );
  const defaultPriority = safe_get(
    projectStore.getProjectProperty("grievances", {}),
    "default_priority",
    "",
  );
  const [inputKey, setInputKey] = useState("");
  const [file, setFile] = useState({});
  const [buffer, setBuffer] = useState([]);
  const [mediainputKey, setMediaInputKey] = useState("");
  const [mediafile, setMediaFile] = useState({});
  const [mediabuffer, setmediaBuffer] = useState([]);
  const [grievanceSubmit, setGrievanceSubmit] = useState(false);
  const [isAttachementComplete, setAttachmentComplete] = useState(true);
  const [isRichMediaComplete, setRichMediaComplete] = useState(true);
  const [comments, setComments] = useState("");
  const [selectedEngineer, setSelectedEngineer] = useState({} as any);
  const [userExist, setUserExist] = useState(true);
  const { t } = useTranslation(["grievance_management", "beneficiary"]);
  const [newComplaint, setNewComplaint] = useState("");
  const [piv_level_2, setPiv_level_2] = useState(
    grievance_edit_mode ? selectedGrievance.summary.piv_level_2 : [],
  );
  const beneficiary = projectStore.getProjectProperty("beneficiary", {});
  const concerned_office_persons = safe_get(beneficiary, "owners", []);
  const village_key = safe_get(
    projectStore.getProjectProperty("grievances", {}),
    "more.village_selector",
    "piv_level1",
  );
  const [casteOptions, setCasteOptions] = useState([]);
  const summaryRef = useRef(null);

  useEffect(() => {
    const selected_voter = {
      name: activeVoter?.profile?.name_eng,
      phone: safe_get(activeVoter, "phone", ""),
      whatsapp: safe_get(activeVoter, "whatsapp", ""),
      voter_id: activeVoter?.profile?.voterid,
      aadhar: "",
      address: activeVoter?.profile?.address,
      caste: activeVoter?.profile?.caste,
      dob: activeVoter?.profile?.dob,
      age: safe_get(activeVoter["profile"], "age", 0),
      // booth_number: safe_get(activeVoter, "part", safe_get(activeBeneficiary["summary"], "profile.booth_number", "N/A")),
      gender: safe_get(activeVoter["profile"], "gender", "N/A"),
      // description: "",
    };
    if (grievance_edit_mode) {
      setInitialValues({
        ...initialValues,
        userDetails: safe_get(
          selectedGrievance.summary,
          "reported_by",
          selected_voter,
        ),
        summary: safe_get(selectedGrievance, "summary", summary),
        comments: selectedGrievance.r1.timeline_log[0].comments,
        dept: selectedGrievance.summary.dept,
      });
      grievanceStore.update_grievance_store_property(
        "selected_voters",
        safe_get(selectedGrievance.r1, "voters", []),
      );
    } else {
      setInitialValues({
        ...initialValues,
        summary: summaryRef.current
          ? summaryRef.current
          : initialValues.summary,
        userDetails: selected_voter,
      });
    }
  }, [activeVoter]);

  const [initialValues, setInitialValues] = useState({
    summary: summary,
    userDetails: selected_voter,
    comments: "",
    dept: "",
    assignedTo: "",
    assigned_office_person: "",
  });
  useEffect(() => {
    console.log(toJS(initialValues));
  }, [initialValues]);
  const [selectedCatergory1, setSelectedCatefory1] = useState("");
  const [selectedCatergory2, setSelectedCategory2] = useState("");
  const [autoCompleteOptions, setAutoCompleteOptions] = useState([]);
  const [officePerson, setOfficePerson] = useState({});

  const ValidationSchema = Yup.object().shape({
    summary: Yup.object().shape({
      title: Yup.string().required("Title is required"),
      // location: Yup.string().required('Location is required'),
      description: Yup.string().required("Description is required"),
      // category_level_1: Yup.string().required('Department is required'),
      // category_level_2: Yup.string().required('Complaint is required'),
      current_status: Yup.string().required("Status is required"),
      // priority: Yup.string().required('Priority is required')
    }),
    userDetails: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      phone: Yup.number().required("Phone is required"),
      // whatsapp:Yup.number().required('whatsapp is required'),
      // voter:Yup.string().required('voter is required'),
      // aadhar:Yup.number().required('aadhar is required'),
      // dob:Yup.string().required('dob is required'),
      // age:Yup.number().required('age is required'),
      address: Yup.string().required("Address is required"),
      // description: Yup.string().required('description is required'),
      // gender:Yup.string().required('gender is required'),
    }),
    // dept: Yup.string().required('Concerned Department is required')
  });

  const formSubmit = async (values) => {
    console.log(values);
    const summary_payload = {
      ...values.summary,
      piv_level_2: piv_level_2,
      reported_by: { ...values.userDetails },
      dept: values.dept,
      assigned: { ...selectedEngineer },
      assigned_office_person: { ...officePerson },
      sla: "1w 1h 2d",
      ts_create: dayjs().format("DD/MM/YYYY HH:mm:ss a"),
    };
    console.log(summary_payload);
    let Attachments = [];
    let richMedia = [];

    if (buffer.length > 0) {
      for (let ele of buffer) {
        Attachments.push({ path: ele.path, id: ele.id, type: ele.type });
      }
    }
    if (mediabuffer.length > 0) {
      for (let ele of mediabuffer) {
        richMedia.push({ path: ele.path, id: ele.id, type: ele.type });
      }
    }
    let r1Payload = {
      timeline_log: [
        {
          id: makeid_alpa_numeric(3),
          status: values.summary.current_status,
          comments: values.comments,
          ts: dayjs().format("DD/MM/YYYY HH:mm:ss"),
          who: {
            //current user info
            name: userStore.user.profile.name,
            phone: userStore.user.phonenum,
            address: userStore.user.profile.address,
            comments: values.comments,
            profileImage: userStore.user.profileimageuri,
          },
        },
      ],
      richmedia: [...richMedia],
      attachments: [...Attachments],
      voters: safe_get(grievanceStore, "selected_voters", []),
    };
    console.log(r1Payload);
    if (grievance_edit_mode) {
      r1Payload.timeline_log.push(...selectedGrievance.r1.timeline_log);
    }
    await flexiStore.update_current_fsd("summary", summary_payload);
    await flexiStore.update_current_fsd("r1", r1Payload);
    await flexiStore.update_current_fsd("projectid", parseInt(projectid));
    setLoading(true);
    const res = await flexiStore.api_update_current_fsd();
    try {
      if (res.status == 200) {
        setLoading(false);
        toast.success(t("grievance_updated_sucessfully"));
        grievanceStore.update_grievance_store_property(
          "grievance_edit_mode",
          false,
        );
        grievanceStore.update_grievance_store_property(
          "isShowGrievanceForm",
          false,
        );
        grievanceStore.update_grievance_store_property(
          "isViewGrievance",
          false,
        );
        props.getGrievanceList();
      } else {
        toast.error(t("something_went_wrong"));
      }
    } catch (e) {
      setLoading(false);
    }
    //reset active voter and other voters as empty
    voterListStore.update_active_voter({});
    grievanceStore.update_grievance_store_property("selected_voters", []);
  };

  const getOptions = (value) => {
    return value.map((ele) => {
      return { key: ele, value: ele };
    });
  };

  const getselectoptions = (value) => {
    return value.map((ele) => {
      return { label: ele, value: ele };
    });
  };

  const prepare_status_options = (status_array) => {
    return status_array.map((x) => {
      return {
        key: x.status,
        value: x.status,
      };
    });
  };

  const hierarchy_option = (hierarchy) => {
    return hierarchy.map((x) => {
      return {
        key: x.label,
        value: x.value,
      };
    });
  };

  useEffect(() => {
    const projectProperties = projectStore.getProjectProperty("grievances", {});
    const profile_questions = projectStore.getProjectProperty(
      "profile_questions",
      {},
    );
    const statusOption = prepare_status_options(
      safe_get(projectProperties, "status_config", []),
    );
    const piv_level1_options = hierarchy_option(
      projectStore.getProjectProperty("hierarchy", []),
    );
    const category1Options = getOptions(
      Object.keys(safe_get(projectProperties, "categories", {})),
    );
    const departmentOptions = getOptions(
      Object.keys(safe_get(projectProperties, "departments", {})),
    );
    const priorityOption = getOptions(
      safe_get(projectProperties, "priority", []),
    );
    setStatusOptions([...initialChoices, ...statusOption]);
    sethierarchyOptions([...initialChoices, ...piv_level1_options]);
    setCategoryLevel1Options([...initialChoices, ...category1Options]);
    setPriorityOptons([...initialChoices, ...priorityOption]);
    setDepartmentOptions([...initialChoices, ...departmentOptions]);
    setCasteOptions([
      ...initialChoices,
      ...getOptions(
        safe_get(profile_questions, "caste", []).map((x) => x.label),
      ),
    ]);
    if (grievance_edit_mode) {
      setSelectedCatefory1(selectedGrievance.summary.category_level_1);
      setBuffer(
        selectedGrievance.r1.attachments
          ? selectedGrievance.r1.attachments
          : [],
      );
      setmediaBuffer(
        selectedGrievance.r1.richmedia ? selectedGrievance.r1.richmedia : [],
      );
      setComments(
        selectedGrievance.r1.timeline_log[0].comments
          ? selectedGrievance.r1.timeline_log[0].comments
          : "",
      );
      setSelectedEngineer(
        selectedGrievance.summary.assigned
          ? selectedGrievance.summary.assigned
          : {},
      );
      // grievanceStore.setSelectedDepartment(selectedGrievance.summary.dept)
    }
  }, [projectid]);

  useEffect(() => {
    if (selectedCatergory1 !== "") {
      const complaintOptions = getselectoptions(
        safe_get(grievanceProperties, `categories.${selectedCatergory1}`, []),
      );

      setCategoryLevel2Options([...complaintOptions]);
      setSelectedCategory2(safe_get(complaintOptions[0], "value", ""));
    }
  }, [selectedCatergory1]);

  useEffect(() => {
    if (selectedDepartment !== "") {
      const userOptions = safe_get(
        grievanceProperties,
        `departments.${selectedDepartment}.owners`,
        [],
      );
      console.log(selectedDepartment);
      console.log(userOptions);
      // const userOptions = grievanceProperties&&grievanceProperties["departments"]&&grievanceProperties["departments"][selectedDepartment]&&grievanceProperties["departments"][selectedDepartment]["owners"]
      setAutoCompleteOptions(userOptions);
    }
  }, [selectedDepartment]);

  const onEngSave = (eng) => {
    setAutoCompleteOptions([...autoCompleteOptions, eng]);
  };
  const handleFormChange = (e) => {
    if (e.target.id === "summary.category_level_1") {
      setSelectedCatefory1(e.target.value);
    }
    if (e.target.id === "summary.category_level_2") {
      setSelectedCategory2(e.target.value);
    }
    if (e.target.id === "comments") {
      setComments(e.target.value);
    }
    if (e.target.id === "summary.current_status") {
      setComments("");
    }
    if (e.target.id === "dept") {
      grievanceStore.update_grievance_store_property(
        "selectedDepartment",
        e.target.value,
      );
      setSelectedEngineer({});
    }
  };

  const onCancelHandler = () => {
    grievanceStore.update_grievance_store_property(
      "isShowGrievanceForm",
      false,
    );
    grievanceStore.update_grievance_store_property(
      "grievance_edit_mode",
      false,
    );
  };

  const attachementsPath = `project-${projectid}/grievance/${flexiStore.current_fsd.id}/docs`;
  const mediaPath = `project-${projectid}/grievance/${flexiStore.current_fsd.id}/media`;

  const delete_file = (name) => {
    const updated_values = remove_item_from_array(buffer, "path", name);
    setBuffer(updated_values);
  };

  const delete_media_file = (name) => {
    const updated_values = remove_item_from_array(mediabuffer, "path", name);
    setmediaBuffer(updated_values);
  };

  useEffect(() => {
    if (Object.keys(file).length > 0) {
      const temp =
        buffer.filter((ele) => ele.path.split("/")[5] == file["file"]["name"])
          .length > 0;
      setAttachmentComplete(temp);
    }
    if (Object.keys(mediafile).length > 0) {
      const temp =
        mediabuffer.filter(
          (ele) => ele.path.split("/")[5] == mediafile["file"]["name"],
        ).length > 0;
      setRichMediaComplete(temp);
    }
  }, [file, mediafile, buffer, mediabuffer]);

  const labelStyle = label_style;
  const fieldStyle = input_style;

  const addNewComplaintHandler = async () => {
    setLoading(true);
    setCategoryLevel2Options([
      ...category_level_2_options,
      {
        label: newComplaint,
        value: newComplaint,
      },
    ]);
    const activeProjectProperties = JSON.parse(
      localStorage.getItem("__active_project"),
    );
    const id = safe_get(activeProjectProperties, "id", 1);
    const payload = {
      id: id,
      properties: {
        ...activeProjectProperties.properties,
        grievances: {
          type: "json",
          value: {
            ...activeProjectProperties.properties.grievances.value,
            categories: {
              ...activeProjectProperties.properties.grievances.value.categories,
              [selectedCatergory1]: [
                ...activeProjectProperties.properties.grievances.value
                  .categories[selectedCatergory1],
                newComplaint,
              ],
            },
          },
        },
      },
    };
    const result = await projectStore.editProjectProperties(payload);
    if (result.status === 200) {
      toast.success(t("new_compliant_suc"));
    } else {
      toast.error(t("something_went_wrong"));
    }
    setLoading(false);
  };

  const FormFields = () => {
    const { values } = useFormikContext();
    summaryRef.current = values["summary"];
    return (
      <>
        <div className={"flex flex-col rounded-md"}>
          <SubHeading title={"Grievance Information"} />
          <div className={form_field_style}>
            <FormikController
              control="input"
              type="text"
              label={t("title")}
              name="summary.title"
              labelStyle={labelStyle}
              fieldStyle={fieldStyle}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="select"
              label={t("department")}
              name="summary.category_level_1"
              options={category_level_1_options}
              labelStyle={labelStyle}
              fieldStyle={`${fieldStyle} bg-white`}
            />
          </div>

          {selectedCatergory1 !== "" && (
            <div
              className={
                "flex flex-row items-center justify-center p-1 formik-select"
              }
            >
              <label className={label_style}> {t("complaint")}</label>
              <Select
                name="complaint"
                options={category_level_2_options}
                value={{ label: selectedCatergory2, value: selectedCatergory2 }}
                onChange={(val, action) => {
                  setSelectedCategory2(val?.value);
                }}
                styles={colourStyles}
                placeholder="Select complaint..."
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                menuPortalTarget={document.querySelector("body")}
              />
              {/*<FormikController*/}
              {/*    control="select"*/}
              {/*    label={t("complaint")}*/}
              {/*    name="summary.category_level_2"*/}
              {/*    options={category_level_2_options}*/}
              {/*    labelStyle={labelStyle}*/}
              {/*    fieldStyle={`${fieldStyle} bg-white`}*/}
              {/*/>*/}
            </div>
          )}
          {(selectedCatergory2 === "Others" ||
            selectedCatergory2 === "Other") && (
            <div
              className={
                "form-control p-2 flex flex-row items-center justify-between text-left"
              }
            >
              <input
                className={`${fieldStyle} w-1/2`}
                type="text"
                value={newComplaint}
                onChange={(e) => setNewComplaint(e.target.value)}
              />
              <button
                type={"button"}
                className="btn btn-sm fp-bg mx-2"
                onClick={addNewComplaintHandler}
              >
                Add
              </button>
            </div>
          )}
          <div className={form_field_style}>
            <FormikController
              control="select"
              type="text"
              label={t("Hobli/Zp")}
              name="summary.piv_level_1"
              options={hierarchyOptions}
              labelStyle={labelStyle}
              fieldStyle={`${fieldStyle} bg-white`}
            />
          </div>
          <div className="flex flex-row items-center justify-center p-1 formik-select">
            <label htmlFor="village" className={labelStyle}>
              {t("location")}
            </label>
            <BoothTree
              onBoothSelection={(data) => {
                setPiv_level_2(data);
              }}
              selected_booth={piv_level_2}
              selector={village_key}
              isMulti={true}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="textArea"
              label={t("description")}
              name="summary.description"
              labelStyle={"text-sm text-gray-600"}
              fieldStyle={fieldStyle}
              height={25}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="select"
              label={t("status")}
              name="summary.current_status"
              options={StatusOptions}
              labelStyle={labelStyle}
              fieldStyle={`${fieldStyle} bg-white`}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="select"
              label={t("priority")}
              name="summary.priority"
              options={priorityOptions}
              labelStyle={labelStyle}
              fieldStyle={`${fieldStyle} bg-white`}
            />
          </div>
        </div>
        <div className="divider" />
        <SubHeading title={t("office_person")} />
        <div className={form_field_style}>
          <label htmlFor="assignedTo" className={labelStyle}>
            {" "}
            {t("assign_to_op")}{" "}
          </label>
          <Autocomplete
            size={"small"}
            className={"bg-white"}
            id="select-user"
            value={officePerson ?? ["name"]}
            options={concerned_office_persons}
            autoHighlight={true}
            filterOptions={(options, { inputValue }) => {
              return options.filter(
                (item) =>
                  item["name"].includes(inputValue) ||
                  item["phone"].includes(inputValue),
              );
            }}
            getOptionLabel={(option) => safe_get(option, "name", "")}
            onChange={(event, v) => {
              htmlValidation(event);
              if (v) {
                setOfficePerson({ ...v });
              } else {
                setOfficePerson({});
              }
            }}
            renderOption={(props, option) => (
              <Box
                key={option["id"]}
                component="li"
                sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <div>
                  <p>{option["name"]}</p>
                  <p className="italic text-sm">{option["designation"]}</p>
                </div>
              </Box>
            )}
            renderInput={(params) => (
              <Field
                component={TextField}
                name="assignedTo"
                {...params}
                className={fieldStyle}
              />
            )}
          />
        </div>
        <div className={form_field_style}>
          <FormikController
            control="select"
            label={t("concerned_departement")}
            name="dept"
            options={departmentOptions}
            labelStyle={labelStyle}
            fieldStyle={`${fieldStyle} bg-white`}
          />
        </div>
        {selectedDepartment !== "" && (
          <div className={form_field_style}>
            <label htmlFor="assignedTo" className={labelStyle}>
              {" "}
              {t("engineer")}{" "}
            </label>
            <Autocomplete
              size={"small"}
              id="select-user"
              className={"bg-white"}
              value={selectedEngineer ?? ["name"]}
              options={autoCompleteOptions}
              autoHighlight={true}
              filterOptions={(options, { inputValue }) => {
                const filteredOptions = options.filter(
                  (item) =>
                    item["name"].includes(inputValue) ||
                    item["phone"].includes(inputValue),
                );
                if (filteredOptions.length <= 0) {
                  setUserExist(false);
                } else {
                  setUserExist(true);
                }
                return filteredOptions;
              }}
              getOptionLabel={(option) => safe_get(option, "name", "")}
              onChange={(event, v) => {
                htmlValidation(event);
                if (v) {
                  setSelectedEngineer({ ...v });
                } else {
                  setSelectedEngineer({});
                }
                // const clone = _.clone(activeTask);
                // clone["user"] = v;
                // clone["userid"] = safe_get(v, "id", -1);
                // setActiveTask(clone);
              }}
              renderOption={(props, option) => (
                <Box
                  key={option["id"]}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                  {...props}
                >
                  <img
                    loading="lazy"
                    width="40"
                    src={option["profileimageuri"]}
                  />
                  <div>
                    <p>{option["name"]}</p>
                    <p className="italic text-sm">{option["designation"]}</p>
                  </div>
                </Box>
              )}
              renderInput={(params) => (
                // <TextField
                //     {...params}
                //     label="Select User"
                //     onChange={event => {
                //         htmlValidation(event);
                //         console.log("event", event.target.value)
                //     }}
                //     inputProps={{
                //         ...params.inputProps,
                //         autoComplete: 'new-password', // disable autocomplete and autofill
                //     }}
                // />
                <Field
                  component={TextField}
                  name="assignedTo"
                  {...params}
                  className={fieldStyle}
                />
              )}
            />

            {
              <a
                className="link link-hover fp-text underline text-sm font-bold text-right"
                onClick={() => {
                  // grievanceStore.udpate_add_engineer_modal(true)
                  grievanceStore.update_grievance_store_property(
                    "isAddNewEnginner",
                    true,
                  );
                }}
              >
                {t("add_engineer")} +
              </a>
            }
          </div>
        )}
        {/* {Object.keys(selectedEngineer).length <= 0 && grievanceSubmit && selectedDepartment !== "" &&
                    <div className="flex flex-row">
                        <div className="error text-sm text-red-600">
                            <i className="text-sm fa-solid fa-triangle-exclamation text-red-600 text-sm pr-2" />
                            {t("engineer_required")}
                        </div>
                    </div>} */}
      </>
    );
  };

  const searchVotersHandler = async () => {
    voterListStore.setShowVoterlist(true);
    grievanceStore.update_grievance_store_property(
      "add_user_from_voterlist",
      true,
    );
    const clone = _.clone(voterListStore.voter_filters);
    clone["projectid"] = parseInt(projectid);
    await voterListStore.update_filters(clone);
  };

  const addVoterHandler = async (e) => {
    grievanceStore.update_grievance_store_property("show_gvoter_list", true);
    const clone = _.clone(voterListStore.voter_filters);
    clone["projectid"] = parseInt(projectid);
    await voterListStore.update_filters(clone);
  };

  const uploadAttachmentsToS3 = (e) => {
    htmlValidation(e);
    if (!file["file"]) return false;
    const s3Path = uploadFile(attachementsPath, file, "urn-assets");
    if (s3Path) {
      const randomString = Math.random().toString(6);
      setInputKey(randomString);
      // @ts-ignore
      e.target.form.reset(); // Clear the input file
      setFile({});
    }
    const file_type = file["file"]["type"];
    setBuffer([
      ...buffer,
      { path: s3Path, type: file_type, id: makeid_alpa_numeric(8) },
    ]);
  };

  const uploadMediaToS3 = (e) => {
    htmlValidation(e);
    if (!mediafile["file"]) return false;
    const s3Path = uploadFile(mediaPath, mediafile, "urn-assets");
    if (s3Path) {
      const randomString = Math.random().toString(6);
      setMediaInputKey(randomString);
      // @ts-ignore
      e.target.form.reset();
      setMediaFile({});
    }
    const file_type = mediafile["file"]["type"];
    setmediaBuffer([
      ...mediabuffer,
      {
        path: s3Path,
        type: file_type,
        id: makeid_alpa_numeric(8),
      },
    ]);
  };

  return (
    <>
      {loading && <LoadingState />}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={formSubmit}
        validationSchema={ValidationSchema}
      >
        <Form
          onChange={handleFormChange}
          className="rounded-lg flex flex-col"
          placeholder={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
        >
          <FormFields />
          <div className="divider" />
          <div className="flex flex-row items-center justify-between mb-2">
            <SubHeading title={t("user_details")} />
            <SearchVotersButton searchVotersHandler={searchVotersHandler} />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="input"
              type="text"
              label={t("name")}
              name="userDetails.name"
              labelStyle={labelStyle}
              fieldStyle={fieldStyle}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="input"
              type="text"
              label={t("phone_number")}
              name="userDetails.phone"
              labelStyle={labelStyle}
              fieldStyle={fieldStyle}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="input"
              type="text"
              label={t("whatsapp_number")}
              name="userDetails.whatsapp"
              labelStyle={labelStyle}
              fieldStyle={fieldStyle}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="input"
              type="text"
              label={t("voter_iD")}
              name="userDetails.voter_id"
              labelStyle={labelStyle}
              fieldStyle={fieldStyle}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="input"
              type="text"
              label={t("aadhar_id")}
              name="userDetails.aadhar"
              labelStyle={labelStyle}
              fieldStyle={fieldStyle}
            />
          </div>

          <div className={form_field_style}>
            <FormikController
              control="input"
              type="date"
              label={t("date_of_birth")}
              name="userDetails.dob"
              labelStyle={labelStyle}
              fieldStyle={fieldStyle}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="input"
              type="number"
              label={t("age")}
              name="userDetails.age"
              labelStyle={labelStyle}
              fieldStyle={fieldStyle}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="input"
              type="text"
              label={t("address")}
              name="userDetails.address"
              labelStyle={labelStyle}
              fieldStyle={fieldStyle}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="select"
              label={t("caste")}
              name="userDetails.caste"
              options={casteOptions}
              labelStyle={labelStyle}
              fieldStyle={`${fieldStyle} bg-white`}
            />
          </div>
          <div className={form_field_style}>
            <FormikController
              control="select"
              label={t("gender")}
              name="userDetails.gender"
              options={[...initialChoices, ...getOptions(genders)]}
              labelStyle={labelStyle}
              fieldStyle={`${fieldStyle} bg-white`}
            />
          </div>
          {/*<div className={form_field_style}>*/}
          {/*    <FormikController*/}
          {/*        control="input"*/}
          {/*        type="text"*/}
          {/*        label={t("more_details")}*/}
          {/*        name="userDetails.description"*/}
          {/*        labelStyle={labelStyle}*/}
          {/*        fieldStyle={fieldStyle}*/}
          {/*    />*/}
          {/*</div>*/}

          <div className={"flex flex-row self-end"}>
            <Button
              onClick={addVoterHandler}
              size={"small"}
              variant={"contained"}
              startIcon={<i className="fas fa-plus-circle" />}
            >
              Add Voters
            </Button>
          </div>

          {grievanceStore.selected_voters.length > 0 && (
            <>
              <div className="font-bold p-2 text-md ">
                Persons Added from Voter List
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                {grievanceStore.selected_voters.map((voter) => {
                  return (
                    <div className="bg-white rounded-lg my-2 p-2">
                      <div
                        className={"flex flex-row items-center justify-between"}
                      >
                        <p className="font-bold text-md">
                          {voter["profile"]["name_eng"]}
                        </p>
                        <i
                          className="fas fa-trash text-red-500 cursor-pointer"
                          onClick={(e) => {
                            const updated = remove_item_from_array(
                              grievanceStore.selected_voters,
                              "id",
                              voter["id"],
                            );
                            // grievanceStore.udpate_slelected_voters(updated);
                            grievanceStore.update_grievance_store_property(
                              "selected_voters",
                              updated,
                            );
                          }}
                        />
                        <i
                          color="#3182CE"
                          onClick={() => {
                            voterListStore.update_edit_voter_modal(
                              !voterListStore.edit_voter_modal,
                            );
                            voterListStore.update_active_voter(voter);
                          }}
                          className="cursor-pointer fas fa-pencil h-8 w-8"
                        />
                      </div>
                      <p>{voter["phone"]}</p>
                      <p>{voter["profile"]["address"]}</p>
                    </div>
                  );
                })}
              </div>
            </>
          )}
          <div className="divider" />
          <div className="py-2 flex flex-row justify-between items-end flex-wrap ml-1">
            <div className="flex flex-col">
              <SubHeading title={t("attachments")} />
              <input
                key={inputKey}
                type="file"
                name="attachments"
                id="attachments"
                className="py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setFile({ file });
                }}
              />
            </div>
            <UploadButton uploadHandler={uploadAttachmentsToS3} />
          </div>

          {!isAttachementComplete && (
            <div className="error text-sm text-red-600">
              {t("upload_the_attachment")}
            </div>
          )}

          <div className="flex flex-col justify-between itesm-center">
            {buffer && buffer.length > 0 && (
              <div className="font-bold text-gray-400 text-sm">
                {t("uploaded_documents")}
              </div>
            )}
            {buffer &&
              buffer.length > 0 &&
              buffer.map((file) => {
                return <AttachmentsList file={file} onDelete={delete_file} />;
              })}
          </div>

          <div className="divider" />

          <div className="flex flex-row justify-between items-end flex-wrap ml-1">
            <div className="flex flex-col">
              <SubHeading title={t("media")} />
              <input
                key={mediainputKey}
                type="file"
                accept="image/png, image/gif, image/jpeg,video/mp4,video/x-m4v,video/*"
                name="Media"
                id="Media"
                className="py-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                onChange={(e) => {
                  const file = e.target.files[0];
                  setMediaFile({ file });
                }}
              />
            </div>
            <UploadButton uploadHandler={uploadMediaToS3} />
          </div>
          {!isRichMediaComplete && (
            <div className="error text-sm text-red-600">
              {t("upload_the_media")}
            </div>
          )}
          <div className="flex flex-col justify-between itesm-center">
            {mediabuffer && mediabuffer.length > 0 && (
              <div className="font-bold text-gray-400 text-sm">
                {t("uploaded_media")}
              </div>
            )}
            {mediabuffer.length > 0 &&
              mediabuffer.map((file) => {
                return (
                  <AttachmentsList file={file} onDelete={delete_media_file} />
                );
              })}
          </div>
          <FormButtons
            onSave={() => setGrievanceSubmit(true)}
            onCancel={onCancelHandler}
          />
        </Form>
      </Formik>
      {isAddNewEnginner && <AddEngineer onEngSave={onEngSave} />}
      <AddVoters
        existing_voters={safe_get(selectedGrievance?.r1, "voters", [])}
      />
      <AddUser />
      {
        <SideOverlay
          children={<FilterOverLay />}
          onClose={() => {
            voterListStore.setFilterStatus(false);
            voterListStore.setShowVoterlist(true);
            voterListStore.setFilterVal(null);
            voterListStore.setSelectedBooths([]);
          }}
          show={voterListStore.FilterStatus}
          title={t("filter_votersearch", { ns: "beneficiary" })}
        />
      }
      <SideOverlay
        onClose={() =>
          voterListStore.update_edit_voter_modal(
            !voterListStore.edit_voter_modal,
          )
        }
        show={voterListStore.edit_voter_modal}
        children={<EditVoter />}
        title={"Edit Voter"}
      />
    </>
  );
});
