import React from "react";
import { observer } from "mobx-react-lite";
import { AddLocation } from "./add-location";
import { LocationCard, LocationCardSkeleton } from "./location-card";
import { useStore } from "../../../../helpers";
import { MasonryGrid } from "../../../../components-new";
import { DeleteLocation } from "./delete-location";

export const LocationHome = observer((props: any) => {
  const {
    serviceRequestsStore: { meta, loading },
  } = useStore();

  return (
    <div className={"overflow-hidden"}>
      <MasonryGrid
        data={meta?.locations}
        isLoading={loading}
        skeletonCount={8}
        renderItem={(location) => (
          <LocationCard key={location["id"]} loc={location} />
        )}
        renderSkeleton={(key) => <LocationCardSkeleton key={key} />}
      />
      <AddLocation />
      <DeleteLocation />
    </div>
  );
});
