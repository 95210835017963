import React from "react";
import { classNames } from "../../helpers";
import { Card, CardContent, CardHeader } from "../../components/card";
import { useShowClientProfile } from "./useShowClientProfile";
import { Typography, useTheme } from "@mui/material";

export const RenderFeatureCard = ({ feature }) => {
  const { heading, subItems } = feature;
  const theme = useTheme();
  const { showClientProfile } = useShowClientProfile();

  if (subItems.length == 0) {
    return null; // Skip rendering if there are no sub-items.
  }
  return (
    <div
      className={`flex flex-col bg-white rounded-3xl p-4 my-4`}
      style={{ boxShadow: "0px 19px 41px 0px #7A7A7A0D" }}
    >
      <Typography
        sx={{ color: theme.palette.grey[500] }}
        className={"p-4"}
        variant={"h5"}
      >
        {heading}
      </Typography>
      <div
        className={classNames(
          showClientProfile
            ? "xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2"
            : "xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2",
          "grid  gap-2",
        )}
      >
        {subItems.map((item, index) => (
          <Card key={index} path={item.path}>
            <CardHeader item={item} />
            <CardContent />
          </Card>
        ))}
      </div>
    </div>
  );
};
