import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../helpers";
import DataTable from "./DataTable";
import { formatHashtagData } from "./util/formatHashtagData";
import { Typography } from "@mui/material";

const HashtagTable = observer(() => {
  const { flexiStore } = useStore();
  const [groupedHashtagData, setGroupedHashtagData] = useState<Record<string, any>[]>([]);

  useEffect(() => {
    if (!flexiStore.current_fsd?.r2) return;

    const hashtagWordcloud = (flexiStore.current_fsd.r2 as Record<string, any>)?.hashtag_wordcloud;
    setGroupedHashtagData(formatHashtagData(hashtagWordcloud)); 
  }, [flexiStore.current_fsd]);

  return (
    <div className="bg-black p-6">
      <div className="flex justify-between items-center text-[#B97CFE] p-12">
        <Typography variant="h3">
          Hashtags Index
        </Typography>
        <Typography variant="h4">
        A snapshot of the hashtags <br/> used and their frequency
        </Typography>
      </div>
      <div className="mt-4 mb-12">
      <DataTable
        title="Hashtag Wordcloud"
        columns={[
          { id: "hashtags", label: "Hashtags" },
          { id: "frequency", label: "Frequency" },
        ]}
        data={groupedHashtagData}
      />
      </div>
    </div>
  );
});

export default HashtagTable;
