import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";
import { useStore } from "../../helpers";
import { useParams } from "react-router-dom";
import { BoothTreeView, FormButtons, TailwindModal } from "../../components";
import { useTranslation } from "react-i18next";
import { TaskSearch } from "./task-search";
import Select from "react-select";
import { safe_get } from "../../report-visuals";

export const CreateTask = observer((props: any) => {
  const rootStore = useStore();
  const [t] = useTranslation("task_manager");
  const { taskManagerStore } = rootStore;
  const { task_add_modal, task_type } = taskManagerStore;
  const { projectid } = useParams();
  const [surveys, setSurveys] = useState([]);
  const [taskPayload, setTaskPayload] = useState({});

  useEffect(() => {
    const active_project = JSON.parse(localStorage.getItem("__active_project"));
    setSurveys(safe_get(active_project, "surveys", []));
  }, [projectid]);

  const fetch_task_list = async () => {
    await taskManagerStore.get_task_list(projectid);
  };

  const set_selected_booth = (booth) => {
    setTaskPayload({ ...taskPayload, node: booth });
  };

  const onSave = async (evt) => {
    const payload = {
      projectid: parseInt(projectid),
      surveyid: taskPayload["surveyid"],
      count: parseInt(taskPayload["count"]),
    };

    if (task_type === "single") {
      const res = await taskManagerStore.create_single_task({
        ...payload,
        node: taskPayload["node"],
      });
      if (res.ok) {
        toast.success("Task created successfully....");
        setTaskPayload({ ...taskPayload, count: "", node: {} });
      }
    }

    if (task_type === "bulk") {
      const res = await taskManagerStore.create_bulk_tasks(payload);
      if (res.data) {
        toast.success("Tasks are created successfully....");
      }
    }

    await fetch_task_list();
    taskManagerStore.set_task_add_modal(false);
  };

  return (
    <>
      <div className="flex flex-col md:flex-row flex-wrap items-center justify-between">
        <div className={"flex flex-1 flex-col"}>
          <div className="font-bold text-lg text-gray-800">
            {t("task_manager")}
          </div>
          <div className="text-gray-500">{t("manage_and_check")}.</div>
        </div>
        <TaskSearch />
      </div>
      <TailwindModal isOpen={task_add_modal}>
        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-lg w-full">
          <div className="p-4">
            <div className="text-xl font-semibold text-gray-900">
              Create Task
            </div>
            <label htmlFor="role" className="block text-gray-700 pt-4">
              Select Survey
            </label>
            <Select
              name={"Select Survey"}
              options={surveys.map((survey) => {
                return {
                  label: survey.name,
                  value: survey.id,
                };
              })}
              onChange={(val, action) => {
                setTaskPayload({
                  ...taskPayload,
                  surveyid: val.value,
                });
              }}
              placeholder={"Survey"}
              className="w-full h-auto rounded z-999 focus:shadow outline-none basic-multi-select "
              classNamePrefix="select"
              required={true}
            />

            <div className="w-full">
              <label
                htmlFor={t("new_project")}
                className="block text-gray-700 pt-4 rounded-lg"
              >
                {t("surveys_per_booth")}
              </label>
              <input
                type="number"
                className="px-4 py-2 rounded-lg border border-gray-300 w-full"
                value={taskPayload["count"]}
                onChange={(e) =>
                  setTaskPayload({ ...taskPayload, count: e.target.value })
                }
              />
            </div>

            {task_type === "single" && (
              <div className="flex flex-col">
                <BoothTreeView
                  onBoothSelection={set_selected_booth}
                  selected_booth={safe_get(taskPayload, "node", {})}
                />
              </div>
            )}
          </div>
          <FormButtons
            onSave={onSave}
            type={"button"}
            onCancel={() => {
              taskManagerStore.set_task_add_modal(false);
            }}
          />
        </div>
      </TailwindModal>
    </>
  );
});
