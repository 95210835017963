import React from "react";
import { LoginInput } from "./login-input";
import { LoginButton } from "./login-button";
import { useTranslation } from "react-i18next";

export const EmailLogin = () => {
  const { t } = useTranslation(["new_login"]);
  return (
    <div className="flex flex-col mt-6 w-full max-w-[480px] gap-y-2 sm:px-6 md:px-8 lg:px-0">
      <div className="flex flex-col mb-2">
        <label className="text-lg leading-xl font-normal text-[#1B1B1D] mb-2">
          {t("email")}
        </label>
        <LoginInput
          name="email"
          type="email"
          placeholder={t("enterEmail")}
          label={t("email")}
          inputProps={{
            maxLength: 254,
            autoComplete: "email",
          }}
        />
      </div>

      <div className="flex flex-col">
        <label className="text-lg leading-xl font-normal text-[#1B1B1D] mb-2">
          {t("password")}
        </label>
        <LoginInput
          name="password"
          type="password"
          placeholder={t("enterPassword")}
          label={t("password")}
          inputProps={{
            minLength: 8,
            maxLength: 64,
          }}
        />
      </div>
      <LoginButton otp={null} phone={null} disabled={null} />
    </div>
  );
};
