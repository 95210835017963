import React, { useCallback } from "react";
import { useStore } from "../../helpers";
import { observer } from "mobx-react-lite";
import { voterType } from "./beneficiary-utils";
import { useTranslation } from "react-i18next";
import { useIsMobile } from "../../custom-hooks";
import { Avatar, Divider, IconButton, Typography } from "@mui/material";

type RenderFamilyMembersProps = {
  family_members: voterType[];
  header?: boolean;
  edit?: boolean;
  deleteRequest?: Function;
};

export const RenderFamilyMembers = observer(
  (props: RenderFamilyMembersProps) => {
    const { t } = useTranslation("beneficiary");
    const {
      family_members,
      header = false,
      edit = false,
      deleteRequest,
    } = props;
    const isMobile = useIsMobile();
    const { beneficiaryStore } = useStore();

    const editHandler = useCallback((member) => {
      beneficiaryStore.setActiveFamilyMember(member);
      beneficiaryStore.update_add_familymember_modal(true);
    }, []);

    if (!(family_members && family_members.length > 0)) return null;

    return (
      <div className="my-2 py-2 rounded-lg">
        {header && (
          <div className="flex flex-row items-center px-2 ">
            <i className="fas fa-users text-xl pr-4 text-purple-500" />
            <h2 className="text-xl text-purple-500 font-bold py-2">
              {t("family_members")}
            </h2>
          </div>
        )}
        <ul role="list" className="grid grid-cols-1 gap-3">
          {family_members.map((member) => {
            //@ts-ignore
            const { profile, profile_photo } = member;

            if (!profile) return;
            return (
              <li key={member["id"]}>
                <div className={"flexBoxRow"}>
                  <div className={"flex flex-row gap-x-4 items-center"}>
                    <Avatar />
                    <div>
                      <Typography>{member?.profile?.name_eng}</Typography>
                      <div className={"flex flex-row gap-x-4 items-center"}>
                        <Typography>{member?.profile?.age} Years</Typography>
                        <Divider orientation="vertical" flexItem />
                        <Typography>{member?.profile?.age}</Typography>
                      </div>
                    </div>
                  </div>
                  {edit ? (
                    <div className={"flex flex-row gap-x-4"}>
                      <IconButton
                        size={"small"}
                        disableFocusRipple
                        onClick={() => editHandler(member)}
                      >
                        <i className={"fas fa-edit"} />
                      </IconButton>
                      <IconButton
                        size={"small"}
                        color={"error"}
                        disableFocusRipple
                        onClick={() => deleteRequest(member["id"])}
                      >
                        <i className={"fas fa-trash"} />
                      </IconButton>
                    </div>
                  ) : null}
                </div>

                {/*{"profile_photo" in member && profile_photo.length > 0 ? (*/}
                {/*  <div className="avatar">*/}
                {/*    <div className="mr-3 h-16 w-16 rounded-full">*/}
                {/*      <ViewAttachment*/}
                {/*        attachment={profile_photo[0]}*/}
                {/*        s3_bucket="urn-assets"*/}
                {/*        label={false}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*) : (*/}
                {/*  <div className="flex flex-nowrap flex-row items-center">*/}
                {/*    <div className="rounded-full bg-blue-200 text-4xl h-16 w-16 flex items-center justify-center">*/}
                {/*      <i className={"fa-solid fa-user-large"} />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*)}*/}
                {/*<div className="flex flex-col w-full">*/}
                {/*  <div className="flex flex-row justify-between items-center">*/}
                {/*    <h3 className="font-bold text-indigo-600">*/}
                {/*      {profile.name_eng}*/}
                {/*    </h3>*/}
                {/*    {edit && (*/}
                {/*      <div className="flex flex-row items-center justify-between flex-wrap mr-1.5">*/}
                {/*        <i*/}
                {/*          className={`fas fa-pencil text-gray-600 px-1 cursor-pointer pr-3.5 transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" : ""}`}*/}
                {/*          onClick={async () => {*/}
                {/*            await beneficiaryStore.setActiveFamilyMember(*/}
                {/*              member,*/}
                {/*            );*/}
                {/*            await beneficiaryStore.update_add_familymember_modal(*/}
                {/*              true,*/}
                {/*            );*/}
                {/*          }}*/}
                {/*        />*/}
                {/*        <i*/}
                {/*          className={`fas fa-trash text-red-600 px-1 cursor-pointer transition-opacity duration-800 ${!isMobile ? "invisible group-hover:visible" : ""} `}*/}
                {/*          onClick={() => deleteRequest(member["id"])}*/}
                {/*        />*/}
                {/*      </div>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*  <div className="grid grid-cols-1 sm:grid-cols-3 gap-2">*/}
                {/*    <p className="text-gray-600">{profile.age} Yrs</p>*/}
                {/*    <p className="">{member.phone}</p>*/}
                {/*    {profile?.additional && (*/}
                {/*      <p className="">{profile.additional}</p>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*</div>*/}
              </li>
            );
          })}
        </ul>
      </div>
    );
  },
);
