import React, { useLayoutEffect, useRef } from "react";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4plugins_wordCloud from "@amcharts/amcharts4/plugins/wordCloud";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

interface WordCloudProps {
  chartId: string;
  data: { tag: string; count: number; color: string }[];
}

const WordCloudChart: React.FC<WordCloudProps> = ({ chartId, data }) => {
  const chartRef = useRef<am4plugins_wordCloud.WordCloud | null>(null);

  useLayoutEffect(() => {
    let chart = am4core.create(chartId, am4plugins_wordCloud.WordCloud);
    chartRef.current = chart;

    let series = chart.series.push(new am4plugins_wordCloud.WordCloudSeries());
    series.data = data;
    series.dataFields.word = "tag";
    series.dataFields.value = "count";

    series.labels.template.adapter.add("fill", (fill, target) => {
      const word = target.dataItem?.dataContext as { tag: string; color: string };
      return am4core.color(word?.color || "#000");
    });

    series.heatRules.push({
      target: series.labels.template,
      property: "fontSize",
      min: 10,
      max: 50,
    });

    return () => {
      chart.dispose();
    };
  }, [chartId, data]);

  return <div id={chartId} className="w-full h-full" />;
};

export default WordCloudChart;
