import React from "react";
import { FTPPagination, FTPSearchBar } from "../../../components-new";
import { FTPTheme } from "../../../ftp-theme";
import { useStore } from "../../../helpers";
import { Button, IconButton } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterButton from "../../../components-new/Buttons/FilterButton";

export const PaginationAndFilters = () => {
  const {
    projectWorkStoreV2: {
      update_project_work_property,
      toggleProjectWorkFilterModal,
    },
  } = useStore();
  return (
    <div
      className={
        "flex flex-col-reverse md:flex-row justify-between md:items-center my-5"
      }
    >
      <div>
        <FTPPagination
          handlePageChange={() => {}}
          pageNumber={1}
          totalPages={10}
        />
      </div>
      <div
        className={
          "flex flex-row-reverse justify-end md:justify-between md:flex-row md:items-center gap-x-4 mb-5 md:mb-0"
        }
      >
        {/* for dektop */}
        <div className="hidden md:block">
          <FilterButton
            onClick={toggleProjectWorkFilterModal}
            text={"Filters"}
          />
        </div>

        <div className="block md:hidden">
          <IconButton
            size="large"
            onClick={toggleProjectWorkFilterModal}
            disableRipple
          >
            <i className="fas fa-sliders-h text-gray-800 px-3 py-2 border rounded-lg border-black"/>
          </IconButton>
        </div>

        <FTPSearchBar
          placeHolder={"Search By Name"}
          onSearch={(e) => {
            update_project_work_property("searchQuery", e);
          }}
        />
      </div>
    </div>
  );
};
