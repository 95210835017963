import React from "react";
import { observer } from "mobx-react-lite";
import { Stack, Typography, Grid, Box } from "@mui/material";
import { useStore } from "../../../helpers";
import { styled } from "@mui/material/styles";
import { safe_get } from "../../../report-visuals";
import { toJS } from "mobx";
import { DefaultFamilyMembers } from "../sr-render-family";

const StyledDiv = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "#ffffff",
  padding: "1rem",
  borderRadius: "12px",
  flex: 1,
  minWidth: "120px",
}));

export const SrPersonalInfo = observer(() => {
  const rootStore = useStore();
  const { serviceRequestsStore } = rootStore;
  const { activeServiceRequest } = serviceRequestsStore;

  const subject = safe_get(activeServiceRequest, "subject.subject", {});
  const additional = safe_get(activeServiceRequest, "subject.additional", {});
  const familyMembers = toJS(subject?.additional?.family_members_list);
  const is_outsider =
    safe_get(activeServiceRequest.subject.subject.is_outsider) ?? true;

  return (
    <Box>
      <Stack spacing={1}>
        <Typography variant="h5" sx={{ fontWeight: "bold" }}>
          {subject?.profile?.name_eng}
        </Typography>
        <Typography>
          {activeServiceRequest["location"]?.location?.location}
        </Typography>
      </Stack>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {[
          { img: "Aadhar.svg", value: subject?.aadharid },
          { img: "Vector.svg", value: subject?.profile?.voterid },
          { img: "Frame.svg", value: subject?.phone },
          { img: "Whatsapp.svg", value: subject?.phone },
        ].map((item, index) => (
          <Grid item xs={6} sm={3} key={index}>
            <StyledDiv>
              <img
                src={`/images/srimages/${item.img}`}
                style={{ width: "62px", height: "36px" }}
                alt=""
              />
              <Typography sx={{ pt: 1, fontWeight: 600 }}>
                {item.value}
              </Typography>
            </StyledDiv>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ backgroundColor: "#fff", mt: 3, borderRadius: "12px", p: 2 }}>
        {[
          { label: "Caste:", value: subject?.caste?.label },
          { label: "Address:", value: subject?.profile?.address },
          {
            label: "Date of Birth / Age:",
            value: `${subject?.profile?.dob} / ${subject?.profile?.age}`,
          },
          { label: "Gender:", value: subject?.profile?.gender },
          {
            label: "Relative Name:",
            value: subject?.profile?.relation_name_eng,
          },
        ].map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "grid",
              gridTemplateColumns: "150px 1fr",
              alignItems: "center",
              py: 1,
            }}
          >
            <Typography fontWeight={600}>{item.label}</Typography>
            <Typography fontWeight={550}>{item.value}</Typography>
          </Box>
        ))}
      </Box>

      <Box sx={{ backgroundColor: "#fff", mt: 3, borderRadius: "12px", p: 2 }}>
        {[
          { label: "Booth:", value: additional?.booth?.display },
          {
            label: "Polling Station:",
            value: additional?.booth?.booth_address,
          },
        ].map((item, index) => (
          <Box
            key={index}
            sx={{
              display: "grid",
              gridTemplateColumns: "150px 1fr",
              alignItems: "center",
              py: 1,
            }}
          >
            <Typography fontWeight={600}>{item.label}</Typography>
            <Typography fontWeight={550}>{item.value}</Typography>
          </Box>
        ))}
      </Box>

      {is_outsider && (
        <Box
          sx={{ backgroundColor: "#fff", mt: 3, borderRadius: "12px", p: 2 }}
        >
          <Typography fontWeight={600} color="#3A80F4">
            Different Assembly Constituency Voter
          </Typography>
          {/* Card Content */}
        </Box>
      )}

      <Box sx={{ backgroundColor: "#fff", mt: 3, borderRadius: "12px", p: 2 }}>
        <Typography color="#3A80F4" sx={{ pb: 2 }}>
          Family Members
        </Typography>
        <Grid container spacing={2}>
          {familyMembers?.length > 0 ? (
            familyMembers?.map((member, index) => (
              <Grid item xs={12} sm={6} key={index}>
                <FamilyMemberCard
                  name={member.profile.name_eng}
                  age={member.profile.age}
                  relation={member.profile.relationship_eng}
                />
              </Grid>
            ))
          ) : (
            <DefaultFamilyMembers />
          )}
        </Grid>
      </Box>
    </Box>
  );
});

const FamilyMemberCard = ({ name, age, relation }) => {
  return (
    <Box sx={{ backgroundColor: "#F1F1F1", p: 2, borderRadius: "8px" }}>
      <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
        {name}
      </Typography>
      <Typography sx={{ fontSize: "13px", color: "#666" }}>
        {age} | {relation}
      </Typography>
    </Box>
  );
};
