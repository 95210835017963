import React, { useEffect, useMemo, useState } from "react";
import { SideOverlay } from "../../../../components";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../helpers";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AddAssigneeValidationSchema,
  defaultAssignee,
} from "./add-assignee-schema";
import { tabStyles } from "./MUIstyles";
import {
  FTPAutoComplete,
  FTPFormButton,
  FTPInput,
} from "../../../../components-new";
import { useParams } from "react-router";
import {
  useAddAssigneeRequest,
  useUpdateAssigneeRequest,
  useAssignee,
} from "./hooks";
import { getUniqueDepartmentsAndPositions } from "./util/lib";
import { safe_get } from "../../../../report-visuals";
import { VortexSpinner } from "../../../../features/digital-repository";
import FilePondUpload from "./upload-profile-pic";
import { IconButton } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";

import OfficePersonAutoComplete from "./office-person-auto-complete";
import { useTranslation } from "react-i18next";

const AddAssignee = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { activeAssignee } = serviceRequestsStore;
  const [schema, setSchema] = useState(AddAssigneeValidationSchema(false));
  const { projectid } = useParams();
  const [currentTabValue, setCurrentTabValue] = useState("External");
  const {
    isLoading: isAddResponseLoading,
    mutate: addRequest,
    isError: isAddError,
    isSuccess: isAddSuccess,
  } = useAddAssigneeRequest();
  const {
    isLoading: isUpdateResponseLoading,
    isSuccess: isUpdateSuccess,
    mutate: updateRequest,
    isError: isUpdateError,
  } = useUpdateAssigneeRequest();

  const id = safe_get(activeAssignee, "id", null);
  const [currentPic, setCurrentPic] = useState(null);
  const { t } = useTranslation(["assignee"]);
  const classes = tabStyles();
  useEffect(() => {
    if (id) {
      setCurrentPic(safe_get(activeAssignee, "more.profile_pic", null));
    } else {
      setCurrentPic(null);
    }
  }, [id]);

  const methods = useForm({
    defaultValues: defaultAssignee,
    resolver: yupResolver(schema),
  });

  const { data: officeUserData } = useAssignee();

  const uniqueDepartmentsAndPositions = useMemo(
    () => getUniqueDepartmentsAndPositions(serviceRequestsStore.meta.assignee),
    [projectid, serviceRequestsStore.meta.assignee]
  );

  useEffect(() => {
    methods.setValue(
      "is_office_person",
      currentTabValue.includes(t("office_person"))
    );
  }, [currentTabValue]);
  const isOfficePerson = methods.watch("is_office_person");

  // dynamic schema for dynamic validation
  useEffect(() => {
    setSchema(AddAssigneeValidationSchema(isOfficePerson));
  }, [isOfficePerson]);

  // using the same form to add and edit by populating the form by active assignee details
  useEffect(() => {
    if (id) {
      methods.reset({
        assignee: activeAssignee["assignee"],
        is_office_person: activeAssignee["is_office_person"],
        office_person: {
          id: activeAssignee["user_profile"]["id"],
          name: activeAssignee["user_profile"]["name"],
          phonenum: activeAssignee["user_profile"]["phone"],
        },
      });
      setCurrentTabValue(
        activeAssignee["is_office_person"] ? t("office_person") : t("external")
      );
    } else {
      methods.reset(defaultAssignee);
      setCurrentTabValue(t("external"));
    }
  }, [activeAssignee]);

  async function closeForm() {
    serviceRequestsStore.update_modal_status("addAssignee", false);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setCurrentPic(null);
    serviceRequestsStore.setActiveAssignee(null);
    methods.reset(defaultAssignee);
  }

  const onSubmit = async (data) => {
    if (!id) {
      const payload = {
        projectid: parseInt(projectid),
        userid: data?.is_office_person ? data?.office_person?.id : null,
        is_office_person: data.is_office_person,
        assignee: {
          name: data?.is_office_person ? "" : data.assignee.name,
          phone: data?.is_office_person ? "" : data.assignee.phone,
          department: data.assignee.department,
          position: data.assignee.position,
          description: data?.is_office_person ? "" : data.assignee.description,
        },
        more: {
          profile_pic: data?.profile_pic,
        },
      };
      try {
        //@ts-ignore
        addRequest(payload);
      } catch (e) {
        console.log(e);
      }
    } else {
      const payload = {
        id: id,
        projectid: parseInt(projectid),
        userid: data?.is_office_person ? data?.office_person?.id : null,
        assignee: data.assignee,
        is_office_person: data.is_office_person,
        // if current user was set to office person then keep profile pic as none, else add from the uploaded data or if not deleted keep the existing one
        more: {
          profile_pic: data?.is_office_person
            ? ""
            : data?.profile_pic
              ? data?.profile_pic
              : currentPic
                ? currentPic
                : "",
        },
        user_profile: {},
      };
      try {
        //@ts-ignore
        updateRequest(payload);
      } catch (e) {
        console.log(e);
      }
    }
    const hasError = isAddError || isUpdateError;

    if (!hasError) {
      setTimeout(() => closeForm(), 1000);
    }
  };

  function onError(error) {
    console.log(error);
  }

  return (
    <SideOverlay
      onClose={closeForm}
      show={serviceRequestsStore.modals.addAssignee}
      title={activeAssignee ? t("edit") : t("create")}
      size={"large"}
    >
      <FormProvider {...methods}>
        <form
          className={"flex flex-col bg-white rounded-xl p-4 gap-y-4 mb-8"}
          onSubmit={methods.handleSubmit(onSubmit, onError)}
        >
          {/* tab here */}
          <TabContext value={currentTabValue}>
            <TabList
              // @ts-ignore
              indicatorColor="#B88EFE"
              variant={"fullWidth"}
              onChange={(e, value) => {
                setCurrentTabValue(value);
              }}
              className={classes.tab}
            >
              {[t("external"), t("office_person")].map((tab) => (
                <Tab key={tab} label={tab} value={tab} />
              ))}
            </TabList>
          </TabContext>
          {/* tab here */}

          {isOfficePerson ? (
            <OfficePersonAutoComplete
              name="office_person"
              label={`${t("office_person")}`}
              officeUserData={officeUserData}
            />
          ) : (
            <>
              {currentPic ? (
                <div className="flex gap-x-4 items-center">
                  <div className="rounded-[18px] bg-background-imagePlaceholder flex items-center justify-center overflow-clip">
                    <img
                      src={currentPic}
                      alt="profile"
                      className="h-[120px] w-[120px] object-scale-down rounded-[18px] "
                    />
                  </div>
                  <div className=" bg-error-delete rounded-[25px] px-1.5">
                    <IconButton
                      onClick={() => {
                        setCurrentPic(null);
                      }}
                    >
                      <i className="fa-regular fa-trash-can text-[18px] font-bold text-white rounded-lg" />
                    </IconButton>
                  </div>
                </div>
              ) : (
                <FilePondUpload
                  path={`project-${projectid}/servicerequests/assignee`}
                  files={[]}
                  label={`${t("upload_pic")}`}
                  accept="image/*"
                  multiple={false}
                  onUpdate={(files) => {
                    if (files.length > 0)
                      methods.setValue("profile_pic", files[0]?.location);
                  }}
                />
              )}
              <div className="grid md:grid-cols-2 grid-cols-1 gap-x-2">
                <FTPInput label={`${t("name")}`} name={"assignee.name"} />
                <FTPInput
                  label={"Phone Number"}
                  name={"assignee.phone"}
                  disabled={isOfficePerson}
                />
              </div>

              <FTPInput
                label={`${t("description")}`}
                name={"assignee.description"}
                multiline
                rows={4}
              />
            </>
          )}
          <div className="grid md:grid-cols-2 grid-cols-1 gap-x-2">
            <FTPAutoComplete
              name={"assignee.position"}
              label={`${t("position")}`}
              data={uniqueDepartmentsAndPositions.position}
              placeholder="Select position"
            />
            <FTPAutoComplete
              name={"assignee.department"}
              data={uniqueDepartmentsAndPositions.department}
              label={`${t("department")}`}
              placeholder="Select Department"
            />
          </div>

          <div className="bg-background-default w-full pr-8 pt-1 fixed bottom-2 right-0 flex gap-x-2  justify-end mt-4">
            <VortexSpinner
              visible={isAddResponseLoading || isUpdateResponseLoading}
            />
            <FTPFormButton
              handleButtonClick={() => closeForm()}
              actionType="Cancel"
              type="button"
            />
            <FTPFormButton
              actionType="Save"
              handleButtonClick={() => {}}
              type="submit"
            />
          </div>
        </form>
      </FormProvider>
    </SideOverlay>
  );
});

export default AddAssignee;
