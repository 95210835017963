import React from "react";
import {ViewAttachment} from "./ViewAttachements";
import {useStore} from "../helpers";
import {safe_get} from "../report-visuals";

const isValidImageExtension = (filePath) => {
    const validExtensions = ["png", "jpg", "jpeg", "gif", "webp"];
    const extension = filePath?.split(".").pop()?.toLowerCase();
    return validExtensions.includes(extension);
};

const ProfileDetails = ({name, description}) => (
    <div className="text-center">
        <h1 className="mt-4 text-lg font-bold">{name}</h1>
        <h6 className="mt-3 text-[14px] font-normal leading-[17.08px]">
            {description}
        </h6>
        <div className="w-full h-0 border-t-2 border-gray-300 mt-4"/>
    </div>
);

const Notification = ({date, heading, news}) => (
    <div
        className="w-full px-4 py-4 rounded-lg bg-white border border-transparent hover:border-[#9E9E9E] transition-all group">
        <div className="flex items-center justify-between">
            <h2 className="text-base font-medium leading-[1.172] w-[85%]">
                {heading}
            </h2>
            <div
                className="flex items-center justify-center w-[48px] h-[24px] bg-gray-200 group-hover:bg-[#C0E0FF] border border-gray-300 rounded-full text-[10px] font-semibold leading-[11.73px] transition-colors duration-200">
                {date}
            </div>
        </div>
        <p className="mt-3 text-xs font-normal leading-[14.08px] text-gray-700">
            {news}
        </p>
    </div>
);

const ClientProfile = ({profileName, profileDescription, notifications}) => {

    const {projectStore} = useStore();
    const client_profile = projectStore.getProjectProperty("client_profile", {});
    const profileImage = safe_get(client_profile, "profile", null);
    const showClientProfile = safe_get(
        client_profile,
        "show_client_profile",
        false
    );

    const isValidImage = profileImage && isValidImageExtension(profileImage);
    const imageType = isValidImage
        ? profileImage.split(".").pop().toLowerCase()
        : "png";

    if (!showClientProfile) {
        return null;
    }

    return (
        <div className="fixed mt-4 max-h-[88vh] h-fit bg-white border border-gray-300 rounded-3xl overflow-hidden">
            <div className="flex flex-col items-center p-4">
                <div className="rounded-2xl">
                    {isValidImage ? (
                        <ViewAttachment
                            attachment={{
                                path: profileImage,
                                type: `image/${imageType}`,
                            }}
                            label={false}
                        />
                    ) :
                        <p>No valid image available</p>
                    }
                </div>
                <ProfileDetails name={profileName} description={profileDescription}/>
            </div>
            <div className="overflow-y-auto scrollbar-hidden h-[calc(100vh-200px)] px-4">
                {notifications.map((notification, index) => (
                    <Notification
                        key={index}
                        date={notification.date}
                        heading={notification.heading}
                        news={notification.news}
                    />
                ))}
            </div>
        </div>
    );
};

export default ClientProfile;
