import React from "react";
import { Checkbox, FormControl, FormControlLabel, Stack } from "@mui/material";
import { FilterHeader } from "../filterHeader";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

export const GendersFilter = observer((props: any) => {
  const { handleBoothFilter, genders } = props;
  const [t] = useTranslation("voter_list");

  return (
    <Stack>
      <FilterHeader title={t("gender")} content={t("search_gender")} />
      <FormControl>
        <FormControlLabel
          value={t("male")}
          control={
            <Checkbox
              checked={genders.includes("Male")}
              value={t("male")}
              onChange={handleBoothFilter}
            />
          }
          label={t("male")}
        />
        <FormControlLabel
          value={t("female")}
          onChange={handleBoothFilter}
          control={<Checkbox checked={genders.includes("Female")} />}
          label={t("female")}
        />
        <FormControlLabel
          value={t("third_gender")}
          onChange={handleBoothFilter}
          control={<Checkbox checked={genders.includes("Third Gender")} />}
          label={t("third_gender")}
        />
      </FormControl>
    </Stack>
  );
});
