import React from "react";
import { observer } from "mobx-react-lite";
import { Typography } from "@mui/material";
import { useStore } from "../../../helpers";
import { SrRequestDetailsInfo } from "./sr-request-details-info";

export const SrRequestServiceInfo = observer((props) => {
  const rootStore = useStore();
  const { serviceRequestsStore } = rootStore;
  const { activeServiceRequest } = serviceRequestsStore;

  return (
    <>
      <div>
        <div className="flex flex-col bg-common-white mt-3 rounded-xl">
          <div className="p-4">
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "16px",
                lineHeight: "18.77px",
                letterSpacing: "0%",
                color: "#3A80F4",
              }}
            >
              Requested Service
            </Typography>
          </div>
          <div className="m-4">
            <div className="flex flex-col bg-gray-100 rounded-xl p-4 items-center justify-center">
              <div className="bg-common-black rounded-2xl p-2 mt-3 text-common-white text-center font-normal">
                {activeServiceRequest["type"].type.category} /{" "}
                {activeServiceRequest["type"].type.subcategory}
              </div>
              <div className="mt-4 text-label-scheme">
                {activeServiceRequest["type"].type.display.map(
                  (item, index) => (
                    <span
                      key={index}
                      className="flex flex-col text-center font-semibold pb-1"
                    >
                      {item.text}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
        <SrRequestDetailsInfo />
      </div>
    </>
  );
});
