import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import ManageVoters from "./manage-voters";
import { useParams } from "react-router";
import { LoadingState } from "../../components";
import { FTPBreadCrumbs } from "../../components-new";

const ManageVotersdex = observer((props: any) => {
  const { projectid } = useParams();
  const { voterReachOutStore } = useStore();
  const { isLoading } = voterReachOutStore;

  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: true },
    { name: "Manage Voters", href: "#", current: true },
  ];

  return (
    <>
      {isLoading && <LoadingState />}
      <FTPBreadCrumbs crumbs={crumbs} />
      <ManageVoters />
    </>
  );
});

export default ManageVotersdex;
