import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useParams } from "react-router-dom";
import {
  useStore,
  htmlValidation,
  useFileUpload,
  makeid_alpa_numeric,
  remove_item_from_array,
  zero_pad,
} from "../../helpers";
import { FormButtons, UploadButton } from "../../components";
import { Controller, useForm } from "react-hook-form";
import { safe_get } from "../../report-visuals";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { LoadingState } from "../../components";
import CreatableSelect from "react-select/creatable";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import {
  createTag,
  Field,
  FieldSet,
  initialValues,
  Input,
  TextArea,
} from "./da-utils";
import { Vortex } from "react-loader-spinner";
import { toJS } from "mobx";

export const VortexSpinner = ({ visible }) => {
  return (
    <Vortex
      visible={visible}
      height="50"
      width="50"
      ariaLabel="vortex-loading"
      wrapperStyle={{}}
      wrapperClass="vortex-wrapper"
      colors={["red", "green", "blue", "yellow", "orange", "purple"]}
    />
  );
};

export const AddFileOrFolder = observer((props: any) => {
  const { projectid } = useParams();
  const rootStore = useStore();
  const queryClient = useQueryClient();
  const { digitalArchiveStore } = rootStore;
  const { activeFileorFolder } = digitalArchiveStore;
  const isFolder = safe_get(activeFileorFolder, "is_folder", false);
  const [activeType, setActiveType] = useState(isFolder ? "folder" : "file");
  const [files, setFiles] = useState([]);
  const fid = safe_get(activeFileorFolder, "id", "");
  const defaultTags = rootStore.projectStore.getProjectProperty(
    "digital_archive_tags",
    [],
  );
  const tags = safe_get(defaultTags, "tags", []);
  const options = tags.map((tag) => createTag(tag));
  const [tagOptions, setTagOptions] = useState(options);
  const [selectedTag, setSelectedTag] = useState([]);

  const { isUploading, uploadFileToS3 } = useFileUpload();

  const [uploadedFiles, setUploadedFiles] = useState([
    {
      path: safe_get(activeFileorFolder, "s3key", ""),
      type: safe_get(activeFileorFolder, "mimetype", ""),
      id: safe_get(activeFileorFolder, "id", ""),
    },
  ]);

  const delete_file = (name) => {
    const updated_values = remove_item_from_array(uploadedFiles, "path", name);
    setUploadedFiles(updated_values);
  };

  const createTagHandler = (val) => {
    const newOption = createTag(val);
    setTagOptions((prev) => [...prev, newOption]);
    setSelectedTag((prev) => [...prev, newOption]);
  };

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
    reset,
    setValue,
  } = useForm<initialValues>();

  useEffect(() => {
    if (fid) {
      setValue("name", safe_get(activeFileorFolder, "title", ""));
      setValue("description", safe_get(activeFileorFolder, "description", ""));
      setValue("file", safe_get(activeFileorFolder, "s3key", ""));
      setValue("date", safe_get(activeFileorFolder, "update_time", ""));
      setValue("tags", safe_get(activeFileorFolder, "tags", []));
    }
  }, [activeFileorFolder, fid]);

  const sub_path = digitalArchiveStore.folderBreadCrumbs
    .map((x) => {
      if (x.title === "Home") return;
      return x.title;
    })
    .join("/");

  const path =
    sub_path !== ""
      ? `project-${projectid}/digital-archive/${sub_path}`
      : `project-${projectid}/digital-archive`;

  const resetModal = () => {
    digitalArchiveStore.update_DAStoreProp("addNewModal", false);
  };

  const submitForm = async (data) => {
    //TODO: Its same for both file and folder
    const basePayload = {
      title: data.name,
      description: data.description,
      parent: digitalArchiveStore.activeParentId,
      update_time: data.date,
      is_folder: activeType === "folder",
      projectid: parseInt(projectid),
    };

    //TODO: Folder specific payload
    const folderPayload = [
      {
        ...basePayload,
        id: makeid_alpa_numeric(6),
        mimetype: "",
        s3key: "",
      },
    ];

    //TODO: cooking multiple files payload
    const payload = uploadedFiles
      .filter((item) => item.id !== "")
      .map((file, index) => {
        return {
          ...basePayload,
          title: `${basePayload.title} - ${zero_pad(index + 1, 2)}`,
          id: makeid_alpa_numeric(6),
          mimetype: file?.type,
          s3key: file?.path,
        };
      });

    if (
      activeType === "file" &&
      files.length === 0 &&
      uploadedFiles[0]?.path === ""
    ) {
      toast.warning("Please upload the file");

      return;
    }

    //TODO: Edit file or folder
    if (fid) {
      await editMutate({
        ...activeFileorFolder,
        title: data.name,
        update_time: data.date,
        description: data.description,
        mimetype: safe_get(uploadedFiles.at(-1), "type", ""),
        s3key: safe_get(uploadedFiles.at(-1), "path", ""),
      });
    } else {
      await mutate(activeType === "folder" ? folderPayload : payload);
    }

    resetModal();
  };

  const addFileOrFolder = async (payload: any) => {
    const res = await digitalArchiveStore.add_file_to_digital_archive(payload);
    if (!res.ok) {
      throw Error("Something went wrong, please try again...");
    } else {
      return res;
    }
  };

  const { mutate, isLoading: isAdding } = useMutation({
    mutationFn: addFileOrFolder,
    onSuccess: async () => {
      setTimeout(async () => {
        await queryClient.invalidateQueries([
          "files",
          digitalArchiveStore.activeParentId,
          projectid,
        ]);
      }, 1000);
      toast.success("Added Successfully");
    },
    onError: ({ message }) => {
      toast.error(message);
    },
  });

  const editFileOrFolder = async (payload: any) => {
    return await digitalArchiveStore.edit_file_to_digital_archive(payload);
  };

  const { mutate: editMutate } = useMutation({
    mutationFn: editFileOrFolder,
    onSuccess: async () => {
      setTimeout(async () => {
        await queryClient.invalidateQueries([
          "files",
          digitalArchiveStore.activeParentId,
          projectid,
        ]);
      }, 1000);
      toast.success("Updated Successfully");
    },
  });

  const onFileUpload = async (e) => {
    htmlValidation(e);
    const filesArray = [...files];
    if (!filesArray.length) {
      toast.error("Please select the file...");
      return;
    }
    Array.from(filesArray).forEach((fileToUpload, index) => {
      //Todo: timeout for better user experience while uploading multiple files.
      setTimeout(async () => {
        const uploadedData = await uploadFileToS3(
          path,
          fileToUpload,
          "urn-assets",
        );
        if (uploadedData?.status === 204) {
          setUploadedFiles((prevUploadedFiles) => [
            ...prevUploadedFiles,
            {
              path: uploadedData.key,
              type: fileToUpload.type,
              id: makeid_alpa_numeric(4),
            },
          ]);
        }
      }, 1000 * index);
    });
  };

  if (isAdding) return <LoadingState />;

  return (
    <>
      <div className={""}>
        <FormControl>
          <FormLabel id="select-type">
            Do you want to add file or folder ?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="select-type"
            defaultValue="file"
            name="select-type"
            value={activeType}
            onChange={(e) => setActiveType(e.target.value)}
          >
            <FormControlLabel value="file" control={<Radio />} label="File" />
            <FormControlLabel
              value="folder"
              control={<Radio />}
              label="Folder"
            />
          </RadioGroup>
        </FormControl>

        <form onSubmit={handleSubmit(submitForm)}>
          <FieldSet label={`New ${activeType}`}>
            <Field htmlFor={"name"} label="Name" error={errors.name}>
              <Input
                {...register("name", { required: "Name is required" })}
                type="text"
                id="name"
              />
            </Field>
            {activeType === "file" ? (
              <>
                <Field htmlFor={"tags"} label="Tags" error={""}>
                  <Controller
                    name="tags"
                    control={control}
                    render={({ field: { value, onChange, ...field } }) => (
                      <CreatableSelect
                        isMulti
                        options={tagOptions}
                        value={selectedTag}
                        onChange={(val, action) => {
                          // @ts-ignore
                          setSelectedTag(val);
                        }}
                        onCreateOption={createTagHandler}
                      />
                    )}
                  />
                </Field>
                <Field
                  htmlFor={"desc"}
                  label="Description"
                  error={errors.description}
                >
                  <TextArea
                    {...register("description", {
                      maxLength: {
                        value: 100,
                        message:
                          "Description cannot be longer than 100 characters",
                      },
                    })}
                    id="description"
                    rows={5}
                  />
                </Field>
                <Field htmlFor={"file"} label="Picture" error={errors.file}>
                  <div className={"flex flex-row justify-between items-center"}>
                    <Controller
                      control={control}
                      name={"file"}
                      render={({ field: { value, onChange, ...field } }) => {
                        return (
                          <Input
                            {...field}
                            type="file"
                            id="file"
                            multiple={!fid}
                            value={value?.fileName}
                            onChange={(e) => {
                              setFiles(Array.from(e.target.files));
                              onChange(e.target.files);
                            }}
                          />
                        );
                      }}
                    />
                    <UploadButton uploadHandler={onFileUpload} />
                    <VortexSpinner visible={isUploading} />
                  </div>
                </Field>
              </>
            ) : null}

            {activeType === "folder" ? (
              <>
                <Field htmlFor={"date"} label="Date" error={errors.date}>
                  <Input
                    {...register("date", { required: "Date is required" })}
                    type="date"
                    id="date"
                  />
                </Field>
              </>
            ) : null}
          </FieldSet>
          {!isFolder &&
            uploadedFiles.map((file) => {
              if (file.id === "") return;
              return (
                <div key={file.id} className={"flex flex-row items-center"}>
                  <p className={"px-4"}>{file?.path?.split("/").at(-1)}</p>
                  <IconButton
                    color={"error"}
                    size={"small"}
                    onClick={() => delete_file(file.id)}
                  >
                    <i className={"fas fa-trash"} />
                  </IconButton>
                </div>
              );
            })}
          <FormButtons
            onSave={() => {}}
            onCancel={resetModal}
            type={"submit"}
          />
        </form>
      </div>
    </>
  );
});
