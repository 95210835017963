import React from "react";
import { FormHelperText, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { FormLabel } from "../utils";

const styles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: "15px",
      // border: "2px solid #B6B6B6",
    },
    "& legend": { display: "none" },
    "& fieldset": { top: 0 },
    zIndex: 0,
  },
}));

export const FTPInput = ({ name, label, ...props }) => {
  const classes = styles();

  const {
    control,
    trigger,
    formState: { errors },
  } = useFormContext();

  return (
    <div className={`flex flex-col ${props.className || ""}`}>
      <FormLabel>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <div>
            <TextField
              placeholder={label}
              {...props}
              {...field}
              fullWidth
              className={classes.root}
              error={!!errors[`${name}`]}
              onBlur={(e) => {
                trigger(name);
                field.onBlur();
                if (props.onBlur) props.onBlur(e);
              }}
            />
            {error && <FormHelperText error>{error?.message}</FormHelperText>}
          </div>
        )}
      />
    </div>
  );
};
