import { action, makeAutoObservable } from "mobx";
import _, { update } from "lodash";
export class InfluenceLeaderProjectPropertyStore {
  leaders = {};
  tabs = [];
  activeTab = { title: "", data: [] };
  constructor() {
    makeAutoObservable(this);
  }

  @action
  initializeStore(data) {
    this.leaders = { ...data };
    this.tabs = _.keys(this.leaders); //keys for left side panel ..buttons
    this.activeTab = {
      title: this.tabs[0],
      data: [...this.leaders[this.tabs[0]]],
    };
  } //initialize data inside leaders variable and access this data from our sr-settings-sr-components

  @action
  showOptions(leaderParty) {
    console.log(leaderParty);
    this.activeTab = {
      title: leaderParty,
      data: [...this.leaders[leaderParty]],
    };
  }
  @action
  addOption(title, added_arr) {
    // Extract values directly from added_arr
    const new_arr = added_arr.map((nameParty) => nameParty.value);
    // Update leaders with new values
    this.leaders[title] = [...(this.leaders[title] || []), ...new_arr];
    this.showOptions(title);
  }

  @action
  updateOption(title, updated_arr) {
    this.leaders[title] = [...updated_arr];
    this.showOptions(title);
  }
  @action
  deleteOption(title, option) {
    this.leaders[title] = _.filter(
      this.leaders[title],
      (Leaderparty) => Leaderparty !== option,
    );
    this.showOptions(title);
  }

  get getLeaders() {
    return this.leaders;
  }
}

// const InfluenceLeadPropertiesStore = new InfluenceLeaderPropertyStore();
// export default InfluenceLeadPropertiesStore;
