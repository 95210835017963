import React, { forwardRef, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { IconButton, InputBase, Paper } from "@mui/material";
import { htmlValidation } from "../../helpers";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";

type FTPSearchBarType = {
  onSearch: (args) => void;
  placeHolder: string;
  clientSideSearch?: boolean;
};

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  p: { sm: "6px", md: "9px", lg: "12px", xl: "15px" },
  margin: "3px",
  border: `1px solid ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.grey[400],
  // "& :hover": {
  //   backgroundColor: theme.palette.grey[400],
  //   color: theme.palette.common.white,
  // },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  flex: 1,
  p: { sm: "8px", md: "10px", lg: "12px", xl: "15px" },
  ml: "5px",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  border: `3px solid ${theme.palette.grey[200]}`,
  backgroundColor: theme.palette.common.white,
  borderRadius: "35px",
  padding: "0px 0px 0px 20px",
  alignSelf: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
    marginBotttom: "20px",
  },
}));

export const FTPSearchBar = observer(
  forwardRef((props: FTPSearchBarType, ref) => {
    const { onSearch, placeHolder, clientSideSearch } = props;
    const queryRef = useRef(null);
    const [query, setQuery] = useState("");

    const onKeyDownHandler = (event) => {
      if (event.key === "Enter") {
        htmlValidation(event);
        onSearch(queryRef.current.value);
      }
    };

    const onChangeHandler = (event) => {
      if (clientSideSearch) {
        setQuery(event.target.value.trim());
        onSearch(event.target.value.trim());
      }
    };

    return (
      <StyledPaper color={"primary"}>
        <StyledInputBase
          placeholder={placeHolder}
          inputProps={{ "aria-label": "search" }}
          inputRef={queryRef}
          onKeyDown={onKeyDownHandler}
          onChange={onChangeHandler}
        />
        <StyledIconButton
          type="button"
          aria-label="search"
          onClick={() => onSearch(queryRef.current.value)}
        >
          <SearchIcon />
        </StyledIconButton>
      </StyledPaper>
    );
  })
);
