import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import _ from "lodash";
import { safe_get } from "../../report-visuals";
import { Chrono } from "react-chrono/dist/react-chrono";
import ProgressBar from "../../components/ProgressBar";
import { useParams } from "react-router-dom";
import { MapModal } from "./map-modal";
import { useStore, htmlValidation } from "../../helpers";
import { useWindowSize } from "react-use";
import { TaskLogs } from "./task-logs";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { SideOverlay } from "../../components";
import dayjs from "dayjs";

const LocationCoverage = (props) => {
  const [t] = useTranslation("task_manager");
  const { data } = props;
  if (!data)
    return <p className="font-bold py-4">{t("location_data_not_found")}</p>;

  return (
    <>
      {Object.keys(data).map((location) => {
        return (
          <div
            key={location}
            className="flex flex-row items-center justify-between py-2"
          >
            <div className="flex flex-row items-center">
              {data[location]["complete"] ? (
                <i className="fa-solid fa-circle-check text-xl text-green-500" />
              ) : (
                <i className="fa-solid fa-circle-exclamation text-xl text-yellow-500" />
              )}
              <p className="px-2">
                {t("location")}: {location}
              </p>
            </div>
            <div className="flex flex-row items-center justify-between">
              <i className="fa-solid fa-diamond-turn-right text-blue-500 text-xl mx-2" />
              <a
                href={`${
                  "http://maps.google.com/maps?saddr=" +
                  "12.9737549,77.5735036" +
                  "&daddr=" +
                  `${data[location]["ref"]["lat"]},${data[location]["ref"]["lng"]}`
                }`}
                className="text-blue-500"
                target="_blank"
              >
                {t("get_directions")}
              </a>
            </div>
          </div>
        );
      })}
    </>
  );
};

const TaskSubHeading = ({ title }) => {
  return (
    <div className="font-bold text-gray-400 sm:text-lg uppercase">{title}</div>
  );
};

export const TaskView = observer((props) => {
  const rootStore = useStore();
  const { taskManagerStore, projectStore, userStore } = rootStore;
  const { isRoot, isClient } = userStore;
  const { active_task, task_view_modal, show_booth_map, loading } =
    taskManagerStore;

  const params = useParams();
  const projectid = parseInt(params.projectid);
  const { width } = useWindowSize();
  const isMobile = width <= 766;

  const [geoJson, setGeoJson] = useState(null);
  const [task, setTask] = useState({});
  const [edit_task_permission, setEdit_task_permission] = useState(false);
  const [show_log, set_show_log] = useState(false);
  const [show_surveys, set_show_surveys] = useState(false);
  const [show_location_coverage_status, setShow_location_coverage_status] =
    useState(false);

  const image_url = safe_get(
    task["user"],
    "profileimageuri",
    "/images/user.png",
  );
  const booth_map_url = safe_get(task["node"], "booth_map", "");
  const booth_info_url = safe_get(task["node"], "booth_info", "");
  const geo_json_url = booth_map_url.replace("gj.html?path=", "");

  const [t] = useTranslation("task_manager");

  useEffect(() => {
    fetch_task_details();
    setEdit_task_permission(projectStore.getPermission("edit_tasks", false));
  }, [active_task]);

  useEffect(() => {
    load_booth_geo_json(geo_json_url);
  }, [geo_json_url]);

  const fetch_task_details = async () => {
    const res = await taskManagerStore.view_task();
    if (res.status === 200) {
      setTask(res.data);
      const ml = safe_get(res.data, "more.marked_locations", []);
      taskManagerStore.set_marked_locations(ml);
    } else {
      toast.error("Something went wrong, please try again.");
    }
  };

  const load_booth_geo_json = async (url) => {
    try {
      if (url !== "") {
        const response = await fetch(url);
        const gj = await response.json();
        setGeoJson(gj);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const survey_logs = () => {
    const items =
      task &&
      task["status"]["surveys"]
        .map((x) => {
          return {
            title: "",
            cardTitle: x["clientid"],
            cardSubtitle: dayjs(x["dates"]["end"]).format(
              "MMMM DD YYYY, h:mm:ss a",
            ),
            cardDetailedText: x["qa"]["qa_status"],
          };
        })
        .reverse();
    return (
      <div className="task-view" style={{ width: "500px", height: "950px" }}>
        <Chrono items={items} mode="VERTICAL" cardHeight={100} />
      </div>
    );
  };

  if (_.isEmpty(task))
    return (
      <p className="font-bold text-gray-400 text-xl p-4">
        {t("something_went_wrong")}
      </p>
    );

  const TaskRender = (innerprops) => {
    const loc_summary = task["status"]["by_loc"]["loc_summary"];
    const loc_coverage = task["status"]["by_loc"]["loc_coverage"];

    return (
      <>
        <div className="flex flex-row no-wrap items-center">
          <div className="text-xl sm:text-2xl font-bold text-indigo-500">
            {task["more"]["name"]}
          </div>
          <div className="ml-auto italic text-gray-400">{task["id"]}</div>
        </div>

        <div className="flex flex-row flex-wrap sm:no-wrap ">
          <div className="flex flex-row items-center mt-2">
            <div className="text-xl sm:text-3xl text-gray-400 mr-2 sm:mr-4 rounded-full flex justify-center border-gray-300 border-2 h-12 w-12 sm:h-16 sm:w-16 shadow-lg items-center bg-white">
              <i className="fa-solid fa-location-pin" />
            </div>
            <div className="flex flex-col">
              <div className="">
                {task["node"]["piv_level1"]}/{task["node"]["piv_level2"]}
              </div>
              <div className="flex flex-row">
                <a
                  className="text-indigo-400 mr-2"
                  target="_blank"
                  href={booth_info_url}
                >
                  {t("details")}
                </a>
                &middot;
                <a
                  className="text-indigo-400 mx-2"
                  target="_blank"
                  href={booth_map_url}
                >
                  {t("map")}
                </a>
              </div>
            </div>
          </div>

          {task["assigned"] ? (
            <div className="flex flex-row items-center mt-2 lg:ml-auto">
              <div className="mr-2 text-md sm:text-3xl sm:mr-4 rounded-full flex justify-center border-gray-400 border-2 h-12 w-12 sm:h-16 sm:w-16 shadow-lg items-center bg-white">
                <img
                  src={image_url}
                  className="rounded-full h-12 w-12 sm:h-16 sm:w-16"
                />
              </div>
              <div className="flex flex-col">
                <div className="sm:text-md">
                  {safe_get(task["user"], "name", "Not Assigned")}
                </div>
                <p>{safe_get(task["user"], "phonenum", "")}</p>
              </div>
            </div>
          ) : (
            <div className="flex flex-row items-center mt-2 lg:ml-auto">
              <div className="text-3xl text-gray-500 mr-4 rounded-full flex justify-center border-gray-400 border-2 h-12 w-12 sm:h-16 sm:w-16 shadow-lg items-center bg-white">
                <i className="fa-solid fa-user-slash" />
              </div>
              <div className="sm:text-md">{t("unassigned")}</div>
            </div>
          )}
        </div>

        <div className="flex flex-row mt-2 sm:mt-4">
          <TaskSubHeading title={t("assign_interview")} />
          <div className="font-bold text-gray-600 sm:text-lg mx-6">
            {task["count"]["count"]}
          </div>
        </div>

        <div>
          <TaskSubHeading title={t("status")} />
          <div className="flex flex-row justify-around">
            <div className="flex flex-col">
              <div>{t("valid")}</div>
              <div className="flex flex-row items-center">
                <div
                  title="Valid"
                  className="cursor-pointer text-xl sm:text-2xl mr-2"
                  style={{ color: "#138D75" }}
                >
                  <i className="fa-solid fa-circle-check" />
                </div>
                <div className="text-center">
                  {safe_get(task, "status.by_qastatus.valid", 0)}
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div>{t("invalid")}</div>
              <div className="flex flex-row items-center">
                <div
                  title="Invalid"
                  className="cursor-pointer text-xl sm:text-2xl mr-2"
                  style={{ color: "#CB4335" }}
                >
                  <i className="fa-solid fa-circle-xmark" />
                </div>
                <div className="text-center">
                  {safe_get(task, "status.by_qastatus.invalid", 0)}
                </div>
              </div>
            </div>

            <div className="flex flex-col">
              <div>{t("not_checked")}</div>
              <div className="flex flex-row items-center">
                <div
                  title="Not Checked"
                  className="cursor-pointer text-xl sm:text-2xl mr-2"
                  style={{ color: "#F1C40F" }}
                >
                  <i className="fa-solid fa-circle-exclamation" />
                </div>
                <div className="text-center">
                  {safe_get(task, "status.by_qastatus.not-checked", 0)}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <TaskSubHeading title={t("progress")} />
          <ProgressBar
            title={t("field_work")}
            color="#2ECC71"
            bgcolor="white"
            progress={task["status"]["progress_field_work"]}
            height={isMobile ? 15 : 20}
          />
          <ProgressBar
            title={t("qc_check")}
            color="#F1C40F"
            bgcolor="white"
            progress={task["status"]["progress_qa"]}
            height={isMobile ? 15 : 20}
          />
          {loc_summary && (
            <ProgressBar
              title={`Location Coverage (Visited ${loc_summary["done"]} of ${loc_summary["ref"]} Locations)`}
              color="#9B59B6"
              bgcolor="white"
              progress={loc_summary["percent"]}
              height={isMobile ? 15 : 20}
            />
          )}
        </div>

        {(edit_task_permission || isRoot || isClient) && (
          <>
            <TaskSubHeading title={t("actions")} />
            <div className="flex flex-row flex-wrap justify-around mt-2">
              <div
                className="flex cursor-pointer text-lg sm:text-2xl h-10 w-10 sm:h-12 sm:w-12 rounded-full items-center justify-center"
                style={{ color: "#000", backgroundColor: "#85C1E9" }}
                onClick={(e) => {
                  htmlValidation(e);
                  taskManagerStore.set_active_task(task);
                  taskManagerStore.set_task_view_modal(false);
                  taskManagerStore.set_task_edit_modal(true);
                }}
                title="Edit Task"
              >
                <i className="fa-solid fa-pencil" />
              </div>
              <div
                className="flex cursor-pointer text-lg sm:text-2xl h-10 w-10 sm:h-12 sm:w-12 rounded-full items-center justify-center"
                style={{ color: "#000", backgroundColor: "#85C1E9" }}
                onClick={(e) => {
                  htmlValidation(e);
                  taskManagerStore.refresh_user_tasks(parseInt(task["id"]));
                  fetch_task_details();
                }}
                title="Sync Task"
              >
                <i className="fa-solid fa-rotate" />
              </div>
              <div
                className="flex cursor-pointer text-lg sm:text-2xl h-10 w-10 sm:h-12 sm:w-12 rounded-full items-center justify-center"
                style={{ color: "#000", backgroundColor: "#85C1E9" }}
                onClick={async (e) => {
                  htmlValidation(e);
                  await taskManagerStore.clone_task(parseInt(task["id"]));
                  taskManagerStore.get_task_list(projectid);
                  taskManagerStore.set_task_view_modal(false);
                  taskManagerStore.set_active_task({});
                }}
                title="Clone Task"
              >
                <i className="fa-solid fa-copy" />
              </div>
              <div
                className="flex cursor-pointer text-lg sm:text-2xl h-10 w-10 sm:h-12 sm:w-12 rounded-full items-center justify-center"
                style={{ color: "#fff", backgroundColor: "#EC7063" }}
                onClick={async (e) => {
                  htmlValidation(e);
                  await taskManagerStore.delete_task(parseInt(task["id"]));
                  taskManagerStore.get_task_list(projectid);
                  taskManagerStore.set_task_view_modal(false);
                  taskManagerStore.set_active_task({});
                }}
                title="Delete Task"
              >
                <i className="fa-solid fa-trash-can" />
              </div>
            </div>
          </>
        )}

        <div
          className="flex flex-row flex-no-wrap items-center cursor-pointer mt-4 sm:mt-6"
          onClick={() => {
            set_show_surveys(!show_surveys);
          }}
        >
          <TaskSubHeading title={t("surveys")} />
          <div className="ml-auto text-xl sm:text-3xl text-gray-600">
            {show_surveys ? (
              <i className="fa-solid fa-angle-up" />
            ) : (
              <i className="fa-solid fa-angle-down" />
            )}
          </div>
        </div>
        {show_surveys && survey_logs()}

        {(isRoot || isClient) && (
          <>
            <div
              className="flex flex-row flex-no-wrap items-center cursor-pointer mt-4 sm:mt-6"
              onClick={(e) => set_show_log(!show_log)}
            >
              <TaskSubHeading title={t("task_logs")} />
              <div className="ml-auto text-xl sm:text-3xl text-gray-600">
                {show_log ? (
                  <i className="fa-solid fa-angle-up" />
                ) : (
                  <i className="fa-solid fa-angle-down" />
                )}
              </div>
            </div>
            {show_log && <TaskLogs task={task} />}
          </>
        )}

        <div
          className="flex flex-row flex-no-wrap items-center cursor-pointer mt-4 sm:mt-6"
          onClick={() => {
            setShow_location_coverage_status(!show_location_coverage_status);
          }}
        >
          <TaskSubHeading title={t("location_status")} />
          <div className="ml-auto text-xl sm:text-3xl text-gray-600">
            {show_location_coverage_status ? (
              <i className="fa-solid fa-angle-up" />
            ) : (
              <i className="fa-solid fa-angle-down" />
            )}
          </div>
        </div>

        {show_location_coverage_status && (
          <LocationCoverage data={loc_coverage} />
        )}

        <button
          className="flex self-end rounded-lg shadow-lg p-2 bg-purple-500 text-white float-right mt-2 sm:mt-4"
          onClick={async (e) => taskManagerStore.update_show_booth_map(true)}
        >
          {t("show_map")}
        </button>

        {geoJson && show_booth_map && (
          <MapModal
            enable_map={show_booth_map}
            geojson={geoJson}
            data={task}
            onClose={() => taskManagerStore.update_show_booth_map(false)}
          />
        )}
      </>
    );
  };

  const close_task_view_modal = () => {
    taskManagerStore.set_task_view_modal(false);
  };

  return (
    <>
      {!loading && (
        <SideOverlay
          children={<TaskRender />}
          show={task_view_modal}
          onClose={close_task_view_modal}
          title={"Task Details"}
        />
      )}
    </>
  );
});
