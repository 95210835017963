
export const LANGUAGE_OPTIONS = [
    {value: "en", label: "English"},
    {value: "kn", label: "Kannada"},
    {value: "hi", label: "Hindi"},
    {value: "te", label: "Telugu"},
    {value: "tl", label: "Tamil"},
    {value: "mr", label: "Marathi"},
    {value: "ml", label: "Malayalam"},
    {value: "or", label: "Odiya"},
    {value: "gu", label: "Gujarathi"},
];

export const REQUIRED_DOCUMENTS = []


export const transformArrayToObject = (options) => {
    return options.reduce((acc, option) => {
        acc[option["language"]] = option["text"];
        return acc;
    }, {});
};
