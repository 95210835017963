import React from "react";
import { observer } from "mobx-react-lite";
import { DeleteModal } from "../../../../components";
import { useDeleteStatus } from "./status-hooks";
import { useStore } from "../../../../helpers";
import { safe_get } from "../../../../report-visuals";
import { useTranslation } from "react-i18next";

export const DeleteStatus = observer(() => {
  const { serviceRequestsStore } = useStore();
  const { activeStatus, modals, setActiveStatus } = serviceRequestsStore;
  const { t } = useTranslation(["status"]);
  const { mutate: deleteStatusMutate } = useDeleteStatus();
  const id = safe_get(activeStatus, "id", null);

  const onDeleteStatus = async () => {
    await deleteStatusMutate(id);
    closeDeleteModal();
  };

  const closeDeleteModal = () => {
    serviceRequestsStore.update_modal_status("deleteStatus", false);
    setActiveStatus({ id: null });
  };

  return (
    <DeleteModal
      onDeleteClick={onDeleteStatus}
      onCancelClick={closeDeleteModal}
      isOpen={modals.deleteStatus}
      id
      desc={t("delete_status_confirm")}
    />
  );
});
