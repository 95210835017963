import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Avatar, Divider, IconButton, Typography } from "@mui/material";
import { useStore } from "../../helpers";
import { toJS } from "mobx";
export const DefaultFamilyMembers = () => {
  return (
    <div className={"flex flex-1 flex-col items-center justify-center"}>
      <img
        src={"/images/service-request/family.svg"}
        className="w-24 h-24"
        alt={"family members"}
      />
      <Typography variant={"caption"}>
        Family members you added will be shown here
      </Typography>
    </div>
  );
};
export const SrRenderFamily = observer((props: any) => {
  const {
    beneficiaryStoreV2,
    serviceRequestsStore: { selectedFamilyMembers },
  } = useStore();
  const { familyMembers } = beneficiaryStoreV2;

  return (
    <>
      {selectedFamilyMembers && selectedFamilyMembers?.length > 0 ? (
        selectedFamilyMembers.map((member) => (
          <FamilyMemberCard key={member} member={member} />
        ))
      ) : (
        <DefaultFamilyMembers />
      )}
    </>
  );
});

interface FamilyMemberCardProps {
  member: any;
}

const FamilyMemberCard = observer((props: FamilyMemberCardProps) => {
  const { member } = props;
  const {
    serviceRequestsStore: {
      selectedFamilyMembers,
      activeFamilyMember,
      setFamilyMembers,
      setActiveFamilyMember,
      update_modal_status,
    },
  } = useStore();
  const { beneficiaryStore } = useStore();

  const handleSelect = () => {
    // console.log("clicked here");
    setActiveFamilyMember(member);
    update_modal_status("addFamilyMember", true);
    beneficiaryStore.setActiveFamilyMember(member);
    beneficiaryStore.update_add_familymember_modal(true);
  };
  const handleDelete = () => {
    setFamilyMembers(
      selectedFamilyMembers.filter((mem) => mem.id !== member.id),
    );
    // if (selectedFamilyMembers.length === 0) {
    //   setActiveFamilyMember({});
    // }
  };
  return (
    <div className={"flexBoxRow"}>
      <div className={"flex flex-row gap-x-4 items-center"}>
        <Avatar />
        <div>
          <Typography>{member?.profile?.name_eng}</Typography>
          <div className={"flex flex-row gap-x-4 items-center"}>
            <Typography>{member?.profile?.age} Years</Typography>
            <Divider orientation="vertical" flexItem />
            {/*<Typography>{member?.profile?.age}</Typography>*/}
          </div>
        </div>
      </div>
      <div className={"flex flex-row gap-x-4"}>
        <IconButton
          size={"small"}
          disableFocusRipple
          onClick={() => handleSelect()}
        >
          <i className={"fas fa-edit"} />
        </IconButton>
        <IconButton
          size={"small"}
          color={"error"}
          disableFocusRipple
          onClick={() => handleDelete()}
        >
          <i className={"fas fa-trash"} />
        </IconButton>
      </div>
    </div>
  );
});
