import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Button, useTheme } from "@mui/material";

export const AddNewButton = ({ text, onButtonClick }) => {
  const theme = useTheme();
  return (
    <Button
      startIcon={<AddCircleOutlineIcon />}
      variant={"contained"}
      size={"large"}
      onClick={onButtonClick}
      sx={{
        "&&": {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.secondary.contrastText,
          fontSize: "12px",
          fontWeight: "500",
          width: "100%",

          padding: "12px",

          "&:hover": {
            backgroundColor: theme.palette.grey[500],
          },
          // For desktop screens
          "@media (min-width: 1024px)": {
            fontSize: "18px",
            padding: "15px 25px",
            width: "auto",
          },
        },
      }}
    >
      {text}
    </Button>
  );
};
