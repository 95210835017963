import React from "react";
import { IconButton, Stack } from "@mui/material";
import { AddNewButton, FeatureHeader } from "../../../components-new";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router";
import { useStore } from "../../../helpers";
import { Button } from "@mui/material";

export const ServiceRequestHeader = observer(() => {
  const navigate = useNavigate();
  const rootStore = useStore();
  const { serviceRequestsStore } = rootStore;
  return (
    <>
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        className="w-full"
      >
        {/* Desktop view: Inline, Mobile view: Stack */}
        <div className="w-full flex flex-col md:flex-row md:justify-between md:items-center">
          <FeatureHeader title={"Service Request"} />
          <a href="http://localhost:3000/project/171/c3/service_request">
            <i className="fa fa-external-link" aria-hidden="true"></i>
            Link to
          </a>
          <div className="flex flex-row-reverse justify-between items-center  md:flex-row md:gap-x-4">
            <IconButton disableFocusRipple onClick={() => navigate("manage")}>
              <i
                className={
                  "fas fa-cog md:text-2xl  text-gray-800 border rounded-lg border-black text-lg px-3 py-2"
                }
              />
            </IconButton>
            <div className="block md:hidden">
              <Button
                startIcon={
                  <i className="fas fa-sliders-h text-gray-800 px-3 py-2 border rounded-lg border-black text-lg"></i>
                }
                size="large"
                onClick={() => {
                  serviceRequestsStore.setServiceFilter(true);
                }}
                sx={{
                  padding: "0px",
                  "& .MuiButton-startIcon": {
                    margin: 0,
                  },
                }}
              ></Button>
            </div>

            <AddNewButton
              text={"Create Service Request"}
              onButtonClick={() => navigate("newrequest")}
            />
          </div>
        </div>
      </Stack>
    </>
  );
});
