import { useStore } from "../../../../../helpers";
import { useRequestTypeMutation } from "../../../sr-hooks";

export const useAddPriorityRequest = () => {
  const rootStore = useStore();

  const addPriorityRequest = async (payload) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-priorities-add",
      payload,
      "Failed to add priority",
    );
  };
  return useRequestTypeMutation(
    addPriorityRequest,
    "Priority Added Successfully.",
  );
};
