import { observer } from "mobx-react-lite";
import React from "react";
import { Rating } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";

export const C3StarRating = observer((props: any) => {
  const { value, onStarClickHandler, isEditable } = props;

  return (
    <>
      <div style={{ fontSize: 24 }}>
        <Rating
          name="size-large"
          value={value}
          size="large"
          readOnly={!isEditable}
          onChange={(event, newValue) => {
            onStarClickHandler(newValue);
          }}
          emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
      </div>
    </>
  );
});
