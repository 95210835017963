import React, { useCallback, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SideOverlay } from "../../../../components";
import { useStore } from "../../../../helpers";
import { useStyles } from "./request-type-styles";

import {
  defaultServiceRequest,
  REQUEST_TYPES,
  serviceRequestValidationSchema,
} from "./request-type-schema";
import {
  FTPAutoComplete,
  FTPFormButton,
  FTPInput,
  FTPRadioGroup,
} from "../../../../components-new";
import {
  useAddServiceRequestType,
  useEditServiceRequestType,
} from "../../sr-hooks";
import { useParams } from "react-router";
import { useWindowSize } from "react-use";
import { DynamicFieldGroup, SRColorPicker } from "../sr-settings-components";
import { safe_get } from "../../../../report-visuals";
import { VortexSpinner } from "../../../../features/digital-repository";
import { useTranslation } from "react-i18next";

export const AddRequestType = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { projectid } = useParams();
  const { height } = useWindowSize();
  const { activeRequestType } = serviceRequestsStore;
  const { t } = useTranslation("sr-request-type");
  const id = safe_get(activeRequestType, "id", null);

  const methods = useForm({
    defaultValues: defaultServiceRequest,
    // @ts-ignore
    resolver: yupResolver(serviceRequestValidationSchema),
  });

  const { mutate, isLoading, isSuccess } = useAddServiceRequestType();
  const {
    mutate: edit,
    isLoading: isEditing,
    isSuccess: editSuccess,
  } = useEditServiceRequestType();

  const categories = useMemo(() => {
    const values = serviceRequestsStore.meta.type.map(
      (val) => val["type"]["category"]
    );
    return Array.from(new Set(values));
  }, [projectid]);

  const sub_categories = useMemo(() => {
    const values = serviceRequestsStore.meta.type.map(
      (val) => val["type"]["subcategory"]
    );
    return Array.from(new Set(values));
  }, [projectid]);

  useEffect(() => {
    if (id) {
      methods.reset(activeRequestType.type);
    } else {
      methods.reset({ ...activeRequestType });
    }
  }, [id]);

  const onSubmit = async (data) => {
    if (!id) {
      const payload = {
        projectid: parseInt(projectid),
        default_assignee: null,
        type: data,
        more: {},
        request_type: data.request_type,
      };
      // @ts-ignore
      mutate(payload);
    } else {
      const payload = {
        ...activeRequestType,
        type: data,
      };
      // @ts-ignore
      edit(payload);
    }
  };

  const onError = (error) => {
    console.log(error);
  };

  const closeModal = useCallback(() => {
    setTimeout(() => {
      serviceRequestsStore.setActiveRequestType({});
      methods.reset({ ...defaultServiceRequest });
    }, 1000);
    serviceRequestsStore.update_modal_status("addRequestType", false);
  }, []);

  useEffect(() => {
    if (isSuccess || editSuccess) {
      closeModal();
    }
  }, [editSuccess, isSuccess]);

  return (
    <SideOverlay
      onClose={closeModal}
      show={serviceRequestsStore.modals.addRequestType}
      title={t("create_service")}
      size={"large"}
    >
      <FormProvider {...methods}>
        <form
          className="flex flex-col bg-white rounded-xl p-4 gap-y-2 mb-12 "
          onSubmit={methods.handleSubmit(onSubmit, onError)}
        >
          <FTPRadioGroup
            name={"request_type"}
            label={"Request Type"}
            options={REQUEST_TYPES}
          />
          <div className={"flex flex-col gap-y-4"}>
            <div
              className={"grid grid-cols-1 sm:grid-cols-2 gap-4 justify-center"}
            >
              <FTPInput label={t("name")} name={"name"} />
              <SRColorPicker name="color" defaultValue="#F4D1F4" />
            </div>

            <FTPAutoComplete
              name={"category"}
              label={t("category")}
              data={categories}
              placeholder="Select Category"
              addNewOption={true}
            />
            <FTPAutoComplete
              name={"subcategory"}
              label={t("sub_category")}
              data={sub_categories}
              placeholder="Select Sub-Category"
              addNewOption={true}
            />
            <DynamicFieldGroup label={t("display")} name="display" />
            <DynamicFieldGroup label={t("description")} name="description" />

            <FTPAutoComplete
              name={"tags"}
              label={t("tags")}
              data={["Aadhar Card", "Voter Card", "Ration Card", "Passport"]}
              multiple={true}
              placeholder={"Select Tags"}
            />
            <FTPAutoComplete
              name={"required_docs"}
              label={t("required_documents")}
              data={["Aadhar Card", "Voter Card", "Ration Card", "Passport"]}
              multiple={true}
              placeholder={"Select Required Documents"}
            />
          </div>
          <div className="bg-background-default w-full pr-8 py-2 fixed bottom-2 right-0 flex gap-x-2 justify-end">
            <VortexSpinner visible={isLoading || isEditing} />
            <FTPFormButton
              actionType={"Cancel"}
              handleButtonClick={closeModal}
            />
            <FTPFormButton
              type={"submit"}
              actionType={"Save"}
              handleButtonClick={() => {}}
            />
          </div>
        </form>
      </FormProvider>
    </SideOverlay>
  );
});
