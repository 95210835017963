import { useParams } from "react-router-dom";
import { FTPBreadCrumbs } from "../../../components-new/BreadCrumbs";
import React from "react";

export const SRCrumbs = (props) => {
  const { projectid } = useParams();
  const crumbs = [
    { name: "DashBoard", href: `/project/${projectid}`, current: true },
    { name: "Service Requests", href: "#", current: true },
  ];
  return <FTPBreadCrumbs crumbs={crumbs} />;
};
