import React, { forwardRef, useRef } from "react";
import { observer } from "mobx-react-lite";
import { IconButton, InputBase, Paper } from "@mui/material";
import { htmlValidation } from "../../helpers";
import SearchIcon from "@mui/icons-material/Search";

export const RNSearchBar = observer(
  forwardRef((props: any, ref) => {
    const queryRef = useRef(null);

    return (
      <>
        <Paper
          component="form"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: 400,
          }}
          color={"primary"}
        >
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            placeholder="Search by Name"
            inputProps={{ "aria-label": "search" }}
            inputRef={queryRef}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                htmlValidation(event);
                props.onSearch(queryRef.current.value);
              }
            }}
          />
          <IconButton
            type="button"
            sx={{ p: "10px" }}
            aria-label="search"
            onClick={() => props.onSearch(queryRef.current.value)}
          >
            <SearchIcon />
          </IconButton>
        </Paper>
      </>
    );
  }),
);
