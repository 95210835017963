import {observer} from "mobx-react-lite";
import {useParams} from "react-router-dom";
import React, {useState} from "react";
import {useWindowSize} from 'react-use';
import 'react-dropdown-tree-select/dist/styles.css'
import {classNames} from "../../helpers";
import {ChatBubbleBottomCenterTextIcon, ChartBarSquareIcon} from "@heroicons/react/24/solid";
import {SurveyPlanning} from "./survey-planning";
import {SurveyProgress} from "./survey-progress";
import {PaymentQCStatus} from "./qc-status";
import {useTranslation} from "react-i18next";
import {FTPBreadCrumbs} from "../../components-new/BreadCrumbs";

export const FieldPayment = observer((props: any) => {
        const [t] = useTranslation("field_payment")
        const tabs = [
            {name: t("planning"), icon: ChatBubbleBottomCenterTextIcon},
            {name: t("progress"), icon: ChatBubbleBottomCenterTextIcon},
            {name: t("qc"), icon: ChartBarSquareIcon},
        ];
        const [activeTabName, setActiveTabName] = useState("Planning");
        const [activeTab, setActiveTab] = useState(tabs[0]);
        const {projectid} = useParams();
        const {width} = useWindowSize();
        const isMobile = width <= 766;

        const crumbs = [
            {name: "DashBoard", href: `/project/${projectid}`, current: true},
            {name: 'Field Payments', href: '/project/' + projectid + '/fieldpayment', current: false},
        ];

        const TabView = () => {
            return (
                <div className="pl-5">
                    <div className="sm:hidden">
                        <label htmlFor="tabs" className="sr-only">
                            {t("select_a_tab")}
                        </label>
                        <select
                            id="tabs"
                            name="tabs"
                            className="p-4 block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-lg"
                            defaultValue={activeTab.name}
                            value={activeTabName}
                            onChange={(eve) => setActiveTabName(eve.target.value)}
                        >
                            {tabs.map((tab) => (
                                <option key={tab.name}>{tab.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="hidden sm:block">
                        <div className="border-b-2 border-gray-400">
                            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                                {tabs.map((tab) => {
                                    const isThisActiveTab = activeTabName === tab.name;
                                    return (
                                        <a
                                            key={tab.name}
                                            className={classNames(
                                                isThisActiveTab
                                                    ? "border-indigo-500 text-indigo-600"
                                                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                                                "group inline-flex items-center py-2 px-1 border-b-2 cursor-pointer"
                                            )}
                                            aria-current={isThisActiveTab ? "page" : undefined}
                                            onClick={(e) => setActiveTabName(tab.name)}
                                        >
                                            <tab.icon
                                                className={classNames(
                                                    isThisActiveTab
                                                        ? "text-indigo-500"
                                                        : "text-gray-400 group-hover:text-gray-500",
                                                    "-ml-0.5 mr-2 h-8 w-8"
                                                )}
                                                aria-hidden="true"
                                            />
                                            <span className="text-lg font-medium">{tab.name}</span>
                                        </a>
                                    );
                                })}
                            </nav>
                        </div>
                    </div>
                </div>
            );
        };


        return (
            <>
                <div className=''>
                    <FTPBreadCrumbs crumbs={crumbs}/>
                    <TabView/>
                    <div className='py-2'/>
                    {activeTabName === "Planning" ? <SurveyPlanning/> : null}
                    {activeTabName === "Progress" ? <SurveyProgress/> : null}
                    {activeTabName === "QC Status" ? <PaymentQCStatus/> : null}

                </div>
            </>
        )
    }
)