import React, { useState } from "react";
import { observer } from "mobx-react-lite";
import { C3BoothFilter, C3ConcernedPerson, DateFilter } from "./filters";
import { Button } from "@mui/material";
import { FormButtons, SideOverlay } from "../../components";
import { useLocation } from "react-router-dom";
import { useStore } from "../../helpers";
import dayjs from "dayjs";
import { RepresentativeFilter } from "../../components/cm-filters/representative-filter";
import { safe_get } from "../../report-visuals";

import { ServiceRequestFilter } from "./serviceRequestFilter";

type c3FilterType = {
  piv_level_2: Array<string>;
  start_date: string;
  end_date: string;
  office_persons: Array<string>;
  rep?: string[];
};

const defaultC3Filters: c3FilterType = {
  piv_level_2: [],
  start_date: "",
  end_date: "",
  office_persons: [],
  rep: [],
};

type servicerequestFilter = {
  key?: string;
  request_type?: Array<string>;
  priority?: Array<string>;
  location?: Array<string>;
  status?: Array<string>;
  assigned?: Array<string>;
};

const defaultserviceFilters: servicerequestFilter = {
  key: "",
  request_type: [],
  priority: [],
  location: [],
  status: [],
  assigned: [],
};

export const C3Filters = observer((props: any) => {
  const { c3Store } = useStore();
  const { pathname } = useLocation();
  const rootStore = useStore();
  const { projectStore, beneficiaryStore } = rootStore;
  const [loading, setLoading] = useState(false);

  const [beneficiarySelectedFilters, setBeneficiarySelectedFilters] = useState(
    beneficiaryStore.filters
  );

  const beneficiery_property = projectStore.getProjectProperty(
    "beneficiary",
    {}
  );
  const representativeOptions = safe_get(
    beneficiery_property,
    "more.representatives",
    []
  );

  const [c3Filters, setC3Filters] = useState<c3FilterType>(defaultC3Filters);
  const [serviceFilter, setServiceFilter] = useState<servicerequestFilter>(
    c3Store.servicerequestFilter
  );

  const showDateSlider =
    pathname.includes("beneficiaries") || pathname.includes("grievance");

  const showservicereq = pathname.includes("service_request");

  const onBoothSelection = (booths: []) => {
    setC3Filters({ ...c3Filters, piv_level_2: booths });
  };

  const onPersonSelection = (booths: []) => {
    setC3Filters({ ...c3Filters, office_persons: booths });
  };

  const onDateSelection = (
    start_date,
    end_date = dayjs().format("YYYY-MM-DD")
  ) => {
    setC3Filters({ ...c3Filters, start_date: start_date, end_date: end_date });
  };
  // servicefilter

  const [selectedValues, setSelectedValues] = useState([]);

  const filter_save_handler = () => {
    setLoading(true);
    c3Store.update_selected_booths(c3Filters.piv_level_2);
    c3Store.update_date_filters(c3Filters.start_date, c3Filters.end_date);
    c3Store.update_selected_office_persons(c3Filters.office_persons);
    c3Store.update_rep(c3Filters.rep);

    // Add piv_level_2 to serviceFilter before saving
    c3Store.setServicerequestFilter({
      ...serviceFilter,
      piv_level_2: c3Filters.piv_level_2,
    });

    c3Store.update_c3FilterModal(false);
    setLoading(false);
  };

  const filter_cancel_handler = () => {
    c3Store.update_c3FilterModal(false);

    c3Store.setServicerequestFilter({
      ...defaultserviceFilters,
      piv_level_2: [],
    }); //global

    setServiceFilter({
      ...defaultserviceFilters,
    }); //local

    setC3Filters({ ...defaultC3Filters });
    c3Store.update_selected_booths([]);

    setSelectedValues([]);
  };
  return (
    <>
      <SideOverlay
        title="Select Filters"
        onClose={filter_cancel_handler}
        show={c3Store.c3FiltersModal}
      >
        <>
          {showservicereq && (
            <>
              <ServiceRequestFilter />
            </>
          )}

          {!showservicereq && (
            <>
              <div className="bg-gray-100 rounded-lg flex flex-col flex-1">
                <div className="custom-dropdown flex flex-col flex-1">
                  <p className="text-blue-600 font-bold text-xl">
                    Booth Filters
                  </p>
                  <C3BoothFilter onBoothSelection={onBoothSelection} />
                </div>

                <div className="custom-dropdown flex flex-col flex-1">
                  <p className="text-blue-600 font-bold text-xl px-2 py-4">
                    Concerned Office Person
                  </p>
                  <C3ConcernedPerson onPersonSelection={onPersonSelection} />
                </div>

                <div>
                  {representativeOptions.length > 0 && (
                    <div className="custom-dropdown flex flex-col flex-1">
                      <p className="text-blue-600 font-bold text-xl px-2 py-4">
                        Representative Type
                      </p>
                      <RepresentativeFilter
                        onPersonSelection={(val) =>
                          setBeneficiarySelectedFilters({
                            ...beneficiarySelectedFilters,
                            rep: val,
                          })
                        }
                      />
                    </div>
                  )}
                </div>
                {showDateSlider && (
                  <div className="flex flex-col flex-1">
                    <p className="text-blue-600 font-bold text-xl px-2 py-4">
                      Date
                    </p>
                    <div className={"flex flex-col px-2"}>
                      <DateFilter
                        onDateChange={onDateSelection}
                        startDate={c3Filters.start_date}
                        endDate={c3Filters.end_date}
                      />
                    </div>
                  </div>
                )}
              </div>
              <FormButtons
                onSave={filter_save_handler}
                onCancel={filter_cancel_handler}
              />
            </>
          )}
        </>
      </SideOverlay>
    </>
  );
});
