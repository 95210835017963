import { Typography } from '@mui/material';
import React from 'react';

const ProfileGlance = () => {
  return (
    <div className="bg-white p-10">
      <Typography variant="h1" sx={{ textAlign: 'center' }}>
        Profile at a Glance
      </Typography>
      <div className=''>
      <img 
        src="/images/benefic.jpg" 
        alt="FB Profile" 
        className="w-[60%] h-[50%] mx-auto object-cover rounded-3xl mt-6 border-[3px] border-[#FFFA00]"
        style={{
          boxShadow: `
            0px 5px 11px 0px #2527284A,
            0px 20px 20px 0px #25272842,
            0px 44px 27px 0px #25272826,
            0px 79px 31px 0px #2527280A,
            0px 123px 34px 0px #25272803
          `
        }}
      />
      </div>
    </div>
  );
};

export default ProfileGlance;
