import React from "react";
import { observer } from "mobx-react-lite";
import { DeleteModal } from "../../components";
import { useStore } from "../../helpers";
import { useDeleteServiceRequestSubject } from "./sr-hooks";
import { toJS } from "mobx";

export const ServiceRequestDelete = observer((props: any) => {
  const { serviceRequestsStore } = useStore();
  const { mutate } = useDeleteServiceRequestSubject();
  const closeModal = () => {
    serviceRequestsStore.update_modal_status("deleteServiceRequest", false);
  };
  return (
    <div>
      <DeleteModal
        isOpen={serviceRequestsStore.modals.deleteServiceRequest}
        onDeleteClick={() => {
          if (serviceRequestsStore.activeServiceRequest.id) {
            console.log(toJS(serviceRequestsStore.activeServiceRequest.id));
            console.log(toJS(serviceRequestsStore.activeServiceRequest));
            // @ts-ignore
            mutate({ id: serviceRequestsStore.activeServiceRequest.id });
            closeModal();
          }
        }}
        desc={"Are you sure you want to delete this service request?"}
        onCancelClick={closeModal}
      />
    </div>
  );
});
