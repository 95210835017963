import * as yup from "yup";

export type priorityType = {
  name: string;
  type: string;
  default: boolean;
  display: [
    {
      language: string;
      text: string;
    },
  ];
};

export const defaultPriorityRequest: priorityType = {
  name: "",
  type: "",
  default: false,
  display: [{ language: "", text: "" }],
};

export const priorityValidationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  type: yup.string().required("Type is required"),
  // display: yup.object().required("Display object is required"),
  display: yup.array().of(
    yup.object().shape({
      language: yup.string().required("Language is required"),
      text: yup.string().required("Display text is required"),
    })
  ),
  default: yup.boolean(),
});
