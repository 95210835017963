import React from "react";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Button,
} from "@headlessui/react";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";

const getOverlayWidth = (size) => {
  var overlayWidth;

  switch (size) {
    case "small":
      overlayWidth = "xl";
      break;
    case "medium":
      overlayWidth = "2xl";
      break;
    case "large":
      overlayWidth = "3xl";
      break;
    default:
      overlayWidth = size;
  }

  return overlayWidth;
};

type SideOverlayProps = {
  children: React.ReactNode;
  onClose: Function;
  show: boolean;
  title?: string;
  size?: "small" | "medium" | "large" | "5xl" | "7xl";
  buttontitle?: string;
};

export const SideOverlay = (props: SideOverlayProps) => {
  const {
    children,
    onClose,
    show,
    title,

    buttontitle = "",
    size = "small",
  } = props;
  return (
    <>
      <Dialog open={show} onClose={() => {}} className="relative z-[999]">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity duration-500 ease-in-out data-[closed]:opacity-0"
        />
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-lg sm:max-w-full pl-10">
              <DialogPanel
                transition
                className={`pointer-events-auto w-screen max-w-${getOverlayWidth(
                  size,
                )} transform transition duration-500 ease-in-out data-[closed]:translate-x-full sm:duration-700 m-0 sm:ml-6 sm:my-6 sm:mr-12`}
              >
                <div className="flex h-full flex-col bg-gray-100 py-6 shadow-xl rounded-xl">
                  <div className="px-4 sm:px-6 ">
                    <div className="flex justify-between items-center">
                      <DialogTitle className="text-3xl font-normal text-[#1B1B1D] tracking-normal">
                        {title}
                      </DialogTitle>

                      <div className="flex items-center space-x-3.5">
                        {/* conditional when button is present in side overlay */}
                        {/*{buttontitle !== "" && (*/}
                        {/*  <div className="">*/}
                        {/*    <Button className="text-black rounded-[30px] bg-background-default py-2 px-4 hover:bg-black  hover:text-white border-2 border-black">*/}
                        {/*      <span className="text-lg font-semibold ">*/}
                        {/*        {title === "Filter VoterSearch"*/}
                        {/*          ? "Apply Filters"*/}
                        {/*          : buttontitle}*/}
                        {/*      </span>*/}
                        {/*    </Button>*/}
                        {/*  </div>*/}
                        {/*)}*/}
                        <IconButton
                          onClick={() => onClose()}
                          sx={{
                            border: "1px #F0F0F0",
                          }}
                          size={"large"}
                        >
                          <CloseIcon className="inline-block" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                  <div className="relative mt-3 flex-1 px-4 sm:px-6 overflow-y-auto overflow-x-hidden">
                    {children}
                  </div>
                </div>
              </DialogPanel>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
