import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { useStore, classNames } from "../../../helpers";
import dayjs from "dayjs";
import { Button, Popover, Slider, Typography } from "@mui/material";

const valuetext = (value) => {
  return value;
};

const MARKS = [
  { value: 0, label: "1W" },
  { value: 10, label: "2W" },
  { value: 20, label: "1M" },
  { value: 30, label: "3M" },
  { value: 40, label: "6M" },
  { value: 50, label: "1Y" },
  { value: 60, label: "Custom" },
  { value: -1, label: "today" },
];

type DateSliderProps = {
  onDateChange: (start_date, end_date?) => void;
  startDate: string;
  endDate: string;
};

export const DateFilter = observer((props: DateSliderProps) => {
  const { onDateChange, startDate, endDate } = props;
  const rootStore = useStore();
  const { c3Store } = rootStore;
  const { date_filetrs } = c3Store;
  const { start_date, end_date } = date_filetrs;
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [sliderValue, setSliderValue] = useState(start_date === "" ? 0 : null);
  const [label, setLabel] = useState("");

  const [customDate, setCustomDate] = useState({
    isCustom: false,
    start_date,
    end_date,
  });

  const open = () => {
    return Boolean(anchorEl);
  };

  const labelChangeHandler = (value) => {
    setSliderValue(value);
    if (value === 60) {
      setCustomDate({ ...customDate, isCustom: true });
    } else {
      setCustomDate({ ...customDate, isCustom: false });
    }

    switch (value) {
      // @ts-ignore
      case -1:
        setLabel("Today");
      case 0:
        onDateChange(transform_date("days", 7));
        setLabel("1 Week");
        break;
      case 10:
        onDateChange(transform_date("weeks", 2));
        setLabel("2 Weeks");
        break;
      case 20:
        onDateChange(transform_date("month", 1));
        setLabel("1 Month");
        break;
      case 30:
        onDateChange(transform_date("months", 3));
        setLabel("3 Months");
        break;
      case 40:
        onDateChange(transform_date("months", 6));
        setLabel("6 Months");
        break;
      case 50:
        onDateChange(transform_date("year", 1));
        setLabel("1 Year");
        break;
      case 60:
        setLabel("Custom");
        break;
      default:
        onDateChange(transform_date("days", 7));
    }
  };

  const transform_date = (key, amount) => {
    return dayjs().subtract(amount, key).format("YYYY-MM-DD");
  };

  const clickHandler = () => {
    onDateChange(customDate.start_date, customDate.end_date);
    setAnchorEl(null);
  };

  const dateselect = () => {
    c3Store.update_date_filters("", "");
    setCustomDate({
      isCustom: false,
      start_date: "",
      end_date: "",
    });
    setSliderValue(0);
    setLabel("");
    onDateChange("", "");
    setAnchorEl(null);
  };

  return (
    <>
      <Typography color={"primary"}>
        Select a date range to view data from a specific time period.
      </Typography>

      <>
        <div className=" flex flex-col  overflow-hidden px-6 mt-2 w-full">
          <div>
            <Slider
              min={0}
              max={60}
              value={sliderValue}
              marks={MARKS}
              track={false}
              step={null}
              // @ts-ignore
              getAriaValueText={valuetext}
              onChangeCommitted={(e, value) => {
                labelChangeHandler(value);
                if (value !== 60) {
                  setAnchorEl(null);
                }
              }}
            />
          </div>
          <div
            className="flex flex-row flex-wrap cursor-pointer items-center font-bold"
            onClick={(event) => {
              // @ts-ignore
              setAnchorEl(event.currentTarget);
            }}
          >
            {label && (
              <>
                <div className="flex justify-between items-center w-full">
                  <Typography variant={"h5"} className="font-bold">
                    {label}
                  </Typography>

                  <Typography
                    variant={"h5"}
                    color={"primary"}
                    className=" px-2"
                  >
                    {startDate} to {endDate}
                  </Typography>
                  <Button
                    variant={"text"}
                    color={"secondary"}
                    onClick={dateselect}
                  >
                    Reset
                  </Button>
                </div>
              </>
            )}
          </div>
          {customDate.isCustom ? (
            <div className="flex flex-col flex-wrap mt-5">
              <div className="flex flex-col">
                <label className="font-bold" htmlFor="From">
                  From:
                </label>
                <input
                  type="date"
                  id="From"
                  name="From"
                  value={dayjs(customDate.start_date).format("YYYY-MM-DD")}
                  className="border border-gray-300 p-2"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setCustomDate({
                      ...customDate,
                      start_date: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="flex flex-col mt-2">
                <label className="font-bold" htmlFor="From">
                  To:
                </label>
                <input
                  type="date"
                  id="From"
                  name="From"
                  value={dayjs(customDate.end_date).format("YYYY-MM-DD")}
                  className="border border-gray-300 p-2"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setCustomDate({
                      ...customDate,
                      end_date: e.target.value,
                    });
                  }}
                />
              </div>

              <div className="flex flex-row justify-end mt-4">
                <button
                  className="bg-orange-500 hover:bg-orange-700 text-white font-bold p-2 mr-2 rounded"
                  onClick={dateselect}
                >
                  Cancel
                </button>
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold p-2 rounded"
                  onClick={clickHandler}
                >
                  Apply
                </button>
              </div>
            </div>
          ) : null}
        </div>

        {/*<Popover*/}
        {/*  id="datefilter-popover"*/}
        {/*  open={open()}*/}
        {/*  anchorEl={anchorEl}*/}
        {/*  onClose={() => {*/}
        {/*    setAnchorEl(null);*/}
        {/*  }}*/}
        {/*  anchorOrigin={{*/}
        {/*    vertical: "bottom",*/}
        {/*    horizontal: "center",*/}
        {/*  }}*/}
        {/*  transformOrigin={{*/}
        {/*    vertical: "top",*/}
        {/*    horizontal: "center",*/}
        {/*  }}*/}
        {/*></Popover>*/}
      </>
    </>
  );
});
