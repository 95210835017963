import { useStore } from "../../../../../helpers";
import { useRequestTypeMutation } from "../../../sr-hooks";
import { useTranslation } from "react-i18next";

export const useAddLocation = () => {
  const rootStore = useStore();
  const { t } = useTranslation(["location"]);

  const onAddLocation = async (payload: any) => {
    return rootStore.apiRequestHandler(
      "unified_service_request/sr-locations-add",
      payload,
      t("add_location_failed"),
    );
  };

  return useRequestTypeMutation(onAddLocation, t("add_location_success"));
};
