import React, { useEffect, useState } from "react";
import { NotionRenderer } from "react-notion-x";
import { observer } from "mobx-react-lite";
import { Collection } from "react-notion-x/build/third-party/collection";
import { useStore } from "../helpers";

export const NotionPage = observer((props) => {
  const rootStore = useStore();

  const [loading, set_loading] = useState(false);
  const [error, set_error] = useState(false);
  const [page_data, set_page_data] = useState(null);

  const fetch_page = async () => {
    const pageId = "1a75e8e97a3f8052a306e489e1d9d3fb";
    set_loading(true);
    const response = await rootStore.api.post(
      "notion_webhook/notion_page_data",
      {
        page_id: pageId,
      },
    );
    const recordMap = response.data;
    set_page_data(recordMap);
    console.log(recordMap);
    set_loading(false);
  };

  useEffect(() => {
    //fetch_page();
  }, []);

  if (loading) {
    return <div>Loading ...</div>;
  }

  return (
    <div className={"flex flex-col gap-4 px-4 pb-4"}>
      <iframe
        className="w-full h-full border-0"
        src="http://localhost:8000/1a75e8e97a3f807484cccdb53ef440da"
      ></iframe>
    </div>
  );
});
