import { observer } from "mobx-react-lite";
import React from "react";

type CardContent = {
  layout: any;
};

export const CardContent = (props) => {
  const { layout } = props;

  return <>{props.children}</>;
};

export const CardGraph = () => {
  return (
    <>
      {/* Pie Chart */}
      <div className="flex justify-center items-center mb-[60px]">
        <div className="relative w-[250px] h-[250px] mt-[32px]">
          {/* Outer Circle */}
          <div className="absolute top-0 left-0 w-full h-full rounded-full bg-blue-300 cardgraphshadow" />
          {/* Segment: In Progress */}
          <div
            className="absolute top-0 left-0 w-full h-full rounded-full bg-blue-500 clip-pie"
            style={{ clipPath: "polygon(50% 50%, 100% 0, 100% 100%)" }}
          />
          {/* Segment: Completed */}
          <div
            className="absolute top-0 left-0 w-full h-full rounded-full bg-blue-700 clip-pie"
            style={{ clipPath: "polygon(50% 50%, 50% 0, 100% 0)" }}
          />
        </div>
      </div>
      <CardStatus />
    </>
  );
};

export const CardCount = () => {
  const cardcontent = [
    { name: "Teams", count: 3 },
    { name: "Field workers", count: 7 },
    {
      name: "Party workers",
      count: 12,
    },
  ];

  return (
    <>
      <div className="flex mt-4">
        {cardcontent.map((item) => {
          return (
            <>
              <div className="flex flex-1 flex-col">
                <span className="text-[14px] font-sans font-medium leading-[17px]">
                  {item.name}
                </span>
                <span className="font-normal text-[32px] leading-[39px] text-[#B6B6B6]">
                  {item.count}
                </span>
              </div>
            </>
          );
        })}
      </div>
    </>
  );
};

export const CardStatus = observer((props: any) => {
  return (
    <>
      <div className="flex justify-around items-center text-sm">
        <div className="flex items-center space-x-1">
          <div className="w-4 h-4 bg-[#0D74CE] rounded-[10px]" />
          <span className="text-gray-700 text-[12px] font-medium">
            Not Started
          </span>
        </div>
        <div className="flex items-center space-x-1">
          <div className="w-4 h-4 bg-[#0090FF] rounded-[10px]" />
          <span className="text-gray-700 text-[12px] font-medium">
            In Progress
          </span>
        </div>
        <div className="flex items-center space-x-1">
          <div className="w-4 h-4 bg-[#8EC8F6] rounded-[10px]" />
          <span className="text-gray-700 text-[12px] font-medium">
            Completed
          </span>
        </div>
      </div>
    </>
  );
});
