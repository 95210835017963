import { Stack, Typography } from "@mui/material";
import React from "react";
import { useWindowSize } from "react-use";

const SocialMediaAnalysis = () => {
  const { width, height } = useWindowSize();
  const aspect = width / height;

  const adjustedHeight = Math.ceil(width / aspect);
  return (
    <div className="relative flex items-center justify-center h-screen">
      <Typography
        variant="h1"
        sx={{
          fontSize: { sm: "3rem", md: "4rem", lg: "5rem", xl: "8rem" },
          fontFamily: "WorkSansExtraBold",
        }}
        className="text-white text-9xl uppercase text-center boldest z-10 relative"
      >
        Social Media
        <br />
        Analysis
      </Typography>
      <Stack
        className="absolute bottom-1/2 right-[18%] top-[16%] z-0"
        sx={{
          width: { sm: 200, md: 250, lg: 320, xl: 392 },
          height: { sm: 200, md: 220, lg: 230, xl: 247 },
        }}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 430 277"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M420.695 70.8816C421.865 74.8551 419.592 79.0246 415.618 80.1945L350.867 99.2589C346.893 100.429 342.724 98.156 341.554 94.1825C340.384 90.2091 342.657 86.0395 346.63 84.8696L393.229 71.1497C259.05 8.44156 97.6083 53.8111 16.3204 179.885L13.3035 184.564L0.696777 176.436L3.71366 171.757C89.4551 38.7755 260.106 -8.71362 401.387 58.4114L387.241 10.3663C386.071 6.39282 388.344 2.22329 392.317 1.05341C396.291 -0.116481 400.461 2.15629 401.63 6.12978L420.695 70.8816Z"
            fill="#FFFA00"
          />
        </svg>
      </Stack>
      <Stack
        className="absolute top-1/2 left-[8%] bottom-[36%] z-0"
        sx={{
          width: { sm: 200, md: 250, lg: 320, xl: 392 },
          height: { sm: 200, md: 220, lg: 230, xl: 247 },
        }}
      >
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 430 277"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            d="M338.119 209.102L386.755 193.844L391.245 208.156L342.609 223.414C306.619 234.705 269.508 242.049 231.931 245.317C199.38 248.148 166.598 244.069 135.733 233.348L133.765 232.665C66.9849 209.468 28.0937 140.005 43.2243 70.9483L53.5773 23.6964L11.9851 50.3383C8.49713 52.5725 3.85843 51.5561 1.62424 48.0682C-0.609951 44.5803 0.40641 39.9416 3.89434 37.7074L60.7335 1.29913C64.2214 -0.935059 68.8601 0.0812977 71.0943 3.56923L107.503 60.4084C109.737 63.8963 108.72 68.535 105.232 70.7692C101.745 73.0034 97.1058 71.987 94.8716 68.4991L68.2298 26.9068L57.8767 74.1587C44.3722 135.793 79.0837 197.792 138.687 218.495L140.654 219.178C169.523 229.206 200.185 233.021 230.631 230.373C267.126 227.2 303.167 220.068 338.119 209.102Z"
            fill="#FFFA00"
          />
        </svg>
      </Stack>
    </div>
  );
};

export default SocialMediaAnalysis;
