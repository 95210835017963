import React from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../helpers";
import { toJS } from "mobx";
import { useTranslation } from "react-i18next";
import { Table, TableHeader } from "./survey-summary";
import Masonry from "@mui/lab/Masonry";

const header = (index) => {
  switch (index) {
    case 0:
      return "Option";
    case 1:
      return "Voter";
    case 2:
      return "Voter%";
  }
};

export const AnalyticalSummary = observer((props) => {
  const { surveyResponseStore } = useStore();
  const { analytical_meta, analytical_data, new_filters } = surveyResponseStore;
  const filter_keys = Object.keys(new_filters);
  const { t } = useTranslation("survey_management");

  let columns_def = [];

  if (!toJS(analytical_data)) return null;

  return (
    <>
      <div className="block text-lg text-gray-800 font-bold uppercase mt-4">
        {t("analytical_summary")}
      </div>

      <Masonry columns={{ sm: 1, md: 2, lg: 3, xl: 4 }} spacing={2}>
        {analytical_meta.map((response) => {
          const { key, title } = response;
          const table_data = analytical_data[key];
          try {
            columns_def = Object.keys(table_data[0]).map((x, index) => {
              if (index == 2) {
                return {
                  Header: header(index),
                  accessor: x,
                };
              } else {
                return {
                  Header: header(index),
                  accessor: x,
                  Footer:
                    index === 0
                      ? "Total"
                      : surveyResponseStore.get_total_surveys(table_data, x),
                };
              }
            });
          } catch (e) {}

          return (
            <div key={key} className="w-100 overflow-x-scroll">
              <TableHeader title={title} />
              <Table
                data={table_data}
                columns={columns_def}
                onRowClick={(x) => {
                  surveyResponseStore.update_new_filters(
                    key,
                    x["key"],
                    `${title}: ${x["key"]}`
                  );
                }}
              />
            </div>
          );
        })}
      </Masonry>
    </>
  );
});
