import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export const FilterButton = ({ filterOnClickHandler, ...props }) => {
  const { t } = useTranslation("survey_management");
  // const { title, icon } = props;
  return (
    <>
      <div className="hidden md:block">
        <Button
          variant={"contained"}
          endIcon={<i className="fas fa-filter" />}
          onClick={filterOnClickHandler}
          {...props}
          sx={{ backgroundColor: "orange" }}
          className={"self-start"}
        >
          {t("filters")}
        </Button>
      </div>

      <div className="block md:hidden">
        <Button
          endIcon={
            <i className="fas fa-sliders-h text-gray-800 px-3 py-2 border rounded-lg border-black"></i>
          }
          onClick={filterOnClickHandler}
          {...props}
        ></Button>
      </div>
    </>
  );
};
